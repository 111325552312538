<style lang="scss">
    .GameFormalNum1 {

    }
</style>
<template>
    <div class="GameFormalPlay GameFormalNum1">
        <ul v-if="params" class="box" :class="{'near': template.sub[0].length == 5 ,'compact': template.sub[0].length > 5}">
            <li v-for="(row,unit) in template.sub[0]" class="row l-flex l-flex-1">
                <div class="title">
                    <p>{{ row }}</p>
                </div>

                {{/* 选球组 */}}
                <div class="balls">
                    <li v-for="(item,index) in MakeBallArray()" class="ball" :class="{'active': params[unit] && params[unit][index]}" @click="Pick(unit,index)">
                        <i v-if="CheckRank(row,item)" class="rank-number" :class="{'active': CheckRank(row,item).active}">{{ CheckRank(row,item).number }}</i>
                        <span>{{ item }}</span>
                    </li>
                </div>

                {{/* 工具组 */}}
                <div class="tools">
                    <li v-for="(item,index) in ['全','大','小','单','双','清']" class="ball" @click="Tools(unit,item)">
                        <span>{{ item }}</span>
                    </li>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import Common from './num1.js'
export default {
    mixins: [Common],
    components: {

    }
}
</script>
