<style lang="scss">
    .GameCreditSSC {

    }
</style>
<template>
    <div v-if="List" v-loading="!List.length" class="GameCreditSSC">

        <Category title="整合">
            <el-row tag="div">
                <el-col v-for="unit in [0,1,2,3,4,5]" :key="unit" :span="4">
                    <Group :list="List[unit]" :prompt="prompt" :prompt-interval="promptInterval" />
                </el-col>
            </el-row>
            <Group :list="[List[6],List[7],List[8]]" :length="5" :prompt="prompt" :prompt-interval="promptInterval" type="queuetab" />
        </Category>

        <Category title="龙虎斗">
            <el-row v-for="(group,index) in [[0,1,2,3],[4,5,6],[7,8],[9]]" :key="index" tag="div" type="flex" justify="center">
                <el-col v-for="unit in group" :key="unit" :span="6">
                    <Group :list="List[unit]" :color="['#FF4848','#06A21B','#0020FF']" type="row" />
                </el-col>
            </el-row>
        </Category>

        <Category title="全5中1">
            <Group :list="List[0]" :length="5" :prompt="prompt" :prompt-interval="promptInterval" type="queue" />
        </Category>
    </div>
</template>

<script>
import Credit from './other.js'
// import Category from './components/category'
// import Row from './components/row'
// import Group from './components/group'
export default {
    name: 'GameCreditSSC',
    mixins: [Credit],
    data() {
        return {

        }
    },
    //props : ['List'],
    computed: {
        // prompt(){
        //     return this.COptions.type == 1 ? ['号码','赔率','金额'] : ['号码','赔率']
        // },
        // promptInterval(){
        //     return this.COptions.type == 1 ? [7,6,11] : [12,12]
        // },
    },
    methods: {

    }
    // components:{
    //     Category, Row, Group,
    // },
}
</script>
