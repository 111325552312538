<style lang="scss">
    @import "../../../../../style/config.scss";
    .OddsGroup {
        .title {
            background-color:rgb(90,121,198); color:#fff; text-align:center; padding:4px;
        }
        table.table-2{
            tr td {
                padding:3px; vertical-align:middle;
            }
            tbody {
                tr td:first-child {
                    background-color:rgb(192,214,235)!important;
                }
                tr,td {
                    background-color:#e5e5e5!important;
                }
            }
            .row.double td {
                height:60px;
            }
            $height:24px;
            .goods.ball {
                position:relative; width:40px; margin:0 auto;
                a {
                    width:$height; height:$height; line-height:$height;  border-radius:99px; padding:0; margin:0 auto;
                    //position:absolute; top:$height*0.13; right:$height*0.13; bottom:$height*0.13; left:$height*0.13;
                    background-color:#ff6969; color:#fff;
                    z-index:1; color:#fff;
                    &.red { background-color:$color-six-red;}
                    &.blue { background-color:$color-six-blue;}
                    &.green { background-color:$color-six-green;}
                }
            }
            .goods.text {
                .red {color:$color-six-red;}
                .blue {color:$color-six-blue;}
                .green {color:$color-six-green;}
            }
            a:hover {color:#333;}
        }
        input {
            height:20px; line-height:20px; width:45px; padding:0 5px;
            border:1px solid #ccc; border-radius:4px; outline:none;
            &:focus, &.change {
                background-color:#fdebdf; border-color:#ffbf95;
            }
        }
        .Button.Button_mini{
            button {
                display:inline-block; width:20px; height:20px; line-height:20px; text-align:center; padding:0;
            }
        }
        &[size=big] {
            input {
                width:65px;
            }
        }
    }
</style>
<template>
    <table class="OddsGroup">
        <tr>
            <td v-if="title" class="title" colspan="100">{{ title }}</td>
        </tr>
        <tr class="layout-tr">
            <td v-for="(pack,unit) in MakeQueue(list,length)" class="layout-td" style="vertical-align:top;">
                <table class="table-2">
                    <thead>
                        <tr v-if="prompt">
                            <td style="text-align:center;" :style="WidthStyle">号码</td>
                            <td>赔率A</td>
                            <td v-if="side > 0">赔率B</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in pack" class="row" :class="{'double': double}">
                            <td v-if="dice" class="goods" style="text-align:center;" :style="WidthStyle">
                                <template v-if="IsInt(item.name)">
                                    <a v-for="num in item.name.split('')" class="dice" style="display:inline-block;">
                                        <img v-if="num == 1" src="../../../../../image/game/dice/1.png"">
                                        <img v-if="num == 2" src="../../../../../image/game/dice/2.png"">
                                        <img v-if="num == 3" src="../../../../../image/game/dice/3.png"">
                                        <img v-if="num == 4" src="../../../../../image/game/dice/4.png"">
                                        <img v-if="num == 5" src="../../../../../image/game/dice/5.png"">
                                        <img v-if="num == 6" src="../../../../../image/game/dice/6.png"">
                                    </a>
                                </template>
                                <span v-else>{{ item.name }}</span>
                            </td>
                            <td v-else class="goods" style="text-align:center;" :style="WidthStyle" :class="IsInt(item.name) && ball ? 'ball' : 'text'">
                                <a :class="BallType(item.name)">{{ item.name }}</a>
                            </td>
                            <td>
                                <div v-if="item.has_double_odds > 0">
                                    <div>
                                        <input v-model.number="item.odds" :class="{'change': item.odds != item._odds}">
                                    </div>
                                    <div class="o-mt-s">
                                        <input v-model.number="item.max_odds" :class="{'change': item.max_odds != item._max_odds}">
                                    </div>
                                </div>
                                <input v-else v-model.number="item.odds" :class="{'change': item.odds != item._odds}">
                            </td>
                            <td v-if="side > 0">
                                <input v-model.number="item.odds_b" :class="{'change': item.odds_b != item._odds_b}">
                            </td>
                            <td v-if="btn">
                                <div style="font-size:0;">
                                    <Button size="mini" style="font-size:14px;" @click="Sub(item)">-</Button>
                                    <Button size="mini" style="font-size:14px;" @click="Add(item)">+</Button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
    </table>
</template>

<script>
import SixMix from '@lottery.admin.2.0/mixin/six.js'
export default {
    name: 'OddsGroup',
    mixins: [SixMix],
    data() {
        return {

        }
    },
    props: {
        list: {},
        prompt: {
            default: false
        },
        color: {
            default: true
        },
        length: {
            default: 1
        },
        side: {
            default: 0
        },
        fast: {
            default: false
        },
        double: {
            default: false
        },
        ball: {
            default: true
        },
        vertical: {
            default: true
        },
        title: {},
        width: {},
        btn: {},
        tools: {},
        dice: {}
    },
    computed: {
        minQueue(len) {
            if (this.min && this.length) {
                return this.min / this.length
            }
            return false
        },
        WidthStyle() {
            if (this.width) {
                return `width:${this.width};`
            }
            return ''
        }
    },
    methods: {
        IsInt(str) {
            if (!str) {
                return false
            }
            return /^[0-9]*$/.test(str)
        },
        BallType(str) {
            let type = 'text'
            if (str%1 === 0 && this.ball) {
                type = 'ball'
            }
            if (this.color) {
                if (type === 'ball') {
                    type = `${type} ${this.CodeColor(str)}`
                }
                if (type === 'text') {
                    type = `${type} ${{'红': 'red', '蓝': 'blue', '绿': 'green'}[str.substr(0, 1)]}`
                }
            }
            return type
        },
        MakeQueue(arr=[], column=1, fill) {
            let vertical = this.vertical
            let queue = []
            if (vertical) {
                let rows = parseInt(arr.length / column)
                if (arr.length%column > 0) {
                    rows++
                }
                for (let i=0;i<arr.length;i++) {
                    let col = parseInt(i / rows)
                    if (!queue[col]) {
                        queue[col] = []
                    }
                    queue[col].push(arr[i])
                }
            } else {
                for (let i=0;i<column;i++) {
                    queue.push([])
                }
                let unit = 0
                for (let item of arr) {
                    if (unit == column) {
                        unit = 0
                    }
                    queue[unit].push(item)
                    unit++
                }
            }
            if (vertical && fill && queue.length > 1) {
                let lindex = queue.length - 1
                let max = queue[0].length
                while (queue[lindex].length < max) {
                    queue[lindex].push({})
                }
            }
            return queue
        },
        Add(item) {
            let calcu = 0.1
            if (item.odds) {
                calcu = this.Big(item.odds).add(0.01).toNumber()
            }
            this.$set(item, 'odds', calcu)
        },
        Sub(item) {
            let calcu = 0
            if (item.odds  >= 0.1) {
                calcu = this.Big(item.odds).sub(0.01).toNumber()
            }
            this.$set(item, 'odds', calcu)
        }
    },
    components: {

    }
}
</script>
