
export default {
    data() {
        return {
            BreadcrumbActiveID: 'id',
            crumbkey: 'pid',
            breadcrumb: [
                {
                    title: '所有下级',
                    type: 1,
                    id: ''
                }
            ]
        }
    },
    computed: {
        BreadcrumbTarget() {
            let index = this.breadcrumb.length - 1
            if (index >= 0) {
                return this.breadcrumb[index]
            }
            return {}
        }
    },
    methods: {
        init() {

        },
        ReloadFilter() {
            let key = this.BreadcrumbActiveID
            this.DataReset('breadcrumb')
            this.DataReset('Filter')
            this.init()
            this.$nextTick(() => {
                let query = this.Origin(this.$route.query)
                try {
                    for (let key in query) {
                        let value = query[key]
                        this.$set(this.Filter, key, query[key])
                    }
                } catch (err) {}
                if (!query.username && query.pid) {
                    this.FindSuperior(query.pid, 0)
                }
                this.MakeFilter().then(res => {
                    if (query.username && res.data && res.data.length > 0) {
                        this.FindSuperior(res.data[0][key])
                    }
                })
            })
        },
        MakeFilterGo() {
            let Filter = {}
            for (let key in this.Filter) {
                if (key != 'start' && key != 'end') {
                    Filter[key] = this.Filter[key]
                }
            }
            this.Clean()
            this.Go(this.$route.name, {}, {...Filter})
        },
        Breadcrumb(index, key) {
            let target = this.breadcrumb[index]
            this.GetByBreadcrumb(target, key)
        },
        GetByBreadcrumb(item, key=this.BreadcrumbActiveID) {
            this.Filter.username = undefined
            this.Filter.status = '1'
            this.Filter.pid = item[key]
            this.MakeFilterGo()
        },
        FindSuperior(id, location=1) {
            this.Dp('credit/_GET_SUPERIOR', {params: {id}}).then(res => {
                if (res.data && res.data.length >= 2) {
                    let list = res.data.reverse()
                    for (let i=0;i<list.length;i++) {
                        if (i && i != list.length - location) {
                            let item = list[i]
                            this.breadcrumb.push({
                                id: item.id,
                                title: item.name,
                                type: item.type
                            })
                        }
                    }
                }
            })
        }
    },
    mounted() {
        this.init()
    },
    activated() {

    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.ReloadFilter()
        })
    },
    beforeRouteUpdate(to, from, next) {
        this.ReloadFilter()
        next()
    }
}