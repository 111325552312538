<style lang="scss">
    @import "../../../../style/config.scss";
    .GameFormalHistory {
        .history-header {
            height:38px; line-height:38px; background-color:#f0f0f0; text-align:center;
            a {
                height:26px; line-height:26px; font-size:13px; padding:0 16px; border-radius:4px; margin:6px 10px 0 10px;
                background-color:#796057; color:#fff; display:inline-block;
                &[disabled] {
                    background-color:#d8d8d8; color:#888;
                }
            }
        }
        .el-button--mini{
            padding: 5px 8px;
        }
        .el-button--mini+.el-button--mini {
            margin-left: 5px;
        }
        td {
            cursor:pointer;
        }
        a.link {
            text-decoration:underline; padding:0 3px;
        }

        table {
            td {
                text-align:center; vertical-align:middle;
                line-height:1rem; padding:8px 4px; font-size:.65rem; word-break:break-word;
                background-color:#FFF; color:#000;
                transition:background,0.3s;
            }
            thead {
                td {
                    background-color:#7baaff;
                }
            }
            tbody {
                tr:nth-child(odd){
                    td {
                        background-color:#edf1fb;
                    }
                }
                tr:hover>td{
                    background-color:#eadcf3;
                }
            }
        }
    }
</style>
<template>
    <div class="GameFormalHistory">
        <!--         <div class="history-header">
            <a style="float:left;" :disabled="!selection.length" @click="cancelOrderBatch()">批量撤单</a>
            <b>操作状态</b>
            <a style="float:right;" @click="Go('my/record/query')">更多记录</a>
            <a style="float:right;" @click="refresh()">刷新</a>
        </div> -->
        <table style="width:100%;">
            <colgroup>
                <col>
                <col>
                <col>
                <col width="17%">
                <col>
                <col>
                <col>
                <col width="150px">
                <col width="80px">
                <col>
            </colgroup>
            <thead>
                <tr>
                    <td>注单编号</td>
                    <td>期号</td>
                    <td>玩法</td>
                    <td>投注内容</td>
                    <td>倍率</td>
                    <td>总金额</td>
                    <td>奖金</td>
                    <td>投注时间</td>
                    <td>状态</td>
                    <td>追号</td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item,index) in list" @click="">
                    <td>{{ item.sequence }}</td>
                    <td>{{ item.lottery_number }}</td>
                    <td>{{ item.plays }}</td>
                    <td>{{ CheckContextLength(item.code,24) }}</td>
                    <td>{{ item.multiple }}</td>
                    <td>{{ item.amount }}</td>
                    <td>{{ item.bonus }}</td>
                    <td>{{ item.create_at }}</td>
                    <td>{{ item.status }}</td>
                    <td>
                        <span v-if="!item.follow" class="c-color-g">无</span>
                        <template v-else>
                            <a class="link" @click="itemFollow(item)">详情</a>
                            <a v-if="item.follow == 1" class="link" @click="cancelFollow(item)">撤销</a>
                        </template>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr v-if="list.length == 0">
                    <td colspan="10" style="padding:35px 5px; color:#aaa;">暂无历史注单数据</td>
                </tr>
            </tfoot>
        </table>
        <!--         <el-table v-if="list" :data="list" height="250" style="width:100%; font-size:12px;" size="mini" @row-click="checkDetails" @selection-change="handleSelectionChange" v-loading="Running.enable && _order._loading">
            <el-table-column type="selection" width="40"></el-table-column>
            <el-table-column prop="sequence" label="注单编号" width="135" align="center"></el-table-column>
            <el-table-column prop="lottery_number" label="期号" width="120" align="center"></el-table-column>
            <el-table-column prop="plays" label="玩法" width="120" align="center"></el-table-column>
            <el-table-column prop="code" label="投注内容" align="center">
                <template slot-scope="scope">
                    {{ CheckContextLength(scope.row.code,24) }}
                </template>
            </el-table-column>
            <el-table-column prop="multiple" label="倍率" width="70" align="center"></el-table-column>
            <el-table-column prop="amount" label="总金额"  width="70" align="center"></el-table-column>
            <el-table-column prop="bonus" label="奖金" width="70" align="center"></el-table-column>
            <el-table-column prop="create_at" label="投注时间" width="135" align="center"></el-table-column>
            <el-table-column prop="status" label="状态" width="70" align="center"></el-table-column>
            <el-table-column label="追号" width="130" align="center">
                <template slot-scope="scope">
                    <span v-if="!scope.row.follow" class="c-color-g">无</span>
                    <template v-else>
                        <a class="link" @click="itemFollow(scope.row)">详情</a>
                        <a v-if="scope.row.follow == 1" class="link" @click="cancelFollow(scope.row)">撤销</a>
                    </template>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="170" align="center">
                <template slot-scope="scope">
                    <el-button size="mini" type="danger" v-if="scope.row.int_status == 1" @click.native.stop="cancelOrder(scope.row)">撤单</el-button>
                    <el-button size="mini" type="warning" @click.native.stop="Append(scope.row)">再下一注</el-button>
                </template>
            </el-table-column>
        </el-table> -->
        <append :item="append" @done="Reload()" />
        <details :item="details" @append="Append" />
        <details-follow :item="detailsFollow" @done="Reload()" />
    </div>
</template>

<script>
import Append from './append'
import Details from './details'
import DetailsFollow from './details.follow'
export default {
    name: 'GameFormalHistory',
    data() {
        return {
            list: [],
            selection: [],
            append: {
                view: false,
                order: {}
            },
            details: {
                view: false,
                order: {}
            },
            detailsFollow: {
                view: false,
                order: {}
            }
        }
    },
    computed: {
        _order() {
            return this.$store.state.game.order
        }
    },
    watch: {
        'Running.id'(id) {
            if (id) {
                this.Reload()
            }
        },
        'Running.expect.now'(val) {
            // console.log('history:Running.expect.now',val)
            this.Reload()
        }
    },
    methods: {
        init() {
            this.Reload()
            // 开奖后刷新历史注单列表
            this.Event = this.$store.state.socket.Event
            this.Event.on('UPDATE_LOTTERY', (res) => {
                this.Reload()
            })
        },
        Reload() {
            // 自动触发刷新条件：切换游戏品种时，下单成功时，有新开奖时，撤单时，新开盘时
            if (this.Running.remote_data) {
                this.$store.dispatch('base/BASE_USER') // 重置余额
                return this.$store.dispatch('game/_GET_ORDER', {
                    params: {
                        games_id: this.Running.id,
                        page: 1,
                        per_page: 5
                    }
                }).then(({data}) => {
                    this.$set(this.$data, 'list', data)
                    return data
                })
            }
        },
        cancelOrderBatch() {
            if (this.selection.list) {
                this.$set(this.$data, 'selection', [])
            }
        },
        cancelOrder(item) {
            this.Confirm(() => {
                this.$store.dispatch('game/_PUT_ORDER', {id: item.id, data: {"status": 2}}).then(({data}) => {
                    if (data) {
                        this.Suc('操作成功')
                        this.Reload()
                    }
                })
            }, '您是否确定撤单？', '撤单')
        },
        itemFollow(item) {
            if (item.follow) {
                // let sequence = item.follow_sequence
                // this.Dp('game/ITEM_FOLLOW',sequence).then(res=>{
                //     console.log(res)
                // })
                this.detailsFollow.view = true
                this.$set(this.detailsFollow, 'order', item)
            }
        },
        cancelFollow(item) {
            if (item.follow) {
                let sequence = item.follow_sequence
                this.Confirm(() => {
                    this.Dp('game/CANCEL_FOLLOW', sequence).then(({data}) => {
                        if (data) {
                            this.Suc('操作成功')
                            this.Reload()
                        }
                    })
                }, '您是否确认撤销追号？', '撤销追号')
            }
        },
        refresh() {
            if (!this._order._loading) {
                this.Reload().then(res => {
                    this.Suc('刷新成功')
                })
            }
        },
        handleSelectionChange(rows) {
            this.selection = []
            for (let item of rows) {
                if (item.status == 1) {
                    this.selection.push(item)
                }
            }
        },
        checkDetails(row, event, column) {
            // this.details.view = true
            // this.$set(this.details,'order',row)
        },
        Append(item) {
            this.append.view = true
            this.$set(this.append, 'order', item)
        }
    },
    components: {
        Append, Details, DetailsFollow
    },
    mounted() {
        this.init()
    }
}
</script>
