<style lang="scss">
    @import "../../../../style/config.scss";
    .GameCreditBackupMudul {
        a.btn {
            padding:12px 20px; background-color:$color-p; color:#fff; border-radius:5px;
        }
    }
</style>
<template>
    <el-dialog class="GameCreditBackupMudul" :title="title" :visible.sync="view" size="mini" top="5vh" :modal-append-to-body="false">
        <p v-if="!url" class="c-text-c">数据生成中，请稍候</p>
        <div v-else class="c-text-c o-pb-l">
            <p class="o-pb-h">数据已生成，请点击下载</p>
            <a class="btn" :href="url" download>下载报表</a>
        </div>
    </el-dialog>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.modul.js'
import Config from '@lottery.2.0/library/config.js'
export default {
    name: 'GameCreditBackupMudul',
    mixins: [StoreMix],
    data() {
        return {
            store: 'myTeam/backup',
            url: null
        }
    },
    methods: {
        init() {
            this.url = null
            let params = this.Origin(this.form)
            this.Dp('myTeam/FILE_BACKUP', params).then(res => {
                if (res.data) {
                    this.url = Config.host + res.data
                }
            })
        }
    }
}
</script>
