<style lang="scss">
    @import "../../../../../style/config.scss";
    .OddsFast {
        input {
            height:20px; line-height:20px; width:45px; padding:0 5px; vertical-align:middle;
            border:1px solid #ccc; border-radius:4px; outline:none;
            &:focus, &.change {
                background-color:#fdebdf; border-color:#ffbf95;
            }
        }
        .el-radio__label {
            padding-left:7px;
        }
    }
</style>
<template>
    <div class="OddsFast">
        <div v-if="tabs" style="padding-bottom:6px;">
            <el-radio v-for="(item,index) in tabs" :key="item.value" v-model="tabsActive" style="margin-right:15px;" :label="item.value">{{ item.title }}</el-radio>
        </div>
        <span>
            <slot />
        </span>
        <span v-if="side" class="o-pr-s">A:</span>
        <input v-model.number="FastTable.odds" class="o-ml-s">
        <template v-if="side">
            <span class="o-pr-s">B:</span>
            <input v-model.number="FastTable.odds_b" class="o-ml-s">
        </template>
        <span class="o-pl" />
        <Button v-for="item in Tools" :key="item" size="mini" @click="FastInput(item)">{{ item }}</Button>
    </div>
</template>

<script>
import SixMix from '@lottery.admin.2.0/mixin/six.js'
export default {
    name: 'OddsFast2',
    mixins: [SixMix],
    data() {
        return {
            FastTable: {
                input: 0,
                marks: {
                    '自选不中': 0,
                    '正码特': 0
                },
                //tools : ['所有','大','小','单','双'],
                odds: null,
                odds_b: null
            },
            tabsActive: null
        }
    },
    props: {
        table: {},
        group: {},
        side: {
            default: 0
        },
        tools: {},
        type: {
            default: 7
        },
        tabs: {}
    },
    computed: {
        groupDir() {
            if (this.group) {
                let dir = {}
                if (this.group.length) {
                    for (let key of this.group) {
                        dir[key] = true
                    }
                } else {
                    dir[this.group] = true
                }
                return dir
            }
            return false
        },
        Tools() {
            let opt = ['所有', '大', '小', '单', '双']
            return this.tools ? this.tools : opt
        }
    },
    methods: {
        FastInput(name) {
            for (let key of ['odds', 'odds_b']) {
                if (this.FastTable[key] > 0 && this.groupDir) {
                    let targetDir = this.FindTarget(name)
                    let tabsActive = this.tabsActive
                    if (tabsActive != null) {
                        if (this.groupDir[tabsActive]) {
                            for (let item of this.table[tabsActive]) {
                                if (targetDir[item.name]) {
                                    this.$set(item, key, this.FastTable[key])
                                }
                            }
                        }
                    } else {
                        for (let index in this.table) {
                            if (this.groupDir[index]) {
                                for (let item of this.table[index]) {
                                    if (targetDir[item.name]) {
                                        this.$set(item, key, this.FastTable[key])
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        FindTarget(name) {
            let target = {}
            switch (name) {
                case '所有':
                    for (let code=0;code<=9;code++) {
                        target[code] = true
                    }
                    break;
                case '大':
                    for (let code=0;code<=9;code++) {
                        if (code >= 5) {
                            target[code] = true
                        }
                    }
                    break;
                case '小':
                    for (let code=0;code<=9;code++) {
                        if (code < 5) {
                            target[code] = true
                        }
                    }
                    break;
                case '单':
                    for (let code=0;code<=9;code++) {
                        if (code % 2 == 1) {
                            target[code] = true
                        }
                    }
                    break;
                case '双':
                    for (let code=0;code<=9;code++) {
                        if (code % 2 != 1) {
                            target[code] = true
                        }
                    }
                    break;
            }
            return target
        }
    },
    components: {

    },
    mounted() {
        if (this.tabs && this.tabs.length) {
            this.tabsActive = this.tabs[0].value
        }
    }
}
</script>
