<style lang="scss">

</style>
<template>
    <div class="CreditSix CreditSixActlog">
        <div class="l-flex-c o-plr o-mb">
            <div class="ctrl o-ml l-flex-1">
                <DatePicker v-model="Filter.interval" @change="MakeFilter()" />
                <!--                 <span>时间筛选：</span>
                <el-date-picker v-model="Filter.interval" size="small" type="datetimerange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss" :default-time="['00:00:00','23:59:59']" :picker-options="FilterIntervalMenu" @change="MakeFilter()" align="left"></el-date-picker> -->
            </div>
        </div>
        <table v-loading="main._loading" class="table" border="0" cellspacing="1" cellpadding="0">
            <colgroup>
                <col width="10%">
                <col width="10%">
                <col width="15%">
                <col>
                <col width="120">
                <col width="160">
                <col width="60">
            </colgroup>
            <thead>
                <tr>
                    <td>帐号</td>
                    <td>目标帐号</td>
                    <td style="text-align:left;">操作项</td>
                    <td style="text-align:left;">操作内容</td>
                    <td>操作IP</td>
                    <td>操作时间</td>
                    <td>详情</td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item,index) in main._list" class="c-text-c">
                    <td>{{ item.username }}</td>
                    <td>{{ item.aims_username }}</td>
                    <td style="text-align:left;">{{ item.title }}</td>
                    <td style="text-align:left;">{{ CheckContextLength(item.note,100) }}</td>
                    <td>{{ item.ip }}</td>
                    <td>{{ item.create_at }}</td>
                    <td>
                        <Button size="mini" @click="Edit(item)">详情</Button>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr v-if="main._empty">
                    <td class="empty" colspan="100">暂无数据</td>
                </tr>
            </tfoot>
        </table>
        <Pagination v-if="main._total" v-model="Page" class="o-mtb" :total="main._total" :count="main._count" :page-size="Filter.per_page" @turning="Get" />
        <Editer v-model="Editer.view" title="日志详情" :form="Editer.form" />
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
import Editer from './model/actlog'
export default {
    name: 'CreditSixActlog',
    mixins: [StoreMix],
    data() {
        return {
            store: 'credit/actlog',
            Filter: {
                per_page: 20
            }
        }
    },
    methods: {
        init() {
            this.FilterIntervalSet('-30')
            this.Get()
        }
    },
    components: {

    },
    components: {
        Editer
    },
    activated() {
        if (!this.Opt.isTeam) {
            this.Rd('credit')
        }
        this.init()
    }
}
</script>
