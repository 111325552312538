<style lang="scss">
    @import "../../../../style/config.scss";
    .CreditSystemBackwater {
        table.table {
            input {
                width:80px; padding:2px 5px;
            }
            tr td {
                border:1px solid #aaa;
            }
            .pick {
                background-color:#ffd5bc!important;
            }
        }
    }
</style>
<template>
    <div class="CreditSystemBackwater o-pb">
        <div class="l-flex-c o-pb">
            <div class="l-flex-1">
                <span>游戏：</span>
                <el-select v-model="Filter.games_id" style="width:120px;" size="small" @change="load()">
                    <el-option v-for="(item,index) in Opt.hasDir.game" :key="item.id" :label="item.title" :value="item.id" />
                </el-select>
            </div>
            <Button @click="load()">重读数据</Button>
            <Button @click="Submit()">提交修改</Button>
        </div>
        <div v-loading="main._loading">
            <table v-loading="main._loading" class="table" border="0" cellspacing="1" cellpadding="0">
                <thead>
                    <tr style="text-align:left;">
                        <td />
                        <td>玩法</td>
                        <td>单注最低</td>
                        <td>单注最高</td>
                        <td>单项最高</td>
                        <td>退水A(%)</td>
                        <td>退水B(%)</td>
                        <td>退水C(%)</td>
                        <td>退水D(%)</td>
                        <td>退水E(%)</td>
                    </tr>
                    <tr style="text-align:left;">
                        <td><el-checkbox v-model="pickall" @change="PickAll($event)" /></td>
                        <td>所有</td>
                        <td><input v-model.number="table.fast.order_min_amount"></td>
                        <td><input v-model.number="table.fast.order_max_amount"></td>
                        <td><input v-model.number="table.fast.bets_limit_amount"></td>
                        <td><input v-model.number="table.fast.backwater_a"></td>
                        <td><input v-model.number="table.fast.backwater_b"></td>
                        <td><input v-model.number="table.fast.backwater_c"></td>
                        <td><input v-model.number="table.fast.backwater_d"></td>
                        <td><input v-model.number="table.fast.backwater_e"></td>
                    </tr>
                </thead>
                <tbody style="text-align:left;">
                    <tr v-for="(item,index) in table.list" class="c-text-c" :class="{'pick': item.pick}">
                        <td>
                            <el-checkbox v-model="item.pick" />
                        </td>
                        <td>{{ item.name }}</td>
                        <td><input v-model="item.order_min_amount"></td>
                        <td><input v-model="item.order_max_amount"></td>
                        <td><input v-model="item.bets_limit_amount"></td>
                        <td><input v-model="item.backwater_a"></td>
                        <td><input v-model="item.backwater_b"></td>
                        <td><input v-model="item.backwater_c"></td>
                        <td><input v-model="item.backwater_d"></td>
                        <td><input v-model="item.backwater_e"></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <el-dialog title="配置保存中" :visible.sync="waiting.view" width="420px" :close-on-click-modal="false">
            <div class="c-text-c">
                <Load />
            </div>
        </el-dialog>
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
export default {
    name: 'CreditSystemBackwater',
    mixins: [StoreMix],
    data() {
        return {
            store: 'admin/backwater',
            Filter: {
                games_id: 31
            },
            Params: {},
            table: {
                list: [],
                origin: [],
                fast: {
                    order_min_amount: null,
                    order_max_amount: null,
                    order_limit_amount: null,
                    backwater_a: null,
                    backwater_b: null,
                    backwater_c: null,
                    backwater_d: null
                }
            },
            waiting: {
                view: false
            },
            pickall: false
        }
    },
    computed: {
        ActiveGame() {
            let id = this.Filter.games_id
            for (let item of this.$store.state.game.games._list) {
                if (item.id == id) {
                    return item
                }
            }
            return null
        }
    },
    watch: {
        'table.fast.order_min_amount'(val) { if (val) for (let item of this.table.list) { if (item.pick) this.$set(item, 'order_min_amount', val)} },
        'table.fast.order_max_amount'(val) { if (val) for (let item of this.table.list) { if (item.pick) this.$set(item, 'order_max_amount', val)} },
        'table.fast.bets_limit_amount'(val) { if (val) for (let item of this.table.list) { if (item.pick) this.$set(item, 'bets_limit_amount', val)} },
        'table.fast.backwater_a'(val) { if (val) for (let item of this.table.list) { if (item.pick) this.$set(item, 'backwater_a', val)} },
        'table.fast.backwater_b'(val) { if (val) for (let item of this.table.list) { if (item.pick) this.$set(item, 'backwater_b', val)} },
        'table.fast.backwater_c'(val) { if (val) for (let item of this.table.list) { if (item.pick) this.$set(item, 'backwater_c', val)} },
        'table.fast.backwater_d'(val) { if (val) for (let item of this.table.list) { if (item.pick) this.$set(item, 'backwater_d', val)} },
        'table.fast.backwater_e'(val) { if (val) for (let item of this.table.list) { if (item.pick) this.$set(item, 'backwater_e', val)} }
    },
    methods: {
        init() {
            this.waiting.view = false
            this.$set(this.Filter, 'games_id', this.Running.id)
            this.load()
        },
        PickAll(e) {
            for (let item of this.table.list) {
                this.$set(item, 'pick', e)
            }
        },
        load() {
            this.pickall = false
            this.DataReset('table')
            let params = this.Origin(this.Filter)
            this.Dp('admin/_GET_BACKWATER', {params}).then(res => {
                let list = []
                for (let item of res.data) {
                    list.push({
                        pick: false,
                        ...item
                    })
                }
                this.$set(this.table, 'origin', this.Origin(list))
                this.$set(this.table, 'list', list)
            })
        },
        Submit() {
            let data = [] // this.Origin(this.table.list)
            this.table.list.forEach((item, index) => {
                if (JSON.stringify(item) != JSON.stringify(this.table.origin[index])) {
                    data.push(item)
                }
            })
            this.waiting.view = true
            this.Dp('admin/_POST_BACKWATER', {
                data: {
                    data: JSON.stringify(data)
                }
            }).then(res => {
                this.waiting.view = false
                this.Suc('限额已修改成功')
            }, err => {
                this.waiting.view = false
            })
        }
    },
    components: {

    },
    mounted() {
        this.init()
    }
}
</script>
