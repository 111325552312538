<style lang="scss">
    .GameCreditHezhi2 {
        .balls, .tools {
            width:600px; text-align:left;
            .ball {
                text-align:center;
            }
        }
    }
</style>
<template>
    <div class="GameFormalPlay GameCreditHezhi2">
        <ul v-if="params" class="box">
            <div class="o-mb o-plr-s">
                <span>选择位置：</span>
                <span class="o-plr">
                    <el-checkbox v-for="(item,index) in template.defaultCheckedPos" :key="item" v-model="digitDir[item].value">{{ digitDir[item].name }}</el-checkbox>
                </span>
                <span>( 温馨提示：你选择了 <b class="c-color-t">{{ programme.number }}</b> 个位置，系统会自动根据位置组合成 <b class="c-color-t">{{ programme.total }}</b> 个方案 )</span>
            </div>
            <li v-for="(row,unit) in template.sub[0]" class="row l-flex-1">
                <div class="title">
                    <p>{{ row }}</p>
                </div>
                {{/* 选球组 */}}
                <div class="balls">
                    <li v-for="(item,index) in Dir" class="ball" :class="{'active': params[unit] && params[unit][index]}" @click="Pick(unit,index)">
                        <span>{{ item }}</span>
                    </li>
                    <!--                     <li class="ball" v-for="(item,index) in Dir" @click="Pick(unit,index)">
                        <template v-if="params[unit][index]">
                            <span class="c-color-f">{{ item }}</span>
                            <img src="../../../../../image/game/formal/ball_a.png">
                        </template>
                        <template v-else>
                            <span>{{ item }}</span>
                            <img src="../../../../../image/game/formal/ball.png">
                        </template>
                    </li> -->
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import Common from './hezhi2.js'
export default {
    mixins: [Common],
    components: {

    }
}
</script>
