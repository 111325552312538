
<template>
    <Operate ref="operate" :games="games" :types="types" :newbtn="true" store="game" :user_type="User.user_type" :bind="2" :only-six="Running.id" />
</template>
<script>
import CommonMix from './common.js'
export default {
    name: 'GameSubOperate',
    mixins: [CommonMix]
}
</script>
