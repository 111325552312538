<style lang="scss">
@import "../../style/config.scss";
.Login {
    position:fixed; top:0; left:0; right:0; bottom:0; overflow-x:auto;
    .from {
        width:720px; margin:3rem auto 1.5rem auto; padding:2rem 1.2rem; border-radius:10px; box-shadow:0 5px 20px rgba(0,0,0,.15); overflow:hidden; background-color:#fff; background-color:rgba(255,255,255,.9);
        border:3px solid #c0c0c0; position:relative;
        margin-top:16vh;
        .line {
            width:420px; padding-right:20px; float:left; overflow:hidden;
            .item {
                float:left; width:33.33%; margin-top:4px;
                .inside {
                    padding:5px; display:block;
                    .box {
                        height:48px; line-height:48px;
                        border-radius:4px; background-color:#f5f5f5; border:2px solid #ddd;
                        .icon {
                            color:#ddd;
                        }
                        &.active {
                            background-color:#fff; border-color:#c2cdf1;
                            .icon {
                                color:#4b8fc1;
                            }
                        }
                        &:hover {
                            background-color:#fff; box-shadow:0 2px 8px rgba(0,0,0,.1);
                        }
                    }
                }
                &:nth-child(3n-1) {
                    .inside {
                        padding-left:0;
                    }
                }
                &:nth-child(3n-2) {
                    .inside {
                        padding-right:0;
                    }
                }
            }
        }
        .login {
            width:280px; float:left; padding:1.55rem 16px 1rem 16px; box-sizing:border-box; border-left:1px solid #d5d5d5;
        }
    }
    .cooperation-btn {
        position:absolute; right:40px; bottom:20px; color:#212888; text-decoration:underline;
    }
    .cooperation-dialog {
        .dialog {
            width:860px;
        }
        .cooperation-row {
            padding:5px 0; border-bottom:1px solid #EEE;
            p {
                line-height:18px; padding-top:2px;
            }
            input {
                display:block; border:none; outline:none; height:36px; line-height:36px; width:100%;
            }
            input::-webkit-input-placeholder {
                color:#ccc;
            }
        }
    }
    .el-input--prefix .el-input__inner{
        padding-left:38px;
    }
    i.icon {
        padding-left:5px; line-height:40px; color:#666;
    }
    .app-download {
        width:160px; height:180px; background-color:#fff; border-radius:5px; box-shadow:0 5px 10px rgba(0,0,0,.2);
        padding:10px;
        position:absolute; right:30px; bottom:30px;
    }
}
</style>


<template>
    <div class="Login l-flex-v">
        <div class="core">
            <div class="from">
                <ul class="line">
                    <h5 class="o-pb">线路选择 <span class="c-text-65 o-pl-l" style="color:#41bb4b;">线路 ms 数值越小访问速度越快</span></h5>
                    <li v-for="(item,index) in main.list" class="item">
                        <a class="inside" :href="item + '#/login'" target="_blank">
                            <div class="box" :class="{'active': main.active && main.active.origin == item}">
                                <div class="o-plr l-flex-c">
                                    <span class="l-flex-c l-flex-1">
                                        <Icon class="icon" size="1.1" name="roundcheckfill" />
                                        <span style="padding-left:4px;">线路{{ index + 1 }}</span>
                                    </span>
                                    <span class="c-text-6" style="position:relative; top:1px;">{{ ping[index] }}<span>ms</span></span>
                                </div>
                            </div>
                        </a>
                    </li>
                    <p class="c-text-65 o-pt-s" style="float: left;font-size:14px;">选择不同的线路，可以在同一浏览器内打开多个账号</p>
                </ul>
                <div class="login o-plr-l">
                    <div class="input o-mt">
                        <el-input v-model="params.username" type="text" placeholder="请输入用户名" @keydown.native.13="submit">
                            <i slot="prefix" class="icon icon-my" />
                        </el-input>
                    </div>
                    <div class="input o-mt">
                        <el-input v-model="params.pass" type="password" placeholder="请输入密码" @keydown.native.13="submit">
                            <i slot="prefix" class="icon icon-lock" />
                        </el-input>
                    </div>
                    <!--                     <div class="input o-mt">
                        <Drag v-model="verify.check" :image="verify.image" v-loading="verify.loading" ref="verify"></Drag>
                    </div> -->
                    <div class="o-mt o-pt">
                        <Button size="big" :disabled="$store.state.base.sign._loading" full @click="submit()">用户登录</Button>
                        <div class="c-text-r o-pt-h">
                            <el-popover v-if="Config.environment != 'mobile'" placement="top-end" width="160" trigger="hover">
                                <img :src="Config.host + '/static/app_qrcode.png'" style="display:block; margin:0 auto; width:160px; height:160px;">
                                <a slot="reference" class="btn">查看APP下载二维码</a>
                            </el-popover>
                            <a v-else :href="ToMobileLinks" style="text-decoration:underline;">切换为手机版</a>
                        </div>
                    </div>
                </div>
                <a class="cooperation-btn" @click="cooperation.view = true">联系我们</a>
            </div>
        </div>
        <div class="c-line-12 c-color-f" style="opacity:.8;">
            <div v-if="Opt" class="c-text-c c-text-6">{{ Opt.copy_right }}</div>
        </div>

        <el-dialog title="联系我们" class="cooperation-dialog" :visible.sync="cooperation.view" :modal-append-to-body="false" :close-on-click-modal="false" top="7vh">
            <pre class="c-line-10" style="white-space: pre-wrap; word-wrap: break-word;">{{ cooperation.tips }}</pre>
            <div class="o-pt">
                <div class="cooperation-row">
                    <p>姓名</p>
                    <input v-model="cooperation.params.name" name="cooperation-name" autocomplete="off" placeholder="请填写姓名">
                </div>
                <div class="cooperation-row">
                    <p>电话号码</p>
                    <input v-model="cooperation.params.phone" name="cooperation-phone" autocomplete="off" placeholder="请填写电话号码">
                </div>
                <div class="cooperation-row">
                    <p>微信号</p>
                    <input v-model="cooperation.params.wx" name="cooperation-wx" autocomplete="off" placeholder="请填写微信号">
                </div>
                <div class="cooperation-row">
                    <p>QQ</p>
                    <input v-model="cooperation.params.qq" name="cooperation-qq" autocomplete="off" placeholder="请填写QQ">
                </div>
                <div class="cooperation-row">
                    <p>邮箱</p>
                    <input v-model="cooperation.params.email" name="cooperation-email" autocomplete="off" placeholder="请填写邮箱">
                </div>
                <div class="cooperation-row">
                    <p>备注</p>
                    <input v-model="cooperation.params.note" name="cooperation-note" autocomplete="off" placeholder="请填写备注">
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <Button @click="cooperation.view = false">取 消</Button>
                <Button type="primary" @click="CooperationSubmit()">提交</Button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import signMixin from '../../mixin/sign'
export default {
    name: 'Login',
    mixins: [signMixin],
    data() {
        return {
            ping: {},
            cooperation: {
                view: false,
                tips: '',
                params: {
                    name: '',
                    phone: '',
                    wx: '',
                    qq: '',
                    email: '',
                    note: ''
                }
            }
        }
    },
    computed: {
        ToMobileLinks() {
            let loc = window.location
            let host = loc.host.split('.')
            if (host.length > 2) {
                host[0] = 'wap'
            } else {
                host.unshift('wap')
            }
            return `${loc.protocol}//${host.join('.')}`
        }
    },
    methods: {
        init() {
            this.DataReset('params')
            if (this.$refs['video']) {
                this.$refs['video'].play()
            }
            this.imgVerifyLoad()
            this.Dp('base/GET_LINE')
            for (let item of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]) {
                this.$set(this.ping, item, Math.round(Math.random()*192))
            }
            this.DataReset('cooperation')
            this.Dp('base/TIPS_COOPERATION').then(res => {
                this.cooperation.tips = res.data
            })
        },
        Ping(ip) {
            let img = new Image()
            let start = new Date().getTime()
            let flag = false
            let isCloseWifi = true
            let hasFinish = false
            img.onload = () => {
                if (hasFinish) {
                    flag = true;
                    hasFinish = true;
                    console.log('Ping ' + ip + ' success. ')
                    console.log(start, new Date().getTime(), new Date().getTime() - start)
                }
            };
            img.onerror = () => {
                if (!hasFinish) {
                    if (!isCloseWifi) {
                        flag = true
                        console.log('Ping ' + ip + ' success. ')
                        console.log(start, new Date().getTime(), new Date().getTime() - start)
                    }
                    hasFinish = true;
                }
            };
            setTimeout(function() {
                isCloseWifi = false;
                console.log('network is working, start ping...')
            }, 2);
            //img.src = ip + '/' + start;
            img.src = ip
            let timer = setTimeout(function() {
                if (!flag) {
                    hasFinish = true
                    flag = false
                    console.log('Ping ' + ip + ' fail. ')
                }
            }, 3000)
        },
        CooperationSubmit() {
            let data = this.Origin(this.cooperation.params)
            if (!data.name) {
                return this.Toast('请填写姓名', 'error')
            }
            if (!data.phone && !data.wx && !data.qq && !data.email) {
                return this.Toast('请至少填写一项联系方式', 'error')
            }
            this.Dp('base/POST_COOPERATION', data).then(res => {
                if (res.status == 1) {
                    this.Suc('操作成功，稍后客服将会与您联系。')
                    this.cooperation.view = false
                }
            })
        }
    },
    activated() {
        this.init()
    },
    mounted() {

    }
}
</script>
