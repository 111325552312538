<style lang="scss">
    @import "./group.scss";
    .GameCreditFast {
        .amount {
            input {
                padding:0 8px;
            }
        }
        ul {
            height:30px; line-height:30px;
            a {
                display:inline-block; line-height:20px; padding:1px 8px; border-radius:3px; border:1px solid #ddd; background-color:#f0f0f0; margin-right:5px;
                &.active, &:hover {
                    background-color:#ca81d2; border-color:#a22faf; color:#fff;
                }
            }
        }
        .explain {
            padding-left:58px; padding-top:5px;
        }
        .textarea {
            position:absolute; top:280px; padding:10px; left:-300px;
            width:270px;
            background-color:#f0f0f0; border:1px solid #ccc; border-radius:6px;
            input {
                padding:0 8px;
            }
        }
        .color-violet{
            color:#a22faf;
        }
    }
</style>
<template>
    <div v-if="list && (list.child || list.length)" class="GameCreditFast">
        <div class="l-flex">
            <div class="l-flex-1">
                <ul v-for="(pack,unit) in boardGroup" class="l-flex-c">
                    <p class="c-text-r o-pr color-violet" style="width:50px;"><b>{{ pack }}</b></p>
                    <li v-for="(item,index) in boardDir[pack]">
                        <span v-if="index" style="color:#ddd; padding-left:4px; padding-right:6px;">|</span>
                        <template v-for="(sub,i) in item">
                            <a :class="{'active': boardPick[sub]}" @click="PickByGroup(sub)">{{ sub }}</a>
                        </template>
                    </li>
                    <div v-if="pack === '生肖' && COptions.type === 1" class="l-flex-c">
                        <p class="o-pr-s c-text-r" style="width:86px;">下注金额：</p>
                        <el-popover v-model="popoverView" popper-class="quick-modul" placement="top" width="85" trigger="click" :disabled="!COptions.quick.enable || COptions.quick.value.length === 0">
                            <div v-for="(val,i) in COptions.quick.value" class="quick-item" @click="amount = val;popoverView = false">{{ val }}</div>
                            <el-input slot="reference" v-model.number="amount" class="amount o-mr" size="mini" style="width:5.4rem;" clearable @keyup.enter.native="TransmitAmount()" />
                        </el-popover>
                        <Button size="mini" @click="TransmitAmountInput()">传送</Button>
                        <template v-if="outbtn">
                            <Button size="mini" style="margin-left:32px;" plain @click="Reset()">清空数据</Button>
                            <Button size="mini" @click="Checkout()">确认下单</Button>
                        </template>
                    </div>
                </ul>
            </div>
            <div v-if="textarea && COptions.type === 1" class="textarea">
                <h4><b class="c-color-p c-line-18">快捷选号<span v-if="directionName">（{{ directionName }}）</span></b></h4>
                <p>如需下注 01$10,02$20,03$30</p>
                <p>可直接在输入框中输入以逗号分隔的数字：01,10,02,20,03,30</p>
                <div class="o-pt-s">
                    <el-input v-model="fastStr" size="small" placeholder="" @keyup.enter.native="textareaInput()">></el-input>
                </div>
                <div class="o-ptb-s c-text-c">
                    <Button size="mini" @click="fastStr = null">清空选号</Button>
                    <Button size="mini" @click="textareaInput()">确认选号</Button>
                </div>
            </div>
        </div>
        <p class="explain">
            <span>【提示】 有关<b class="c-color-e">两面的</b>号码并不包含 <b class="six-color-text" :class="CodeColor(49)">49</b><span v-if="type === '特码'">，<b class="c-color-e">合尾大小</b>则不包含 <b class="six-color-text" :class="CodeColor(25)">25</b></span>，如有需要请在传送后自行补填，一起确认。</span>
        </p>
    </div>
</template>

<script>
import Credit from '../../../../../mixin/credit.js'
import Prompt from './prompt'
import SixMix from '@lottery.admin.2.0/mixin/six.js'
export default {
    name: 'GameCreditFast',
    mixins: [SixMix, Credit],
    data() {
        return {
            amount: null,
            fastStr: null,
            boardDir: {
                '特码': [['单', '双', '大', '小', '合单', '合双'], ['红波', '蓝波', '绿波'], ['家禽', '走兽'], ['特尾大', '特尾小', '合尾大', '合尾小']],
                '正码': [['单', '双', '大', '小', '合单', '合双'], ['红波', '蓝波', '绿波'], ['家禽', '走兽', '尾大', '尾小']],
                '正特': [['单', '双', '大', '小', '合单', '合双'], ['红波', '蓝波', '绿波'], ['家禽', '走兽', '尾大', '尾小']],
                '头尾': [['0头', '1头', '2头', '3头', '4头'], ['0尾', '1尾', '2尾', '3尾', '4尾', '5尾', '6尾', '7尾', '8尾', '9尾']],
                '半波': [['红单', '红双', '红大', '红小'], ['蓝单', '蓝双', '蓝大', '蓝小'], ['绿单', '绿双', '绿大', '绿小']],
                '生肖': [['鼠', '牛', '虎', '兔', '龙', '蛇', '马', '羊', '猴', '鸡', '狗', '猪']]
            },
            boardPick: {},
            popoverView: false
        }
    },
    props: {
        type: {
            default: '特码'
        },
        textarea: {
            default: false
        },
        list: {},
        direction: {
            default: 'a'
        },
        outbtn: {
            default: false
        }
    },
    computed: {
        boardGroup() {
            let list = ['头尾', '半波', '生肖']
            list.unshift(this.type)
            return list
        },
        directionName() {
            if (this.type && this.direction) {
                return `${this.type}${this.direction.toUpperCase()}`
            }
            return ''
        }
    },
    methods: {
        Reset() {
            this.$emit('Reset')
        },
        Checkout() {
            this.$emit('Checkout')
        },
        PickByGroup(name) {
            this.$set(this.boardPick, name, !this.boardPick[name])
            let list = this.QuickBetList[name]
            if (list && this.list && this.list.child) {
                for (let item of this.list.child) {
                    for (let code of list) {
                        if (item.name == code) {
                            this.$set(item, 'pick', this.boardPick[name])
                        }
                    }
                }
            }
        },
        TransmitAmount() {
            if (this.amount && this.amount > 0) {
                this.TransmitAmountInput()
            } else {
                //return this.Checkout()
                this.$store.state.base.Event.emit('credit_order_checkout')
            }
        },
        TransmitAmountInput() {
            //console.log('TransmitAmountInput',this.amount,this.Is('int',this.amount))
            this.$set(this, 'boardPick', {})
            if (this.Is('int', this.amount) && this.amount > 0) {
                if (this.list && this.list.child) {
                    for (let item of this.list.child) {
                        if (item.pick) {
                            this.$set(item, 'amount', this.amount)
                            this.$set(item, 'pick', false)
                        }
                    }
                }
                this.amount = null
            } else {
                return this.Err('金额格式填写错误，金额必须是整数。')
            }
        },
        textareaInput() {
            let str = this.fastStr
            if (str) {
                str = str.replace(/，/g, ",")
                let list = str.split(',')
                let obj = {}
                let error = 0
                while (list.length >= 2) {
                    let code = Number(list.splice(0, 1)[0])
                    let amount = Number(list.splice(0, 1)[0])
                    if (code && code >=1 && code<=49 && amount && this.Is('int', amount) && amount > 0) {
                        obj[code] = amount
                    }
                }
                if (this.list && this.list.child) {
                    for (let item of this.list.child) {
                        if (obj[item.name]) {
                            this.$set(item, 'amount', obj[item.name])
                        }
                    }
                }
                this.fastStr = null
            }
        }
    },
    components: {

    }
}
</script>
