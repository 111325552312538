
import Formal from '../../../../../mixin/formal'
export default {
    name: 'GameFormalNum6',
    mixins: [Formal],
    data() {
        return {
            table: null,
            params: null
        }
    },
    computed: {
        MakeBallDir() {
            let arr = []
            for (let i=0;i<this.template.sub[0].length;i++) {
                let obj = {
                    title: this.template.sub[0][i],
                    balls: []
                }
                for (let item of this.template.sub[i+1]) {
                    let ball = {
                        title: item,
                        id: null,
                        odds: null
                    }
                    try {
                        let key = this.Mapskey[item]
                        ball.id = this.template.enableMapping[key]
                        // ball.odds = this.template.oddsEnableMapping[key]
                        ball.odds = this.Running.play.odds[ball.id].odds
                    } catch (err) {}
                    obj.balls.push(ball)
                }
                arr.push(obj)
            }
            return arr
        }
    },
    props: ['template', 'rank'],
    watch: {
        params: {
            handler(val) {
                if (val) {
                    this.$emit('change', this.MakeOrder())
                }
            },
            deep: true
        }
    },
    methods: {
        init() {
            this.Reset()
        },
        Reset() {
            let table = []
            let params = []
            for (let pack of this.template.sub[0]) {
                let row = []
                let row2 = []
                for (let item in this.MakeBallArray()) {
                    row.push(item)
                    row2.push(false)
                }
                table.push(row)
                params.push(row2)
            }
            this.$set(this.$data, 'table', table)
            this.$set(this.$data, 'params', params)
        },
        // MakeBallArray(index){
        //     return this.template.sub[index+1]
        // },
        // MakeOrder(){

        //     let order = []
        //     let chip = 0
        //     this.MakeBallDir.forEach((pack,unit)=>{
        //         pack.balls.forEach((item,index)=>{
        //             if(this.params[unit][index]){
        //                 chip++
        //                 order.push({
        //                     id : item.id,
        //                     chip : 1,
        //                     code : item.title,
        //                     suffix : pack.title,
        //                     odds : item.odds,
        //                 })
        //             }
        //         })
        //     })
        //     return {
        //         order,
        //         chip,
        //     }
        // },
        Pick(unit, index) {
            this.$set(this.params[unit], index, !this.params[unit][index])

            // 二同号每行只能选择一个
            if (this.template.title == '二同号单选') {
                if (this.params[unit][index]) {
                    for (let u=0;u<this.params.length;u++) {
                        if (u!=unit) {
                            this.$set(this.params[u], index, false)
                        }
                    }
                }
            }
        }
    },
    components: {

    },
    mounted() {
        this.init()
    }
}