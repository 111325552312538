<style lang="scss">
    .CreditSixOpen {
        // .ball {
        //     width:24px; height:24px; line-height:24px; text-align:center; border-radius:99px; display:inline-block;
        //     font-size:15px; font-weight:bold; background-color:#ccc;
        //     &.red {background-color:#f56c6c; color:#fff;}
        //     &.blue {background-color:#409eff; color:#fff;}
        //     &.green {background-color:#67c23a; color:#fff;}
        // }
        // .text {
        //     display:inline-block; padding:1px 3px;
        //     &.red {background-color:#f56c6c; color:#fff;}
        //     &.blue {background-color:#409eff; color:#fff;}
        //     &.green {background-color:#67c23a; color:#fff;}
        // }
        // .text[mark="1"] {color:#6c00ff;}
        // .text[mark="2"] {color:#e422d4;}
        // .text[mark="3"] {color:#ff9b00;}
    }
</style>
<template>
    <div class="CreditSix CreditSixOpen">
        <div class="l-flex-c o-plr o-mb">
            <div class="ctrl o-ml l-flex-1">
                <span class="o-pl">游戏类型：</span>
                <el-select v-model="Filter.games_id" style="width:6.5rem;" size="small" @change="MakeFilter()">
                    <el-option v-for="(item, index) in Opt.hasDir.game" :key="index" :label="item.title" :value="item.id"></el-option>
                    <!-- <el-option v-for="(item,index) in validGames" :key="item.id" :label="item.title" :value="item.id" @click.native="ChangeGame(item)"></el-option> -->
                    <!-- <el-option label="香港六合彩" :value="31" @click.native="ChangeGame({id:31, type:7})"></el-option> -->
                    <!-- <el-option label="澳门六合彩" :value="34" @click.native="ChangeGame({id: 34, type: 7})" /> -->
                    <!-- <el-option label="台湾大乐透" :value="35" @click.native="ChangeGame({id:35, type:7})"></el-option>
                    <el-option label="新加坡多多彩" :value="36" @click.native="ChangeGame({id:36, type:7})"></el-option> -->
                </el-select>
                <span class="o-pl" />
                <DatePicker v-model="Filter.interval" @change="MakeFilter()" />
            </div>
            <Button @click="Edit()">新增开奖</Button>
        </div>
        <history ref="history" :filter="Filter" btn="开奖" @Handel="Handel" />
        <!--         <table class="table u-bl u-br u-bb" border="0" cellspacing="1" cellpadding="0" v-loading="main._loading">
            <thead>
                <tr>
                    <td rowspan="2">期数</td>
                    <td rowspan="2">星期</td>
                    <td rowspan="2">日期</td>
                    <td colspan="7">彩球号码</td>
                    <td rowspan="2">特<br>单双</td>
                    <td rowspan="2">特<br>大小</td>
                    <td rowspan="2">总分</td>
                    <td rowspan="2">总<br>单双</td>
                    <td rowspan="2">总<br>大小</td>
                    <td rowspan="2">家禽<br>野兽</td>
                    <td rowspan="2">合<br>单双</td>
                    <td rowspan="2">合<br>大小</td>
                    <td rowspan="2">合尾<br>大小</td>
                    <td rowspan="2">波<br>段</td>
                    <td rowspan="2">操作</td>
                </tr>
                <tr>
                    <td>一</td>
                    <td>二</td>
                    <td>三</td>
                    <td>四</td>
                    <td>五</td>
                    <td>六</td>
                    <td>特</td>
                </tr>
            </thead>
            <tbody>
                <tr class="c-text-c" v-for="(item,index) in main._list" :key="item.id">
                    <td>{{ item.number }}</td>
                    <td>{{ item.week }}</td>
                    <td>{{ item.date }}</td>
                    <td v-for="(sub,i) in ['一','二','三','四','五','六','特']">
                        <template v-if="FormatCode(item.data)[i]">
                            <a class="ball" :class="CodeColor(FormatCode(item.data)[i])">{{ FormatCode(item.data)[i] }}</a>
                            <b class="c-text-65">{{ CodeZodiac(item,sub) }}</b>
                        </template>
                    </td>
                    <td><span class="text" v-markColor>{{ item.special_single_double }}</span></td>
                    <td><span class="text" v-markColor>{{ item.special_big_small }}</span></td>
                    <td><span class="text" v-markColor>{{ item.total_num }}</span></td>
                    {{/*总*/}}
                    <td><span class="text" v-markColor>{{ item.total_num_single_double }}</span></td>
                    <td><span class="text" v-markColor>{{ item.total_num_big_small }}</span></td>
                    <td><span class="text" v-markColor>{{ item.poultry_beast }}</span></td>
                    {{/*合*/}}
                    <td><span class="text" v-markColor>{{ item.total_single_double }}</span></td>
                    <td><span class="text" v-markColor>{{ item.total_big_small }}</span></td>
                    <td><span class="text" v-markColor>{{ item.total_tail_big_small }}</span></td>
                    <td>
                        <span class="text" :class="CodeColor(item.wave_band)">{{ item.wave_band }}</span>
                    </td>
                    <td>
                        <Button size="mini"@click="Edit(item)">开奖</Button>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr v-if="main._empty">
                    <td class="empty" colspan="100">暂无数据</td>
                </tr>
            </tfoot>
        </table>
        <Pagination v-if="main._total" class="o-mtb" v-model="Page" @turning="Get" :total="main._total" :count="main._count" :pageSize="Filter.per_page"></Pagination>      -->
        <editer v-model="Editer.view" :filter="Filter" :form="Editer.form" @lottery="Lottery" @finish="Reload()" />
        <!-- <Report v-model="Report.view" title="颁奖过账核对" :form="Report.form"></Report> -->
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
import SixMix from '@lottery.admin.2.0/mixin/six.js'
import Editer from './model/open-lhc'
import Report from './model/open-report'
import History from './components/history'
export default {
    name: 'CreditSixOpen',
    mixins: [StoreMix, SixMix],
    data() {
        return {
            store: 'game/history',
            Filter: {
                games_id: 34,
                games_type: 7,
                per_page: 20
            },
            useGame: [4, 5, 31],
            Report: {
                view: false,
                form: {}
            }
        }
    },
    computed: {
        validGames() {
            let list = []
            for (let item of this.Opt.hasDir.game) {
                let {id} = item
                if (~this.useGame.indexOf(id)) {
                    list.push(item)
                }
            }
            return list
        }
    },
    methods: {
        init() {
            this.FilterIntervalSet('nextyear')
            this.ReloadCheck()
        },
        ChangeGame(item) {
            this.ReloadCheck(item.id, item.type)
        },
        ReloadCheck(id=this.Running.id, type=this.Running.group) {
            this.Filter.games_id = id
            this.Filter.games_type = type
            for (let item of this.useGame) {
                if (item == id) {
                    this.Filter.games_id = id
                    this.Filter.games_type = type
                }
            }
            this.Reload()
        },
        Reload() {
            if (this.$refs['history'] && this.$refs['history'].reload) {
                this.$refs['history'].reload()
            }
        },
        // FormatCode(str){
        //     if(str){
        //         let normal = str.split('+')[0]
        //         if(normal){
        //             normal = normal.split(',')
        //         }
        //         let special = str.split('+')[1]
        //         if(special){
        //             special = special.split(',')
        //             normal = normal.concat(special)
        //         }
        //         return normal
        //     }
        //     return []
        // },
        Lottery(info) {
            // this.Report.view = true
            this.Go('credit/report', {auto: true})
        },
        // CodeZodiac(item,unit){
        //     let map = {
        //         '一': 'one_zodiac',
        //         '二': 'two_zodiac',
        //         '三': 'three_zodiac',
        //         '四': 'four_zodiac',
        //         '五': 'five_zodiac',
        //         '六': 'six_zodiac',
        //         '特': 'special_zodiac',
        //     }
        //     return map[unit] && item[map[unit]] ? item[map[unit]] : '-'
        // },
        Handel(e) {
            this.Edit(e)
        }
    },
    components: {
        Editer,
        Report,
        History
    },
    // directives: {
    //     markColor: {
    //         inserted(el,e,vnode,oldvnode) {
    //             let text = el.innerText, mark;
    //             if(text == '大' || text == '单' || text == '家禽'){
    //                 mark = '1'
    //             }
    //             if(text == '小' || text == '双' || text == '野兽'){
    //                 mark = '2'
    //             }
    //             if(text == '和'){
    //                 mark = '3'
    //             }
    //             if(mark >= 0 && mark <= 3){
    //                 el.setAttribute('mark',mark)
    //             }
    //         },
    //         update(el,binding,vnode,oldvnode){
    //             let text = el.innerText, mark;
    //             if(text == '大' || text == '单' || text == '家禽'){
    //                 mark = '1'
    //             }
    //             if(text == '小' || text == '双' || text == '野兽'){
    //                 mark = '2'
    //             }
    //             if(text == '和'){
    //                 mark = '3'
    //             }
    //             console.log('update',text,mark)
    //             if(mark >= 0 && mark <= 3){
    //                 el.setAttribute('mark',mark)
    //             }
    //         },
    //     },
    // },
    activated() {
        if (!this.Opt.isTeam) {
            this.Rd('credit')
        }
        this.init()
    }
}
</script>
