<style lang="scss">
    @import "./group.scss";
    .GameCreditGroup.SixFull {
        .normal li{
            border-right: 1px solid #ddd;
            border-bottom: 1px solid #ddd;
        }
    }
</style>
<template>
    <ul v-if="list && (list.child || list.length)" class="GameCreditGroup SixFull" :class="['type-' + COptions.type,type]">
        <template v-if="type == 'normal'">
            <prompt v-model="prompt" :interval="promptInterval" />
            <el-row v-for="(item,index) in list.child" :key="index" tag="li" :class="{'pick': item.pick && Quick}" @click.native="Pick(item)">
                <el-col tag="div" :span="promptInterval[0]">
                    <span class="c-text-75">{{ item.name }}</span>
                </el-col>
                <el-col v-if="promptInterval[1]" tag="div" :span="promptInterval[1]">
                    <template v-if="!CalcuOdds(item)">
                        <div v-if="odds._loading">
                            <Load size=".8" color="rgba(0,0,0,.3)" speed="fast" />
                        </div>
                        <div v-else class="odds">-</div>
                    </template>
                    <div v-else v-odds="CalcuOdds(item)" class="odds">
                        <span>{{ CalcuOdds(item).odds }}</span><span v-if="CalcuOdds(item).has_double_odds"><span style="padding:0 2px;">/</span>{{ CalcuOdds(item).max_odds }}</span>
                    </div>
                </el-col>
                <el-col tag="div" :span="(!Quick || mode === 'box') ? promptInterval[2] : 0">
                    <div v-if="mode == 'box'">
                        <el-checkbox v-model="item.pick" :checked="item.pick" :disabled="disabled || item.disabled || (hasOdds && !CalcuOdds(item))" @change="$emit('box',item)" />
                    </div>
                    <input v-else v-model.number="item.amount" @keyup.enter="Checkout()">
                </el-col>
                <el-col tag="div" class="c-text-l o-pl-l" :span="(!Quick || mode === 'box') ? promptInterval[3] : (promptInterval[3] + promptInterval[2])">
                    <ul v-if="QuickBetList[item.name]">
                        <a v-for="i in BetList(item.name)" class="six_ball_mini" :class="CodeColor(i)">{{ i }}</a>
                    </ul>
                </el-col>
            </el-row>
        </template>
        <template v-else-if="type == 'queue'">
            <el-row tag="div">
                <el-col v-for="(pack,unit) in MakeQueue(list,length)" :key="unit" :span="Span(length)">
                    <GameCreditGroupSixFull :list="pack" :title="false" :prompt="prompt" :prompt-interval="promptInterval" :mode="mode" :fortynine="fortynine" :has-odds="hasOdds" :disabled="disabled" @box="$emit('box',$event)" />
                </el-col>
            </el-row>
        </template>
    </ul>
</template>

<script>
import Credit from '../../../../../mixin/credit.js'
import Prompt from './prompt'
import SixMix from '@lottery.admin.2.0/mixin/six.js'
export default {
    name: 'GameCreditGroupSixFull',
    mixins: [SixMix, Credit],
    data() {
        return {
            active: 0
        }
    },
    props: {
        list: {},
        prompt: {
            type: Array,
            default() {
                return ['项目', '赔率', '金额', '号码']
            }
        },
        quick: {
            default: true
        },
        promptInterval: {
            type: Array,
            default() {
                return [2, 2, 3, 17]
            }
        },
        color: {
            default: null
        },
        length: {
            default: 5
        },
        dice: {
            default: false
        },
        type: {
            default: 'normal'
        },
        mode: {
            default: 'normal'
        },
        disabled: {
            default: false
        },
        fortynine: {
            default: true
        },
        hasOdds: {
            default: true
        }
    },
    computed: {
        odds() {
            return this.$store.state.game.odds
        },
        Quick() {
            if (this.COptions.opt && this.COptions.opt.onlyQuick) {
                return false
            }
            return this.COptions.type == 2
        }
    },
    directives: {
        odds: {
            inserted(el, e, vnode, oldvnode) {
                el.setAttribute('oddsanimation', 0)
            },
            update(el, binding, vnode, oldvnode) {
                let {value, oldValue} = binding
                if (oldValue && value && value.odds) {
                    if (value.odds != oldValue.odds || value.has_double_odds && value.max_odds != oldValue.max_odds) {
                        let count = Number(el.getAttribute('oddsanimation'))
                        el.setAttribute('oddsanimation', count + 1)
                        if (el.className.split(' ').indexOf('change') < 0) {
                            el.className = el.className + ' change'
                        }
                        setTimeout(() => {
                            if (el) {
                                let count = Number(el.getAttribute('oddsanimation'))
                                el.setAttribute('oddsanimation', count - 1)
                                if (count <= 1) {
                                    let classNames = el.className.split(' ')
                                    let arr = []
                                    for (let item of classNames) {
                                        if (item != 'change') {
                                            arr.push(item)
                                        }
                                    }
                                    el.className = arr.join(' ')
                                }
                            }
                        }, 20000)
                    }
                }
            }
        }
    },
    methods: {
        Pick(item) {
            if (item.id) {
                if (item && this.Quick) {
                    this.$set(item, 'pick', !item.pick)
                }
            }
        },
        IsInt(str) {
            if (!str) {
                return false
            }
            return /^[0-9]*$/.test(str)
        },
        Input(item, val) {
            this.$set(item, 'amount', val)
            this.$set(item, 'quickView', false)
        },
        Span(len) {
            return this.Big(24).div(len).round(0, 1).toNumber()
        },
        BetList(name) {
            let list = this.Origin(this.QuickBetList[name])
            if (this.fortynine === false) {
                for (let item of list) {
                    if (item===49) {
                        list.pop()
                        return list
                    }
                }
            }
            return list
        }
    },
    components: {
        Prompt
    }
}
</script>
