<style lang="scss">
    @import "../../../../../style/config.scss";
    .OddsGroupTql2 {

    }
</style>
<template>
    <div class="OddsGroupTql2">

        <div v-if="nav.active.name === '主盘势'">
            <group :list="Table[1]" :length="5" width="2.2rem" title="一字组合" :color="false" :btn="true" />
            <fast class="o-mt-s o-mb-l" :table="Table" :group="[1]">一字组合</fast>
            <!--             <div class="l-flex-c" v-for="(item,index) in ['佰定位','拾定位','个定位','佰拾和数','佰个和数','拾个和数','佰拾个和数','佰拾个和数尾数']">
                <div class="l-flex-c u-b" style="width:120px; height:35px; background:#E0E0E0;">
                    <p v-html="item" class="c-text-c c-text-b c-text-75" style="width:100%;"></p>
                </div>
                <Group class="u-br u-bt u-bb" :list="Table[index+2]" :length="6" width="2.2rem" title="" :color="false" :btn="true"></Group>
            </div> -->
            <div class="l-flex-c">
                <div class="l-flex-c u-b" style="width:120px; height:35px; background:#E0E0E0;">
                    <p class="c-text-c c-text-b c-text-75" style="width:100%;">佰定位</p>
                </div>
                <group class="u-br u-bt u-bb" :list="Table[2]" :length="2" width="2.2rem" title="" :color="false" :btn="true" />
                <group class="u-br u-bt u-bb" :list="Table[3]" :length="2" width="2.2rem" title="" :color="false" :btn="true" />
                <group class="u-br u-bt u-bb" :list="Table[4]" :length="2" width="2.2rem" title="" :color="false" :btn="true" />
            </div>
            <div class="l-flex-c">
                <div class="l-flex-c u-b" style="width:120px; height:35px; background:#E0E0E0;">
                    <p class="c-text-c c-text-b c-text-75" style="width:100%;">拾定位</p>
                </div>
                <group class="u-br u-bt u-bb" :list="Table[5]" :length="2" width="2.2rem" title="" :color="false" :btn="true" />
                <group class="u-br u-bt u-bb" :list="Table[6]" :length="2" width="2.2rem" title="" :color="false" :btn="true" />
                <group class="u-br u-bt u-bb" :list="Table[7]" :length="2" width="2.2rem" title="" :color="false" :btn="true" />
            </div>
            <div class="l-flex-c">
                <div class="l-flex-c u-b" style="width:120px; height:35px; background:#E0E0E0;">
                    <p class="c-text-c c-text-b c-text-75" style="width:100%;">个定位</p>
                </div>
                <group class="u-br u-bt u-bb" :list="Table[8]" :length="2" width="2.2rem" title="" :color="false" :btn="true" />
                <group class="u-br u-bt u-bb" :list="Table[9]" :length="2" width="2.2rem" title="" :color="false" :btn="true" />
                <group class="u-br u-bt u-bb" :list="Table[10]" :length="2" width="2.2rem" title="" :color="false" :btn="true" />
            </div>
            <div class="l-flex-c">
                <div class="l-flex-c u-b" style="width:120px; height:35px; background:#E0E0E0;">
                    <p class="c-text-c c-text-b c-text-75" style="width:100%;">佰拾和数</p>
                </div>
                <div style="width:238px; padding:0 2.2rem;" />
                <group class="u-br u-bt u-bb" :list="Table[11]" :length="2" width="2.2rem" title="" :color="false" :btn="true" />
            </div>
            <div class="l-flex-c">
                <div class="l-flex-c u-b" style="width:120px; height:35px; background:#E0E0E0;">
                    <p class="c-text-c c-text-b c-text-75" style="width:100%;">佰个和数</p>
                </div>
                <div style="width:238px; padding:0 2.2rem;" />
                <group class="u-br u-bt u-bb" :list="Table[12]" :length="2" width="2.2rem" title="" :color="false" :btn="true" />
            </div>
            <div class="l-flex-c">
                <div class="l-flex-c u-b" style="width:120px; height:35px; background:#E0E0E0;">
                    <p class="c-text-c c-text-b c-text-75" style="width:100%;">佰拾个和数</p>
                </div>
                <div style="width:238px; padding:0 2.2rem;" />
                <group class="u-br u-bt u-bb" :list="Table[13]" :length="2" width="2.2rem" title="" :color="false" :btn="true" />
            </div>
            <div class="l-flex-c">
                <div class="l-flex-c u-b" style="width:120px; height:35px; background:#E0E0E0;">
                    <p class="c-text-c c-text-b c-text-75" style="width:100%;">佰拾个和数尾数</p>
                </div>
                <group class="u-br u-bt u-bb" :list="Table[14]" :length="2" width="2.2rem" title="" :color="false" :btn="true" />
                <group class="u-br u-bt u-bb" :list="Table[15]" :length="2" width="2.2rem" title="" :color="false" :btn="true" />
            </div>
        </div>

        <div v-else-if="nav.active.name === '一字盘势'">
            <group :list="Table[1]" :length="5" width="2.2rem" title="一字不定位快选" :color="false" :btn="true" />
            <fast class="o-mt-s o-mb-l" :table="Table" :group="[1]">一字不定位快选</fast>

            <div v-for="(item,index) in ['佰定位<br>XXOXX','拾定位 XXXOX','个定位 XXXXO']" class="l-flex-c o-mb-s">
                <div class="l-flex-c u-b" style="width:100px; height:60px; background:#E0E0E0;">
                    <p class="c-text-c c-text-b c-text-75" style="width:100%;" v-html="item" />
                </div>
                <group class="u-br u-bt u-bb" :list="Table[index + 2]" :length="5" width="2.2rem" title="" :color="false" :btn="true" />
            </div>
        </div>

        <div v-else-if="nav.active.name === '二字定位'">
            <div v-for="(item,index) in ['佰拾定位','佰个定位','拾个定位']" class="l-flex-c">
                <div class="l-flex-c u-b" style="width:120px; height:35px; background:#E0E0E0;">
                    <p class="c-text-c c-text-b c-text-75" style="width:100%;" v-html="item" />
                </div>
                <group class="u-br u-bt u-bb" :list="Table[index + 1]" :length="6" width="4rem" title="" :color="false" :btn="true" />
            </div>
        </div>

        <div v-else-if="nav.active.name === '二字组合'">
            <group class="u-br u-bt u-bb" :list="Table[1]" :length="5" width="2.2rem" title="二字组合" :color="false" :btn="true" />
        </div>

        <div v-else-if="nav.active.name === '二字和数'">
            <div class="o-mb-s">
                <div v-for="(item,index) in ['佰拾和数<br>OOX','佰拾和数尾数<br>OOX']" class="l-flex-c">
                    <div class="l-flex-c u-b" style="width:120px; background:#E0E0E0;" :style="!index ? 'height:64px;' : 'height:70px;'">
                        <p class="c-text-c c-text-b c-text-75" style="width:100%;" v-html="item" />
                    </div>
                    <group class="u-br u-bt u-bb" :list="Table[index + 1]" :length="index ? 5 : 6" width="2.2rem" title="" :ball="false" :color="false" :btn="true" />
                </div>
            </div>
            <div class="o-mb-s">
                <div v-for="(item,index) in ['佰个和数<br>OXO','佰个和数尾数<br>OXO']" class="l-flex-c">
                    <div class="l-flex-c u-b" style="width:120px; background:#E0E0E0;" :style="!index ? 'height:64px;' : 'height:70px;'">
                        <p class="c-text-c c-text-b c-text-75" style="width:100%;" v-html="item" />
                    </div>
                    <group class="u-br u-bt u-bb" :list="Table[index + 3]" :length="6" width="2.2rem" title="" :ball="false" :color="false" :btn="true" />
                </div>
            </div>
            <div class="o-mb-s">
                <div v-for="(item,index) in ['拾个和数<br>XOO','拾个和数尾数<br>XOO']" class="l-flex-c">
                    <div class="l-flex-c u-b" style="width:120px; background:#E0E0E0;" :style="!index ? 'height:64px;' : 'height:70px;'">
                        <p class="c-text-c c-text-b c-text-75" style="width:100%;" v-html="item" />
                    </div>
                    <group class="u-br u-bt u-bb" :list="Table[index + 5]" :length="6" width="2.2rem" title="" :ball="false" :color="false" :btn="true" />
                </div>
            </div>
        </div>

        <div v-else-if="nav.active.name === '三字定位'">
            <group class="u-br u-bt u-bb" :list="Table[1]" :length="3" width="4rem" title="三字定位" :color="false" :btn="true" />
        </div>

        <div v-else-if="nav.active.name === '三字组合'">
            <group class="u-br u-bt u-bb" :list="Table[1]" :length="3" width="4rem" title="三字组合" :color="false" :btn="true" />
        </div>

        <div v-else-if="nav.active.name === '三字和数'">
            <div class="o-mb">
                <div v-for="(item,index) in ['佰拾个和数<br>OOO','佰拾个和数尾数<br>OOO']" class="l-flex-c o-mb-s">
                    <div class="l-flex-c u-b" style="width:120px; background:#E0E0E0;" :style="!index ? 'height:96px;' : 'height:70px;'">
                        <p class="c-text-c c-text-b c-text-75" style="width:100%;" v-html="item" />
                    </div>
                    <group class="u-br u-bt u-bb" :list="Table[index + 1]" :length="index ? 5 : 6" width="2.2rem" title="" :ball="false" :color="false" :btn="true" />
                </div>
            </div>
        </div>

        <div v-else-if="nav.active.name === '组选三'">
            <div class="l-flex">
                <div v-for="(item,index) in ['5码','6码','7码','8码','9码','全包']">
                    <group class="u-br u-bt u-bb" :list="Table[index + 1]" :length="1" width="2.2rem" :title="item" :color="false" :btn="true" />
                </div>
            </div>
            <fast class="o-mt" :table="Table" :group="[1,2,3,4,5]" :tabs="[{title: '选5码',value: 1},{title: '选6码',value: 2},{title: '选7码',value: 3},{title: '选8码',value: 4},{title: '选9码',value: 5}]">组选三</fast>
        </div>

        <div v-else-if="nav.active.name === '组选六'">
            <div class="l-flex">
                <div v-for="(item,index) in ['4码','5码','6码','7码','8码']">
                    <group class="u-br u-bt u-bb" :list="Table[index + 1]" :length="1" width="2.2rem" :title="item" :color="false" :btn="true" />
                </div>
            </div>
            <fast class="o-mt" :table="Table" :group="[1,2,3,4,5]" :tabs="[{title: '选4码',value: 1},{title: '选5码',value: 2},{title: '选6码',value: 3},{title: '选7码',value: 4},{title: '选8码',value: 5}]">组选六</fast>
        </div>

        <div v-else-if="nav.active.name === '复式组合'">
            <group class="u-br u-bt u-bb" :list="Table[1]" :length="3" width="4rem" title="复式组合" :color="false" :btn="true" />
        </div>

        <div v-else-if="nav.active.name === '一字过关'">
            <div class="l-flex">
                <template v-for="(item,index) in ['佰','拾','个']">
                    <group class="o-mr-s" :list="Table[index + 1]" :length="2" width="2.2rem" :title="item" :vertical="false" :color="false" :btn="true" />
                </template>
            </div>
        </div>

        <div v-else-if="nav.active.name === '跨度'">
            <!-- <Group class="" :list="Table[1]" :length="2" width="2.2rem" title="跨度" :color="false" :btn="true"></Group> -->
            <div v-for="(item,index) in ['跨度']" class="l-flex-c o-mb-s">
                <div class="l-flex-c u-b" style="width:100px; height:60px; background:#E0E0E0;">
                    <p class="c-text-c c-text-b c-text-75" style="width:100%;" v-html="item" />
                </div>
                <group class="u-br u-bt u-bb" :list="Table[index + 1]" :length="5" width="2.2rem" title="" :color="false" :btn="true" />
            </div>
        </div>
        <div v-else-if="nav.active.name === '其他'">
            <div v-for="(item,index) in ['其他','准对','不出牌']" class="l-flex-c o-mb-s">
                <div class="l-flex-c u-b" style="width:100px; background:#E0E0E0;" :style="!index ? 'height:35px;' : 'height:70px;'">
                    <p class="c-text-c c-text-b c-text-75" style="width:100%;" v-html="item" />
                </div>
                <group class="u-br u-bt u-bb" :list="Table[index + 1]" :length="5" width="2.2rem" title="" :color="false" :btn="true" />
            </div>
        </div>

        <div v-else-if="nav.active.name === '定位胆'">
            <div class="l-flex">
                <group class="u-bb u-br" size="big" :list="Table[1]" :length="1" title="定位胆百位" :color="false" />
                <group class="u-bb u-br" size="big" :list="Table[2]" :length="1" title="定位胆十位" :color="false" />
                <group class="u-bb u-br" size="big" :list="Table[3]" :length="1" title="定位胆个位" :color="false" />
            </div>
        </div>
        <div v-else>
            <group class="u-bb u-br" size="big" :list="TableAll" :length="5" :title="nav.active.name" :color="false" />
        </div>
        <!-- 主盘势 yi'zi-->
    </div>
</template>

<script>
import Group from './odds-group'
import Fast from './odds-fast-2'
export default {
    name: 'OddsGroupTql2',
    mixins: [],
    data() {
        return {

        }
    },
    props: {
        nav: {},
        Table: {},
        tabs: {}
    },
    computed: {
        TableAll() {
            let list = []
            for (let unit in this.Table) {
                for (let item of this.Table[unit]) {
                    list.push(item)
                }
            }
            return list
        }
    },
    methods: {
        GroupStep(unit, step=0.01) {
            if (unit && this.Table[unit]) {
                for (let item of this.Table[unit]) {
                    this.$set(item, 'odds', this.Big(item.odds).add(step).toNumber())
                }
            }
        }
    },
    components: {
        Group, Fast
    }
}
</script>
