<style lang="scss">
    .GameFormalRenxuan1 {

    }
</style>
<template>
    <div class="GameFormalPlay GameFormalRenxuan1">
        <ul v-if="params" class="box">
            <div class="o-mb o-plr-s">
                <span>选择位置：</span>
                <span class="o-plr">
                    <el-checkbox v-for="(item,index) in template.defaultCheckedPos" :key="item" v-model="digitDir[item].value">{{ digitDir[item].name }}</el-checkbox>
                </span>
                <span>( 温馨提示：你选择了 <b class="c-color-t">{{ programme.number }}</b> 个位置，系统会自动根据位置组合成 <b class="c-color-t">{{ programme.total }}</b> 个方案 )</span>
            </div>
            <li v-for="(row,unit) in template.sub[0]" class="row l-flex-1">
                <div class="title">
                    <p>{{ row }}</p>
                </div>

                {{/* 选球组 */}}
                <div class="balls">
                    <li v-for="(item,index) in MakeBallArray()" class="ball" :class="{'active': params[unit] && params[unit][index]}" @click="Pick(unit,index)">
                        <i v-if="CheckRank(row,item)" class="rank-number" :class="{'active': CheckRank(row,item).active}">{{ CheckRank(row,item).number }}</i>
                        <span>{{ item }}</span>
                    </li>
                </div>

                {{/* 工具组 */}}
                <div class="tools">
                    <li v-for="(item,index) in ['全','大','小','单','双','清']" class="ball" @click="Tools(unit,item)">
                        <span>{{ item }}</span>
                    </li>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import Common from './num1.js'
export default {
    mixins: [Common],
    components: {

    }
}

</script>
