<style lang="scss">
    @import "../../../../style/config.scss";
    .GameFormalFollow {
        overflow:hidden;
        i {
            color:#ccc; font-size:1rem; cursor:pointer;
            &.active {
                color:$color-t;
            }
        }
        .el-dialog {
            min-width:600px; max-width:650px;
        }
        .btn-type-red-1 {
            padding:12px 0; font-size:.8rem; width:30.33%; margin-left:1%; margin-right:1%;
        }
        .el-input__inner {
            padding:0 6px;
        }
        .el-checkbox+.el-checkbox{
            margin-left:16px;
        }
    }
</style>
<template>
    <div class="GameFormalFollow o-pt">
        <div class="c-float-l o-pt" style="width:45%;">
            <Button type="primary" size="small" class="" @click="FollowView()">我要追号</Button>
        </div>
        <div class="c-float-r" style="width:55%;">
            <div class="o-mt-s">
                <el-checkbox v-model="stop_skips">官方跳开即停</el-checkbox>
            </div>
            <div class="o-mt">
                <el-checkbox v-model="stop_win">中奖后停止追号</el-checkbox>
            </div>
        </div>
        <el-dialog :title="this.Running.title + '追号计划'" :visible.sync="view" class="pt-modul-big" top="8vh">

            <div class="o-pb u-bb">
                <el-radio-group v-model="type" size="small">
                    <el-radio-button :label="1">同倍追号</el-radio-button>
                    <el-radio-button :label="2">翻倍追号</el-radio-button>
                </el-radio-group>
                <span class="c-float-r">
                    <el-checkbox v-model="stop_skips">官方跳开即停</el-checkbox>
                    <el-checkbox v-model="stop_win">中奖后停止追号</el-checkbox>
                </span>
            </div>
            <div class="o-pt">
                <span>追号计划：</span>
                <template v-if="type == 2">
                    <span>每隔</span>
                    <el-input v-model="options.interval" type="number" size="small" style="width:2.5rem;" />
                    <span>期翻</span>
                    <el-input v-model="options.multiple" type="number" size="small" style="width:2.5rem;" />
                    <span>倍，</span>
                </template>
                <span>起始倍数：</span>
                <el-input v-model="options.start" type="number" size="small" style="width:2.5rem;" />
                <span class="o-ml-s">追号期数：</span>
                <el-input v-model="options.length" type="number" size="small" style="width:2.5rem;" />
            </div>

            {{/* 追号配置 */}}
            <div class="o-mt">
                <span>追号期数</span>
                <el-select v-model="options.preinstall" class="o-mlr-s" placeholder="请选择" size="small" style="width:3.3rem;">
                    <el-option v-for="item in [{label: '5',value: 5},{label: '10',value: 10},{label: '15',value: 15},{label: '20',value: 20},{label: '25',value: 25},{label: '所有',value: 9999}]" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
                <span>追号总期数：</span>
                <b class="c-color-t">{{ cacluTotal.count }}</b>
                <span>，追号总金额：</span>
                <b class="c-color-t">{{ cacluTotal.total }}</b>
                <span>元</span>
                <!-- <el-button class="c-float-r" type="warning" size="small" @click="Plan()">生成追号计划</el-button> -->
                <Button class="c-float-r" @click="Plan()">生成追号计划</Button>
            </div>

            {{/* 追号列表 */}}
            <el-table v-if="list" v-loading="$store.state.game.plan._loading" class="o-mt" :data="list" height="270" style="width:100%; font-size:12px;" size="mini">
                <el-table-column label="" width="40" align="center">
                    <template slot-scope="scope">
                        <i class="el-icon-circle-check" :class="{'active': scope.row.multiple > 0}" @click="Pick(scope.row)" />
                    </template>
                </el-table-column>
                <el-table-column prop="number" label="期号" width="130" align="center" />
                <el-table-column label="倍数" align="center">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.multiple" class="o-mr-s" size="mini" style="width:3rem;" />倍
                    </template>
                </el-table-column>
                <el-table-column label="当前投入" align="center">
                    <template slot-scope="scope">
                        {{ calcuAmount(scope.row.multiple) }}元
                    </template>
                </el-table-column>
                <el-table-column prop="time" label="截至时间" width="150" align="center" />
                <!--                <el-table-column label="截至时间" width="150" align="center">
                    <template slot-scope="scope">
                        {{ Time(scope.row.time * 1000,'yyyy-MM-dd hh:mm:ss') }}
                    </template>
                </el-table-column> -->
            </el-table>

            <!--             <div class="o-mt-l">
                <a class="btn-type-red-1" @click="Clean()">清空追号</a>
                <a class="btn-type-red-1" @click="Checkout()">{{ cacluTotal.count ? '追号投注' : '直接投注' }}</a>
                <a class="btn-type-red-1" @click="view=false">取消</a>
            </div> -->
            <div class="l-flex-c o-pt">
                <div class="l-flex-1 o-plr c-text-c">
                    <Button size="big" full @click="Clean()">清空追号</Button>
                </div>
                <div class="l-flex-1 o-plr c-text-c">
                    <Button size="big" full @click="Checkout()">{{ cacluTotal.count ? '追号投注' : '直接投注' }}</Button>
                </div>
                <div class="l-flex-1 o-plr c-text-c">
                    <Button size="big" full @click="view = false">取消</Button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Common from './follow.js'
export default {
    mixins: [Common],
    components: {

    }
}
</script>
