<style lang="scss">
    .GameCreditPK10 {

    }
</style>
<template>
    <div v-if="List" v-loading="!List.length" class="GameCreditPK10">
        <category title="整合">
            <group :list="List[0]" :length="4" :prompt="prompt" :prompt-interval="promptInterval" type="queue" />
            <el-row tag="div">
                <el-col v-for="unit in [1,2,3,4,5]" :key="unit" :span="5">
                    <group :list="List[unit]" :prompt="prompt" :prompt-interval="promptInterval" />
                </el-col>
            </el-row>
            <el-row tag="div">
                <el-col v-for="unit in [6,7,8,9,10]" :key="unit" :span="5">
                    <group :list="List[unit]" :prompt="prompt" :prompt-interval="promptInterval" />
                </el-col>
            </el-row>
        </category>
        <category title="第1-10名">
            <el-row tag="div">
                <el-col v-for="unit in [0,1,2,3,4]" :key="unit" :span="5">
                    <group :list="List[unit]" :prompt="prompt" :prompt-interval="promptInterval" />
                </el-col>
            </el-row>
            <el-row tag="div">
                <el-col v-for="unit in [5,6,7,8,9]" :key="unit" :span="5">
                    <group :list="List[unit]" :prompt="prompt" :prompt-interval="promptInterval" />
                </el-col>
            </el-row>
        </category>
        <category title="冠亚和值">
            <group :list="List[0]" :title="false" :length="5" :prompt="prompt" :prompt-interval="promptInterval" type="queue" />
        </category>
        <category title="冠亚组合">
            <group :list="List[0]" :title="false" :length="5" :prompt="prompt" :prompt-interval="promptInterval" type="queue" />
        </category>
    </div>
</template>

<script>
import Credit from './other.js'
import Category from './components/category'
import Row from './components/row'
import Group from './components/group'

export default {
    name: 'GameCreditPK10',
    mixins: [Credit],
    data() {
        return {

        }
    },
    props: ['List'],
    computed: {
        prompt() {
            return this.COptions.type == 1 ? ['号码', '赔率', '金额'] : ['号码', '赔率']
        },
        promptInterval() {
            return this.COptions.type == 1 ? [7, 6, 11] : [12, 12]
        }
    },
    methods: {

    },
    components: {
        Category, Row, Group
    }
}
</script>
