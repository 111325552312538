<style lang="scss">
    @import "../style/config.scss";
    .Button {
        cursor:pointer; display:inline-block; font-size:.7rem;
        button, a {
            padding:8px 16px; outline:none; cursor:pointer;
            background-color:#fff; background:linear-gradient(180deg,#fff,#f5f5f5,#c0c0c0);
            border:1px solid #aaa; color:#333;
            &:active, &:hover {
                background-color:#f0f0f0; background:linear-gradient(180deg,#fff,#f5f5f5,#b5b5b5);
                border-color:#999;
            }
        }
        &+.Button {
            margin-left:6px;
        }
        &.Button_big {
            button, a {
                padding:11px 18px;
            }
        }
        &.Button_small {
            button, a {
                padding:5px 13px;
            }
        }
        &.Button_mini {
            button, a {
                padding:4px 10px; font-size:.6rem;
            }
            &+.Button {
                margin-left:4px;
            }
        }
        &[full] {
            display:block;
            button, a {
                width:100%; padding-left:0; padding-right:0; text-align:center;
            }
        }
    }
</style>
<template>
    <div class="Button" :class="'Button_' + size">
        <a v-if="href" :href="href" @click="Submit($event)">
            <slot />
        </a>
        <button v-else :type="mode" @click="Submit($event)">
            <slot />
        </button>
    </div>
</template>

<script>
export default {
    name: 'Button',
    data() {
        return {

        }
    },
    props: {
        size: {
            default: 'small'
        },
        type: {
            default: 'normal'
        },
        disabled: {
            default: false
        },
        loading: {
            default: false
        },
        href: {
            default: null
        },
        mode: {
            default: 'button'
        }
    },
    computed: {

    },
    methods: {
        Submit(e) {
            if (!this.disabled && !this.loading) {
                this.$emit('click', e)
            }
        }
    }
}
</script>