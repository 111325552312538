import Vue from 'vue'

// 分页控件
import Pagination from '@lottery.2.0/components/pagination.vue'
Vue.component('Pagination', Pagination)

import Loading from '@lottery.2.0/components/loading.vue'
Vue.component('Loading', Loading)


import Load from '@lottery.2.0/components/load.vue'
Vue.component('Load', Load)

import Avatar from '@lottery.2.0/components/avatar.vue'
Vue.component('Avatar', Avatar)

import VueContextMenu from '@xunlei/vue-context-menu'
Vue.use(VueContextMenu)

import Sound from '../components/sound.vue'
Vue.component('Sound', Sound)

// import tabs from '@lottery.2.0/components/tabs.vue'
// Vue.use(tabs)

import ImageUnload from '@lottery.admin.2.0/components/image-unload.vue'
Vue.component('ImageUnload', ImageUnload)

import Drag from '../components/drag.vue'
Vue.component('Drag', Drag)

import Icon from '@lottery.2.0/components/icon.vue'
Vue.component('Icon', Icon)

import Button from '@lottery.2.0/components/button.vue'
Vue.component('Button', Button)

import Password from '@lottery.2.0/components/password.vue'
Vue.component('Password', Password)

import DatePicker from '@lottery.2.0/components/datepicker.vue'
Vue.component('DatePicker', DatePicker)
