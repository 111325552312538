
import Formal from '../../../../../mixin/formal'
export default {
    name: 'GameCreditHezhi2',
    mixins: [Formal],
    data() {
        return {
            dir: null,
            table: null,
            params: null
        }
    },
    computed: {
        Dir() {
            return this.MakeBallArray()
        }
    },
    props: ['template', 'rank'],
    watch: {
        params: {
            handler(val) {
                if (val) {
                    this.$emit('change', this.MakeOrder())
                }
            },
            deep: true
        }
    },
    methods: {
        init() {
            this.Reset()
            for (let key of this.template.defaultCheckedPos) {
                this.digitDir[key].value = true
            }
        },
        Reset() {
            this.HezhiDirInit(this.template.algorithm[4] === 'C' ? 'group' : 'direct')
            let table = []
            let params = []
            for (let pack of this.template.sub[0]) {
                let row = []
                let row2 = []
                for (let item in this.MakeBallArray(this.template.sub[1])) {
                    row.push(item)
                    row2.push(false)
                }
                table.push(row)
                params.push(row2)
            }
            this.$set(this.$data, 'table', table)
            this.$set(this.$data, 'params', params)
        },
        Pick(unit, index) {
            this.$set(this.params[unit], index, !this.params[unit][index])
        }
    },
    components: {

    },
    mounted() {
        this.init()
    }
}