<style lang="scss">
    @import "./group.scss";
    .GameCreditFastInput {
        position:absolute; right:-35px; top:170px; width:25px; text-align:center;
        .btn {
            background-color:#9041dc; color:#fff; border-radius:0 10px 10px 0; cursor:pointer;
            border:1px solid #60209e; border-left:none;
        }
        .textarea {
            //position:absolute; top:280px; padding:10px; left:-300px;
            width:100%; box-sizing:border-box;
            border:1px solid #ccc; border-radius:6px;
            input {
                padding:0 8px;
            }
        }
        .color-violet{
            color:#a22faf;
        }
    }
    .fast-input-popover {
        .longcoderow {
            background-color:#fff2dd;
            &:nth-child(odd){
                background-color:#f1e6e6;
            }
        }
    }
</style>
<template>
    <div v-if="list && (list.child || list.length)" class="GameCreditFastInput" style="z-index:100;">
        <el-popover popper-class="longcode-popover" placement="left-start" width="300" trigger="hover">
            <div style="min-height:100px; background-color:#f0f0f0;">
                <div v-if="COptions.type === 1" class="textarea o-p">
                    <h4><b class="c-color-p c-line-18">快捷选号<span v-if="directionName">（{{ directionName }}）</span></b></h4>
                    <p>如需下注 01$10,02$20,03$30</p>
                    <p>可直接在输入框中输入以逗号分隔的数字</p>
                    <p>例 01,10,02,20,03,30</p>
                    <div class="o-pt-s">
                        <el-input v-model="fastStr" size="small" placeholder="" @keyup.enter.native="textareaInput()">></el-input>
                    </div>
                    <div class="o-ptb-s c-text-c">
                        <Button size="mini" @click="fastStr = null">清空选号</Button>
                        <Button size="mini" @click="textareaInput()">确认选号</Button>
                    </div>
                </div>
            </div>
            <div slot="reference" class="btn o-ptb">
                <p>快</p>
                <p>捷</p>
                <p>选</p>
                <p>号</p>
            </div>
        </el-popover>
    </div>
</template>

<script>
import Credit from '../../../../../mixin/credit.js'
import Prompt from './prompt'
import SixMix from '@lottery.admin.2.0/mixin/six.js'
export default {
    name: 'GameCreditFast',
    mixins: [SixMix, Credit],
    data() {
        return {
            amount: null,
            fastStr: null
        }
    },
    props: {
        type: {
            default: '特码'
        },
        list: {},
        direction: {
            default: 'a'
        },
        outbtn: {
            default: false
        }
    },
    computed: {
        // boardGroup(){
        //     let list = ['头尾','半波','生肖']
        //     list.unshift(this.type)
        //     return list
        // },
        directionName() {
            if (this.type && this.direction) {
                return `${this.type}${this.direction.toUpperCase()}`
            }
            return ''
        }
    },
    methods: {
        Reset() {
            this.$emit('Reset')
        },
        Checkout() {
            this.$emit('Checkout')
        },
        // PickByGroup(name){
        //     this.$set(this.boardPick,name,!this.boardPick[name])
        //     let list = this.QuickBetList[name]
        //     if(list && this.list && this.list.child){
        //         for(let item of this.list.child){
        //             for(let code of list){
        //                 if(item.name == code){
        //                     this.$set(item,'pick',this.boardPick[name])
        //                 }
        //             }
        //         }
        //     }
        // },
        // TransmitAmount(){
        //     if(this.amount && this.amount > 0){
        //         this.TransmitAmountInput()
        //     }else{
        //         //return this.Checkout()
        //         this.$store.state.base.Event.emit('credit_order_checkout')
        //     }
        // },
        // TransmitAmountInput(){
        //     //console.log('TransmitAmountInput',this.amount,this.Is('int',this.amount))
        //     this.$set(this,'boardPick',{})
        //     if(this.Is('int',this.amount) && this.amount > 0){
        //         if(this.list && this.list.child){
        //             for(let item of this.list.child){
        //                 if(item.pick){
        //                     this.$set(item,'amount',this.amount)
        //                     this.$set(item,'pick',false)
        //                 }
        //             }
        //         }
        //         this.amount = null
        //     }else{
        //         return this.Err('金额格式填写错误，金额必须是整数。')
        //     }
        // },
        textareaInput() {
            let str = this.fastStr
            if (str) {
                str = str.replace(/，/g, ",")
                let list = str.split(',')
                let obj = {}
                let error = 0
                while (list.length >= 2) {
                    let code = Number(list.splice(0, 1)[0])
                    let amount = Number(list.splice(0, 1)[0])
                    if (code && code >=1 && code<=49 && amount && this.Is('int', amount) && amount > 0) {
                        obj[code] = amount
                    }
                }
                if (this.list && this.list.child) {
                    for (let item of this.list.child) {
                        if (obj[item.name]) {
                            this.$set(item, 'amount', obj[item.name])
                        }
                    }
                }
                this.fastStr = null
            }
        }
    },
    components: {

    }
}
</script>
