<style lang="scss">
    @import "../../../../style/config.scss";
    .GameCreditActlogMudul {
        .note {
            padding:10px; border-radius:4px; background-color:#f5f5f5; overflow:auto; min-height:100px; max-height:250px;
        }
    }
</style>
<template>
    <el-dialog class="GameCreditActlogMudul" :title="title" :visible.sync="view" size="big" top="5vh" :modal-append-to-body="false">
        <div>
            <div class="c-line-13 c-color-g">
                <span>帐号：{{ Params.username }}</span>
                <span class="o-plr c-color-g">/</span>
                <span>目标帐号：{{ Params.aims_username }}</span>
                <span class="o-plr c-color-g">/</span>
                <span>操作时间：{{ Params.create_at }}</span>
            </div>
            <h5 class="o-mt-s">操作项：{{ Params.title }}</h5>
            <div class="note o-mt-s o-mb">
                <div class="c-line-12">{{ Params.note ? Params.note : '' }}</div>
            </div>
        </div>
        <div class="c-text-r">
            <Button type="info" plain @click="Cancel()">确定</Button>
        </div>
    </el-dialog>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.modul.js'
export default {
    name: 'GameCreditActlogMudul',
    mixins: [StoreMix],
    data() {
        return {
            //store : 'admin/account',
            Params: {
                menu: ''
            }
        }
    },
    computed: {

    },
    methods: {
        init() {

        }
    }
}
</script>
