<style lang="scss">
    @import "../../../../style/config.scss";
    .CreditSystemAnnoun {

    }
</style>
<template>
    <div class="CreditSystemAnnoun">
        <div class="l-flex-c o-mb">
            <div class="ctrl l-flex-1">
                <DatePicker v-model="Filter.interval" @change="MakeFilter()" />
            </div>
            <Button @click="Edit()">新增数据</Button>
        </div>
        <table v-loading="main._loading" class="table u-bl u-bb u-br" border="0" cellspacing="1" cellpadding="0">
            <colgroup>
                <col title="标题" width="20%">
                <col title="内容">
                <col title="状态" width="80px">
                <col title="创建时间" width="140px">
                <col title="操作" width="180px">
            </colgroup>
            <thead>
                <tr style="text-align:left;">
                    <td>标题</td>
                    <td>内容</td>
                    <td>状态</td>
                    <td>创建时间</td>
                    <td>操作</td>
                </tr>
            </thead>
            <tbody style="text-align:left;">
                <tr v-for="(item,index) in main._list" class="c-text-c">
                    <td>{{ item.title }}</td>
                    <td>{{ item.content }}</td>
                    <td>
                        <el-tag v-if="item.status === 1" type="success" size="small">正常</el-tag>
                        <el-tag v-if="item.status === 2" type="info" size="small">停用</el-tag>
                    </td>
                    <td>{{ item.create_at }}</td>
                    <td>
                        <Button v-if="item.status === 2" size="mini" @click="shelves(item,1)">启用</Button>
                        <Button v-if="item.status === 1" size="mini" @click="shelves(item,2)">停用</Button>
                        <Button size="mini" @click="Edit(item)">编辑</Button>
                        <Button size="mini" @click="Del(item)">删除</Button>
                    </td>
                </tr>
            </tbody>
        </table>
        <Pagination v-if="main._total" v-model="Page" class="o-mtb" :total="main._total" :count="main._count" :page-size="Filter.per_page" @turning="Get" />
        <editer v-model="Editer.view" :form="Editer.form" @finish="Get(Page)" />
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
import Editer from './model/announ'
export default {
    name: 'CreditSystemAnnoun',
    mixins: [StoreMix],
    data() {
        return {
            store: 'admin/announ',
            Filter: {
                per_page: 20
            }
        }
    },
    methods: {
        init() {
            this.Get()
        },
        shelves(item, status) {
            this.Put({
                ...item,
                status
            }).then(res => {
                this.Get(this.Page)
            })
        }
    },
    components: {
        Editer
    },
    mounted() {
        this.init()
    }
}
</script>
