<style lang="scss">
    @import "../../../../style/config.scss";
    .CreditSystemBase {
        header {
            background-color:#7BAAFF; font-size:16px; font-weight:bold; text-align:center; padding:3px 0;
        }
        .block-bg {
            background-color:#DFF9FF;
        }
        .row {
            padding:2px 0; max-width:280px;
        }
        .row-2 {
            padding:2px 0;
        }
    }
</style>
<template>
    <div v-loading="main._loading" class="CreditSystemBase">
        <el-row>
            <el-col :span="6">
                <header>六合彩设定</header>
                <div class="o-p u-bb">
                    <div class="row l-flex-c">
                        <div class="l-flex-1">新澳门每期开盘时间</div>
                        <el-time-picker v-model="Params.lhc_xac_open_time" size="mini" value-format="HH:mm:ss" placeholder="选择日期时间" :picker-options="{selectableRange: '00:00:00 - 23:59:59'}" :style="inputStyle" />
                    </div>
                    <div class="row l-flex-c">
                        <div class="l-flex-1">新澳门连码截至时间</div>
                        <el-time-picker v-model="Params.lhc_xac_serial_end_time" size="mini" value-format="HH:mm:ss" placeholder="选择日期时间" :picker-options="{selectableRange: '00:00:00 - 23:59:59'}" :style="inputStyle" />
                    </div>
                    <div class="row l-flex-c">
                        <div class="l-flex-1">新澳门正码截至时间</div>
                        <el-time-picker v-model="Params.lhc_xac_positive_time" size="mini" value-format="HH:mm:ss" placeholder="选择日期时间" :picker-options="{selectableRange: '00:00:00 - 23:59:59'}" :style="inputStyle" />
                    </div>
                    <div class="row l-flex-c">
                        <div class="l-flex-1">新澳门两面截至时间</div>
                        <el-time-picker v-model="Params.lhc_xac_side_end_time" size="mini" value-format="HH:mm:ss" placeholder="选择日期时间" :picker-options="{selectableRange: '00:00:00 - 23:59:59'}" :style="inputStyle" />
                    </div>
                    <div class="row l-flex-c">
                        <div class="l-flex-1">新澳门特码截至时间</div>
                        <el-time-picker v-model="Params.lhc_xac_special_time" size="mini" value-format="HH:mm:ss" placeholder="选择日期时间" :picker-options="{selectableRange: '00:00:00 - 23:59:59'}" :style="inputStyle" />
                    </div>
                </div>
                <div class="o-p u-bb">
                    <div class="row l-flex-c">
                        <div class="l-flex-1">香港每期开盘时间</div>
                        <el-time-picker v-model="Params.lhc_open_time" size="mini" value-format="HH:mm:ss" placeholder="选择日期时间" :picker-options="{selectableRange:'00:00:00 - 23:59:59'}" :style="inputStyle"></el-time-picker>
                    </div>
                    <div class="row l-flex-c">
                        <div class="l-flex-1">香港连码截至时间</div>
                        <el-time-picker v-model="Params.lhc_serial_end_time" size="mini" value-format="HH:mm:ss" placeholder="选择日期时间" :picker-options="{selectableRange:'00:00:00 - 23:59:59'}" :style="inputStyle"></el-time-picker>
                    </div>
                    <div class="row l-flex-c">
                        <div class="l-flex-1">香港正码截至时间</div>
                        <el-time-picker v-model="Params.lhc_positive_time" size="mini" value-format="HH:mm:ss" placeholder="选择日期时间" :picker-options="{selectableRange:'00:00:00 - 23:59:59'}" :style="inputStyle"></el-time-picker>
                    </div>
                    <div class="row l-flex-c">
                        <div class="l-flex-1">香港两面截至时间</div>
                        <el-time-picker v-model="Params.lhc_side_end_time" size="mini" value-format="HH:mm:ss" placeholder="选择日期时间" :picker-options="{selectableRange:'00:00:00 - 23:59:59'}" :style="inputStyle"></el-time-picker>
                    </div>
                    <div class="row l-flex-c">
                        <div class="l-flex-1">香港特码截至时间</div>
                        <el-time-picker v-model="Params.lhc_special_time" size="mini" value-format="HH:mm:ss" placeholder="选择日期时间" :picker-options="{selectableRange:'00:00:00 - 23:59:59'}" :style="inputStyle"></el-time-picker>
                    </div>
                </div>
                <div class="o-p u-bb">
                    <div class="row l-flex-c">
                        <div class="l-flex-1">澳门每期开盘时间</div>
                        <el-time-picker v-model="Params.lhc_ac_open_time" size="mini" value-format="HH:mm:ss" placeholder="选择日期时间" :picker-options="{selectableRange: '00:00:00 - 23:59:59'}" :style="inputStyle" />
                    </div>
                    <div class="row l-flex-c">
                        <div class="l-flex-1">澳门连码截至时间</div>
                        <el-time-picker v-model="Params.lhc_ac_serial_end_time" size="mini" value-format="HH:mm:ss" placeholder="选择日期时间" :picker-options="{selectableRange: '00:00:00 - 23:59:59'}" :style="inputStyle" />
                    </div>
                    <div class="row l-flex-c">
                        <div class="l-flex-1">澳门正码截至时间</div>
                        <el-time-picker v-model="Params.lhc_ac_positive_time" size="mini" value-format="HH:mm:ss" placeholder="选择日期时间" :picker-options="{selectableRange: '00:00:00 - 23:59:59'}" :style="inputStyle" />
                    </div>
                    <div class="row l-flex-c">
                        <div class="l-flex-1">澳门两面截至时间</div>
                        <el-time-picker v-model="Params.lhc_ac_side_end_time" size="mini" value-format="HH:mm:ss" placeholder="选择日期时间" :picker-options="{selectableRange: '00:00:00 - 23:59:59'}" :style="inputStyle" />
                    </div>
                    <div class="row l-flex-c">
                        <div class="l-flex-1">澳门特码截至时间</div>
                        <el-time-picker v-model="Params.lhc_ac_special_time" size="mini" value-format="HH:mm:ss" placeholder="选择日期时间" :picker-options="{selectableRange: '00:00:00 - 23:59:59'}" :style="inputStyle" />
                    </div>
                </div>
                <div class="o-p u-bb">
                    <div class="row l-flex-c">
                        <div class="l-flex-1">台湾每期开盘时间</div>
                        <el-time-picker v-model="Params.lhc_tw_open_time" size="mini" value-format="HH:mm:ss" placeholder="选择日期时间" :picker-options="{selectableRange:'00:00:00 - 23:59:59'}" :style="inputStyle"></el-time-picker>
                    </div>
                    <div class="row l-flex-c">
                        <div class="l-flex-1">台湾连码截至时间</div>
                        <el-time-picker v-model="Params.lhc_tw_serial_end_time" size="mini" value-format="HH:mm:ss" placeholder="选择日期时间" :picker-options="{selectableRange:'00:00:00 - 23:59:59'}" :style="inputStyle"></el-time-picker>
                    </div>
                    <div class="row l-flex-c">
                        <div class="l-flex-1">台湾正码截至时间</div>
                        <el-time-picker v-model="Params.lhc_tw_positive_time" size="mini" value-format="HH:mm:ss" placeholder="选择日期时间" :picker-options="{selectableRange:'00:00:00 - 23:59:59'}" :style="inputStyle"></el-time-picker>
                    </div>
                    <div class="row l-flex-c">
                        <div class="l-flex-1">台湾两面截至时间</div>
                        <el-time-picker v-model="Params.lhc_tw_side_end_time" size="mini" value-format="HH:mm:ss" placeholder="选择日期时间" :picker-options="{selectableRange:'00:00:00 - 23:59:59'}" :style="inputStyle"></el-time-picker>
                    </div>
                    <div class="row l-flex-c">
                        <div class="l-flex-1">台湾特码截至时间</div>
                        <el-time-picker v-model="Params.lhc_tw_special_time" size="mini" value-format="HH:mm:ss" placeholder="选择日期时间" :picker-options="{selectableRange:'00:00:00 - 23:59:59'}" :style="inputStyle"></el-time-picker>
                    </div>
                </div>
                <div class="o-p u-bb">
                    <div class="row l-flex-c">
                        <div class="l-flex-1">新加坡每期开盘时间</div>
                        <el-time-picker v-model="Params.lhc_xjp_open_time" size="mini" value-format="HH:mm:ss" placeholder="选择日期时间" :picker-options="{selectableRange:'00:00:00 - 23:59:59'}" :style="inputStyle"></el-time-picker>
                    </div>
                    <div class="row l-flex-c">
                        <div class="l-flex-1">新加坡连码截至时间</div>
                        <el-time-picker v-model="Params.lhc_xjp_serial_end_time" size="mini" value-format="HH:mm:ss" placeholder="选择日期时间" :picker-options="{selectableRange:'00:00:00 - 23:59:59'}" :style="inputStyle"></el-time-picker>
                    </div>
                    <div class="row l-flex-c">
                        <div class="l-flex-1">新加坡正码截至时间</div>
                        <el-time-picker v-model="Params.lhc_xjp_positive_time" size="mini" value-format="HH:mm:ss" placeholder="选择日期时间" :picker-options="{selectableRange:'00:00:00 - 23:59:59'}" :style="inputStyle"></el-time-picker>
                    </div>
                    <div class="row l-flex-c">
                        <div class="l-flex-1">新加坡两面截至时间</div>
                        <el-time-picker v-model="Params.lhc_xjp_side_end_time" size="mini" value-format="HH:mm:ss" placeholder="选择日期时间" :picker-options="{selectableRange:'00:00:00 - 23:59:59'}" :style="inputStyle"></el-time-picker>
                    </div>
                    <div class="row l-flex-c">
                        <div class="l-flex-1">新加坡特码截至时间</div>
                        <el-time-picker v-model="Params.lhc_xjp_special_time" size="mini" value-format="HH:mm:ss" placeholder="选择日期时间" :picker-options="{selectableRange:'00:00:00 - 23:59:59'}" :style="inputStyle"></el-time-picker>
                    </div>
                </div>
                <div class="o-p u-bb">
                    <div class="row l-flex-c">
                        <div class="l-flex-1">本年度的生肖</div>
                        <el-select v-model="Params.animals_year" placeholder="请选择" size="mini" :style="inputStyle2">
                            <el-option v-for="(item,index) in ['鼠','牛','虎','兔','龙','蛇','马','羊','猴','鸡','狗','猪']" :key="index" :label="item" :value="item" />
                        </el-select>
                    </div>
                </div>
                <!-- <div class="o-p">
                    <div class="row l-flex-c">
                        <div class="l-flex-1">特码赔率表 B = A -</div>
                        <el-input v-model="Params.lhc_decrease_formula_b_a" size="mini" :style="inputStyle2"></el-input>
                    </div>
                    <div class="row l-flex-c">
                        <div class="l-flex-1">特码赔率表 C = A -</div>
                        <el-input v-model="Params.lhc_decrease_formula_c_a" size="mini" :style="inputStyle2"></el-input>
                    </div>
                    <div class="row l-flex-c">
                        <div class="l-flex-1">特码赔率表 D = A -</div>
                        <el-input v-model="Params.lhc_decrease_formula_d_a" size="mini" :style="inputStyle2"></el-input>
                    </div>
                    <div class="row l-flex-c">
                        <div class="l-flex-1">特码赔率表 E = A -</div>
                        <el-input v-model="Params.lhc_decrease_formula_e_a" size="mini" :style="inputStyle2"></el-input>
                    </div>
                    <div class="row l-flex-c">
                        <div class="l-flex-1">特两面赔率 B = A -</div>
                        <el-input v-model="Params.lhc_decrease_formula_side_b_a" size="mini" :style="inputStyle2"></el-input>
                    </div>
                    <div class="row l-flex-c">
                        <div class="l-flex-1">特两面赔率 C = A -</div>
                        <el-input v-model="Params.lhc_decrease_formula_side_c_a" size="mini" :style="inputStyle2"></el-input>
                    </div>
                    <div class="row l-flex-c">
                        <div class="l-flex-1">特两面赔率 D = A -</div>
                        <el-input v-model="Params.lhc_decrease_formula_side_d_a" size="mini" :style="inputStyle2"></el-input>
                    </div>
                    <div class="row l-flex-c">
                        <div class="l-flex-1">特两面赔率 E = A -</div>
                        <el-input v-model="Params.lhc_decrease_formula_side_e_a" size="mini" :style="inputStyle2"></el-input>
                    </div>
                    <div class="row l-flex-c">
                        <div class="l-flex-1">连码减倍设定值</div>
                        <el-input v-model="Params.lhc_serial_formula" size="mini" :style="inputStyle2"></el-input>
                    </div>
                    <div class="row l-flex-c">
                        <div class="l-flex-1">连码减倍最大值(3)</div>
                        <el-input v-model="Params.lhc_serial_formula_max" size="mini" :style="inputStyle2"></el-input>
                    </div>
                </div> -->
            </el-col>
            <el-col :span="15">
                <header>全系统设定</header>
                <div class="block-bg o-p">
                    <p>
                        <span>全博弈系统维护中：</span>
                        <el-radio v-model="Params.web_maintain_level" label="1">是</el-radio>
                        <span class="o-pr" />
                        <el-radio v-model="Params.web_maintain_level" label="2">否</el-radio>
                        <span style="color:red;">（全博弈系统维护中开启时，仅公司阶层之管理者可登录）</span>
                    </p>
                </div>
                <div class="o-p u-bb">
                    <p class="c-line-16">【管理人员公告】</p>
                    <el-input v-model="Params.platform_lantern_agent" type="textarea" :rows="4" placeholder="请输入内容" />
                    <p class="c-line-16 o-pt-s">【会员跑马灯讯息】</p>
                    <el-input v-model="Params.platform_lantern_members" type="textarea" :rows="4" placeholder="请输入内容" />
                </div>
                <div class="o-p u-bb">
                    <p class="c-line-16">【六合彩相关设定】</p>
                    <el-row tag="div" class="row-2" :gutter="16">
                        <el-col :span="4">
                            <p class="c-text-r">系统维护中</p>
                        </el-col>
                        <el-col :span="4">
                            <el-radio v-model="Params.lhc_is_service" label="1">是</el-radio>
                            <span class="o-pr-s" />
                            <el-radio v-model="Params.lhc_is_service" label="0">否</el-radio>
                        </el-col>
                        <el-col :span="4">
                            <p class="c-text-r">大股东操盘功能</p>
                        </el-col>
                        <el-col :span="4">
                            <el-radio v-model="Params.lhc_trander_status" label="1">开</el-radio>
                            <span class="o-pr-s" />
                            <el-radio v-model="Params.lhc_trander_status" label="0">关</el-radio>
                        </el-col>
                        <el-col :span="8">
                            <p>※ 最高在线人数： {{ online.online }}</p>
                        </el-col>
                    </el-row>
                    <el-row tag="div" class="row-2" :gutter="16">
                        <el-col :span="4">
                            <p class="c-text-r">管理层特码减倍</p>
                        </el-col>
                        <el-col :span="4">
                            <el-radio v-model="Params.lhc_special_code_reduction_status" label="1">开</el-radio>
                            <span class="o-pr-s" />
                            <el-radio v-model="Params.lhc_special_code_reduction_status" label="0">关</el-radio>
                        </el-col>
                        <el-col :span="4">
                            <p class="c-text-r">允许自动走飞</p>
                        </el-col>
                        <el-col :span="4">
                            <el-radio v-model="Params.lhc_auto_matic_transfer_status" label="1">开</el-radio>
                            <span class="o-pr-s" />
                            <el-radio v-model="Params.lhc_auto_matic_transfer_status" label="0">关</el-radio>
                        </el-col>
                        <el-col :span="8">
                            <p>※ 最高代理在线人数： {{ online.online_agent }}</p>
                        </el-col>
                    </el-row>
                    <el-row tag="div" class="row-2" :gutter="16">
                        <el-col :span="4">
                            <p class="c-text-r">走飞启动中</p>
                        </el-col>
                        <el-col :span="4">
                            <el-radio v-model="Params.lhc_transfer_status" label="1">是</el-radio>
                            <span class="o-pr-s" />
                            <el-radio v-model="Params.lhc_transfer_status" label="0">否</el-radio>
                        </el-col>
                        <el-col :span="4">
                            <p class="c-text-r">走飞值盘中修改</p>
                        </el-col>
                        <el-col :span="4">
                            <el-radio v-model="Params.lhc_transfer_value_status" label="1">开</el-radio>
                            <span class="o-pr-s" />
                            <el-radio v-model="Params.lhc_transfer_value_status" label="0">关</el-radio>
                        </el-col>
                        <el-col :span="8">
                            <p>※ 最高会员在线人数： {{ online.online_member }}</p>
                        </el-col>
                    </el-row>
                </div>
                <div class="o-p">
                    <Button @click="Post()">确定修改</Button>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
export default {
    name: 'CreditSystemBase',
    mixins: [StoreMix],
    data() {
        return {
            store: 'admin/settings',
            Params: {},
            inputStyle: 'width:140px;',
            inputStyle2: 'width:100px;',
            online: {}
        }
    },
    methods: {
        init() {
            this.Get().then(res => {
                this.$set(this, 'Params', res.data)
            })
            this.OnLine()
        },
        OnLine() {
            this.Dp('base/GET_ONLINE').then(res => {
                this.$set(this, 'online', res.data)
            })
        }
    },
    components: {

    },
    mounted() {
        this.init()
    },
    activated() {
        this.OnLine()
    }
}
</script>
