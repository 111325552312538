<style lang="scss">
    //@import "../../../../../style/config.scss";
    .GameFormalNum6 {
        .row {
            .balls, .tools {
                .ball {
                    width:78px; height:30px; line-height:30px; margin-top:5px; margin-right:14px;
                    position:relative; display:inline-block; cursor:pointer; border-radius:5px;
                    border: 2px solid #ddd;
                    // &.active {
                    //     background:$color-t; border-color:$color-t;
                    //     span {
                    //         color:#fff;
                    //     }
                    // }
                }
                span {z-index:2; font-size:14px;}
            }
        }
    }
</style>
<template>
    <div class="GameFormalPlay GameFormalNum6">
        <ul v-if="params" class="box">
            <li v-for="(row,unit) in template.sub[0]" class="row l-flex l-flex-1">
                <div class="title">
                    <p>{{ row }}</p>
                </div>
                {{/* 选球组 */}}
                <div class="balls">
                    <li v-for="(item,index) in MakeBallArray(null,unit + 1)" class="ball" :class="{'active': params[unit] && params[unit][index]}" @click="Pick(unit,index)">
                        <span>{{ item }}</span>
                    </li>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import Common from './num6.js'
export default {
    mixins: [Common],
    components: {

    }
}
</script>
