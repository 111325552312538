<style lang="scss">
    @import "../../../style/config.scss";
    .CreditSixOnline {
        .breadcrumb {
            background-color:#f5f5f5; border-radius:4px; padding:10px 12px;
        }
        .table {
            a.btn {
                text-decoration:underline; padding:1px 3px;
            }
        }
    }
</style>
<template>
    <div class="CreditSix CreditSixOnline">
        <div class="ctrl l-flex-c o-mb">
            <button v-if="breadcrumb.length > 1" class="btn" plain @click="Breadcrumb(breadcrumb.length - 2,'id')">返回上一级</button>
            <div class="l-flex-1 o-pl o-mr-l">
                <div class="breadcrumb">
                    <el-breadcrumb separator="/">
                        <el-breadcrumb-item v-for="(item,index) in breadcrumb" :key="index"><a @click="Breadcrumb(index,'id')">{{ item.title }}</a></el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>
            <Button class="o-mlr" @click="Get(Page)">刷新</Button>
        </div>
        <table v-loading="main._loading" class="table" border="0" cellspacing="1" cellpadding="0">
            <thead>
                <tr>
                    <td>帐号</td>
                    <td>类型</td>
                    <td>当前在线人数</td>
                    <td>在线代理</td>
                    <td>在线会员</td>
                    <td>登录时间</td>
                    <td>登录IP</td>
                    <td>浏览器信息</td>
                    <td>系统</td>
                    <td>操作</td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item,index) in main._list" class="c-text-c">
                    <td>
                        <a v-if="item.type == 1" class="btn" @click="GetByBreadcrumb(item,'uid')">{{ item.username }}</a>
                        <a v-if="item.type == 0" class="">{{ item.username }}</a>
                    </td>
                    <td>
                        <span v-if="item.type == 0">会员</span>
                        <span v-if="item.type == 1">代理</span>
                    </td>
                    <td>{{ item.online_persons }}</td>
                    <td>{{ item.online_proxys }}</td>
                    <td>{{ item.online_members }}</td>
                    <td>{{ item.create_at }}</td>
                    <td>{{ item.ip }}</td>
                    <td>{{ item.browser }}</td>
                    <td>{{ item.os }}</td>
                    <td>
                        <Button size="mini" @click="LevelView(item)">关系</Button>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr v-if="main._empty">
                    <td class="empty" colspan="100">暂无数据</td>
                </tr>
            </tfoot>
        </table>
        <Pagination v-if="main._total" v-model="Page" class="o-mtb" :total="main._total" :count="main._count" :page-size="Filter.per_page" @turning="Get" />
        <level v-model="Level.view" title="关系" :form="Level.form" />
    </div>
</template>

<script>
import Level from './model/user_level'
import LevelMix from '@lottery.2.0/mixin/level.v3.js'
import StoreMix from '@lottery.2.0/mixin/store.js'
export default {
    name: 'CreditSixOnline',
    mixins: [StoreMix, LevelMix],
    data() {
        return {
            store: 'credit/online',
            Filter: {
                per_page: 20
            },
            Level: {
                view: false,
                form: null
            },
            BreadcrumbActiveID: 'uid'
        }
    },
    methods: {
        init() {
            this.Get()
        },
        LevelView(item) {
            this.Level.view = true
            this.Level.form = this.Origin({
                ...item,
                id: item.uid
            })
        }
    },
    components: {
        Level
    },
    activated() {
        if (!this.Opt.isTeam) {
            this.Rd('credit')
        }
        this.init()
    }
}
</script>
