export default {
    '龙': '6',
    '虎': '7',
    '和': '8',
    '大': '9',
    '小': '10',
    '单': '11',
    '双': '12',
    '5单0双': 's5d0',
    '4单1双': 's4d1',
    '3单2双': 's3d2',
    '2单3双': 's2d3',
    '1单4双': 's1d4',
    '0单5双': 's0d5',
    '0': 'a',   '00': 'a',
    '1': 'b',   '01': 'b',
    '2': 'c',   '02': 'c',
    '3': 'd',   '03': 'd',
    '4': 'e',   '04': 'e',
    '5': 'f',   '05': 'f',
    '6': 'g',   '06': 'g',
    '7': 'h',   '07': 'h',
    '8': 'i',   '08': 'i',
    '9': 'j',   '09': 'j',
    '10': 'k',
    '11': 'l',
    '12': 'm',
    '13': 'n',
    '14': 'o',
    '15': 'p',
    '16': 'q',
    '17': 'r',
    '18': 's',
    '19': 't',
    '20': 'u',
    '21': 'v',
    '22': 'w',
    '23': 'x',
    '24': 'y',
    '25': 'z'
}