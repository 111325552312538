<style lang="scss">
    @import "../../../../../style/config.scss";
    .OddsGroupTql6 {
        .long-row {
            .text {
                width:55px!important;
            }
        }
    }
</style>
<template>
    <div class="OddsGroupTql6">
        <div v-if="nav.active.name === '整合'">
            <div class="l-flex o-mt">
                <group class="u-bb u-br" :list="Table[1]" :length="2" title="冠亚大小" />
                <group class="u-bb u-br" :list="Table[2]" :length="2" title="冠亚单双" />
            </div>
            <div class="l-flex long-row o-mt">
                <div v-for="(item,index) in ['冠军','亚军','第三名','第四名','第五名']">
                    <group class="u-bb u-br" size="big" :list="Table[index * 3 + 4]" :length="1" :title="item" />
                    <group class="u-bb u-br" size="big" :list="Table[index * 3 + 5]" :length="1" />
                    <group class="u-bb u-br" size="big" :list="Table[index * 3 + 3]" :length="1" />
                </div>
            </div>
            <div class="l-flex long-row o-mt">
                <div v-for="(item,index) in ['第六名','第七名','第八名','第九名','第十名']">
                    <group class="u-bb u-br" size="big" :list="Table[index * 2 + 18]" :length="1" :title="item" />
                    <group class="u-bb u-br" size="big" :list="Table[index * 2 + 19]" :length="1" />
                </div>
            </div>
        </div>
        <div v-else-if="nav.active.name === '第1-10名'">
            <div class="l-flex">
                <group class="u-bb u-br" :list="Table[1]" :length="1" title="冠军" :color="false" />
                <group class="u-bb u-br" :list="Table[2]" :length="1" title="亚军" :color="false" />
                <group class="u-bb u-br" :list="Table[3]" :length="1" title="第三名" :color="false" />
                <group class="u-bb u-br" :list="Table[4]" :length="1" title="第四名" :color="false" />
                <group class="u-bb u-br" :list="Table[5]" :length="1" title="第五名" :color="false" />
            </div>
            <div class="l-flex o-mt-s">
                <group class="u-bb u-br" :list="Table[6]" :length="1" title="第六名" :color="false" />
                <group class="u-bb u-br" :list="Table[7]" :length="1" title="第七名" :color="false" />
                <group class="u-bb u-br" :list="Table[8]" :length="1" title="第八名" :color="false" />
                <group class="u-bb u-br" :list="Table[9]" :length="1" title="第九名" :color="false" />
                <group class="u-bb u-br" :list="Table[10]" :length="1" title="第十名" :color="false" />
            </div>
        </div>
        <div v-else-if="nav.active.name === '龙虎'">
            <div class="l-flex">
                <div v-for="(item,index) in ['1vs10','2vs9','3vs8','4vs7','5vs6']">
                    <group class="u-bb u-br" :list="Table[index + 1]" :length="1" :title="item" :color="false" />
                </div>
            </div>
        </div>
        <div v-else-if="nav.active.name === '定位胆'">
            <div class="l-flex">
                <div v-for="(item,index) in ['第一','第二','第三','第四','第五']">
                    <group class="u-bb u-br" :list="Table[index + 1]" :length="1" :title="item" :color="false" />
                </div>
            </div>
            <div class="l-flex o-mt-s">
                <div v-for="(item,index) in ['第六','第七','第八','第九','第十']">
                    <group class="u-bb u-br" :list="Table[index + 6]" :length="1" :title="item" :color="false" />
                </div>
            </div>
        </div>
        <div v-else-if="nav.active.name === '冠亚和值'">
            <group class="u-bb u-br" :list="Table[1]" :length="5" title="冠亚和值" :color="false" />
            <div class="l-flex o-mt-s">
                <group class="u-bb u-br" :list="Table[2]" :length="1" title="冠亚和值大小" :color="false" />
                <group class="u-bb u-br" :list="Table[3]" :length="1" title="冠亚和值单双" :color="false" />
            </div>
        </div>
        <div v-else>
            <group class="u-bb u-br" size="big" :list="TableAll" :length="5" :title="nav.active.name" :color="false" />
        </div>
    </div>
</template>

<script>
import Group from './odds-group'
import Fast from './odds-fast'
export default {
    name: 'OddsGroupTql6',
    mixins: [],
    data() {
        return {

        }
    },
    props: {
        nav: {},
        Table: {},
        tabs: {}
    },
    computed: {
        TableAll() {
            let list = []
            for (let unit in this.Table) {
                for (let item of this.Table[unit]) {
                    list.push(item)
                }
            }
            return list
        }
    },
    methods: {

    },
    components: {
        Group, Fast
    }
}
</script>
