import Mapskey from './formal_mapkey'

const digitDir = {
    '5': {value: null, title: '万位', name: '万'},
    '4': {value: null, title: '千位', name: '千'},
    '3': {value: null, title: '百位', name: '百'},
    '2': {value: null, title: '十位', name: '十'},
    '1': {value: null, title: '个位', name: '个'},

    'a': {value: null, title: '第一', name: '第一'},
    'b': {value: null, title: '第二', name: '第二'},
    'c': {value: null, title: '第三', name: '第三'},
    'd': {value: null, title: '第四', name: '第四'},
    'e': {value: null, title: '第五', name: '第五'},
    'f': {value: null, title: '第六', name: '第六'},
    'g': {value: null, title: '第七', name: '第七'},
    'h': {value: null, title: '第八', name: '第八'},
    'i': {value: null, title: '第九', name: '第九'},
    'j': {value: null, title: '第十', name: '第十'}
}
const MeanDir = {
    '万': '5', '万位': '5',
    '千': '4', '千位': '4',
    '百': '3', '百位': '3',
    '十': '2', '十位': '2',
    '个': '1', '个位': '1',

    '一': 'a', '第一': 'a', '第一位': 'a', '冠军': 'a', '猜冠军': 'a',
    '二': 'b', '第二': 'b', '第二位': 'b', '亚军': 'b',
    '三': 'c', '第三': 'c', '第三位': 'c', '季军': 'c',
    '四': 'd', '第四': 'd', '第四位': 'd',
    '五': 'e', '第五': 'e', '第五位': 'e',
    '六': 'f', '第六': 'f', '第六位': 'f',
    '七': 'g', '第七': 'g', '第七位': 'g',
    '八': 'h', '第八': 'h', '第八位': 'h',
    '九': 'i', '第九': 'i', '第九位': 'i',
    '十': 'j', '第十': 'j', '第十位': 'j'
}

export default {
    data() {
        return {
            digitDir,
            MeanDir,
            Mapskey
        }
    },
    watch: {
        digitDir: {
            handler(val) {
                if (val) {
                    this.$emit('change', this.MakeOrder())
                }
            },
            deep: true
        }
    },
    computed: {
        programme() {
            let number = 0
            let algorithm = this.template.algorithm
            let posLen = this.template.posLen ? this.template.posLen : algorithm[0]
            if (posLen) {
                for (let key in this.digitDir) {
                    if (this.digitDir[key].value) {
                        number++
                    }
                }
            }
            return {
                number,
                total: number >= posLen ? this.Combination(number, posLen) : 0
            }
        },
        // 根据配置生成可选球的数组
        BallUnit() {
            if (this.template.sub[1] && this.template.sub[1][1] >= 10) {
                return 2
            }
            return 1
        }
    },
    methods: {
        isNumberPosition(val) {
            return !!this.MeanDir[val]
        },
        applyOdds(number) {
            if (number) {
                //return this.Big(number).mul(this.User.odds_rebate).round(4,3).toNumber()
                return this.Big(number).round(4, 3).toNumber()
            }
            return '-'
        },
        MakeBallArray(arr, row=1) {
            if (this.template.sub.length == 2) {
                row = 1
            }
            if (!arr) {
                arr = this.template.sub[row]
                if (arr.length !== 2) {
                    return arr
                }
            }
            let start = arr[0]
            let end = arr[1]
            let list = []
            while (start <= end) {
                let ball = start + ''
                // 球号补0（和值玩法中不会生效）
                if (end >= 10 && ball.length < 2 && this.template.algorithm[0] != 'R4') {
                    ball = '0' + ball
                }
                list.push(ball)
                start++
            }
            return list
        },
        // 和值数注计算器
        HezhiDirInit(type) {
            let loop  = this.template.algorithm[1]
            let start = this.template.algorithm[2]
            let end   = this.template.algorithm[3]
            // 计算数组
            let arr = []
            while (start<=end) {
                arr.push(parseInt(start))
                start++
            }
            // 计算可能性
            let list = arr
            while (loop>1) {
                let n = arr
                let t = []
                for (let i=0;i<list.length;i++) {
                    for (let x=0;x<n.length;x++) {
                        t.push(`${list[i]},${n[x]}`)
                    }
                }
                list = t
                loop--
            }
            // 组选和值
            if (type=='group') {
                list = this.HezhiDirGroup(list)
            }
            let dir = {}
            for (let item of list) {
                let total = 0
                for (let num of item.split(',')) {
                    total += parseInt(num)
                }
                dir[total] = dir[total] ? dir[total] + 1 : 1
            }
            this.$set(this.$data, 'dir', dir)
        },
        HezhiDirGroup(list) {
            let _list = []
            let dir = {}
            // 数组去重，三码筛选掉豹子，双码筛选掉对子
            for (let code of list) {
                let sort = code.split(',').sort()
                let equivalent = true // 是否相等
                for (let i=0;i<sort.length-1;i++) {
                    if (sort[i] != sort[i+1]) {
                        equivalent = false
                        continue
                    }
                }
                if (!equivalent) {
                    dir[sort.join(',')] = true
                }
            }
            // 排序数组，去掉数字相同的结果
            for (let code in dir) {
                _list.push(code)
            }
            return _list
        },
        MakeOrder() {
            let BetOdds = this.CalcuBetOdds()
            //console.log('BetOdds',BetOdds)
            let params = this.params
            let order = []
            let chip = null

            let valid = []
            let group = []

            // 算法
            let algorithm = this.template.algorithm
            // 球盒
            let balls = this.MakeBallArray()

            switch (algorithm[0]) {

                // 直选复式：注数 = 所有列的球数互乘
                case 'R1':
                    for (let pack of params) {
                        let num = 0
                        for (let item of pack) {
                            if (item) {
                                num++
                            }
                        }
                        if (!num) {
                            chip = 0
                            break;
                        }
                        if (chip===null) {
                            chip = 0
                        }
                        if (algorithm[1]) {
                            chip = chip + num >= algorithm[1] ? this.Combination(num, algorithm[1]) : 0
                        } else {
                            chip = chip ? chip * num : num
                        }
                    }
                    for (let u=0;u<this.params.length;u++) {
                        let row = []
                        for (let i=0;i<this.params[u].length;i++) {
                            if (this.params[u][i]) {
                                //row.push(balls[i])
                                row.push(this.MakeBallArray(null, u+1)[i])
                            }
                        }
                        group.push(row.join(','))
                    }
                    order = [{
                        chip: chip,
                        code: group.join('|'),
                        bonus_prop: BetOdds.odds
                    }]
                    break;

                // 直选组合：注数 = 所有列的球数互乘 x 总列数
                case 'R2':
                    for (let pack of params) {
                        let num = 0
                        for (let item of pack) {
                            if (item) {
                                num++
                            }
                        }
                        if (chip===null) {
                            chip = num
                        } else {
                            chip = chip * num
                        }
                    }
                    for (let u=0;u<this.params.length;u++) {
                        let row = []
                        for (let i=0;i<this.params[u].length;i++) {
                            if (this.params[u][i]) {
                                row.push(balls[i])
                            }
                        }
                        group.push(row.join(','))
                    }
                    chip = chip * params.length // 比复式多了这个
                    order = [{
                        chip: chip,
                        code: group.join('|'),
                        bonus_prop: BetOdds.odds
                    }]
                    break;

                // 单行组选 + 双行组选，最多两组，去重逻辑
                case 'R3':

                    let len1 = algorithm[1];
                    let len2 = algorithm[2];

                    // 【单行组选】
                    if (len1 && !len2) {
                        params[0].forEach((item, i) => {
                            if (item) {
                                group.push(balls[i])
                            }
                        })
                        order = [{
                            chip: group.length >= algorithm[1] ? this.Combination(group.length, algorithm[1]) : 0,
                            code: group.join('|'),
                            bonus_prop: BetOdds.odds
                        }]
                    }

                    // 【双行组选】 必定是两行，某一行必选球数必定为 1
                    if (len1 && len2) {
                        let obj = {
                            '1': [],
                            '2': []
                        }
                        let single = len1 == 1 ? 1 : 2
                        let multi  = len1 == 1 ? 2 : 1
                        params[0].forEach((item, i) => {
                            if (item) {
                                obj[1].push(balls[i])
                            }
                        })
                        params[1].forEach((item, i) => {
                            if (item) {
                                obj[2].push(balls[i])
                            }
                        })

                        // 先算多行倍数，按照排列组合
                        let length = obj[multi].length
                        let chipMulti = length >= algorithm[multi] ? this.Combination(length, algorithm[multi]) : 0
                        let chipMultiRepeat  = length - 1 >= algorithm[multi] ? this.Combination(length-1, algorithm[multi]) : 0

                        // 拆解目录，查看单行目录是否与多行目录有重复
                        let chipTotal = 0
                        if (obj[single].length >= 1 && obj[multi].length >= algorithm[multi]) {
                            for (let code1 of obj[single]) {
                                let isRepeat = false
                                for (let code2 of obj[multi]) {
                                    if (code1 == code2) {
                                        isRepeat = true
                                        break
                                    }
                                }
                                chipTotal += isRepeat ? chipMultiRepeat : chipMulti
                            }
                        }
                        order = [{
                            chip: chipTotal,
                            code: [obj[1].join(','), obj[2].join(',')].join('|'),
                            bonus_prop: BetOdds.odds
                        }]
                    }
                    break;

                // 和值算法
                case 'R4':
                    chip = 0;
                    for (let pack of params) {
                        for (let i=0;i<pack.length;i++) {
                            if (pack[i]) {
                                let num = balls[i]
                                chip += this.dir[num]
                            }
                        }
                    }
                    for (let u=0;u<this.params.length;u++) {
                        let row = []
                        for (let i=0;i<this.params[u].length;i++) {
                            if (this.params[u][i]) {
                                row.push(balls[i])
                            }
                        }
                        group.push(row.join('|'))
                    }
                    if (chip) {
                        order = [{
                            chip,
                            code: group.join(''),
                            bonus_prop: BetOdds.odds
                        }]
                    }
                    break;

                // 组三选二 n(n+1)
                case 'R5':
                    params[0].forEach((item, i) => {
                        if (item) {
                            group.push(balls[i])
                        }
                    });
                    let length = group.length - 1
                    if (length > 0) {
                        chip = length * (length + 1)
                    }
                    order.push({
                        chip: chip ? chip : 0,
                        code: group.join('|'),
                        bonus_prop: BetOdds.odds
                    })
                    break;

                // 定位胆多列，每个球算一注，每行生成单独的一条订单
                case 'R6':
                    params.forEach((pack, unit) => {
                        let row = {
                            chip: 0,
                            code: []
                        }
                        let rowTitle = this.template.sub[0][unit]
                        let rowKey = this.MeanDir[rowTitle]
                        let rowId = this.template.subset[unit].id //this.template.enableMapping[rowKey]

                        pack.forEach((item, i) => {
                            if (item) {
                                let id = rowId
                                row.id = rowId
                                row.code.push(balls[i])
                                row.suffix = this.template.sub[0][unit]
                                //row.bonus_prop = BetOdds.subset[rowId] || BetOdds[rowId]
                                row.bonus_prop = this.Running.play.odds[id] && this.Running.play.odds[id].odds ? this.Running.play.odds[id].odds : 0
                            }
                        });
                        row.chip = row.code.length
                        row.code = row.code.join('|')
                        if (row.chip) {
                            order.push(row)
                        }
                    })
                    break;

                // 任选复式
                case 'R7':
                    // 先获取或有行所选的球数
                    let rows = []
                    let position = this.template.sub[0].length // 1:元 2:十 3:百 4:千 5:万
                    for (let pack of params) {
                        let num = 0
                        for (let item of pack) {
                            if (item) {
                                num++
                            }
                        }
                        rows.push(`${position}/${num}`)
                        position--
                    }
                    // 获取所有的排列组合
                    let combination = this.CombinationToArray(rows, algorithm[1])
                    // 计算所有排列组合的乘积
                    chip = 0
                    // 递归所有排列组合
                    for (let item of combination) {
                        let total = null
                        let map = [] // 组 ID 索引
                        let suffix = [] // 商品后缀
                        group = []
                        for (let code of item.split(',')) {
                            let position = code.split('/')[0] // 本组号码位置（万千百十个）
                            let number = code.split('/')[1] // 本组号码所选球数
                            // 标记
                            map.push(position)
                            suffix.push({5: '万', 4: '千', 3: '百', 2: '十', 1: '个'}[position])
                            total = total === null ? number : total * number
                            // 对应球盒数列
                            let index = Math.abs(this.template.sub[0].length - position)
                            let row = []
                            for (let i=0;i<this.params[index].length;i++) {
                                if (this.params[index][i]) {
                                    row.push(balls[i])
                                }
                            }
                            group.push(row.join(','))
                        }
                        map = map.sort().reverse().join('')
                        if (total) {
                            chip += total
                            let id = this.template.enableMapping[map]
                            order.push({
                                id,
                                suffix: `(${suffix.join('')})`,
                                chip: total,
                                code: group.join('|'),
                                bonus_prop: this.Running.play.odds[id] && this.Running.play.odds[id].odds ? this.Running.play.odds[id].odds : 0
                            })
                        }
                    }
                    break;

                // 不定胆 + 二码不定胆，每个球算一注，每行生成单独的一条订单
                case 'R8':
                    if (algorithm[1]) {
                        params[0].forEach((item, i) => {
                            if (item) {
                                group.push(balls[i])
                            }
                        });
                        let length = group.length - 1
                        //window.Combination = this.Combination
                        if (group.length >= algorithm[1]) {
                            chip = this.Combination(group.length, algorithm[1]) //(length * (length + 1)) / 2
                        }
                        order.push({
                            chip: chip ? chip : 0,
                            code: group.join('|'),
                            bonus_prop: BetOdds.odds
                        })
                    } else {
                        for (let pack of params) {
                            let row = {
                                chip: 0,
                                code: []
                            }
                            pack.forEach((item, i) => {
                                if (item) {
                                    row.code.push(balls[i])
                                }
                            });
                            row.chip = row.code.length
                            row.code = row.code.join('|')
                            row.bonus_prop = BetOdds.odds,
                            order.push(row)
                        }
                    }
                    break;

                // 猜前几
                case 'R9':
                    // 先获取或有行所选的球数
                    let need = this.template.sub[0].length
                    let table = []
                    params.forEach((pack, unit) => {
                        let row = []
                        pack.forEach((item, index) => {
                            if (item) {
                                row.push(balls[index])
                            }
                        })
                        table.push(row)
                        group.push(row.join(','))
                    })
                    let _bets = this.CombinationAcrossToArray(...table)
                    let bets = {
                        length: 0
                    }
                    for (let item of _bets) {
                        let arr = `${item}`.split(',').sort()
                        if (arr.length === params.length && !this.isRepeat(arr)) {
                            bets[arr.join(',')] = true
                            bets.length++
                        }
                    }
                    if (bets.length) {
                        order.push({
                            chip: bets.length,
                            code: group.join('|'),
                            bonus_prop: BetOdds.odds
                        })
                    }
                    break;

                // 单式
                default:
                    //let unit   = this.BallUnit
                    let Format = this.DanShiFormat()
                    if (Format.list.length) {
                        let list = []
                        for (let item of Format.list) {
                            let balls = []
                            let ball = ''
                            for (let b of item.split('')) {
                                ball = ball + b
                                if (ball.length==this.BallUnit) {
                                    balls.push(ball)
                                    ball = ''
                                }
                            }
                            list.push(balls.join(','))
                        }
                        order.push({
                            chip: Format.list.length,
                            code: list.join('|'),
                            bonus_prop: BetOdds.odds
                        })
                    }
                    break;
            }

            // 任选订单
            if (this.template.defaultCheckedPos && algorithm[0] !== 'R7') {
                let digitDir = this.Origin(this.digitDir)
                let posLen = this.template.posLen ? this.template.posLen : algorithm[0]
                let digit = []
                for (let key in digitDir) {
                    if (this.digitDir[key].value) {
                        digit.push(key)
                    }
                }
                let torder = order[0]
                if (torder && digit.length >= posLen) {
                    order = []
                    let combination = this.CombinationToArray(digit, posLen)
                    for (let map of combination) {
                        map = map.split(',').sort().reverse()
                        let suffix = []
                        for (let position of map) {
                            suffix.push(this.digitDir[position].name)
                        }
                        let id = this.template.enableMapping[map.join('')]
                        let fullTitle = null //let { enableMapping, subset, tpl } = this.template
                        for (let item of this.template.subset) {
                            if (item.id == id) {
                                fullTitle = item.name
                            }
                        }
                        order.push({
                            id,
                            suffix: `(${suffix.join('')})`,
                            fullTitle,
                            chip: torder.chip,
                            code: torder.code,
                            bonus_prop: this.Running.play.odds[id] && this.Running.play.odds[id].odds ? this.Running.play.odds[id].odds : 0
                        })
                        chip = torder.chip * combination.length

                    }
                } else {
                    order = []
                    chip = 0
                }
            }

            // 返回结果
            return {
                order,
                chip
            }
        },
        CalcuBetOdds() {
            let {depot_active, depot_active_id, depot: template} = this.Running
            if (depot_active && template && template.formal) {
                let formal = template.formal
                let obj = {
                    id: template.id,
                    title: template.title,
                    template: {
                        ...formal
                    },
                    odds: null,
                    subset: {}
                }
                try {
                    if (formal.enableMapping && formal.subset) {
                        for (let sub of formal.subset) {
                            obj[sub.id] = this.Running.play.odds[sub.id].odds
                        }
                    } else {
                        obj.odds = this.Running.play.odds[depot_active_id].odds
                    }
                } catch (err) {
                    return obj
                }
                return obj
            }
            return null
        },
        // 过滤无效字符串（单式）
        DanShiFilter(str=this.content) {
            if (str) {
                return str.replace(/[^\d.]/g, '')
            }
            return ''
        },
        // 代码检测
        DanShiCheck() {
            if (!this.contentFilter) {
                return this.Notify('操作失败', `您还没有输入号码`, 'error')
            }
            let Format = this.DanShiFormat()
            // 赋值到文本框
            this.content = Format.list.join(',')
            this.Notify('操作成功', `有效号码:${Format.list.length}组，重复选号:${Format.repeat}组，无效号码:${Format.invalid}个，被过滤掉的:${Format.garbage.join(',')}`)
        },

        // 格式化
        DanShiFormat() {
            let length  = this.template.algorithm[0]
            let content = this.DanShiFormatRange(this.contentFilter)
            let list    = []
            let count   = {}
            let garbage = []
            let statis = {
                repeat: 0, // 统计重复号码
                invalid: 0 // 统计失效号码
            }
            if (content.length < length) {
                statis.invalid = content.length
                garbage.push(content)
            }
            // let group = ''
            // let repeat = {};
            // for(let ball of content){
            //     if(repeat[ball]){
            //         break;
            //     }
            //     repeat[ball] = true
            //     group = group + ball
            // }

            let group = []
            let repeat = {}
            let need = length
            while (content.length >= need) {
                let ball = content.splice(0, 1)
                //if(!repeat[ball]){
                group.push(ball)
                repeat[ball] = true
                need = need - 1
                if (group.length == length) {

                    group = group.join('')
                    // 出现重复号码处理
                    if (count[group]) {
                        statis.repeat++
                        garbage.push(group)
                    } else {
                        count[group] = true
                        list.push(group)
                    }
                    // 失效号码处理
                    if (content.length < length) {
                        statis.invalid = content.length
                        garbage.push(content.splice(0, length).join(''))
                    }
                    // 重置单组重复判断器
                    group = []
                    repeat = {}
                    need = length
                }
                //}
            }
            return {
                list,
                content,
                garbage,
                ...statis
            }
        },
        DanShiFormatRange(content) {
            if (content) {
                let min = this.template.sub[1][0]
                let max = this.template.sub[1][1]
                let balls = []
                let ball = ''

                for (let b of content.split('')) {
                    ball = ball + b
                    if (ball.length == this.BallUnit) {
                        if (parseInt(ball) >= parseInt(min) && parseInt(ball) <= parseInt(max)) {
                            balls.push(ball)
                        }
                        ball = ''
                    }
                }
                return balls
            }
            return ''
            // if(this.template.sub[1] && this.template.sub[1][1]){
            //     let max = this.template.sub[1][1]
            //     let balls = []
            //     let ball = ''
            //     for(let b of group.split('')){
            //         ball = ball + b
            //         if(ball.length == this.BallUnit){
            //             if(ball > max){
            //                 return true
            //             }
            //             ball = ''
            //         }
            //     }
            // }
            // return false
        },
        // ReloadHistory(){
        //     //this.$p
        // },

        // 排列组合计算
        Combination(m, n) {
            return this.Factorial(m, n) / this.Factorial(n, n);
        },
        Factorial(m, n) {
            let num = 1;
            let count = 0;
            for (let i = m;i > 0;i--) {
                if (count == n) {
                    break;
                }
                num = num * i;
                count++;
            }
            return num;
        },
        // 普通排列组合
        CombinationToArray(data, deep, index=0, group=[]) {
            let need_apply = new Array();
            need_apply.push(`${data[index]}`);
            for (let i = 0; i < group.length; i++) {
                need_apply.push(`${group[i]},${data[index]}`);
            }
            group.push.apply(group, need_apply);
            if (index + 1 >= data.length) {
                if (deep) {
                    let output = []
                    for (let item of group) {
                        if (item.split(',').length == deep) {
                            output.push(item)
                        }
                    }
                    return output
                }
                return group;
            } else {
                return this.CombinationToArray(data, deep, index + 1, group);
            }
        },

        // 交叉排列组合
        CombinationAcrossToArray() {
            let heads = arguments[0];
            for (let i=1, len=arguments.length;i<len;i++) {
                if (arguments[i].length) {
                    heads = this.CombinationAcrossToArrayCallback(heads, arguments[i]);
                }
            }
            return heads;
        },
        CombinationAcrossToArrayCallback(heads, choices) {
            let result=[];
            for (let i=0, len=heads.length;i<len;i++) {
                for (let j=0, lenj=choices.length;j<lenj;j++) {
                    result.push(heads[i]+','+choices[j]);
                }
            }
            return result;
        },

        Tools(unit, type='全') {
            let balls = this.MakeBallArray()
            let length = balls.length
            for (let i=0;i<this.params[unit].length;i++) {
                switch (type) {
                    case '全':
                        this.$set(this.params[unit], i, true)
                        break;
                    case '大':
                        this.$set(this.params[unit], i, !(Math.floor(length / 2) > i))
                        break;
                    case '小':
                        this.$set(this.params[unit], i, !(Math.floor(length / 2) <= i))
                        break;
                    case '单':
                        this.$set(this.params[unit], i, balls[i] % 2 != 0)
                        break;
                    case '双':
                        this.$set(this.params[unit], i, balls[i] % 2 == 0)
                        break;
                    case '清':
                        this.$set(this.params[unit], i, false)
                        break;
                }
            }
        },
        ResetTable() {
            for (let unit=0;unit<this.params.length;unit++) {
                for (let i=0;i<this.params[unit].length;i++) {
                    this.$set(this.params[unit], i, false)
                }
            }
        },
        isRepeat(arr) {
            let hash = {};
            for (let i in arr) {
                if (hash[arr[i]]) return true;
                hash[arr[i]] = true;
            }
            return false;
        },

        // 导入文件
        ImportFile(e) {
            if (this.$refs.file) {
                let files = this.$refs.file.files
                if (files && files.length) {
                    let reader = new FileReader();
                    reader.onload = res => {
                        this.content = res.target.result
                    }
                    reader.readAsText(files[0]);
                    this.$refs.file.value = null
                }
            }
        },
        CheckRank(row, code) {
            if (this.rank.radio && this.rank.data) {
                let type = this.rank.radio
                let index = MeanDir[row]
                let number = this.rank.data[index].codes[code][type]
                return {
                    active: this.rank.data[index].min[type] == number,
                    number
                }
            }
            return null
        }
    }
}