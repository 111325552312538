<style lang="scss" scoped>
    @import "../../../style/config.scss";
    .GameLayoutOrder {
        position:relative; // min-height:800px;
        .enableView {
            position:absolute; width:100%; height:100%; background-color:rgba(0,0,0,.6); transition:transition .4s;
            .content {
                width:100%; font-size:1rem; color:#fff;
                img {
                    display:block; margin:0 auto; position:absolute; top:5rem; left:0; right:0;
                }
            }
        }
    }
</style>
<template>
    <div v-if="!Opt.isTeam" class="GameLayoutOrder">
        <!--         <div class="enableView l-flex-c" v-if="Running.enable == 0" style="z-index:1002;">
            <div class="content c-text-c">
                <img :src="Img('/game/stop.png')">
            </div>
        </div> -->
        <credit v-if="creditType == 'credit'" />
        <formal v-else-if="creditType == 'formal'" />
    </div>
</template>

<script>
import Credit from '../components/credit.vue'
import Formal from '../components/formal.vue'

export default {
    name: 'GameLayoutOrder',
    data() {
        return {

        }
    },
    computed: {
        creditType() {
            // if(this.Running){
            //     let { nav, model } = this.Running
            //     let map = this.TypeMap[model]
            //     if(nav && nav.item && map){
            //         let { id, title } = this.Running.nav.item
            //         if(id && title){
            //             if(~map.formal.indexOf(title)){
            //                 return 'formal'
            //             }else if(~map.credit.indexOf(title)){
            //                 return 'credit'
            //             }
            //         }
            //     }
            // }
            // return 'credit'
            try {
                return this.Running.nav.item.play_type == 1 ? 'formal' : 'credit'
            } catch (err) {
                return 'credit'
            }
        }
    },
    methods: {

    },
    components: {
        Credit,
        Formal
    },
    activated() {
        if (this.Opt.isTeam) {
            this.Rd('credit/runtime')
        }
    }
}
</script>
