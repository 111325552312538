<style lang="scss">
    @import "../../../style/config.scss";
    .News {
        .el-dialog {
            box-shadow:0 5px 16px rgba(0,0,0,.35);
            .el-dialog__header {
                background-color:#253157!important;
                .el-dialog__title, .el-dialog__close.el-icon.el-icon-close {
                    color:#fff;
                }
            }
            .el-dialog__body {
                height:420px; padding-left:0; padding-right:0; padding-top:0; padding-bottom:0;
            }
        }
        .dir {
            height:100%; overflow-y:auto;
            li {
                line-height:1rem; padding:10px 14px; border-bottom:1px solid #e5e5e5; border-right:4px solid $color-n; cursor:pointer;
                &.active {
                    border-right-color:$color-t;
                }
            }
        }
        .content {
            height:100%; overflow-y:auto; border-left:1px solid #e5e5e5; line-height:1rem; font-size:.8rem;
        }
    }
</style>

<template>
    <el-dialog title="平台公告" class="News" size="big" top="10vh" :visible.sync="dialogFormVisible">
        <el-row tag="div" style="height:100%;">
            <el-col tag="ul" class="dir" :span="6">
                <li v-for="(item,index) in main._list" class="c-text-75" :class="{'active': active === index}" @click="active = index">{{ item.title }}</li>
            </el-col>
            <el-col tag="div" class="content" :span="18">
                <div class="o-p">
                    <div class="title c-color-t o-pt-s c-line-14 c-text-85">{{ item.title }}</div>
                    <p class="c-text-65 c-color-g">{{ item.create_at }}</p>
                    <div class="o-mt-l" v-html="item.content" />
                </div>
            </el-col>
        </el-row>
    </el-dialog>
</template>

<script>
export default {
    name: 'News',
    data() {
        return {
            dialogFormVisible: false,
            active: 0
        }
    },
    computed: {
        main() {
            return this.$store.state.base.announ
        },
        item() {
            let active = this.active
            if (this.main._list[active]) {
                return this.main._list[active]
            }
            return {}
        }
    }
}
</script>
