<style lang="scss">
    .CreditSixRules {
        .dir {
            width:8rem; text-align:right;
            .item {
                padding:4px 6px; margin:2px auto; cursor:pointer;
                &.active {
                    background-color: #fdb65c;
                    color: #e400ff;
                    background: linear-gradient(90deg,#ffd8a5,#e78740);
                }
            }
        }
    }
</style>
<template>
    <div v-loading="main._loading" class="CreditSix CreditSixRules">
        <div class="l-flex">
            <div class="dir o-pr u-br">
                <li v-for="(item,index) in Opt.hasDir.game" :key="item.id" class="item" :class="{'active': item.id === Filter.id}" @click="Pick(item)">{{ item.title }}</li>
            </div>
            <div class="l-flex-1 o-pl o-pb">
                <div v-if="main._item" class="o-plr" v-html="main._item.content" />
            </div>
        </div>
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
export default {
    name: 'CreditSixRules',
    mixins: [StoreMix],
    data() {
        return {
            store: 'game/rule',
            Filter: {
                id: 31,
                type: 2
            }
        }
    },
    watch: {
        'Filter.id'(val) {
            this.ChangeGame(val)
        }
    },
    methods: {
        init() {
            if (this.Running.id) {
                this.Filter.id = this.Running.id
                this.Filter.ype = this.Running.model
            }
            this.ChangeGame()
        },
        ChangeGame(id = this.Filter.id) {
            this.Item(id)
        },
        Pick(item) {
            this.$set(this.Filter, 'id', item.id)
            this.ChangeGame()
        }
    },
    activated() {
        this.$set(this.Filter, 'id', this.$route.query.id ? this.$route.query.id : 31)
        this.init()
    }
}
</script>
