<style lang="scss">
    .GameCreditP115 {

    }
</style>
<template>
    <div v-if="List" v-loading="!List.length" class="GameCreditP115">

        <Category title="两面盘">
            <el-row tag="div">
                <el-col v-for="unit in [0,1,2,3,4,5]" :key="unit" :span="4">
                    <Group :list="List[unit]" :min="6" :prompt="prompt" :prompt-interval="promptInterval" />
                </el-col>
            </el-row>
        </Category>

        <Category v-for="unit in ['特码','正码一','正码二','正码三','正码四']" :key="unit" :title="unit">
            <Group :list="List[0]" :title="false" :length="4" :prompt="prompt" :prompt-interval="promptInterval" type="queue" :min="12" />
            <Group :list="List[1]" :title="false" :length="4" :prompt="[]" type="queue" />
        </Category>

        <Category title="龙虎斗">
            <el-row v-for="(group,index) in [[0,1,2,3],[4,5,6],[7,8],[9]]" :key="index" tag="div" type="flex" justify="center">
                <el-col v-for="unit in group" :key="unit" :span="6">
                    <Group :list="List[unit]" :color="['#FF4848','#06A21B','#0020FF']" type="row" />
                </el-col>
            </el-row>
        </Category>

        <Category title="全5中1">
            <Group :list="List[0]" :length="5" :prompt="prompt" :prompt-interval="promptInterval" type="queue" :min="15" />
        </Category>
    </div>
</template>

<script>
import Credit from './other.js'
export default {
    name: 'GameCreditP115',
    mixins: [Credit],
    data() {
        return {

        }
    }
}
</script>
