<style lang="scss">
    .GameFormalDanshi1 {
        height:260px;
    }
</style>
<template>
    <div class="GameFormalPlay GameFormalDanshi1">
        <div class="danshi o-pt">
            <div class="textarea">
                <textarea v-model="content" />
                <p class="o-mt">程序会自动过滤掉不合法的号码。您也可以点击“删除重复号”按钮对输入内容进行格式化。</p>
            </div>
            <div class="tools">
                <div>
                    <Button class="o-mb" size="big" full @click="DanShiCheck()">删除重复选号</Button>
                </div>
                <div>
                    <Button class="o-mb" size="big" full @click="Pick()">导入文件</Button>
                    <input ref="file" type="file" accept="text/*" style="display:none;" @change="ImportFile"></input>
                </div>
                <div>
                    <Button class="o-mb" size="big" full @click="Clean()">清空选择</Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Common from './danshi1.js'
export default {
    mixins: [Common],
    components: {

    }
}
</script>
