<style lang="scss">
    .MySystemAnnounMudul{
        .ql-container {
            height:400px;
        }
    }
</style>
<template>
    <el-dialog class="MySystemAnnounMudul" :title="Title" :visible.sync="view" size="big" top="5vh" :modal-append-to-body="false">
        <el-form v-loading="!ready" label-width="80px">
            <el-form-item label="公告标题">
                <el-input v-model="Params.title" placeholder="请输入公告标题" style="width:18rem;" />
            </el-form-item>
            <div v-if="ready" style="min-height:400px;">
                <quill-editor v-model="Params.content" :options="editorOption" />
            </div>
        </el-form>
        <div class="c-text-r o-mt">
            <Button @click="Cancel()">取消</Button>
            <Button :loading="main.editing" type="primary" @click="Submit()">提交</Button>
        </div>
    </el-dialog>
</template>

<script>
import Vue from 'vue'
import  VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
Vue.use(VueQuillEditor)

import StoreMix from '@lottery.2.0/mixin/store.modul.js'
export default {
    name: 'MySystemAnnounMudul',
    mixins: [StoreMix],
    data() {
        return {
            store: 'admin/announ',
            Params: {

            },
            ready: true,
            editorOption: {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        // ['blockquote', 'code-block'],
                        [{'header': 1}, {'header': 2}, {'header': 3}],
                        [{'list': 'ordered'}, {'list': 'bullet'}],
                        [{'indent': '-1'}, {'indent': '+1'}],
                        [{'color': []}, {'background': []}],
                        [{'align': []}],
                        ['clean']
                    ]
                }
            }
        }
    },
    computed: {

    },
    methods: {
        init() {
            // import('vue-quill-editor').then(Editor=>{
            //     console.log('模块加载完成')
            //     Vue.use(Editor)
            //     this.ready = true
            // })
        }
    },
    mounted() {
        this.init()
    }
}
</script>
