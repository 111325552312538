<style lang="scss">
    .MyOutputLhcMudul {
        input {
            padding:3px 5px; box-sizing: border-box;
        }
        table.table {
            tr {
                td {
                    padding-left:8px!important; padding-right:8px!important; padding-top:10px!important; padding-bottom:10px!important; border-color:#ddd;
                }
                td.label {
                    width:90px!important; background-color:#d5d1f9; vertical-align:middle;
                }
            }
            tbody{
                tr {
                    background-color:#fff!important;
                }
                tr:nth-child(odd) {
                    background-color:#fff!important;
                }
                tr:hover {
                    cursor:auto; background-color:#fff!important;
                }
            }
        }
        &.el-dialog__wrapper[size=small] .el-dialog {
            width: 24rem;
            max-width: 94%;
        }
    }
</style>
<template>
    <el-dialog class="MyOutputLhcMudul" :title="Title" :visible.sync="view" top="5vh" size="small" :modal-append-to-body="false" :close-on-click-modal="false">
        <div v-if="!opening.view && !opening.loading">
            <table class="table u-b">
                <tbody>
                    <tr>
                        <td class="label u-bb">期数</td>
                        <td class="u-bb" style="text-align:left;"><el-input v-model="Params.number" size="small" placeholder="请输入期数" style="width:12rem;" /></td>
                    </tr>
                    <tr>
                        <td class="label u-bb">开奖日期</td>
                        <td class="u-bb" style="text-align:left;"><el-date-picker v-model="Params.start_time" size="small" type="date" value-format="yyyy-MM-dd" placeholder="选择开奖日期" style="width:12rem;" /></td>
                    </tr>
                    <tr>
                        <td class="label">开奖结果</td>
                        <td>
                            <div class="l-flex">
                                <div v-for="(item,index) in Tpl.codes" class="l-flex-1" style="padding-right:5px;">
                                    <span class="c-text-6" style="line-height:18px;">{{ item.title }}</span>
                                    <input v-model.number="Params[item.name]" :maxlength="Tpl.maxlength" style="width:100%;">
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="l-flex-c o-mt">
                <div class="l-flex-1">
                    <template v-if="Params.id && openState">
                        <Button v-if="Params.status == 2" v-loading="main.editing" @click="submit(1)">颁奖</Button>
                        <Button v-if="Params.status == 1" v-loading="main.editing" @click="submit(1)">重新颁奖</Button>
                    </template>
                    <template v-else-if="Params.id">
                        <span class="c-text-6 c-color-g">填写开奖号码并保存后即可颁奖</span>
                    </template>
                </div>
                <Button type="info" plain @click="Cancel()">取消</Button>
                <Button v-if="Params.id" :loading="main.editing" @click="submit(0)">确定</Button>
                <Button v-if="!Params.id" :loading="main.editing" @click="Submit()">确定</Button>
            </div>
        </div>
        <div v-else class="c-text-c">
            <template v-if="opening.timer || opening.loading">
                <Load size="2" weight="3" color="#3d64ab" />
                <p class="o-pt-l o-pb-h">系统正在开奖中，请稍候</p>
            </template>
            <div v-else>
                <i class="icon icon-roundcheckfill c-color-s" style="font-size:42px;" />
                <p class="o-pt o-pb-l">颁奖已完成</p>
                <Button @click="GoReport()">查看报表</Button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.modul.js'
export default {
    name: 'MyOutputLhcMudul',
    mixins: [StoreMix],
    data() {
        return {
            store: 'admin/output_lhc',
            Params: {
                games_id: 31,
                games_type: 7,
                start_time: null,
                number: null
            },
            opening: {
                view: false,
                timer: null,
                count: 0,
                loading: false
            },
            openState: false
        }
    },
    props: {
        filter: {
            default: {}
        }
    },
    computed: {
        Tpl() {
            let {games_id, games_type} = this.Params
            let Map = {
                '2': {
                    maxlength: 1,
                    codes: [
                        {title: '佰', name: 'code_one'},
                        {title: '拾', name: 'code_two'},
                        {title: '个', name: 'code_three'}
                    ]
                },
                '7': {
                    maxlength: 2,
                    codes: [
                        {title: '正码一', name: 'code_one'},
                        {title: '正码二', name: 'code_two'},
                        {title: '正码三', name: 'code_three'},
                        {title: '正码四', name: 'code_four'},
                        {title: '正码五', name: 'code_five'},
                        {title: '正码六', name: 'code_six'},
                        {title: '特码', name: 'special'}
                    ]
                }
            }
            return Map[games_type] ? Map[games_type] : Map[7]
        }
    },
    watch: {
        view(val) {
            if (!val) {
                clearInterval(this.opening.timer)
            }
        }
    },
    methods: {
        init() {
            if (this.filter && this.filter.games_id) {
                this.Params.games_id = this.filter.games_id
                this.Params.games_type = this.filter.games_type
            }
            clearInterval(this.opening.timer)
            this.DataReset('opening')
            if (this.form) {
                if (this.form.date) {
                    this.$set(this.Params, 'start_time', this.form.date)
                }
                if (this.form.data) {
                    this.FormatCode(this.form.data)
                }
            } else {
                this.Params.start_time = this.Untimestamp(new Date(new Date().toLocaleDateString()), 'yyyy-MM-dd')
            }
            if (!this.Params.id) {
                this.AutoNumber()
            }
            this.ChackOpenState()
        },
        submit(num) {
            this.Params.open_status = num
            this.Submit()
        },
        AutoNumber() {
            this.Dp('admin/AUTO_OUTPUT_NUMBER', {
                games_id: this.Params.games_id,
                games_type: this.Params.games_type
            }).then(res => {
                if (!this.Params.number) {
                    this.Params.number = res.data
                }
            })
        },
        Submit(model=this.storePath[1]) {
            let params = this.Origin(this.Params)
            return params.id ?
                this.Put(params, model) :
                this.Post(params, model)
        },
        // 0未填好 2等待开奖 3已开奖
        Put(params=this.Origin(this.Params), model=this.storePath[1]) {
            let path = `${this.storePath[0]}/_PUT_${model.toUpperCase()}`
            let status = params.open_status
            this.opening.loading = true
            return this.$store.dispatch(path, {data: params, id: params.id||params.sequence}).then(res => {
                this.opening.loading = false
                // this.Suc('系统正在开奖，请稍候...')
                if (params.open_status == 1) {
                    this.Open()
                } else {
                    this.Item(this.form.id).then(res => {
                        this.Params.status = res.status
                        if (res && res.data) {
                            this.FormatCode(res.data)
                            this.ChackOpenState()
                        }
                    })
                    this.$emit('finish', res ? res : 1)
                }
                return res
            }, err => {
                this.opening.loading = false
            })
        },
        Open() {
            this.$set(this.opening, 'view', true)
            let params = this.Origin(this.Params)
            this.opening.timer = setInterval(() => {
                this.opening.count = this.opening.count + 1
                if (this.opening.count >= 20 && this.opening.count % 20 == 0) {
                    this.Dp(`admin/_GET_PROCESS`, {id: params.id}).then(res => {
                        if (res.data) {
                            this.$store.state.base.Event.emit('Credit/ExpectInit')
                            clearInterval(this.opening.timer)
                            this.opening.timer = null
                        }
                    })
                }
            }, 1000)
        },
        GoReport() {
            this.view = false
            this.$emit('lottery')
        },
        // 把历史记录转换为表单
        FormatCode(str) {
            let type = this.Params.games_type
            if (str && type) {
                if (type == 7) {
                    [...str.split('+')[0].split(','), ...str.split('+')[1].split(',')].forEach((item, index) => {
                        this.$set(this.Params, this.Tpl.codes[index].name, item)
                    })
                } else {
                    str.split(',').forEach((item, index) => {
                        this.$set(this.Params, this.Tpl.codes[index].name, item)
                    })
                }
            }
        },
        ChackOpenState(data = this.Params) {
            this.openState = false
            for (let item of this.Tpl.codes) {
                let key = item.name
                if (!data || !data[key]) {
                    return false
                }
            }
            this.openState = true
        }
    }
}
</script>
