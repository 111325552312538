<style lang="scss">
    .GameCreditDpc {
        .table-dpc {
            background:#FFF; width:750px;
            .table-row {
                padding:3px 5px;
            }
            td {
                padding:1px 2px; border: 1px solid #CCC; text-align:center;
            }
            .table-code {
                background:#EEE; font-size:.75rem;
            }
            .table-odds {
                color:red; font-weight:700;
            }
            .table-input {
                padding:3px 5px;
                input.fast {
                    border-color:#f60; background-color: #fffbcc;
                }
            }
            thead {
                td.table-title {
                    font-size:.75rem; font-weight:bold; color:#FFF; background:#164677; padding:5px 2px;
                }
                td.table-prompt {
                    font-size:.6rem; color:#FFF; background:#4c6fc5;
                }
            }
            tfoot {
                td.table-prompt {
                    height:24px; line-height:24px; padding:4px 2px;
                }
            }
        }
        input.mini {
            width:100%; height:1.1rem; line-height:1.1rem; border:1px solid #ccc; border-radius:3px; box-sizing:border-box; padding:0 3px;
        }
    }
</style>
<template>
    <div v-if="List" v-loading="!List.length" class="GameCreditDpc">
        <category title="主盘势">
            <group :list="List[0]" :title="false" :length="5" :prompt="prompt" :prompt-interval="promptInterval" type="queue" />
            <div class="l-flex" style="background:#4c6fc5; color:#FFF;">
                <div class="c-text-c l-flex-c u-bb u-br" style="width:120px;" />
                <div class="l-flex l-flex-1">
                    <div v-for="(item,index) in ['大','小','单','双','质','合']" class="l-flex-1 c-text-c u-bb u-br" style="line-height:28px;">{{ item }}</div>
                </div>
            </div>
            <div v-for="(pack,unit) in List" v-if="unit" class="l-flex" :class="'category-1-' + unit" style="background:#eaebec;">
                <div class="c-text-c l-flex-c u-bb" style="width:120px; background:#FFF;">
                    <div style="width:100%;">{{ pack.title }}</div>
                </div>
                <div class="l-flex l-flex-1">
                    <div v-if="unit > 3 && unit < 7" class="l-flex-2 u-bb u-br" />
                    <div class="l-flex-2">
                        <group :list="List[unit]" :title="false" :length="[0,6,6,6,2,2,2,4][unit]" :prompt="[]" type="queue" />
                    </div>
                    <div v-if="unit > 3 && unit < 7" class="l-flex-2 u-bb" />
                    <div v-if="unit >= 7" class="l-flex-1 u-bb" />
                </div>
            </div>
        </category>
        <category title="一字盘势">
            <div v-for="(pack,unit) in List" class="o-mb-s">
                <group :list="pack" :title="pack.title" :length="5" :prompt="prompt" :prompt-interval="promptInterval" type="queue" />
            </div>
        </category>
        <category title="二字定位">
            <group :list="[List[0],List[1],List[2]]" :length="5" :prompt="prompt" :prompt-interval="promptInterval" type="queuetab" />
        </category>
        <category title="二字组合">
            <group :list="List[0]" title="" :length="5" :prompt="prompt" :prompt-interval="promptInterval" type="queue" />
        </category>

        <category title="二字和数">
            <template v-if="TwoMerge">
                <div class="o-pb">
                    <el-radio v-for="(item,index) in List" :key="item.id" v-model="TwoMergeActive" :label="index" style="margin-right:15px;" size="medium" @change="TwoMergeChange(item)">{{ item.title }}</el-radio>
                </div>
                <group class="o-mb" :list="TwoMerge[0]" title="" :length="5" :prompt="prompt" :prompt-interval="promptInterval" type="queue" />
                <group class="o-mb" :list="TwoMerge[1]" title="" :length="5" :prompt="prompt" :prompt-interval="promptInterval" type="queue" />
                <!--                 <div class="l-flex">
                    <Group class="l-flex-2" :list="TwoMerge[2]" title="" :length="2" :prompt="prompt" :promptInterval="promptInterval" type="queue"></Group>
                    <div class="l-flex-3"></div>
                </div> -->
            </template>
        </category>

        <category title="三字定位">
            <div class="o-pl o-ptb-s o-mb-s" style="background-color:#b4d2ff;">
                <span>定位别：</span>
                <el-radio v-for="(item,index) in ['佰','拾','个']" :key="item" v-model="ThreeLocation.location" :label="index" style="margin-right:15px;" size="medium" @change="ThreeLocationTable()">{{ item }}</el-radio>
                <span class="o-pl">定位数：</span>
                <el-radio v-for="(item,index) in [0,1,2,3,4,5,6,7,8,9]" :key="item" v-model="ThreeLocation.number" :label="item" style="margin-right:15px;" size="medium" @change="ThreeLocationTable()">{{ item }}</el-radio>
            </div>
            <div class="o-pl l-flex-c">
                <span>快速选择：</span>
                <Button v-for="item of [0,1,2,3,4,5,6,7,8,9]" :key="item" size="mini" @click="ThreeFast('Location',item,'reset')">{{ item }}</Button>
                <Button size="mini" @click="ThreeFast('Location','double','reset')">对对</Button>
                <Button size="mini" @click="ThreeFast('Location','triple','reset')">豹子</Button>
                <span class="o-pl-l">金额：</span>
                <input v-model.number="ThreeCombination.amount" class="o-mr" type="number" maxlength="6" style="width:70px; height:24px; line-height:24px; padding:1px 4px;">
                <Button size="small" @click="ThreeInput('Location')">传送</Button>
                <Button size="small" @click="$emit('reset')">清空</Button>
                <Button size="small" @click="Checkout('ThreeLocation')">确认下单</Button>
            </div>
            <table class="table-dpc o-mt">
                <colgroup>
                    <template v-for="(item,index) in 5">
                        <col width="4%">
                        <col width="7%">
                        <col width="9%">
                    </template>
                </colgroup>
                <thead>
                    <tr>
                        <template v-for="(item,index) in 5">
                            <td class="table-prompt table-code">号码</td>
                            <td class="table-prompt table-odds">赔率</td>
                            <td class="table-prompt table-input">选择</td>
                        </template>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(pack,unit) in ThreeLocation.table">
                        <template v-for="(item,index) in pack">
                            <td class="table-code"><b>{{ item.code }}</b></td>
                            <td class="table-odds">
                                <span v-if="CalcuOdds(item)">{{ CalcuOdds(item).odds }}</span>
                                <Load v-else :size="1" />
                            </td>
                            <td class="table-input">
                                <input v-model.number="item.amount" type="number" maxlength="6" class="mini" :class="{'fast': item.fast}">
                            </td>
                        </template>
                    </tr>
                </tbody>
            </table>
        </category>


        <category title="三字组合">
            <div class="o-pl l-flex-c">
                <span>快速选择：</span>
                <Button v-for="item of [0,1,2,3,4,5,6,7,8,9]" :key="item" size="mini" @click="ThreeFast('Combination',item,'reset')">{{ item }}</Button>
                <Button size="mini" @click="ThreeFast('Combination','normal','reset')">一般</Button>
                <Button size="mini" @click="ThreeFast('Combination','double','reset')">对对</Button>
                <Button size="mini" @click="ThreeFast('Combination','triple','reset')">豹子</Button>
                <span class="o-pl-l">金额：</span>
                <input v-model.number="ThreeCombination.amount" class="o-mr" type="number" style="width:70px; height:24px; line-height:24px; padding:1px 4px;">
                <Button size="small" @click="ThreeInput('Combination')">传送</Button>
                <Button size="small" @click="$emit('reset')">清空</Button>
                <Button size="small" @click="Checkout('ThreeCombination')">确认下单</Button>
            </div>
            <table class="table-dpc o-mt">
                <colgroup>
                    <template v-for="(item,index) in 5">
                        <col width="4%">
                        <col width="7%">
                        <col width="9%">
                    </template>
                </colgroup>
                <thead>
                    <tr>
                        <template v-for="(item,index) in 5">
                            <td class="table-prompt table-code">号码</td>
                            <td class="table-prompt table-odds">赔率</td>
                            <td class="table-prompt table-input">选择</td>
                        </template>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(pack,unit) in ThreeCombination.table">
                        <template v-for="(item,index) in pack">
                            <td class="table-code" :style="{'backgroundColor': item.color}"><b>{{ item.code }}</b></td>
                            <td class="table-odds">
                                <span v-if="CalcuOdds(item)">{{ CalcuOdds(item).odds }}</span>
                                <Load v-else :size="1" />
                            </td>
                            <td class="table-input">
                                <input v-model.number="item.amount" type="number" maxlength="6" class="mini" :class="{'fast': item.fast}">
                            </td>
                        </template>
                    </tr>
                </tbody>
            </table>
        </category>


        <category title="三字和数">
            <group :list="List[0]" title="" :length="5" :prompt="prompt" :prompt-interval="promptInterval" type="queue" />
            <group :list="List[1]" title="" :length="5" :prompt="prompt" :prompt-interval="promptInterval" type="queue" />
            <div class="l-flex" style="background:#FFF;">
                <div class="c-text-c l-flex-c u-bb u-br" style="width:120px; background:#FFF;" />
                <div class="l-flex l-flex-1">
                    <div v-for="(item,index) in ['大','小','单','双','质','合']" class="l-flex-1 c-text-c u-bb u-br" style="line-height:28px;">{{ item }}</div>
                </div>
            </div>
            <div v-for="unit in [2,3]" v-if="List[unit]" class="l-flex" style="background:#eaebec;">
                <div class="c-text-c l-flex-c u-bb" style="width:120px; background:#FFF;">
                    <div style="width:100%;">{{ List[unit].title }}</div>
                </div>
                <div class="l-flex-1">
                    <group :list="List[unit]" :fill="{'2': '3,2','3': '1,2'}[unit]" :title="false" :length="6" :prompt="[]" type="queue" />
                </div>
            </div>
        </category>


        <category title="组选三">
            <table class="table-dpc o-mt" style="width:100%;">
                <colgroup>
                    <col v-for="index in 18" width="5.555%">
                </colgroup>
                <thead>
                    <tr>
                        <td v-for="(item,index) in CombThree.table" class="table-title" colspan="3">{{ item.title }}</td>
                    </tr>
                    <tr>
                        <template v-for="(item,index) in CombThree.table.length">
                            <td class="table-prompt table-code">号码</td>
                            <td class="table-prompt table-odds">赔率</td>
                            <td class="table-prompt table-input">选择</td>
                        </template>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in [0,1,2,3,4,5,6,7,8,9]">
                        <template v-for="(pack,column) in CombThree.table.length" v-if="CombThree.table[column].child[row]">
                            <td class="table-code" style="padding:6px 2px;">
                                <b>{{ CombThree.table[column].child[row].name }}</b>
                            </td>
                            <td class="table-odds" style="padding:6px 2px;">
                                <span v-if="CalcuOdds(CombThree.table[column].child[row])">{{ CalcuOdds(CombThree.table[column].child[row]).odds }}</span>
                                <Load v-else :size="1" />
                            </td>
                            <td class="table-input" style="padding:6px 2px;">
                                <el-checkbox v-model="CombThree.table[column].child[row].pick" :checked="CombThree.table[column].child[row].pick" :disabled="!CombThree.table[column].child[row].pick && CombThree.table[column].total >= CombThree.table[column].limit" @change="CombPick('CombThree')" />
                            </td>
                        </template>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td v-for="(item,index) in CombThree.table" class="table-prompt" colspan="3">
                            <span v-if="item.total && item.total < item.limit" class="c-color-e">还需选择 <b>{{ item.limit - item.total }}</b> 项</span>
                            <span v-if="item.total == item.limit" class="c-color-p">选择完成</span>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </category>
        <category title="组选六">
            <table class="table-dpc o-mt" style="width:100%;">
                <colgroup>
                    <col v-for="index in 15" width="6.666%">
                </colgroup>
                <thead>
                    <tr>
                        <td v-for="(item,index) in CombSix.table" class="table-title" colspan="3">{{ item.title }}</td>
                    </tr>
                    <tr>
                        <template v-for="(item,index) in CombSix.table.length">
                            <td class="table-prompt table-code">号码</td>
                            <td class="table-prompt table-odds">赔率</td>
                            <td class="table-prompt table-input">选择</td>
                        </template>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in [0,1,2,3,4,5,6,7,8,9]">
                        <template v-for="(pack,column) in CombSix.table.length" v-if="CombSix.table[column].child[row]">
                            <td class="table-code" style="padding:6px 2px;">
                                <b>{{ CombSix.table[column].child[row].name }}</b>
                            </td>
                            <td class="table-odds" style="padding:6px 2px;">
                                <span v-if="CalcuOdds(CombSix.table[column].child[row])">{{ CalcuOdds(CombSix.table[column].child[row]).odds }}</span>
                                <Load v-else :size="1" />
                            </td>
                            <td class="table-input" style="padding:6px 2px;">
                                <el-checkbox v-model="CombSix.table[column].child[row].pick" :checked="CombSix.table[column].child[row].pick" :disabled="!CombSix.table[column].child[row].pick && CombSix.table[column].total >= CombSix.table[column].limit" @change="CombPick('CombSix')" />
                            </td>
                        </template>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td v-for="(item,index) in CombSix.table" class="table-prompt" colspan="3">
                            <span v-if="item.total && item.total < item.limit" class="c-color-e">还需选择 <b>{{ item.limit - item.total }}</b> 项</span>
                            <span v-if="item.total == item.limit" class="c-color-p">选择完成</span>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </category>
        <category title="一字过关">
            <table class="table-dpc o-mt" style="width:100%;">
                <colgroup>
                    <col v-for="index in 18" width="5.555%">
                </colgroup>
                <thead>
                    <tr>
                        <td v-for="(item,index) in CombOne.table" class="table-title" colspan="6">{{ item.title }}</td>
                    </tr>
                    <tr>
                        <template v-for="(item,index) in CombOne.table.length">
                            <td class="table-prompt table-code">号码</td>
                            <td class="table-prompt table-odds">赔率</td>
                            <td class="table-prompt table-input">选择</td>
                            <td class="table-prompt table-code">号码</td>
                            <td class="table-prompt table-odds">赔率</td>
                            <td class="table-prompt table-input">选择</td>
                        </template>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="row in [0,2,4]">
                        <template v-for="(pack,column) in CombOne.table.length" v-if="CombOne.table[column].child[row]">
                            <td class="table-code" style="padding:6px 2px;">
                                <b>{{ CombOne.table[column].child[row].name }}</b>
                            </td>
                            <td class="table-odds" style="padding:6px 2px;">
                                <span v-if="CalcuOdds(CombOne.table[column].child[row])">{{ CalcuOdds(CombOne.table[column].child[row]).odds }}</span>
                                <Load v-else :size="1" />
                            </td>
                            <td class="table-input" style="padding:6px 2px;">
                                <el-checkbox v-model="CombOne.table[column].child[row].pick" :checked="CombOne.table[column].child[row].pick" @change="CombPick('CombOne',column,row)" />
                            </td>
                            <td class="table-code" style="padding:6px 2px;">
                                <b>{{ CombOne.table[column].child[row + 1].name }}</b>
                            </td>
                            <td class="table-odds" style="padding:6px 2px;">
                                <span v-if="CalcuOdds(CombOne.table[column].child[row + 1])">{{ CalcuOdds(CombOne.table[column].child[row + 1]).odds }}</span>
                                <Load v-else :size="1" />
                            </td>
                            <td class="table-input" style="padding:6px 2px;">
                                <el-checkbox v-model="CombOne.table[column].child[row + 1].pick" :checked="CombOne.table[column].child[row + 1].pick" @change="CombPick('CombOne',column,row + 1)" />
                            </td>
                        </template>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td v-for="(item,index) in CombOne.table" class="table-prompt" colspan="6">
                            <span v-if="item.total == item.limit" class="c-color-p">选择完成</span>
                            <span v-else class="c-color-g">请选择</span>
                        </td>
                    </tr>
                    <tr v-if="CombOne.prompt">
                        <td class="table-prompt" colspan="18">
                            <span>勾选内容：</span><span>佰[{{ CombOne.prompt[0] }}],抬[{{ CombOne.prompt[1] }}],个[{{ CombOne.prompt[2] }}]，赔率：</span><b class="c-color-e">{{ CombOne.prompt[3] }}</b></td>
                    </tr>
                </tfoot>
            </table>
        </category>


        <category title="跨度">
            <group :list="List[0]" title="" :length="5" :prompt="prompt" :prompt-interval="promptInterval" type="queue" />
        </category>
        <category title="其他">
            <group :list="List[0]" title="" :length="5" :prompt="prompt" :prompt-interval="promptInterval" type="queue" />
            <group :list="List[1]" title="" :length="5" :prompt="prompt" :prompt-interval="promptInterval" type="queue" />
            <group :list="List[2]" title="" :length="5" :prompt="prompt" :prompt-interval="promptInterval" type="queue" />
        </category>
        <category title="一字过关" />
    </div>
</template>

<script>
import Credit from './other.js'
import Category from './components/category'
import Row from './components/row'
import Group from './components/group'
export default {
    name: 'GameCreditDpc',
    mixins: [Credit],
    data() {
        return {
            TwoMergeActive: 0, // 二字和数
            ThreeLocation: {
                table: [], amount: null, location: 0, number: 0 // 三字定位
            },
            ThreeCombination: {
                table: [], amount: null // 三字组合
            },
            CombOne: {
                table: [], limit: [1, 1, 1, 1], prompt: ''// 一字过关
            },
            CombThree: {
                table: [], limit: [5, 6, 7, 8, 9, 1]// 组选三
            },
            CombSix: {
                table: [], limit: [4, 5, 6, 7, 8] // 组选六
            }
        }
    },
    props: ['List'],
    computed: {
        prompt() {
            return this.COptions.type == 1 ? ['号码', '赔率', '金额'] : ['号码', '赔率']
        },
        promptInterval() {
            return this.COptions.type == 1 ? [7, 6, 11] : [12, 12]
        },
        // 二字和数
        TwoMerge() {
            if (this.Running.nav && this.Running.nav.active_title == '二字和数') {
                let List = this.List
                let active = this.TwoMergeActive
                let {title='', belong, child} = List[active]
                let obj_1 = {title, belong, child: []}
                let obj_2 = {title, belong, child: []}
                let obj_3 = {title, belong, child: []}
                for (let item of child) {
                    if (item.name == '单' || item.name == '双') {
                        obj_3.child.push(item)
                    } else if (item.name.length > 0 && item.name[item.name.length-1] == '尾') {
                        obj_2.child.push(item)
                    } else {
                        obj_1.child.push(item)
                    }
                }
                return [obj_1, obj_2, obj_3]
            }
            return null
        }
    },
    watch: {

    },
    methods: {
        // 玩法变更
        e_GOODS_RELOAD(id, nav, data) {
            let {active_title} = this.Running.nav
            if (active_title === '三字定位') {
                this.ThreeLocationTable()
                this.$emit('updateOpt', {key: 'checkoutBtn', val: false})
            } else if (active_title === '三字组合') {
                this.ThreeCombinationTable()
                this.$emit('updateOpt', {key: 'checkoutBtn', val: false})
            } else {
                this.$emit('updateOpt', {key: 'checkoutBtn', val: true})
            }
            if (active_title === '一字过关') {
                this.CombTable('CombOne')
                this.$emit('updateOpt', {key: 'onlyQuick', val: true})
            } else if (active_title === '组选三') {
                this.CombTable('CombThree')
                this.$emit('updateOpt', {key: 'onlyQuick', val: true})
            } else if (active_title === '组选六') {
                this.CombTable('CombSix')
                this.$emit('updateOpt', {key: 'onlyQuick', val: true})
            } else {
                this.$emit('updateOpt', {key: 'onlyQuick', val: false})
            }
        },
        // 赔率变更
        e_GOODS_UPDATE_ODDS() {

        },
        Reset() {
            this.ThreeReset()
            this.CombReset()
        },
        TwoMergeChange(e) {
            this.$emit('reset')
        },
        // 三字定位
        ThreeLocationTable() {
            let table = [[]]
            let {location, number} = this.ThreeLocation
            for (let item of this.List[0].child) {
                let {id, name: code, games_play_group_id} = item
                if (code[location] == number) {
                    let triple = code[0] == code[1] && code[0] == code[2] && code[1] == code[2]
                    let double = code[0] == code[1] || code[0] == code[2] || code[1] == code[2]
                    let normal = !triple && !double
                    let unit = table.length - 1
                    if (table[unit].length >= 5) {
                        table.push([])
                        unit ++
                    }
                    table[unit].push({
                        id, code, fast: false, amount: null, normal, double, triple, color: '', games_play_group_id
                    })
                }
            }
            this.$set(this.ThreeLocation, 'table', table)
            this.ThreeLocation.amount = null
            this.$emit('reset')
        },
        // 三字组合
        ThreeCombinationTable() {
            let table = [[]], colors = ['#cfcfff', '#CDFFCE', '#CECDFF', '#CDE5FF', '#FEFFCD', '#AEFFCD', '#CDFEFF', '#FFE7CD', '#CECDFF', '#FFCECD'];
            for (let item of this.List[0].child) {
                let {id, name: code, games_play_group_id} = item
                let triple = code[0] == code[1] && code[0] == code[2] && code[1] == code[2]
                let double = code[0] == code[1] || code[0] == code[2] || code[1] == code[2]
                let normal = !triple && !double
                let unit = table.length - 1
                if (table[unit].length >= 5) {
                    table.push([])
                    unit ++
                }
                table[unit].push({
                    id, code, fast: false, amount: null, normal, double, triple, color: colors[code[0]], games_play_group_id
                })
            }
            this.$set(this.ThreeCombination, 'table', table)
            this.ThreeCombination.amount = null
            this.$emit('reset')
        },
        ThreeFast(mode='Combination', name, reset) {
            for (let pack of this[`Three${mode}`].table) {
                for (let item of pack) {
                    let {code, double, triple} = item
                    let fast = false
                    if (code[0]==name || name=='normal'&&normal || name=='double'&&double || name=='triple'&&triple) {
                        fast = true
                    } else if (mode == 'Location') {
                        if (code[1]==name || code[2]==name) {
                            fast = true
                        }
                    }
                    if (fast) {
                        this.$set(item, 'fast', true)
                    } else if (reset) {
                        this.$set(item, 'fast', false)
                    }
                }
            }
        },
        ThreeInput(mode) {
            let {amount} = this.ThreeCombination
            let total = 0
            if (amount >= 1 && amount <= 1000000) {
                for (let pack of this[`Three${mode}`].table) {
                    for (let item of pack) {
                        if (item.fast) {
                            this.$set(item, 'amount', amount)
                            total ++
                        }
                    }
                }
                if (!total) {
                    this.Err('未选择需要传送的玩法')
                }
            } else {
                this.Err('请输入正确金额')
            }
        },
        ThreeReset() {
            for (let key of ['ThreeLocation', 'ThreeCombination']) {
                for (let pack of this[key].table) {
                    for (let item of pack) {
                        this.$set(item, 'amount', null)
                    }
                }
            }
        },


        // 一字过关  组选三、六
        CombTable(mode) {
            let limit = this[mode].limit
            let table = []
            let games_play_group_id = this.Running.nav.item.id
            this.List.forEach((pack, unit) => {
                let obj = {
                    id: pack.id,
                    games_play_group_id,
                    limit: this[mode].limit[unit],
                    title: pack.title,
                    valid: null,
                    total: 0,
                    child: []
                }
                for (let item of pack.child) {
                    obj.child.push({
                        id: item.id, name: item.name, pick: false
                    })
                }
                table.push(obj)
            })
            this.$set(this[mode], 'table', table)
        },
        CombPick(mode, unit, index) {
            let orders = []
            if (mode !='CombOne') {
                for (let pack of this[mode].table) {
                    let limit = pack.limit
                    let codes = []
                    let odds = null
                    for (let item of pack.child) {
                        try {
                            let _odds = this.Running.play.odds[item.id].odds
                            if (odds == null || odds > _odds) {
                                odds = _odds
                            }
                            if (item.pick) {
                                codes.push(item.name)
                            }
                        } catch (err) {}
                    }
                    this.$set(pack, 'total', codes.length)
                    if (codes.length == limit) {
                        let {id, games_play_group_id, title: belong} = pack
                        try {
                            let odds = this.Running.play.odds[id].odds
                        } catch (err) {}
                        let code = codes.join(',')
                        orders.push({
                            id, belong, name: code, code, games_play_group_id, odds
                        })
                    }
                }
            } else {
                this[mode].table[unit].child.forEach((item, i) => {
                    this.$set(item, 'pick', false)
                    if (index == i) {
                        this.$set(item, 'pick', true)
                    }
                })
                let codes = []
                let odds = []
                for (let pack of this[mode].table) {
                    this.$set(pack, 'total', 0)
                    for (let item of pack.child) {
                        if (item.pick) {
                            codes.push(item.name)
                            this.$set(pack, 'total', 1)
                            try {
                                odds.push(this.Running.play.odds[item.id].odds)
                            } catch (e) {}
                        }
                    }
                }
                this.$set(this[mode], 'prompt', '')
                let {id, games_play_group_id} = this[mode].table[0]
                let belong = '一字过关'
                let code = codes.join(',')
                if (codes.length == 3 && odds.length == 3) {
                    orders.push({
                        id, belong, name: code, code, games_play_group_id, odds: this.Big(odds[0]).mul(odds[1]).mul(odds[2]).round(4, 2).toNumber()
                    })
                    this.$set(this[mode], 'prompt', [...codes, odds])
                } else {
                    this.$set(this[mode], 'prompt', null)
                }
            }
            if (orders.length) {
                this.$emit('updateOpt', {key: 'customOrder', val: orders})
            }
        },
        CombReset() {
            for (let key of ['CombOne', 'CombThree', 'CombSix']) {
                for (let pack of this[key].table) {
                    for (let item of pack.child) {
                        this.$set(item, 'pick', false)
                    }
                    this.$set(pack, 'total', 0)
                }
                this.$set(this[key], 'prompt', null)
            }
        },
        Checkout(mode) {
            let orders = []
            if (mode == 'ThreeLocation' || mode == 'ThreeCombination') {
                let {title: belong} = this.List[0]
                for (let pack of this[mode].table) {
                    for (let item of pack) {
                        if (item.amount > 0 && item.amount < 999999) {
                            let {id, code, games_play_group_id, amount} = item
                            try {
                                let odds = this.Running
                                console.log('Checkout', item)
                                orders.push({id, belong, name: code, code, amount, games_play_group_id, odds: this.Running.play.odds[id].odds})
                            } catch (e) {}
                        }
                    }
                }
                this.ThreeReset()
                if (orders.length) {
                    this.$emit('updateOpt', {key: 'customOrder', val: orders})
                    this.$nextTick(() => {
                        this.$emit('checkout')
                        this.$emit('reset')
                    })
                }
            }
        }
    },
    components: {
        Category, Row, Group
    },
    mounted() {
        this.$store.state.game.Event.addListener('GOODS_RELOAD', this.e_GOODS_RELOAD)
        this.$store.state.game.Event.addListener('GOODS_UPDATE_ODDS', this.e_GOODS_UPDATE_ODDS)
    },
    beforeDestroy() {
        this.$store.state.game.Event.removeListener('GOODS_RELOAD', this.e_GOODS_RELOAD)
        this.$store.state.game.Event.removeListener('GOODS_UPDATE_ODDS', this.e_GOODS_UPDATE_ODDS)
    }
}
</script>
