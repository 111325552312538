
export default {
    name: 'GameFormalFollow',
    data() {
        return {
            stop_skips: true, // 官方跳开后停止
            stop_win: true,  // 中将后停止
            view: false,
            list: [],
            type: 1,
            options: {
                start: 1,
                length: 10,
                interval: 1,
                multiple: 2,
                preinstall: 5
            }
        }
    },
    computed: {
        cacluTotal() {
            let count = 0
            let multiple = 0
            let total = 0
            for (let item of this.list) {
                if (item.multiple > 0) {
                    count++
                    // multiple += item.multiple
                    total += this.calcuAmount(item.multiple)
                }
            }
            return {
                count,
                multiple,
                total: this.Big(total).round(4, 3).toNumber()
            }
        },
        stopSkips() {
            if (this.stop_skips && this.stop_win) {
                return 2
            }
            if (this.stop_skips) {
                return 1
            }
            return 0
        }
    },
    props: ['cart'],
    watch: {
        'options.preinstall'(val) {
            if (val) {
                this.options.length = val
                this.checkExpect()
            }
        },
        'view'(val) {
            if (val) {
                this.Reload()
            }
        },
        stop_skips(val) {
            if (!val && this.stop_win) {
                this.stop_win = false
            }
        },
        stop_win(val) {
            if (val && !this.stop_skips) {
                this.stop_skips = true
            }
        }
    },
    methods: {
        init() {
            this.Reload()
        },
        Reload() {
            this.DataReset('options')
            this.$set(this, 'list', [])
        },
        FollowView() {
            if (!this.cart.length) {
                return this.Notify('操作失败', `注单列表为空，请先下注！`, 'error')
            }
            this.view = true
            this.Get()
        },
        Get() {
            this.$store.dispatch('game/_GET_REMAIN', {
                id: this.Running.id,
                params: {games_id: this.Running.id, limit: 120}
            }).then(res => {
                let list = []
                for (let item of res.data) {
                    list.push({
                        multiple: 0,
                        ...item
                    })
                }
                this.$set(this.$data, 'list', list)
            })
        },
        Clean() {
            for (let item of this.list) {
                item.multiple = 0
            }
        },
        checkExpect() {
            if (this.list.length && this.options.length > this.list.length) {
                this.options.length = this.list.length
            }
        },
        Pick(item) {
            item.multiple = item.multiple > 0 ? 0 : this.options.start ? this.options.start : 1
        },
        // 生成追号计划
        Plan() {
            this.Clean()
            let multiple = this.options.start ? this.options.start : 1
            let interval = this.options.interval
            for (let i=0;i<this.options.length;i++) {
                if (this.list[i]) {
                    this.list[i].multiple = multiple
                    if (this.type == 2) {
                        interval--
                        if (!interval) {
                            multiple = multiple * this.options.multiple
                            interval = this.options.interval
                        }
                    }
                }
            }
        },
        calcuAmount(row_multiple) {
            let total = 0
            if (row_multiple) {
                for (let item of this.cart) {
                    total += this.Big(row_multiple).mul(item.chip).mul(item.mode_multiple).mul(item.price).round(4, 3).toNumber()
                }
            }
            return total
        },
        Checkout() {
            // 追号投注
            if (this.cacluTotal.count) {
                // 整理计划
                let plan = []
                for (let item of this.list) {
                    if (item.multiple) {
                        plan.push({
                            lottery_id: item.lottery_id,
                            multiple: item.multiple
                        })
                    }
                }
                // let list = []
                // for(let item of this.cart){
                //     let obj = {
                //         ...item,
                //         number : plan,
                //         type : this.type,// 1同倍追号 2翻倍追号
                //         stop_type : this.stopSkips, // 停追类型 / 1官方跳开即停 2中奖后即停
                //     }
                //     list.push(obj)
                // }
                // this.$store.dispatch('game/POST_FOLLOW',{
                //     data: JSON.stringify(list),
                //     side: this.Running.nav.side.id,
                //     games_id: this.Running.id,
                // }).then(res=>{
                //     this.view = false
                //     this.$emit('finish')
                //     this.Suc('操作成功')
                // })

                // 只选购物车第一项进行追号
                if (this.cart[0]) {
                    this.$store.dispatch('game/POST_FOLLOW', {
                        data: JSON.stringify([
                            {
                                ...this.cart[0],
                                number: plan,
                                type: this.type, // 1同倍追号 2翻倍追号
                                stop_type: this.stopSkips // 停追类型 / 1官方跳开即停 2中奖后即停
                            }
                        ]),
                        side: this.Running.nav.side.id,
                        games_id: this.Running.id
                    }).then(res => {
                        this.view = false
                        this.$emit('finish')
                        this.Suc('操作成功')
                    })
                }
            } else {
                this.view = false
                this.$emit('skip')
            }
        }
    },
    components: {

    },
    mounted() {
        this.init()
    }
}