<style lang="scss">
    @import "../../../../style/config.scss";
    .MyOpenReportMudul {
        .tabs {
            border-bottom:1px solid #aaa; position:relative; height:1.8rem; line-height:1.8rem; width:100%; overflow:hidden; color:#999;
            li {
                position:relative; padding:0 .6rem; bottom:2px; border-bottom:3px solid $color-n; float:left; font-size:.7rem; cursor:pointer;
                &.active{
                    border-color:$color-t; font-weight:bold; color:#333;
                }
            }
        }
        .el-table__expanded-cell[class*=cell]{
            padding:14px 40px 8px 40px;
            h4 {font-size:19px;}
        }
        .el-progress-bar__outer {
            background-color:#dbdde2;
        }
        table.table {
            font-size: 9pt;
            tr td {
                border:1px solid #858585;
            }
            thead tr td {background-color:#b0e5f2}
        }
    }
</style>
<template>
    <el-dialog class="MyOpenReportMudul" :title="Title" :visible.sync="view" top="5vh" size="huge" :modal-append-to-body="false" :close-on-click-modal="false">
        <p class="o-pb">请仔细检查是否过帐正确</p>
        <table v-loading="main._loading" class="table" border="0" cellspacing="1" cellpadding="0" style="border-collapse:collapse;">
            <thead>
                <tr style="text-align:left;">
                    <td style="text-align:left;">名称</td>
                    <td style="text-align:left;">下单数</td>
                    <td style="text-align:left;">下注金额</td>
                    <td style="text-align:left;">会员结果</td>
                    <td style="text-align:left;">代理结果</td>
                    <td style="text-align:left;">公司结果</td>

                    <td style="text-align:left;">收费占成额</td>
                    <td style="text-align:left;">收费占成结果</td>
                    <td style="text-align:left;">走飞金额</td>
                    <td style="text-align:left;">走飞结果</td>

                    <td style="text-align:left;">贡献金额</td>
                    <td style="text-align:left;">贡献比例</td>
                </tr>
            </thead>
            <tbody style="text-align:left;">
                <tr v-for="(item,index) in main._list">
                    <td style="text-align:left;">{{ item.name }}</td>
                    <td style="text-align:left;">{{ item.bets_num }}</td>
                    <td style="text-align:left;">{{ item.bets_amount }}</td>
                    <td style="text-align:left;">{{ item.members_result }}</td>
                    <td style="text-align:left;">{{ item.agent_result }}</td>
                    <td style="text-align:left;">{{ item.company_result }}</td>

                    <td style="text-align:left;"><a class="c-color-t" @click="Edit(scope.row,'EditerTransfer')"><b>{{ item.out_transfer_profit_loss }}</b></a></td>
                    <td style="text-align:left;">{{ item.out_transfer_amount }}</td>
                    <td style="text-align:left;">{{ item.transfer_amount }}</td>
                    <td style="text-align:left;">{{ item.transfer_amount_result }}</td>

                    <td style="text-align:left;">{{ item.proportion_amount }}</td>
                    <td style="width:115px;">
                        <div class="l-flex-c o-pr-s">
                            <el-progress class="PrintHide" style="width:75px;" :percentage="CalcuNumber(item.proportion)" :show-text="false" />
                            <p class="l-flex-1 c-text-6 c-text-r">{{ CalcuNumber(item.proportion) }}%</p>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr v-if="main._empty">
                    <td class="empty" colspan="100">暂无数据</td>
                </tr>
                <tr v-if="!main._empty" class="subtotal">
                    <td style="text-align:left;">合计</td>
                    <td v-if="!Filter.games_type" style="text-align:left;" />
                    <td style="text-align:left;">{{ CalcuRow('bets_num') }}</td>
                    <td style="text-align:left;">{{ CalcuRow('bets_amount') }}</td>
                    <td style="text-align:left;">{{ CalcuRow('members_result') }}</td>
                    <td style="text-align:left;">{{ CalcuRow('agent_result') }}</td>
                    <td style="text-align:left;">{{ CalcuRow('company_result') }}</td>

                    <td style="text-align:left;">{{ CalcuRow('out_transfer_profit_loss') }}</td>
                    <td style="text-align:left;">{{ CalcuRow('out_transfer_amount') }}</td>
                    <td style="text-align:left;">{{ CalcuRow('transfer_amount') }}</td>
                    <td style="text-align:left;">{{ CalcuRow('transfer_amount_result') }}</td>

                    <td style="text-align:left;">{{ CalcuRow('proportion_amount') }}</td>
                    <td style="text-align:left;" />
                </tr>
            </tfoot>
        </table>
    </el-dialog>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.modul.js'
export default {
    name: 'MyOpenReportMudul',
    mixins: [StoreMix],
    data() {
        return {
            store: 'myReport/subsidiary',
            Filter: {
                games_type: 7,
                per_page: 999,
                uid: 1
            }
        }
    },
    methods: {
        init() {
            this.DataReset('Filter')
            this.FilterIntervalSet('day')
            this.Get(1)
        },
        CalcuNumber(number) {
            try {
                return this.Big(number).mul(100).round(2, 0).toNumber()
            } catch (err) {
                return 0
            }
        }
        // CalcuRow(key){
        //     let total = 0
        //     try{
        //         for(let row of this.main._list){
        //             if(row[key]){
        //                 total = this.Big(total).add(row[key]).toNumber()
        //             }
        //         }
        //         return this.Big(total).round(2,0).toNumber()
        //     }catch(err){
        //         return NaN
        //     }
        //     return total
        // },
    },
    mounted() {

    }
}
</script>
