import Tools from '@lottery.2.0/mixin/tools.js'
export default {
    name: 'GameLayoutHeader',
    mixins: [Tools],
    data() {
        return {
            timer: null,
            links: [{
                title: '游戏大厅',
                event: 'index',
                image: 'back'
            },
            {
                title: '联系客服',
                event: 'service',
                image: 'service'
            },
            {
                title: '规则说明',
                event: 'rules',
                image: 'rules'
            }
            ],
            e: null,
            remind: {
                frist: true,
                view: false,
                timer: null,
                next: '',
                last: ''
            },
            ResultTimer: null,
            ResultCount: 0,
            onlineTimer: null,
            online: {}
        }
    },
    computed: {
        CountDown() {
            let close = this.Running.expect.count.close
            let end = this.Running.expect.count.end
            let count = close
            let states = 0
            if (!close || close <= 0) {
                count = 0
                if (end >= 0) {
                    // if(end > 10){
                    //     states = 1
                    // }else{
                    states = 2
                    count = end
                    //}
                } else {
                    states = 3
                }
            }
            return {
                states,
                prompt: {
                    '0': '剩余投注时间',
                    '1': '请等待游戏开奖',
                    '2': '距离开盘时间',
                    '3': '非开盘时间'
                }[states],
                format: this.CountDownFormat(count)
            }
        },
        Info() {
            let code = this.Running.code
            if (code && this.Games && this.Games[code]) {
                return this.Games[code].remote_data
            }
            return null
        },
        progress() {
            try {
                let expect = this.Running.expect
                if (expect) {
                    let max = 1, active = 1;
                    if (expect.now.close >= expect.time) {
                        max = expect.now.close - expect.now.start
                        active = expect.now.close - expect.time
                    } else if (expect.now.end >= expect.time) {
                        max = expect.now.end - expect.now.close
                        active = expect.now.end - expect.time
                    }
                    return `width:${this.Big(max-active).div(max).mul(100).toNumber()}%;`
                }
            } catch (err) {
                return 'width:100%;'
            }
        },
        isOpen() {
            if (this.Running.expect.now.id) {
                return this.Running.expect.now.status == 1
            }
            return false
        },
        ResultObj() {
            let {model} = this.Running
            let obj = {
                result: [],
                special: []
            }
            if (model == 7) {
                let target = this.isTodayOpen ? this.Running.expect.now : this.Running.expect.last
                let colors = target.colors ? target.colors.split(',') : []
                let zodiac = target.zodiac ? target.zodiac.split(',') : []
                for (let key of ['result', 'special']) {
                    if (target[key] && target[key].length) {
                        for (let i=0;i<target[key].length;i++) {
                            obj[key].push({
                                code: target[key][i],
                                color: colors.shift(),
                                text: zodiac.shift()
                            })
                        }
                    }
                }
            } else {
                try {
                    for (let item of this.Running.expect.last.result) {
                        obj.result.push({code: item})
                    }
                } catch (e) {}
            }
            return obj
        },
        Balance() {
            if (this.Opt.platform_method == 'cash') {
                return this.User.balance
            }
            if (this.Opt.platform_method == 'credit' && this.$store.state.game.group._init && this.Running.remote_data) {
                let type = ''
                for (let item of this.$store.state.game.group._list) {
                    if (this.Running.remote_data.type === item.id) {
                        type = item.code
                    }
                }
                if (this.User[`${type}_balance`] != undefined) {
                    return this.User[`${type}_balance`]
                }
            }
            return '-'
        }
    },
    watch: {
        'Running.id'() {
            this.DataReset('remind')
        },
        'Running.expect.now.expect'(next, last) {
            if (next && last) {
                this.remind.next = next
                this.remind.last = last
                this.remindView()
            }
        }
    },
    methods: {
        init() {
            this.ResultTimeRun()
            this.OnlineTimerRun()
            // this.Event = this.$store.state.socket.Event
            // this.Event.on('UPDATE_LOTTERY', (res) => {
            //     // 开奖彩种必须对应当前彩种
            //     if (res.code == this.Running.code) {
            //         this.Log('长连接收到开奖通知：', res)
            //         // 存储最新开奖信息到头部
            //         this.$store.commit('game/CHANGE_EXPECT', {
            //             last: {
            //                 expect: res.last.number,
            //                 result: res.last.code.split(','),
            //                 end: res.last.end_time,
            //             },
            //         })
            //         // 中奖提示
            //         if(this.$route.name == 'game'){
            //             if(this.Opt.isCredit && this.Opt.isTeam && this.Notify){
            //                 this.Notify('开奖提示',`${this.Running.title} ${res.last.number}期已开奖`,'warning',1500)
            //             }else if(res.win == 2) {
            //                 this.winTips('中奖提示', '恭喜，您所投注的注单已中奖')
            //             }else {
            //                 this.winTips('开奖提示', `${this.Running.title} ${res.last.number}期已开奖`)
            //             }
            //         }
            //     }
            // })
        },
        OnlineTimerRun() {
            clearInterval(this.onlineTimer)
            let LoadOnline = () => {
                if (this.Opt.isTeam) {
                    this.Dp('base/RUNING_ONLINE').then(res => {
                        this.$set(this, 'online', res.data)
                    })
                }
            }
            LoadOnline()
            this.oddsTimer = setInterval(() => {
                LoadOnline()
            }, 120000)
        },
        // 剩余时间格式化成倒计时
        CountDownFormat(count = 0) {
            let format = [0, 0, 0]
            for (let i = 0; i < format.length; i++) {
                let unit = [3600, 60, 1][i]
                format[i] = Math.floor(count / unit).toString()
                if (format[i].length < 2) {
                    format[i] = `0${format[i]}`
                }
                count = count > unit ? count % unit : count
            }
            return format
        },
        use(name) {
            if (name == 'logout') {
                return this.Logout()
            }
            if (name == 'rules') {
                let code = this.Running.code
                this.$store.dispatch('game/GET_RULES', {
                    code,
                    type: this.Running.type
                })
                return false
            }
            if (name == 'service') {
                return this.OnlineService()
            }
            this.Go(name)
        },
        winTips(title, msg) {
            let h = this.$createElement;
            this.$message({
                iconClass: '-',
                duration: 3000,
                center: true,
                customClass: this.Config.environment == 'pc' ? 'GameLayoutModulWin' : 'GameLayoutModulWin_M',
                message: h('p', {
                    class: 'modul'
                }, [
                    h('div', {
                        class: 'title'
                    }, title),
                    h('div', {
                        class: 'content',
                        style: 'margin-top:1rem;'
                    }, [
                        h('p', null, msg)
                    ])
                ])
            });
        },
        remindTips() {
            if (this.Opt.isTeam) {
                return
            }
            let h = this.$createElement;
            this.$message({
                iconClass: '-',
                duration: 3000,
                center: true,
                customClass: 'GameLayoutModulRemind_M',
                message: h('p', {
                    class: 'modul'
                }, [
                    h('p', {
                        class: 'c-text-75 c-text-c'
                    }, '请注意，期号已切换'),
                    h('p', {
                        class: 'c-text-75 o-mt-s c-text-c'
                    }, '当前下注期号为'),
                    h('p', {
                        class: 'c-text-85 o-mt-s c-text-c'
                    }, [
                        h('b', {
                            class: ''
                        }, this.remind.next || '未知')
                    ])
                ])
            });
        },
        remindView() {
            if (this.remind.frist) {
                this.remind.frist = false
            } else if (this.Config.environment == 'pc') {
                this.remind.view = true
                clearTimeout(this.remind.timer)
                this.remind.timer = setTimeout(() => {
                    this.remind.view = false
                }, 4000)
            } else {
                this.remindTips()
            }
        },
        ResultTimeRun() {
            //console.log('ResultTimeRun')
            //this.$store.state.base.Event.emit('Credit/ExpectInit')
            clearInterval(this.ResultTimer)
            this.ResultCount = 0
            this.ResultTimer = setInterval(() => {
                this.ResultCount = this.ResultCount + 1
                if (~['LHC'].indexOf(this.Running.code) && this.Running.expect && this.Running.expect.now && this.Running.expect.now.id && this.Running.expect.now.status != 1) {
                    let date = new Date(this.Running.expect.time * 1000)
                    if (date.getHours() > 21 && `${date.getHours()}${date.getMinutes()}${date.getSeconds()}` < '220000') {
                        this.$store.state.base.Event.emit('Credit/ExpectInit')
                    } else if (this.ResultCount && this.ResultCount % 6 === 0) {
                        this.$store.state.base.Event.emit('Credit/ExpectInit')
                        // console.log('默认更新')
                    } else if (this.Opt.isDev) {
                        //this.$store.state.base.Event.emit('Credit/ExpectInit')
                    }
                }
            }, 5000)
        }
    },
    mounted() {
        //this.winTips('中奖提示','您所投注的注单已中奖')
        //this.remindTips()
        this.init()
    }
}
