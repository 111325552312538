<style lang="scss">
.MyReportAmountMudul {
    // .el-table .cell {
    //     padding-left:5px; padding-right:5px;
    // }
    .el-dialog__body {
        padding-top:.6rem;
    }
    table.table {
        tr td.mark {
            color:red!important;
        }
    }
}
</style>

<template>
    <el-dialog class="MyReportAmountMudul" :title="title" :visible.sync="view" :size="Opt.isTeam ? 'bhuge' : 'huge'" top="5vh" :modal-append-to-body="false">
        <div ref="table">
            <div v-if="form" class="o-pb o-plr l-flex-c">
                <div class="l-flex-1 o-pr">时间：<b>{{ form.interval[0] }}</b> 至 <b>{{ form.interval[1] }}</b><span v-if="form.username">，会员名：<b class="c-color-t">{{ form.username }}</b></span></div>
            </div>
            <p-table :table="main" :summary="summary" />
        </div>
        <div class="l-flex-c o-pt-s">
            <div class="l-flex-1">
                <button class="o-ml btn" @click="PrintTable()">表格打印</button>
            </div>
            <Pagination v-if="main._total" v-model="Page" class="o-mtb" :total="main._total" :count="main._count" :page-size="Filter.per_page" @turning="Get" />
        </div>
    </el-dialog>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.modul.js'
import PTable from '../components/amount_table'
export default {
    name: 'MyReportAmountMudul',
    mixins: [StoreMix],
    data() {
        return {
            store: 'game/order',
            Filter: {
                transfer: 0,
                interval: null,
                per_page: 20
            },
            summary: {}
        }
    },
    props: {
        model: {
            default: 'order'
        }
    },
    computed: {

    },
    methods: {
        init() {
            this.Clean()
            this.$set(this, 'Filter', this.form)
            if (this.model == 'transfer') {
                this.Filter.transfer = 1
            }
            this.Filter.per_page = 20
            this.$set(this, 'summary', {})
            this.Cm('game/_GAME_CLEAN', {base: this.model, key: '_list'})
            this.Get(1)
            this.GetSum()
        },
        _Get(page) {
            this.Get(page)
            this.GetSum()
        },
        GetSum() {
            let type = this.model
            let models = {
                order: this.Opt.isTeam ? 'ordersum' : 'ordersum',
                transfer: this.Opt.isTeam ? 'ordersum' : 'ordersum'
            }
            this.Get('ordersum').then(res => {
                this.$set(this, 'summary', res.data)
            })
        }
    },
    components: {
        PTable
    }
}
</script>