export default {
    data() {
        return {
            Tools: {
                dir: {
                    fontsize: {
                        title: '字体大小设置',
                        event: 'ToolFontSize',
                        default: 20
                    }
                },
                opt: {},
                view: false
            }
        }
    },
    computed: {

    },
    methods: {
        ToolGetOpt() {
            let cache = this.Cache('ToolsOpt')
            if (cache) {
                this.$set(this.Tools, 'opt', cache)
            } else {
                this.ToolInit()
            }
            this.ToolLoadOpt()
        },
        ToolSaveOpt(name, val) {
            let cache = this.Cache('ToolsOpt') ? this.Cache('ToolsOpt') : this.Tools.opt
            cache[name] = val
            this.Cache('ToolsOpt', cache)
        },
        ToolInit() {
            for (let name in this.Tools.dir) {
                this.$set(this.Tools.opt, name, this.Tools.dir[name].default)
            }
        },
        ToolLoadOpt() {
            for (let name in this.Tools.dir) {
                let target = this.Tools.dir[name]
                let val = this.Tools.opt[name]
                this[target.event](val)
            }
        },
        ToolFontSize(num=20) {
            this.$set(this.Tools.opt, 'fontsize', num)
            this.ToolSaveOpt('fontsize', num)
            document.getElementsByTagName('html')[0].style.fontSize = `${num}px`
        }
    },
    mounted() {
        this.ToolGetOpt()
    }
}
