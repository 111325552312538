<style lang="scss">
.MyRecordInfoPasswordMudul {

}
</style>

<template>
    <el-dialog class="MyRecordInfoPasswordMudul" :title="title" :visible.sync="view" size="mini" top="15vh" :modal-append-to-body="false">
        <el-form v-loading="main._loading" label-width="80px">
            <el-form-item label="旧密码">
                <el-input v-model="Params.old_pass" max-length="24" type="password" placeholder="请输入旧密码" />
            </el-form-item>
            <el-form-item label="新密码">
                <el-input v-model="Params.pass" max-length="24" type="password" placeholder="请输入新密码" />
            </el-form-item>
            <el-form-item label="确认密码">
                <el-input v-model="Params.confirm_pass" max-length="24" type="password" placeholder="请输入重复输入新密码" />
            </el-form-item>
        </el-form>
        <div class="c-text-r">
            <el-button @click="Cancel()">取消</el-button>
            <el-button :loading="main._editing" type="primary" @click="Submit()">提交</el-button>
        </div>
    </el-dialog>
</template>

<script>
import StoreMix from '../../../../../mixin/store.modul.js'
export default {
    name: 'MyRecordInfoPasswordMudul',
    mixins: [StoreMix],
    data() {
        return {
            store: 'myAccount/safety'
        }
    },
    methods: {
        init() {

        },
        Submit() {
            this.Params.action = 'pass'
            this.Post()
        }
    },
    mounted() {
        this.init()
    }
}
</script>
