<style lang="scss">
    @import "../../../../style/config.scss";
    .CreditSystemImproper {
        table.table {
            box-shadow:1px 1px 8px rgba(0,0,0,.1);
        }
    }
</style>
<template>
    <div class="CreditSystemImproper">
        <table v-loading="main._loading" class="table u-bb" border="0" cellspacing="1" cellpadding="0" style="width:480px;">
            <thead>
                <tr style="text-align:left;">
                    <td>组合序</td>
                    <td>号码1</td>
                    <td>号码2</td>
                    <td>号码3</td>
                    <td>号码4</td>
                    <td>号码5</td>
                </tr>
            </thead>
            <tbody style="text-align:left;">
                <tr v-for="(item,index) in Params.numbers" class="c-text-c">
                    <td>{{ index + 1 }}</td>
                    <td><el-input v-model="item.child[0]" size="mini" style="width:60px;" /></td>
                    <td><el-input v-model="item.child[1]" size="mini" style="width:60px;" /></td>
                    <td><el-input v-model="item.child[2]" size="mini" style="width:60px;" /></td>
                    <td><el-input v-model="item.child[3]" size="mini" style="width:60px;" /></td>
                    <td><el-input v-model="item.child[4]" size="mini" style="width:60px;" /></td>
                </tr>
            </tbody>
        </table>
        <p class="o-ptb-s">自选不中热门号码组合自动降赔率设定（每一组合必须为5个号码）</p>
        <div class="o-pt">
            <span>降赔率：</span>
            <el-input v-model="Params.odds" size="mini" style="width:70px;" />
            <span class="o-pl" />
            <Button @click="Submit()">提交</Button>
        </div>
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
export default {
    name: 'CreditSystemImproper',
    mixins: [StoreMix],
    data() {
        return {
            store: 'admin/improper',
            Params: {
                numbers: [],
                odds: null
            }
        }
    },
    methods: {
        init() {
            this.DataReset()
            this.Get().then(res => {
                this.$set(this.Params, 'odds', res.data[0].odds ? parseFloat(res.data[0].odds) : 0)
                for (let item of res.data) {
                    let obj = {
                        id: item.id,
                        child: []
                    }
                    if (item.str_data) {
                        for (let code of item.str_data.split(',')) {
                            obj.child.push(code)
                        }
                    }
                    while (obj.child.length <= 4 ) {
                        obj.child.push(undefined)
                    }
                    this.Params.numbers.push(obj)
                }
            })
        },
        Submit() {
            let data = {
                data: [],
                odds: this.Params.odds
            }
            for (let item of this.Params.numbers) {
                data.data.push({
                    id: item.id,
                    numbers: item.child.join(',')
                })
            }
            data.data = JSON.stringify(data.data)
            this.Dp('admin/_POST_IMPROPER', {data}).then(res => {
                this.Suc('修改成功')
            })
        }
    },
    mounted() {
        this.init()
    }
}
</script>
