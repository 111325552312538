<style lang="scss">
    @import "../../../../style/config.scss";

    .GameFormalChipin {
        $bg:#573f36; $bg-2:#2d1a0c;

        .notes {
            padding:0 16px 16px 16px;
            span {display:inline-block;}
            a {
                display:inline-block; width:22px; height:22px; line-height:22px; background:#999; color:#fff; border-radius:99px;
                font-size:12px; text-align:center;
            }
            a.example {width:40px;}
            .el-radio {

            }
        }
        .header {
            padding:0 16px; background-color:$bg; border-bottom:1px solid $bg-2; color:#fff;
            height:56px; line-height:56px; overflow:hidden; font-size:12.5px;
            a, span {
                display:inline-block; vertical-align:middle;
            }
            a.header-btn {
                width:26px; height:26px; line-height:26px; text-align:center;
                background:$bg-2; color:#fff; border:1px solid $bg-2;
                font-size:20px; font-weight:bold;
            }
            input {
                width:32px; height:26px; line-height:26px; padding:0 5px; border:1px solid $bg-2; outline:none; color:#fff; background:$bg;
                vertical-align:middle;
            }
            ////////////////
            .multiple, .mode, .rebate, .info,
            .random, .explain {
                display:inline-block; position:relative;
            }
            .rebate {
                .rebate-btn {
                    width:1rem; height:1rem; line-height:1rem; margin:0 3px;  //border:1px solid $color-t;
                    font-size:.65rem; cursor:pointer; text-align:center; font-weight:bold;
                    display:inline-block; background-color:#fff; color:$color-t;  border-radius:999px;
                }
            }
            &.options {
                .multiple {

                }
                .mode {
                    .title {
                        width:30px; height:26px; line-height:26px; text-align:center; border:1px solid $bg-2; color:#fff;
                    }
                    .dropdown {

                    }
                }
                .info {
                    b {color:#fcbd6b;}
                }

                .options-btn {
                    width:100px; height:33px;
                    img {width:100%; height:100%; display:inline-block; vertical-align:middle; position:relative; top:-2px;}
                }
            }
            &.chipin {
                border-top:1px solid #7b645b;
                .explain {
                    text-align:center;
                }
                .chipin-btn-2 {
                    width:72px; height:28px;
                    img {width:100%; height:100%; display:block;}
                }
            }
        }


        .order {
            overflow:hidden;
            .list, .state, .follow {
                height:150px; float:left;
            }
            .list {
                width:60%; overflow-y:auto;
            }
            .follow {
                width:25%;
                a.submit {
                    width:100%; height:48px; line-height:48px; border-radius:99px; text-align: center; background-color:#f60; color:#fff; font-size:17px; margin-left:auto; margin-right:auto; display:block;
                }
            }
            .state {
                width:15%;
            }
            .btn-cancel {
                cursor:pointer; color:#999; font-size:.8rem;
                &:hover {
                    color:$color-t;
                }
            }
            table {
                width:100%; table-layout: fixed;
                th {
                    padding:7px 2px; border-bottom:1px solid #eee; font-size:12px; text-align:center; vertical-align:middle;
                }
            }
        }
    }
</style>
<template>
    <div class="GameFormalChipin">

        {{/* 说明 */}}
        <div v-if="template" class="notes">
            <div class="o-mt">
                <span class="o-mr-h"><b>{{ Running.nav.item.title }}</b>：{{ template.title }}</span>
                <template v-if="template.rank && Is('stringint',template.rank[0])">
                    <el-radio-group v-model="rank.radio" class="o-mr-s" size="mini" :disabled="!template.rank">
                        <el-radio-button :label="1" @click.native="rankRadio(1)">冷热</el-radio-button>
                        <el-radio-button :label="2" @click.native="rankRadio(2)">遗漏</el-radio-button>
                    </el-radio-group>
                </template>
                <span>
                    <el-popover placement="top-start" width="200" trigger="hover">
                        <div class="c-text-65" v-html="template.example" />
                        <a slot="reference" class="example">示例</a>
                    </el-popover>
                </span>
                <span>
                    <el-popover placement="top-start" width="200" trigger="hover">
                        <div class="c-text-65" v-html="template.rule" />
                        <a slot="reference" class="rule">?</a>
                    </el-popover>
                </span>
                <span>{{ template.description }}</span>
            </div>
            <div v-if="Running.remote_data && Running.remote_data.max_money && Running.remote_data.max_turn_money" class="o-mt">
                <b>提示：</b>{{ Running.title }}单玩法最高派奖{{ Running.remote_data.max_money }}元，单期最高派奖{{ Running.remote_data.max_turn_money }}元。
            </div>
        </div>

        {{/* 配置 */}}
        <div class="options header l-flex">
            <div class="multiple">
                <a class="header-btn" @click="multipleReduce">-</a><input v-model="multiple" class="c-text-c"><a class="header-btn" @click="multipleAdd">+</a>
                <span class="o-ml-s">倍，</span>
            </div>
            <div class="info l-flex-1">
                <span>共选 <b>{{ calcuTotal.chip }}</b> 注，</span>
                <span>共投 <b>{{ calcuTotal.total }}</b> 元，</span>
                <span>盈利 <b>{{ calcuTotal.profit }}</b> 元</span>
            </div>
            <div class="info l-flex-1 l-flex-c">
                <span style="padding-right:3px;">单注奖金</span>
                <Load v-if="Running.play.odds_loading" size=".8" color="rgb(255,255,255)" speed="fast" style="position:relative; top:-2px;" />
                <span v-else class="c-text-b" style="color:#fcbd6b;">{{ calcuOdds.bonus }}</span>
                <span style="padding-left:3px;">元</span>
            </div>
            <div>
                <Button size="medium" @click="checkout()">确认选号</Button>
                <Button size="medium" @click="quickOrder()">立即下注</Button>
            </div>
        </div>

        {{/* 下注 */}}
        <div class="chipin header l-flex">
            <div class="random" style="width:175px;">
                <div v-if="template && template.format">
                    <span>随机</span>
                    <input v-model="randomNumber" class="o-mlr-s" maxlength="2" style="width:18px;">
                    <span>注</span>
                    <Button class="o-ml" size="mini" @click="orderRandom()">随机投注</Button>
                    <!-- <a class="chipin-btn-2 o-ml" @click="orderRandom()"><img src="../../../../image/game/order_random.png"></a>    -->
                </div>
            </div>
            <div class="explain l-flex-1" style="width:290px;"><b>投注列表</b></div>
            <div class="random">
                <Button size="mini" @click="cancelOrder(0,999)">清空列表</Button>
                <!-- <a class="chipin-btn-2" @click="cancelOrder(0,999)"><img src="../../../../image/game/order_clear.png"></a>  -->
            </div>
            <div class="explain" style="width:280px;"><b>操作状态</b></div>
            <div class="explain" style="width:200px;">
                <!--                 <el-switch v-model="lock" active-color="#41bb45" inactive-color="#2d1a0c"></el-switch>
                <span class="o-ml-s">是否锁定倍数</span> -->
            </div>
        </div>

        {{/* 订单 */}}
        <div class="order">
            <div class="list">
                <table style="width:100%;">
                    <tr v-for="(item,index) in cart">
                        <th style="width:20%;">{{ item.title }}</th>
                        <th style="width:31%;">{{ item.number.length > 36 ? item.number.slice(0,36) + '...' : item.number }}</th>
                        <th>{{ [null,'厘','分','角','元'][item.mode] }}</th>
                        <th>{{ item.chip }}注</th>
                        <th>{{ item.multiple }}倍</th>
                        <th style="width:10%;">{{ item.total }}元</th>
                        <th style="width:9%;"><i class="el-icon-error btn-cancel" @click="cancelOrder(index)" /><!-- <a @click="cancelOrder(index)">取消</a> --></th>
                    </tr>
                </table>
            </div>
            <div class="state u-bl">
                <div class="o-plr-l">
                    <p class="o-mt">
                        <span>选择</span>
                        <span class="c-float-r">
                            <span><b class="c-color-t">{{ calcuCart.chip }}</b> 注</span>
                            <span><b class="c-color-t">{{ calcuCart.length }}</b> 单</span>
                        </span>
                    </p>
                    <p class="o-mt-h">
                        <span>投注</span>
                        <span class="c-float-r">
                            <span><b class="c-color-t">{{ calcuCart.total }}</b> 元</span>
                        </span>
                    </p>
                    <p class="o-mt-h">
                        <span>追号</span>
                        <span class="c-float-r">
                            <span>无追号</span>
                        </span>
                    </p>
                </div>
            </div>

            <div class="follow u-bl">
                <div class="o-plr-l">
                    <follow :cart="cart" @finish="FollowFinish()" @skip="makeOrder()" />
                    <div class="o-mt-l">
                        <a class="submit" @click="makeOrder()">确认下注</a>
                        <!-- <Button size="big" full>确认下注</Button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Follow from './follow.vue'
import Common from './chipin.js'
export default {
    mixins: [Common],
    components: {
        Follow
    }
}
</script>
