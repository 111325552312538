import Formal from '../../../../../mixin/formal'
export default {
    name: 'GameFormalNum2',
    mixins: [Formal],
    data() {
        return {
            table: null,
            params: null
        }
    },
    computed: {

    },
    props: ['template', 'rank'],
    watch: {
        params: {
            handler(val) {
                if (val) {
                    this.$emit('change', this.MakeOrder())
                }
            },
            deep: true
        }
    },
    methods: {
        init() {
            this.Reset()
        },
        Reset() {
            //this.Log('Reset')
            let table = []
            let params = []
            for (let pack of this.template.sub[0]) {
                let row = []
                let row2 = []
                for (let item in this.MakeBallArray()) {
                    row.push(item)
                    row2.push(false)
                }
                table.push(row)
                params.push(row2)
            }
            this.$set(this.$data, 'table', table)
            this.$set(this.$data, 'params', params)
        },
        Pick(unit, index) {
            this.$set(this.params[unit], index, !this.params[unit][index])
        },
        MakeBallArray() {
            return ['大', '小', '单', '双']
        },
        Tools(unit, type='全') {
            let length = this.MakeBallArray().length
            for (let i=0;i<this.params[unit].length;i++) {
                switch (type) {
                    case '全':
                        this.$set(this.params[unit], i, true)
                        break;
                    case '清':
                        this.$set(this.params[unit], i, false)
                        break;
                }
            }
        },
        MakeOrder() {

            let BetOdds = this.CalcuBetOdds()
            let params = this.params
            let order = []
            let chip = null

            let valid = []
            let group = []

            let algorithm = this.template.algorithm
            let balls = this.MakeBallArray()

            for (let pack of params) {
                let num = 0
                for (let item of pack) {
                    if (item) {
                        num++
                    }
                }
                if (chip===null) {
                    chip = num
                } else {
                    chip = chip * num
                }
            }
            for (let u=0;u<this.params.length;u++) {
                let row = []
                for (let i=0;i<this.params[u].length;i++) {
                    if (this.params[u][i]) {
                        row.push(balls[i])
                    }
                }
                group.push(row.join(','))
            }
            order = [{
                chip: chip,
                code: group.join('|'),
                bonus_prop: BetOdds.odds
            }]

            return {
                order,
                chip
            }
        }
    },
    components: {

    },
    mounted() {
        this.init()
    }
}