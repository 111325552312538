<style lang="scss">
    @import "../../../../style/config.scss";
    .CreditSystemPassword {
        .isSub {
            padding-left:1.5rem; padding-top:1rem;
        }
    }
</style>
<template>
    <div class="CreditSystemPassword">
        <div class="o-pl" :class="{'isSub': $route.name === 'credit/password'}">
            <div style="width:200px;">
                <p class="o-pt-s c-line-12">旧密码</p>
                <el-input v-model="Params.old_pass" size="small" max-length="24" type="password" placeholder="请输入旧密码" />
                <p class="o-pt-s c-line-12">新密码</p>
                <el-input v-model="Params.pass" size="small" max-length="24" type="password" placeholder="请输入新密码" />
                <p class="o-pt-s c-line-12">确认密码</p>
                <el-input v-model="Params.confirm_pass" size="small" max-length="24" type="password" placeholder="请输入重复输入新密码" />
                <div class="o-pt">
                    <Button @click="Submit()">提交</Button>
                </div>
            </div>
            <p class="o-pt-h">为了系统的安全性，密码至少需要６码以上！</p>
        </div>
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
export default {
    name: 'CreditSystemPassword',
    mixins: [StoreMix],
    data() {
        return {
            store: 'myAccount/safety'
        }
    },
    methods: {
        init() {
            this.DataReset()
        },
        Submit() {
            this.Params.action = 'pass'
            this.Post().then(res => {
                this.init()
            })
        }
    },
    mounted() {
        this.init()
    }
}
</script>
