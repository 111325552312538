<style lang="scss">
    @import "../../../../style/config.scss";
    .CreditSystemOddsauto {
        table.table tbody tr td {
            padding-top:4px!important; padding-bottom:4px!important;
            input {
                width:80px; padding:2px 5px;
            }
        }
    }
</style>
<template>
    <div class="CreditSystemOddsauto">
        <div v-loading="main._loading">
            <div class="l-flex-c o-pb">
                <div class="l-flex-1">
                    <span>游戏：</span>
                    <el-select v-model="Filter.games_id" style="width:120px;" size="small" @change="load()">
                        <el-option v-for="(item,index) in Opt.hasDir.game" :key="item.id" :label="item.title" :value="item.id" />
                    </el-select>
                </div>
            </div>
            <table v-loading="main._loading" class="table u-bl u-bb u-br" border="0" cellspacing="1" cellpadding="0" style="width:800px;">
                <thead>
                    <template v-if="TableOpt.step > 1">
                        <tr style="text-align:left;">
                            <td rowspan="2">玩法</td>
                            <td colspan="2">第一阶段(一次性)</td>
                            <td colspan="2">第二阶段(累进)</td>
                            <td rowspan="2">最低赔率</td>
                        </tr>
                        <tr style="text-align:left;">
                            <template v-for="item in TableOpt.step">
                                <td>金额</td>
                                <td>降赔率</td>
                            </template>
                        </tr>
                    </template>
                    <template v-else>
                        <tr style="text-align:left;">
                            <td>玩法</td>
                            <td>金额</td>
                            <td>降赔率</td>
                            <td>最低赔率</td>
                        </tr>
                    </template>
                </thead>
                <tbody style="text-align:left;">
                    <template v-if="list && list.length > 0">
                        <tr v-for="(item,index) in list" class="c-text-c">
                            <td>{{ item.name }}</td>
                            <template v-if="item.name.substr(item.name.length - 1,1) === 'B'">
                                <td colspan="5">(特码 B 合并入特码 A 中，以特码 A 中金额为准)</td>
                            </template>
                            <template v-else>
                                <td><input v-model.number="item.one_step_money"></td>
                                <td><input v-model.number="item.one_step_odds"></td>
                                <td><input v-if="TableOpt.step >= 2" v-model.number="item.two_step_money"></td>
                                <td><input v-if="TableOpt.step >= 2" v-model.number="item.two_step_odds"></td>
                                <td><input v-model.number="item.min_odds"></td>
                            </template>
                        </tr>
                    </template>
                    <tr v-else class="c-text-c">
                        <td class="c-text-c" colspan="99"><p class="o-ptb-l">暂无数据</p></td>
                    </tr>
                </tbody>
            </table>
            <div class="o-ptb">
                <Button class="btn" @click="Submit()">确定更改</Button>
                <Button class="btn" @click="load()">重读</Button>
            </div>
        </div>
        <el-dialog title="配置保存中" :visible.sync="waiting.view" width="420px" :close-on-click-modal="false">
            <div class="c-text-c">
                <Load />
            </div>
        </el-dialog>
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
export default {
    name: 'CreditSystemOddsauto',
    mixins: [StoreMix],
    data() {
        return {
            store: 'admin/autoodds',
            Filter: {
                games_id: 31
            },
            list: [],
            waiting: {
                view: false
            }
        }
    },
    computed: {
        ActiveGame() {
            let id = this.Filter.games_id
            for (let item of this.$store.state.game.games._list) {
                if (item.id == id) {
                    return item
                }
            }
            return null
        },
        TableOpt() {
            let obj = {
                step: 2,
                up: true,
                down: true
            }
            return obj
        }
    },
    methods: {
        init() {
            this.$set(this.Filter, 'games_id', this.Running.id)
            this.load()
        },
        load() {
            this.Get().then(res => {
                this.$set(this, 'list', this.Origin(res.data))
            })
        },
        Submit() {
            let data = this.Origin(this.list)
            this.waiting.view = true
            this.Dp('admin/_POST_AUTOODDS', {
                data: {
                    data: JSON.stringify(data)
                }
            }).then(res => {
                this.Suc('自动赔率设置已保存')
                this.waiting.view = false
                this.load()
            }, err => {
                this.waiting.view = true
            })
        }
    },
    components: {

    },
    mounted() {
        this.init()
    }
}
</script>
