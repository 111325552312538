<style lang="scss">
    @import './theme/credit.scss';
    .Game {
        width:100%; height:100%; background-color:#fff; position:absolute; top:0; left:0; bottom:0; right:0; overflow-y:auto; box-sizing:border-box;
        .credit-box {
            width:1400px; margin:0 auto; box-shadow:0 5px 15px rgba(0,0,0,.1); border-radius:6px;
            background-repeat:no-repeat; border-top:25px solid #ffcc66;
            //
            &.huge {
                width:1520px;
            }
            .game-box {
                background-position-y:60px;
            }
            &.notTeam {
                background-image:url(../../image/head.jpg); padding-top:25px; border-top:none;
            }
        }
        .marquee {
            padding:0 12px;
            .marquee-title {
                padding: 4px 10px; background-color:rgb(148,190,57);
            }
            .marquee-content {
                border:2px solid rgb(148,190,57); background-color:#fff; padding:2px 0;
            }
        }
        section {
            margin-left:-1px; background-color:#fff; color:#333;
        }
        .CreditSix {
            padding:8px; min-height:400px;
        }
        @media screen and (max-width: 1439px){
            .credit-box {
                width:1300px; position:relative; left:-10px;
                &.huge {
                    width:1300px;
                }
            }
        }
        .el-fade-in-linear-leave-active {
            display:none;
        }
        .notId {
            .GameLayoutHeaderCredit {
                margin-top:1.6rem;
            }
        }
        // .home-box {
        //     margin-top:1rem;
        //     .home-dir {
        //         padding:1.4rem 1.8rem 2rem 1.8rem;
        //     }
        //     background:linear-gradient(180deg,#7494d4,#f5f5f5); background-size:100% 200px; background-repeat:no-repeat;
        //     .pack {
        //         width:16.66%;
        //         .pack-title {
        //             min-height: 3rem;
        //             .title {
        //                 color:#3266da;
        //             }
        //             .name {
        //                 padding-top:3px; color:rgba(255,255,255,.45);
        //             }
        //         }
        //     }
        //     .pack-list {
        //         .pack-item {
        //             padding:.3rem .45rem; cursor:pointer; transition:background-color, .3s;
        //             &:hover {
        //                 background-color:rgba(0,0,0,.07);
        //             }
        //             .item-image {
        //                 width:2.6rem; height:2.6rem;
        //             }
        //         }
        //     }
        // }
    }
</style>
<template>
    <div v-if="ready" class="Game credit o-ptb">
        <div class="credit-box l-flex" :class="{'huge': Opt.isTeam,'notTeam': !Opt.isTeam,'notId': !Running.id}" :style="{borderTopColor: Running.bg_color && undefined}">
            <div v-show="Running.id" style="height:100%;">
                <game-nav style="height:100%;" />
            </div>
            <div ref="box" class="game-box l-flex-1">
                <div>
                    <game-header @announ="ShowNews" @output="OutputView" />
                    <game-category @change="NavChange" />
                </div>
                <div class="o-ptb-s">
                    <div class="marquee l-flex-c">
                        <p class="marquee-title">及时讯息</p>
                        <marquee v-if="Opt.isTeam" class="marquee-content l-flex-1 o-plr" behavior="scroll" direction="left" loop="infinite">{{ Opt.platform_lantern_agent }}</marquee>
                        <marquee v-else class="marquee-content l-flex-1 o-plr" behavior="scroll" direction="left" loop="infinite">{{ Opt.platform_lantern_members }}</marquee>
                    </div>
                </div>
                <div class="machine" style="min-height:200px;">
                    <transition name="el-fade-in-linear">
                        <keep-alive>
                            <router-view v-if="routerView" />
                        </keep-alive>
                    </transition>
                </div>
            </div>
            <!--             <template v-else>
                <div class="home-box l-flex-1">
                    <GameHeader @announ="ShowNews" @output="OutputView"></GameHeader>
                    <div class="home-dir l-flex">
                        <div v-for="(pack,unit) in Category" class="pack" v-show="Opt.hasDir.typeById[pack.id]">
                            <div class="pack-title o-pl">
                                <h3 class="title c-text-b c-text-10 c-line-14">{{ pack.title }}</h3>
                                <p class="name c-text-b c-text-75 c-line-10">{{ pack.name }}</p>
                            </div>
                            <ul class="pack-list">
                                <li v-for="(item,index) in pack.games" class="pack-item" @click="ApplyGame(item.code,'credit')">
                                    <div class="l-flex-c">
                                        <img class="item-image" :src="Img(item.icon)">
                                        <div class="l-flex-1 o-plr c-text-8">{{ item.title }}</div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </template> -->
        </div>
        <news ref="news" />
        <game-output v-model="Output.view" title="开奖历史" :from="{}" />
    </div>
</template>

<script>
import Common from './index.js'
import GameHeader from './layout/header_credit.vue'
import GameNav from './layout/nav.vue'
import GameCategory from './layout/category_credit.vue'
import News from '../index/layout/news';
import GameOutput from './sub/model/output';
export default {
    name: 'Credit',
    mixins: [Common],
    data() {
        return {
            theme: 'default',
            Output: {
                view: false
            },
            routerView: true
        }
    },
    computed: {

    },
    watch: {
        '$store.state.base.router.count'(val) {
            this.ReloadRouter()
        }
    },
    methods: {
        init() {
            this.ready = true

            if (this.Opt.hasDir.game.length === 1) {
                this.ApplyGame(this.Opt.hasDir.game[0].name, 'credit') // 如果只有一种玩法则自动进入该玩法
            } else if (this.Opt.isTeam) {
                // 如果有六合彩默认选六合彩
                let name = null
                if (this.$route.params && this.$route.params.code) {
                    let code = this.$route.params.code
                    if (this.Opt.hasDir.gameByName[code]) {
                        name = code
                    }
                }
                if (!name) {
                    name = this.Opt.hasDir.gameByName['FUCAI3D'] ? 'FUCAI3D' : this.Opt.hasDir.game[0].name
                }
                this.ApplyGame(name, 'credit')
            } else if (this.$route.params) {
                let {code} = this.$route.params
                if (code) {
                    if (this.Opt.hasDir.gameByName[code]) {
                        this.ApplyGame(code, 'credit')
                    }
                }
            }

            // 获取通知
            this.Dp('base/_GET_ANNOUN').then(res => {
                if (res.data && res.data.length > 0) {
                    // 非本地测试环境则弹出最新公告窗口
                    if (!this.Opt.isLocalhost) {
                        this.ShowNews()
                    }
                }
            })
            // 系统开奖的消息通知 || 期数变化
            this.$store.state.base.Event.on('Credit/ExpectInit', () => {
                this.ExpectInit()
            })
            // 游戏切换（通过外部变更游戏）
            this.$store.state.base.Event.on('Credit/ChangeGame', (code) => {
                // console.log("Credit/ChangeGame", code)
                this.ApplyGame(code, 'credit')
            })
            // 装载期数
            this.$store.state.base.Event.on('Credit/ExpectLoad', (expect) => {
                this.ExpectLoad(expect)
            })
        },

        // 刷新路由
        ReloadRouter() {
            this.routerView = false
            this.$nextTick(() => {
                this.routerView = true
            })
        },

        // 开奖历史
        OutputView() {
            this.Output.view = true
        },

        // 显示公告
        ShowNews() {
            if (this.$refs['news']) {
                if (this.$store.state.base.announ._init && this.$store.state.base.announ._list.length > 0) {
                    this.$refs['news'].dialogFormVisible = true
                } else {
                    this.Msg('暂无公告')
                }
            }
        },

        // 切换主题
        Theme(name) {
            this.theme = name
            this.Cache('theme', name)
        }
    },
    components: {
        GameHeader,
        GameNav,
        GameCategory,
        News,
        GameOutput
    },
    created() {
        this.theme = this.Cache('theme') ? this.Cache('theme') : 'default'
    }
}
</script>
