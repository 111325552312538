<style lang="scss">
    @import "../../../../style/config.scss";
    .GameHistoryFollowDetails {
        .el-dialog {
            max-width:740px; min-width:740px;
        }
        .content {
            background-color:#f0f0f0; margin:8px 0; min-height:24px; line-height:24px; padding:10px; border-radius:5px;
            .codes {
                min-height:24px; word-wrap:break-word; width:600px;
            }
        }
        .expect, .follow {
            min-height:120px;
        }
        .follow-info {
            background-color:#f0f0f0; margin:8px 0; border-radius:5px; padding:10px;
        }
        .el-dialog__body {
            padding-top:16px;
        }
        .el-form-item--mini.el-form-item {
            margin-bottom:0;
        }
        .c-color-g {color:#aaa;}
    }
</style>
<template>
    <el-dialog class="GameHistoryFollowDetails" size="big" title="注单详情" :visible.sync="item.view">

        {{/* 基础数据 */}}
        <el-row :gutter="16">
            <el-col :span="8">
                <el-form ref="form" label-width="80px" label-position="left" size="mini">
                    <el-form-item label="注单编号">{{ order.sequence }}</el-form-item>
                    <el-form-item label="倍数模式">{{ order.multiple }} 倍 / {{ order.mode ? [null,'厘','分','角','元'][order.mode] : order.mode }}</el-form-item>
                    <el-form-item label="奖期">{{ order.lottery_number }}</el-form-item>
                    <el-form-item label="投注数量">{{ order.bet_num }}</el-form-item>
                </el-form>
            </el-col>
            <el-col :span="8">
                <el-form ref="form" label-width="80px" label-position="left" size="mini">
                    <el-form-item label="游戏名称">{{ order.title }}</el-form-item>
                    <el-form-item label="投单时间">{{ order.create_at }}</el-form-item>
                    <!-- <el-form-item label="注单奖金">{{ order.create_at }}</el-form-item> -->
                    <el-form-item label="开奖内容">{{ order.code_number }}</el-form-item>
                    <!--                     <el-form-item label="注单奖金">
                        <span v-if="supplement.expect">{{ supplement.expect.bonus ? supplement.expect.bonus : 0 }}</span>
                    </el-form-item>
                    <el-form-item label="开奖内容">
                        <span v-if="supplement.expect">{{ supplement.expect.lottery_data }}</span>
                    </el-form-item> -->
                </el-form>
            </el-col>
            <el-col :span="8">
                <el-form ref="form" label-width="80px" label-position="left" size="mini">
                    <el-form-item label="盘类玩法">{{ order.side }} {{ order.plays }}</el-form-item>
                    <el-form-item label="总金额">{{ order.amount }}</el-form-item>
                    <!-- <el-form-item label="动态返点">{{ order.rebate }}</el-form-item> -->
                    <el-form-item label="注单状态">{{ order.status }}</el-form-item>
                </el-form>
            </el-col>
        </el-row>

        {{/* 下注号码 */}}
        <div class="content">
            <div class="l-flex-c">
                <div class="o-pr-s">投注内容</div>
                <div class="l-flex-1">
                    <div class="codes u-bl o-pl">{{ order.code }}</div>
                </div>
            </div>
        </div>

        {{/* 详情 */}}
        <div v-if="order.follow_sequence" class="tab-content u-bt o-pt">
            <div class="follow">
                <el-row :gutter="20">
                    <el-col :span="8">
                        <div class="follow-info">
                            <el-form v-if="supplement.follow" ref="form" label-width="80px" label-position="left" size="mini">
                                <el-form-item label="追号编号">{{ supplement.follow.sequence }}</el-form-item>
                                <el-form-item label="开始期号">{{ supplement.follow.start_number }}</el-form-item>
                                <el-form-item label="终止条件">
                                    <p v-if="!supplement.follow.stop_type">无</p>
                                    <p v-else>官方跳开及停</p>
                                    <p v-if="supplement.follow.stop_type == 2">中奖后停止追号</p>
                                </el-form-item>
                                <el-form-item label="追号进度">
                                    <span>{{ supplement.follow.follow_amount_over + supplement.follow.follow_amount_cancel }} / {{ supplement.follow.follow_amount }}</span>
                                    <span v-if="supplement.follow.follow_amount_cancel">(被取消：{{ supplement.follow.follow_amount_cancel }})</span>
                                </el-form-item>
                            </el-form>
                        </div>
                        <div class="o-pt">
                            <Button @click="cancelFollow()">撤销追号</Button>
                        </div>
                    </el-col>
                    <el-col :span="16">
                        <el-table v-if="supplement.follow && supplement.follow.list" :data="supplement.follow.list" height="240" style="width:100%; font-size:12px;" size="mini">
                            <el-table-column prop="number" label="奖期" align="center" />
                            <el-table-column prop="multiple" label="倍数" align="center" />
                            <el-table-column prop="amount" label="金额" align="center" />
                            <el-table-column prop="status" label="状态" align="center" />
                            <el-table-column prop="bonus" label="奖金" align="center" />
                        </el-table>
                    </el-col>
                </el-row>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'GameHistoryFollowDetails',
    data() {
        return {
            //view : false
            tabActive: 'expect',
            supplement: {
                expect: null,
                follow: null
            }
        }
    },
    props: ['item'],
    computed: {
        order() {
            if (this.item && this.item.order) {
                return this.item.order
            }
            return {}
        }
    },
    watch: {
        'item.view'(val) {
            if (val) {
                this.init()
            }
        }
        // tabActive(val){
        //     if(val == 'follow'){
        //         this.GetFollow()
        //     }
        // },
    },
    methods: {
        init() {
            this.Reload()
        },
        Reload() {
            //this.tabActive = 'expect'
            // this.DataReset('supplement')
            // this.GetExpect()
            this.GetFollow()
        },
        pickMode(e) {
            this.mode = e
        },
        GetExpect() {
            // if(this.order.id){
            //     this.Dp('game/GET_EXPECT_BY_ORDER',this.order.id).then(res=>{
            //         this.$set(this.supplement,'expect',res)
            //     })
            // }
        },
        GetFollow() {
            let sequence = this.order.follow_sequence
            if (sequence) {
                this.Dp('game/ITEM_FOLLOW', sequence).then(res => {
                    this.$set(this.supplement, 'follow', res.data)
                })
            }
        },
        Append() {
            // this.$set(this.item,'view',false)
            // this.$emit('append',this.item.order)
        },
        cancelFollow() {
            let sequence = this.order.follow_sequence
            if (sequence) {
                this.Confirm(() => {
                    console.log(sequence)
                    this.Dp('game/CANCEL_FOLLOW', sequence).then(({data}) => {
                        if (data) {
                            this.Suc('操作成功')
                            this.$emit('done')
                            this.Reload()
                        }
                    })
                }, '您是否确认撤销追号？', '撤销追号')
            }
        }
    },
    mounted() {
        this.init()
    }
}
</script>
