
import map from '~/dgx_z/map.js'
const store = 'game'; const STORE = store.toUpperCase();
const model = 'init'; const MODEL = model.toUpperCase();

export default {
    name: 'GameLayoutNav',
    data() {
        return {
            model: `${store}/${model}`,
            activeCategory: null,
            ind: null,
            icons: {
                '1': {
                    title: '时时彩',
                    icon: 'icon-game-ssc'
                },
                '6': {
                    title: 'PK拾',
                    icon: 'icon-game-pk10'
                },
                '4': {
                    title: '11选5',
                    icon: 'icon-game-115'
                },
                '5': {
                    title: '快乐彩',
                    icon: 'icon-game-k3'
                },
                '3': {
                    title: '分分彩',
                    icon: 'icon-game-ffc'
                },
                '7': {
                    title: '六合彩',
                    icon: 'icon-game-six'
                }
            }
        }
    },
    watch: {
        // 玩法切换时候更新开奖历史
        'Running.id'(id) {
            if (id) {
                this.activeCategory = this.Running.belong
                this.updateHistory()
            }
        },
        // 类型切换时刷新开奖历史
        'Running.type'(val, old) {
            if (val && old && val!=old ) {
                this.updateHistory()
            }
        }
    },
    computed: {
        title() {
            return this.$store.state.game.running.title
        }

    },
    methods: {
        init() {
            //this.updateHistory()
            // 侦听长连接游戏开奖提醒消息
            // this.Event = this.$store.state.socket.Event
            // this.Event.on('UPDATE_LOTTERY',(res)=>{
            //     this.updateHistory()
            // })
        },
        // 更新开奖历史
        updateHistory() {
            this.$store.dispatch('game/_GET_HISTORY', {
                params: {
                    games_id: this.Running.id,
                    games_type: this.Running.group
                }
            }).then(res => {
                if (res) {
                    this.$store.commit('game/RUNNING_SET', {history: res.data})
                }
            })
        },
        // 选择游戏分类
        pickCategory(item) {
            this.activeCategory = item.title
        },
        // 切换游戏
        pickGames(item) {
            // 默认继承当前的玩法类型，如果没有则选择官方玩法
            // let type = this.$route.params.type
            // if(!type){
            //     type = this.Running.type ? this.Running.type : 'formal'
            // }

            // // 查询当前玩法是否拥有实例，没有则切换玩法
            // if(!item[type]){
            //     type = type == 'formal' ? 'credit' : 'formal'
            // }

            let code = item.code
            this.Go('credit/game', {code, type: 'credit'})
        },
        HistoryMore() {
            this.Go('lotteryresult', {code: this.Running.code})
        },
        ...map(store, model, [
            'HISTORY_LIST'
        ])
    },
    activated() {
        this.init()
    },
    mounted() {
        //this.init()
    }
}