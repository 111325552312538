
import config from '@lottery.2.0/library/config'
const isPc = config.environment == 'pc'

export default {
    name: '游戏',
    list: [
        {
            alias: '/',
            path: '/credit/',
            name: 'credit',
            meta: {
                //name: '新资讯-游戏'
                name: '新资讯'
            },
            redirect: {
                name: 'credit/game'
            },
            component: require('@lottery.2.0/page/game/credit.vue').default,
            children: [
                {
                    path: 'game/:code/:type',
                    name: 'credit/game',
                    meta: {
                        //name: '新资讯-游戏'
                        name: '新资讯'
                    },
                    component: require('@lottery.2.0/page/game/sub/index.vue').default
                },
                {
                    path: 'query',
                    name: 'credit/query',
                    meta: {
                        //name: '新资讯-下注明细'
                        name: '新资讯'
                    },
                    component: require('@lottery.2.0/page/game/sub/query.vue').default
                },
                {
                    path: 'report',
                    name: 'credit/report',
                    meta: {
                        //name: '新资讯-结算报表'
                        name: '新资讯'
                    },
                    component: require('@lottery.2.0/page/game/sub/report.vue').default
                },
                // {
                //     path : 'output',
                //     name : 'credit/output',
                //     meta : {
                //         name : '开奖历史',
                //     },
                //     component : require('@lottery.2.0/page/game/sub/output.vue').default,
                // },
                {
                    path: 'member',
                    name: 'credit/member',
                    meta: {
                        //name: '新资讯-会员'
                        name: '新资讯'
                    },
                    component: require('@lottery.2.0/page/game/sub/member.vue').default
                },
                {
                    path: 'agent',
                    name: 'credit/agent',
                    meta: {
                        //name: '新资讯'
                        name: '新资讯-代理'
                    },
                    component: require('@lottery.2.0/page/game/sub/agent.vue').default
                },
                {
                    path: 'announ',
                    name: 'credit/announ',
                    meta: {
                        //name: '新资讯-公告'
                        name: '新资讯'
                    },
                    component: require('@lottery.2.0/page/game/sub/announ.vue').default
                },
                {
                    path: 'rules',
                    name: 'credit/rules',
                    meta: {
                        //name: '新资讯-规则'
                        name: '新资讯'
                    },
                    component: require('@lottery.2.0/page/game/sub/rules.vue').default
                },
                {
                    path: 'user',
                    name: 'credit/user',
                    meta: {
                        //name: '新资讯-帐号管理'
                        name: '新资讯'
                    },
                    component: require('@lottery.2.0/page/game/sub/user.vue').default
                },
                {
                    path: 'runtime',
                    name: 'credit/runtime',
                    meta: {
                        //name: '新资讯-及时注单'
                        name: '新资讯'
                    },
                    component: require('@lottery.2.0/page/game/sub/operate/runtime.vue').default
                },
                {
                    path: 'operate',
                    name: 'credit/operate',
                    meta: {
                        //name: '新资讯-操盘'
                        name: '新资讯'
                    },
                    component: require('@lottery.2.0/page/game/sub/operate/operate.vue').default
                },
                {
                    path: 'transmit',
                    name: 'credit/transmit',
                    meta: {
                        //name: '新资讯-走飞'
                        name: '新资讯'
                    },
                    component: require('@lottery.2.0/page/game/sub/operate/transmit.vue').default
                },
                {
                    path: 'backup',
                    name: 'credit/backup',
                    meta: {
                        //name: '新资讯-注单备份'
                        name: '新资讯'
                    },
                    component: require('@lottery.2.0/page/game/sub/backup.vue').default
                },
                {
                    path: 'actlog',
                    name: 'credit/actlog',
                    meta: {
                       //name: '新资讯-操作日志'
                        name: '新资讯'
                    },
                    component: require('@lottery.2.0/page/game/sub/actlog.vue').default
                },
                {
                    path: 'open',
                    name: 'credit/open',
                    meta: {
                        //name: '新资讯-开奖'
                        name: '新资讯'
                    },
                    component: require('@lottery.2.0/page/game/sub/open.vue').default
                },
                {
                    path: 'account',
                    name: 'credit/account',
                    meta: {
                        //name: '新资讯-子帐号'
                        name: '新资讯'
                    },
                    component: require('@lottery.2.0/page/game/sub/account.vue').default
                },
                {
                    path: 'online',
                    name: 'credit/online',
                    meta: {
                        //name: '新资讯-在线人员'
                        name: '新资讯'
                    },
                    component: require('@lottery.2.0/page/game/sub/online.vue').default
                },
                {
                    path: 'system/',
                    name: 'credit/system',
                    meta: {
                        //name: '新资讯-系统'
                        name: '新资讯'
                    },
                    redirect: {
                        name: 'credit/system/base'
                    },
                    component: require('@lottery.2.0/page/game/sub/system.vue').default,
                    children: [
                        {
                            path: 'base',
                            name: 'credit/system/base',
                            meta: {
                                //name: '新资讯-系统设定'
                                name: '新资讯'
                            },
                            component: require('@lottery.2.0/page/game/sub/sub/base.vue').default
                        },
                        {
                            path: 'website',
                            name: 'credit/system/website',
                            meta: {
                                //name: '新资讯-走飞站点设置'
                                name: '新资讯'
                            },
                            component: require('@lottery.2.0/page/game/sub/sub/website.vue').default
                        },
                        {
                            path: 'oddsbase',
                            name: 'credit/system/oddsbase',
                            meta: {
                                //name: '新资讯-基础退水设定'
                                name: '新资讯'
                            },
                            component: require('@lottery.2.0/page/game/sub/sub/oddsbase.vue').default
                        },
                        {
                            path: 'backwater',
                            name: 'credit/system/backwater',
                            meta: {
                                //name: '新资讯-基本赔率设定'
                                name: '新资讯'
                            },
                            component: require('@lottery.2.0/page/game/sub/sub/backwater.vue').default
                        },
                        {
                            path: 'oddsauto',
                            name: 'credit/system/oddsauto',
                            meta: {
                                //name: '新资讯-自动赔率设定'
                                name: '新资讯'
                            },
                            component: require('@lottery.2.0/page/game/sub/sub/oddsauto.vue').default
                        },
                        {
                            path: 'improper',
                            name: 'credit/system/improper',
                            meta: {
                                //name: '新资讯-自选不中热门'
                                name: '新资讯'
                            },
                            component: require('@lottery.2.0/page/game/sub/sub/improper.vue').default
                        },
                        {
                            path: 'betcancel',
                            name: 'credit/system/betcancel',
                            meta: {
                                //name: '新资讯-取消注单'
                                name: '新资讯'
                            },
                            component: require('@lottery.2.0/page/game/sub/sub/betcancel.vue').default
                        },
                        {
                            path: 'password',
                            name: 'credit/system/password',
                            meta: {
                                //name: '新资讯-变更密码'
                                name: '新资讯'
                            },
                            component: require('@lottery.2.0/page/game/sub/sub/password.vue').default
                        },
                        {
                            path: 'announ',
                            name: 'credit/system/announ',
                            meta: {
                                //name: '新资讯-平台公告'
                                name: '新资讯'
                            },
                            component: require('@lottery.2.0/page/game/sub/sub/announ.vue').default
                            //component : import('@lottery.2.0/page/game/sub/sub/announ.vue'),
                        }
                    ]
                },
                {
                    path: 'password',
                    name: 'credit/password',
                    meta: {
                        name: '新资讯'
                    },
                    component: require('@lottery.2.0/page/game/sub/sub/password.vue').default
                }
            ]
        }
    ]
}