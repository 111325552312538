<style lang="scss">
    .CreditSixQuery {
        font-size:14px;
        a.link {
            text-decoration:underline; padding:0 3px;
        }
    }
</style>
<template>
    <div class="CreditSix CreditSixQuery">
        <div class="l-flex-c o-mb">
            <div class="ctrl l-flex-1">
                <DatePicker v-model="Filter.interval" @change="ExpectChange()" />
                <span class="o-pl">游戏类型：</span>
                <el-select v-model="Filter.games_id" style="width:6.5rem;" size="small" @change="ExpectChange()">
                    <el-option key="0" label="全部" value="0" />
                    <el-option v-for="(item,index) in Opt.hasDir.game" :key="item.id" :label="item.title" :value="item.id" />
                </el-select>
                <span class="o-pl">期数：</span>
                <el-select v-model="Filter.lottery_id" v-loading="$store.state.myRecord.history._loading" size="small" style="width:120px;" @change="ExpectChange()">
                    <el-option label="所有" :value="undefined" />
                    <el-option v-for="(item,index) in expect.list" :key="item.id" :label="item.number" :value="item.id" />
                </el-select>
            </div>
            <Button class="o-ml" @click="PrintTable()">表格打印</Button>
        </div>
        <div ref="table">
            <table v-loading="main._loading" class="table line" border="0" cellspacing="1" cellpadding="0">
                <colgroup>
                    <col title="注单号" width="145px">
                    <col title="期号" width="85px">
                    <col title="下注时间" width="150px">
                    <col title="开奖号码">
                    <col title="盘类 / 玩法">
                    <col title="投注内容">
                    <col title="赔率" width="60px">
                    <col title="金额">
                    <col title="派奖">
                    <col title="退水">
                    <col title="结果">
                    <col title="追号" width="60px">
                    <col title="状态" width="60px">
                </colgroup>
                <thead>
                    <tr>
                        <td>注单号</td>
                        <td>期号</td>
                        <td>下注时间</td>
                        <td>开奖号码</td>
                        <td>盘类 / 玩法</td>
                        <td>投注内容</td>
                        <td>赔率</td>
                        <td>金额</td>
                        <td>派奖</td>
                        <td>退水</td>
                        <td>结果</td>
                        <td>追号</td>
                        <td>状态</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in main._list" class="c-text-c">
                        <td>{{ item.sequence }}</td>
                        <td>{{ item.lottery_number }}</td>
                        <td>{{ item.create_at }}</td>
                        <td>{{ item.code_number }}</td>
                        <td>{{ item.side }} / {{ item.plays }}</td>
                        <td>{{ CheckContextLength(item.code,100) }}</td>
                        <td>{{ item.odds }}</td>
                        <td>{{ item.amount }}</td>
                        <td>{{ item.bonus }}</td>
                        <td>{{ item.backwater }}</td>
                        <td>{{ item.result }}</td>
                        <td>
                            <span v-if="!item.follow" class="c-color-g">无</span>
                            <template v-else>
                                <a class="link" @click="itemFollow(item)">详情</a>
                                <!-- <a v-if="scope.row.follow == 1" class="link" @click="cancelFollow(item)">撤销</a> -->
                            </template>
                        </td>
                        <td>{{ item.status }}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr v-if="main._empty">
                        <td class="empty" colspan="100">暂无数据</td>
                    </tr>
                    <tr v-if="!main._empty && main._total > 1" class="subtotal">
                        <td style="text-align:left;">小计</td>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td>{{ CalcuRow('amount') }}</td>
                        <td>{{ CalcuRow('bonus') }}</td>
                        <td>{{ CalcuRow('backwater') }}</td>
                        <td>{{ CalcuRow('result') }}</td>
                        <td />
                        <td />
                    </tr>
                    <tr v-if="!main._empty && summary" class="total">
                        <td style="text-align:left;">合计</td>
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td />
                        <td>{{ summary.amount }}</td>
                        <td>{{ summary.bonus }}</td>
                        <td>{{ summary.backwater }}</td>
                        <td>{{ summary.result }}</td>
                        <td />
                        <td />
                    </tr>
                </tfoot>
            </table>
        </div>
        <Pagination v-if="main._total" v-model="Page" class="o-mtb" :total="main._total" :count="main._count" :page-size="Filter.per_page" @turning="Get" />
        <details-follow :item="detailsFollow" @done="MakeFilter2()" />
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
import DetailsFollow from '../components/formal/details.follow'

export default {
    name: 'CreditSixQuery',
    mixins: [StoreMix],
    data() {
        return {
            store: 'game/order',
            Filter: {
                games_id: "0",
                lottery_id: undefined,
                per_page: 20
            },
            expect: {
                list: []
            },
            detailsFollow: {
                view: false,
                order: {}
            },
            summary: null
        }
    },
    computed: {
        // games(){
        //     return this.$store.state.game.games
        // },
    },
    methods: {
        init() {
            this.DataReset('Filter')
            this.FilterIntervalSet('day')
            if (this.Running.id) {
                this.Filter.games_id = this.Running.id
                this.Filter.games_type = this.Running.group
            }
            this.Dp('myRecord/_GET_HISTORY', {params: {games_id: 31, games_type: 7, per_page: 5}}).then(res => {
                this.$set(this.expect, 'list', res.data)
            })
            this.Get()
            this.Sum()
        },
        CalcuWin(item={}) {
            try {
                return this.Big(item.amount).mul(item.odds).div(item.amount).toNumber()
            } catch (err) {
                return NaN
            }
        },
        MakeFilter2() {
            this.Sum()
            this.MakeFilter()
        },
        ExpectChange() {
            // this.FilterIntervalSet('all')
            this.Sum()
            this.MakeFilter()
        },
        Sum() {
            this.$set(this, 'summary', null)
            this.Get('ordersum').then(res => {
                this.$set(this, 'summary', res.data)
            })
        },
        itemFollow(item) {
            if (item.follow) {
                this.detailsFollow.view = true
                this.$set(this.detailsFollow, 'order', item)
            }
        }
        // CalcuRow(key){
        //     let total = 0
        //     try{
        //         for(let row of this.main._list){
        //             if(row[key]){
        //                 total = this.Big(total).add(row[key]).toNumber()
        //             }
        //         }
        //         return this.Big(total).round(2,0).toNumber()
        //     }catch(err){
        //         return NaN
        //     }
        //     return total
        // },
    },
    components: {
        DetailsFollow
    },
    activated() {
        this.init()
    },
    mounted() {
        //this.init()
    }
}
</script>
