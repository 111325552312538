<style lang="scss">
    @import "../../../../style/config.scss";
    .CreditSystemWebsite {

    }
</style>
<template>
    <div class="CreditSystemWebsite">
        <div class="l-flex-c o-pb">
            <div class="l-flex-1" />
            <Button @click="Edit()">新增站点</Button>
        </div>
        <table v-loading="main._loading" class="table" border="0" cellspacing="1" cellpadding="0">
            <thead>
                <tr>
                    <td>#</td>
                    <td>名称</td>
                    <td>创建时间</td>
                    <td>修改时间</td>
                    <td>状态</td>
                    <td>操作</td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item,index) in main._list" class="c-text-c">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.create_at }}</td>
                    <td>{{ item.update_at }}</td>
                    <td>
                        <span v-if="item.status == 1">启用</span>
                        <span v-if="item.status == 0">停用</span>
                    </td>
                    <td>
                        <Button size="mini" @click="Edit(item)">修改</Button>
                        <Button size="mini" @click="Del(item)">删除</Button>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr v-if="main._empty">
                    <td class="empty" colspan="100">暂无数据</td>
                </tr>
            </tfoot>
        </table>
        <editer v-model="Editer.view" :form="Editer.form" @finish="Get(Page)" />
    </div>
</template>

<script>
import Editer from './model/website'
import StoreMix from '@lottery.2.0/mixin/store.js'
export default {
    name: 'CreditSystemWebsite',
    mixins: [StoreMix],
    data() {
        return {
            store: 'game/tsite',
            Filter: {
                per_page: 50
            }
        }
    },
    methods: {
        init() {
            this.DataReset()
            this.Get()
        },
        Del(item) {
            this.Dp('game/_PUT_TSITE', {id: item.id, data: {...item, status: 9}}).then(res => {
                this.Get(1)
                this.Suc('操作成功')
            })
        }
        //     this.Params.action = 'pass'
        //     this.Post().then(res=>{
        //         this.init()
        //     })
        // },
    },
    components: {
        Editer
    },
    mounted() {
        this.init()
    }
}
</script>
