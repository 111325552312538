<style lang="scss">
    @import "./group.scss";
</style>
<template>
    <ul v-if="list && (list.child || list.length)" class="GameCreditGroup" :class="['type-' + COptions.type,type]">

        <template v-if="type == 'normal'">
            {{/*普通单列*/}}
            <p v-if="Title" class="title">{{ Title }}</p>
            <prompt v-if="prompt != false" v-model="prompt" :interval="promptInterval" />
            <template v-for="(item,index) in list.child">
                <el-row v-if="!item.fill" :key="index" tag="li" :class="{'pick': item.pick && Quick}" @click.native="Pick(item)">
                    <el-col v-odds="item.odds" :span="Interval[0]" tag="div" @click.native.stop="PickFast(item)">
                        <div v-if="dice" class="goods" :class="IsInt(item.name) ? 'dice' : 'text'">
                            <template v-if="IsInt(item.name)">
                                <a v-for="num in item.name.split('')" class="dice">
                                    <img v-if="num == 1" src="../../../../../image/game/dice/1.png">
                                    <img v-if="num == 2" src="../../../../../image/game/dice/2.png">
                                    <img v-if="num == 3" src="../../../../../image/game/dice/3.png">
                                    <img v-if="num == 4" src="../../../../../image/game/dice/4.png">
                                    <img v-if="num == 5" src="../../../../../image/game/dice/5.png">
                                    <img v-if="num == 6" src="../../../../../image/game/dice/6.png">
                                </a>
                            </template>
                            <span v-else>{{ item.name }}</span>
                        </div>
                        <div v-else class="goods" :class="IsInt(item.name) ? 'ball' : 'text'">
                            <span :class="six_color ? CodeColor(item.name) : ''">{{ item.name }}</span>
                        </div>
                    </el-col>
                    <el-col :span="Interval[1]" @click.native.stop="PickFast(item)">
                        <template v-if="!CalcuOdds(item)">
                            <div v-if="odds._loading">
                                <Load size=".8" color="rgba(0,0,0,.3)" speed="fast" />
                            </div>
                            <div v-else class="odds">-</div>
                        </template>
                        <div v-else :key="item.id" v-odds="CalcuOdds(item)" class="odds">
                            <span>{{ CalcuOdds(item).odds }}</span><span v-if="CalcuOdds(item).has_double_odds"><span style="padding:0 2px;">/</span>{{ CalcuOdds(item).max_odds }}</span>
                        </div>
                    </el-col>
                    <el-col v-if="!Quick" :span="Interval[2]">
                        <template v-if="mode === 'normal'">
                            <el-popover popper-class="quick-modul" placement="bottom" width="85" trigger="click" :disabled="!COptions.quick.value || !COptions.quick.value.length > 0 || !COptions.quick.enable" :value="item.quickView">
                                <div v-for="(val,i) in COptions.quick.value" class="quick-item" @click="Input(item,val)">{{ val }}</div>
                                <input slot="reference" v-model.number="item.amount" :class="{'mark': pickMark && item.pick}" :disabled="disabled" @keyup.enter="Checkout()">
                            </el-popover>
                        </template>
                        <template v-if="mode === 'box'">
                            <el-checkbox v-model="item.pick" :checked="item.pick" :disabled="disabled || item.disabled" @change="$emit('box',item)" />
                        </template>
                    </el-col>
                </el-row>
                <li v-else class="el-row fill" style="min-height:37px;" />
            </template>
            <el-row v-for="(item,index) in Replenish(list.child)" :key="'replenish' + item.i" tag="li"><div class="replenish"><span>{{ item.i }}</span></div></el-row>
        </template>

        <template v-else-if="type == 'row'">
            {{/*普通单行*/}}
            <p v-if="Title" class="title">{{ Title }}</p>
            <el-row tag="ul">
                <el-col v-for="(item,index) in list.child" :key="index" tag="li" :span="Span(list.child.length)" class="c-text-c u-bb" :class="{'pick': item.pick}" @click.native="Pick(item)">
                    <template v-if="!Quick">
                        <div class="row">
                            <b class="o-pr-s" :style="color && color[index] ? 'color:' + color[index] : ''">{{ item.name }}</b>
                            <span class="c-text-6 odds">
                                <span v-if="CalcuOdds(item)">{{ CalcuOdds(item).odds }}</span>
                                <span v-else>-</span>
                            </span>
                        </div>
                        <el-popover popper-class="quick-modul" placement="bottom" width="85" trigger="click" :disabled="!COptions.quick.value || !COptions.quick.value.length > 0 || !COptions.quick.enable" :value="item.quickView">
                            <div v-for="(val,i) in COptions.quick.value" class="quick-item" @click="Input(item,val)">{{ val }}</div>
                            <input slot="reference" v-model.number="item.amount" style="width:90%; margin-bottom:.6rem;" :disabled="disabled" @keyup.enter="Checkout()">
                        </el-popover>
                    </template>
                    <div v-else class="o-ptb-s">
                        <div class="row" :style="color && color[index] ? 'color:' + color[index] : ''">{{ item.name }}</div>
                        <div class="row odds">
                            <span v-if="CalcuOdds(item)">{{ CalcuOdds(item).odds }}</span>
                            <span v-else>-</span>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </template>

        <template v-else-if="type == 'queue'">
            {{/*混组单列*/}}
            <p v-if="Title" class="title">{{ Title }}</p>
            <el-row tag="div">
                <el-col v-for="(pack,unit) in MakeQueue(list,length)" :key="unit" :span="Span(length)">
                    <GameCreditGroup
                        :list="pack" :title="false" :prompt="prompt" :prompt-interval="promptInterval" :dice="dice"
                        :min="minQueue" :six_color="six_color" :mode="mode" :pick-mark="pickMark"
                        :pick-fast="pickFast" :disabled="disabled" @box="$emit('box',$event)"
                    />
                </el-col>
            </el-row>
        </template>

        <template v-else-if="type == 'queuetab'">
            {{/*选项混组单列 */}}
            <el-row tag="div">
                <el-col v-for="(pack,unit) in list" :key="unit" :span="Span(list.length)">
                    <p v-if="pack && pack.title" class="title" :class="{'active': active == unit}" @click="active = unit">{{ pack.title }}</p>
                </el-col>
            </el-row>
            <el-row tag="div">
                <el-col v-for="(pack,unit) in MakeQueue(TabsActive,length)" :key="unit" :span="5">
                    <GameCreditGroup :list="pack" :title="false" :prompt="prompt" :prompt-interval="promptInterval" :dice="dice" :pick-mark="pickMark" :pick-fast="pickFast" />
                </el-col>
            </el-row>
        </template>
    </ul>
</template>

<script>
import Credit from '../../../../../mixin/credit.js'
import SixMix from '@lottery.admin.2.0/mixin/six.js'
import Prompt from './prompt'
export default {
    name: 'GameCreditGroup',
    mixins: [Credit, SixMix],
    data() {
        return {
            active: 0
        }
    },
    props: {
        list: {},
        title: {
            default: true
        },
        prompt: {
            type: Array,
            default() {
                return ['号码', '赔率', '金额']
            }
        },
        quick: {
            default: true
        },
        promptInterval: {
            type: Array,
            default() {
                return [7, 6, 11]
            }
        },
        type: {
            default: 'normal' // row
        },
        color: {
            default: null
        },
        length: {
            default: 5
        },
        dice: {
            default: false //  是否是骰子
        },
        min: {

        },
        mode: {
            default: 'normal'
        },
        disabled: {
            default: false
        },
        six_color: {
            default: false
        },
        pickMark: {
            default: false
        },
        oddsanimation: {
            default: true
        },
        pickFast: {
            default: false
        },
        fill: {}
    },
    computed: {
        odds() {
            return this.$store.state.game.odds
        },
        Interval() {
            let interval = this.promptInterval
            // if(this.Quick && interval.length > 2){
            //     let last = interval.pop()
            //     last = last / interval.length
            //     let interval_new = []
            //     for(let item of interval){
            //         interval_new.push(Math.round(last))
            //     }
            //     if(interval[0] && Math.round(last) !== last){
            //         interval[0] += 1
            //     }
            //     return interval_new
            // }
            return interval
        },
        Title() {
            if (this.title && this.list && this.list.title) {
                if (typeof this.title === 'string') {
                    return this.title
                }
                return this.list.title
            }
            return ''
        },
        Quick() {
            if (this.COptions.opt && this.COptions.opt.onlyQuick) {
                return false
            }
            return this.COptions.type == 2
        },
        TabsActive() {
            let i = this.active
            return this.list[i]
        },
        minQueue(len) {
            if (this.min && this.length) {
                return this.min / this.length
            }
            return false
        }
    },
    directives: {
        odds: {
            inserted(el, e, vnode, oldvnode) {
                el.setAttribute('oddsanimation', 0)
            },
            update(el, binding, vnode, oldvnode) {
                let {value, oldValue} = binding
                if (oldValue && value && value.odds) {
                    if (value.odds != oldValue.odds || value.has_double_odds && value.max_odds != oldValue.max_odds) {
                        let count = Number(el.getAttribute('oddsanimation'))
                        el.setAttribute('oddsanimation', count + 1)
                        if (el.className.split(' ').indexOf('change') < 0) {
                            el.className = el.className + ' change'
                        }
                        setTimeout(() => {
                            if (el) {
                                let count = Number(el.getAttribute('oddsanimation'))
                                el.setAttribute('oddsanimation', count - 1)
                                if (count <= 1) {
                                    let classNames = el.className.split(' ')
                                    let arr = []
                                    for (let item of classNames) {
                                        if (item != 'change') {
                                            arr.push(item)
                                        }
                                    }
                                    el.className = arr.join(' ')
                                }
                            }
                        }, 20000)
                    }
                }
            }
        }
    },
    methods: {
        Pick(item) {
            if (item.id) {
                if (item && this.Quick) {
                    this.$set(item, 'pick', !item.pick)
                }
            }
        },
        PickFast(item) {
            if (this.pickFast) {
                this.$set(item, 'pick', !item.pick)
            }
        },
        IsInt(str) {
            if (!str) {
                return false
            }
            return /^[0-9]*$/.test(str)
        },
        Input(item, val) {
            this.$set(item, 'amount', val)
            this.$set(item, 'quickView', false)
        },
        Span(len) {
            return this.Big(24).div(len).round(0, 1).toNumber()
        },
        Replenish(list, type, len=this.min) {
            let arr = []
            if (list && len && list.length < len) {
                for (let i=0;i<len-list.length;i++) {
                    arr.push({i})
                }
            }
            return arr
        }
    },
    components: {
        Prompt
    }
}
</script>
