<style lang="scss">
    @import "../../../../style/config.scss";
    .CreditSystemBetcancel {

    }
</style>
<template>
    <div class="CreditSystemBetcancel">
        <div class="l-flex o-plr o-mb">
            <div class="l-flex-1">
                <el-input v-model="Filter.sequence" placeholder="请输入关键字" size="small" style="width:320px;" @keyup.enter.native="MakeFilter2()">
                    <el-select slot="prepend" v-model="Filter.type" placeholder="请选择" style="width:120px;">
                        <el-option label="注单号" :value="0" />
                        <el-option label="会员帐号" :value="1" />
                    </el-select>
                </el-input>
                <span class="o-pl-s" />
                <Button @click="MakeFilter2()">搜索注单</Button>
            </div>
        </div>
        <div class="o-p">
            <span class="o-pr-s">全选:</span>
            <el-checkbox v-model="all" @change="PickAll($event)" />
            <span class="o-plr" />
            <Button @click="Cancel()">批量删除</Button>
        </div>
        <div class="betcancel-table">
            <table v-loading="main._loading || main._editing" class="table" border="0" cellspacing="1" cellpadding="0">
                <thead>
                    <tr style="text-align:center;">
                        <td>#</td>
                        <td>注单号</td>
                        <td>用户名</td>
                        <td>期号</td>
                        <td>下注时间</td>
                        <td>盘类 / 玩法</td>
                        <td>投注内容</td>
                        <td>赔率</td>
                        <td>金额</td>
                        <td>状态</td>
                        <td>操作</td>
                    </tr>
                </thead>
                <tbody style="text-align:center;">
                    <tr v-for="(item,index) in main._list">
                        <td>
                            <el-checkbox :key="item.id" v-model="item.pick" />
                        </td>
                        <td>{{ item.sequence }}</td>
                        <td>{{ item.username }}</td>
                        <td>{{ item.lottery_number }}</td>
                        <td>{{ item.create_at }}</td>
                        <td>{{ item.side }} / {{ item.plays }}</td>
                        <td>{{ CheckContextLength(item.code,100) }}</td>
                        <td>{{ item.odds }}</td>
                        <td>{{ item.amount }}</td>
                        <td><b :class="{'c-color-e': item.status == '删除'}">{{ item.status }}</b></td>
                        <td>
                            <el-button v-if="item.status != '删除'" type="danger" size="mini" @click="Cancel(item)">删除</el-button>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr v-if="main._empty">
                        <td class="empty" colspan="100">暂无数据</td>
                    </tr>
                </tfoot>
            </table>
            <Pagination v-if="main._total" v-model="Page" class="o-mtb" :total="main._total" :count="main._count" :page-size="Filter.per_page" @turning="Get2" />
        </div>
    </div>
</template>

<script>
import {Loading} from 'element-ui';
import StoreMix from '@lottery.2.0/mixin/store.js'
export default {
    name: 'CreditSystemBetcancel',
    mixins: [StoreMix],
    data() {
        return {
            store: 'admin/orderinfo',
            Filter: {
                sequence: '',
                type: 1,
                per_page: 20
            },
            list: null,
            all: false,
            Loadinger: false,
            Editinger: false
        }
    },
    methods: {
        init() {

        },
        Get2(page) {
            this.$set(this, 'all', false)
            this.Get(page).then(res)
        },
        PickAll(e) {
            for (let item of this.main._list) {
                this.$set(item, 'pick', e)
            }
        },
        MakeFilter2() {
            this.$set(this, 'all', false)
            this.MakeFilter()
        },
        Cancel(item) {
            let ids = []
            if (item) {
                ids = `${item.id}`
            } else {
                for (let item of this.main._list) {
                    if (item.pick) {
                        ids.push(item.id)
                    }
                }
                if (!ids.length) {
                    return this.Err('请先勾选需要删除的注单')
                }
                ids = ids.join(',')
            }
            this.Editinger = Loading.service({target: '.betcancel-table'})
            this.Dp('admin/DELETE_ORDER', {status: 9, ids}).then(res => {
                this.Editinger.close()
                this.Suc('注单已被删除')
                this.MakeFilter2()
            })
        }
    },
    components: {

    },
    mounted() {
        this.init()
    },
    deactivated() {
        if (this.Loadinger) {
            try {
                this.Loadinger.close()
            } catch (err) {

            }
        }
        if (this.Editinger) {
            try {
                this.Editinger.close()
            } catch (err) {

            }
        }
    }
}
</script>
