<style lang="scss">
    @import "../../../style/config.scss";
    @import "./formal/play/default.scss";
    .GameFormal {
        .depot {
            padding:6px 16px; user-select:none; min-height:70px; position:relative;
            ul{
                height:20px; line-height:20px;
                .title {
                     display:inline-block; width:100px;
                }
                .item {
                    display:inline-block; margin:0 2px; padding:3px 14px; border-radius:99px; user-select:none;
                    &.active {
                        color:$color-t; border-color:$color-t;
                    }
                }
            }
            .side {
                width:15rem; height:3rem; position:absolute; right:.5rem; top:.6rem; text-align:right;
            }
        }
        .pick {
            .ball {
                $ball:42px;
                width:$ball; height:$ball; line-height:$ball;
                border:1px solid #eee; border-radius:999px;
                display:inline-block; font-size:18px; text-align:center;
            }
        }
        .selection {
            min-height:260px;
        }
        // 冷热遗漏
        i.rank-number {
            width:.85rem; height:.85rem; line-height:.85rem; font-style:normal; font-size:.65rem; border:2px solid #eee; background:#fff; border-radius:99px;
            position:absolute; right:-.4rem; top:-.4rem; z-index:2; text-align:center;
            &.active {
                color:#f00; font-weight:bold;
            }
        }

        .header-tools {
            $height:2.4rem;
            height:$height; line-height:$height; overflow:hidden;
            .rebate {
                height:$height; line-height:$height; float:left; width:400px;
            }
            .btn {
                float:right;
            }
        }
        .credit-empty {
            height:4.2rem; line-height:4.2rem; margin:1.5rem 1rem; text-align:center; font-size:.9rem; border-radius:6px;
            background-color:#ff5151; color:#fff;
        }
    }
</style>
<template>
    <div class="GameFormal">
        <div v-if="Running.expect.now.id === null || Running.expect.now.status == 1">
            <div v-if="Opt.isCredit" class="credit-empty">
                <b>目前没有可以下注期数！</b>
            </div>
        </div>
        <template v-else>
            <div class="header-tools o-plr-l">
                <p style="float:left;">
                    <span>当前时间：{{ Time(Running.expect.time,'yyyy-MM-dd hh:mm:ss') }}，</span>
                    <span v-if="Running.expect.now.status == 0">{{ CountDown.prompt }}: {{ CountDown.format[0] }} 时 {{ CountDown.format[1] }} 分 {{ CountDown.format[2] }} 秒</span>
                    <span v-if="Running.expect.now.status == 1">本期已封盘</span>
                </p>
                <el-radio-group v-model="side" class="o-mr-s side" size="mini" @change="NavTable($event,Running.nav.item)">
                    <el-radio-button v-for="(item,index) in hasSide" :key="item.id" :label="item.id">{{ item.title }}</el-radio-button>
                </el-radio-group>
            </div>
            <div v-if="Running.template" class="depot">
                <ul v-for="(pack,unit) in List" class="o-mtb">
                    <span class="title">{{ pack.title }}：</span>
                    <li v-for="(item,index) in pack.child" class="item t-btn" :class="{'active': item.id === Running.depot_active_id}" @click="depotPick(item)">{{ item.title }}</li>
                </ul>
            </div>
            <div v-loading="!template" class="selection o-ptb">
                <component :is="template.tpl" v-if="template" v-show="!Running.nav.loading && !Running.play.loading" ref="play" :rank="rank" :template="template" @change="SaveParams" />
            </div>
            <chipin ref="chipin" :template="template" :rank="rank" />
            <history ref="history" />
        </template>
    </div>
</template>

<script>
import num1 from './formal/play/num1.vue'
import num2 from './formal/play/num2.vue' // 大小单双选号
import num3 from './formal/play/num3.vue'
import num3_1 from './formal/play/num3_1.vue'
import num6 from './formal/play/num6.vue'
import num7 from './formal/play/num7.vue'
import num8 from './formal/play/num8.vue'
import danshi1 from './formal/play/danshi1.vue'
import danshi2 from './formal/play/danshi2.vue'
import hezhi1 from './formal/play/hezhi1.vue'
import hezhi2 from './formal/play/hezhi2.vue'
import renxuan1 from './formal/play/renxuan1.vue'

import Chipin from './formal/chipin.vue'
import History from './formal/history.vue'

import Common from './formal.js'
export default {
    mixins: [Common],
    components: {
        num1, num2, num3, num3_1, num6, num7, num8, danshi1, danshi2, hezhi1, hezhi2, renxuan1,
        Chipin,
        History
    }
}
</script>
