<style lang="scss">
    @import "../../../../style/config.scss";
    .GameHistoryAppend {
        $bg:#573f36; $bg-2:#2d1a0c;
        .el-dialog {
            width:600px; max-width:600px;
        }
        .context {
            background-color:#f5f5f5; border-radius:.3rem; word-wrap:break-word; line-height:1.4em;
        }
        .options {
            * {
                vertical-align:middle;
            }
            a.header-btn {
                width:26px; height:26px; line-height:26px; display:inline-block; text-align:center;
                color:$color-t; border:1px solid #aaa;
                font-size:20px; font-weight:bold;
            }
            .t-btn, input {
                width:32px; height:26px; display:inline-block; line-height:26px; padding:0 5px; border:1px solid #aaa; outline:none;
                vertical-align:middle;
            }
            .info {
                b {color:#fcbd6b;}
            }
            .options-btn {
                width:100px; height:33px;
                img {width:100%; height:100%; display:inline-block; vertical-align:middle; position:relative; top:-2px;}
            }
        }
    }
</style>
<template>
    <el-dialog class="GameHistoryAppend" title="再下一注" :visible.sync="item.view">
        <div class="l-flex">
            <p class="l-flex-1">游戏：{{ Running.title }}</p>
            <p class="l-flex-1">第 {{ Running.expect.now.expect }} 期</p>
            <p class="l-flex-1">玩法：{{ item.order.play_name }}</p>
        </div>
        <div class="context o-mtb">
            <div class="o-p">
                {{ item.order.code }}
            </div>
        </div>
        <div v-if="item.order.play_type == 1" class="options l-flex">
            <div class="multiple">
                <a class="header-btn" style="margin-right:-1px;" @click="multipleReduce">-</a><input v-model="multiple" class="c-text-c"><a class="header-btn" style="margin-left:-1px;" @click="multipleAdd">+</a>
                <span class="o-ml-s">倍，</span>
            </div>
            <div class="mode">
                <span class="o-mr-s">模式</span>
                <el-dropdown class="dropdown" trigger="click" @command="pickMode">
                    <span>
                        <span class="title t-btn" style="margin-right:-1px;">{{ modeTitle }}</span><a class="header-btn"><i class="el-icon-arrow-down" /></a>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item :command="4">元</el-dropdown-item>
                        <el-dropdown-item :command="3">角</el-dropdown-item>
                        <el-dropdown-item :command="2">分</el-dropdown-item>
                        <el-dropdown-item :command="1">厘</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </div>
        </div>
        <div class="c-text-c o-mt-l">
            <template v-if="item.order.play_type == 1">
                <span>操作状态:</span>
                <span><b class="c-color-t">{{ calcuTotal.chip }} </b>注<b class="c-color-t"> 1 </b>单</span>
                <span class="o-ml">投注金额：<b class="c-color-t">{{ calcuTotal.total }} </b>元</span>
            </template>
            <div class="o-pt o-mt">
                <template v-if="item.order.play_type == 2">
                    <span>下注金额：</span>
                    <el-input v-if="item.order.play_type == 2" v-model.number="amount" class="o-mr" style="width:80px;" />
                </template>
                <el-button type="primary" @click="appendOrder()">确认下注</el-button>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'GameHistoryAppend',
    data() {
        return {
            multiple: 1,      // 下注倍数
            mode: 4,          // 模式：元 角 分 厘
            amount: 2
        }
    },
    props: ['item'],
    computed: {
        modeTitle() {
            let mode = this.mode
            return [null, '厘', '分', '角', '元'][mode]
        },
        calcuTotal() {
            let chip = this.item.order.bet_num
            let total = 0
            let price = this.Running.remote_data && this.Running.remote_data.default_price ? this.Running.remote_data.default_price : 2 // 每注单价
            let mode = [0.001, 0.01, 0.1, 1][this.mode-1] // 应用模式
            let multiple = this.multiple ? this.multiple : 1 // 应用倍率
            if (chip && mode && multiple && price) {
                total = this.Big(chip).mul(mode).mul(multiple).mul(price).round(4, 3).toNumber()
            }
            return {chip, total}
        }
    },
    watch: {
        'item.view'(val) {
            if (val) {
                this.$set(this, 'multiple', this.item.order.multiple)
                this.$set(this, 'mode', this.item.order.mode)
                if (this.item.order.play_type == 2) {
                    this.$set(this, 'amount', this.item.order.amount)
                }
            }
        }
    },
    methods: {
        init() {

        },
        Reload() {

        },
        pickMode(e) {
            this.mode = e
        },
        multipleAdd() {
            if (this.Is('int', this.multiple) && this.multiple < 10000000) {
                this.multiple = this.multiple + 1
            }
        },
        multipleReduce() {
            if (this.Is('int', this.multiple) && this.multiple > 1) {
                this.multiple = this.multiple - 1
            }
        },
        appendOrder() {
            this.item.view = false
            let order = {
                games_id: this.Running.id,
                games_play_group_id: this.item.order.games_play_group_id,
                games_play_id: this.item.order.games_play_id,
                lottery_id: this.Running.expect.now.id,
                code: this.item.order.code,
                odds: this.item.order.odds,
                multiple: this.multiple,
                mode: this.mode
            }
            if (this.item.order.play_type == 2) {
                order.money = this.amount
            }
            return this.$store.dispatch('game/POST_ORDER', {
                data: JSON.stringify([order]
                )}).then(res => {
                if (res) {
                    this.Suc('操作成功')
                    this.$emit('done')
                }
                return res
            })
        }
    },
    mounted() {
        this.init()
    }
}
</script>
