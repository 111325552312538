<style lang="scss">
    @import "../../../../../style/config.scss";
    .OddsGroupTql4 {

    }
</style>
<template>
    <div class="OddsGroupTql4">
        <div v-if="nav.active.name === '特码' || nav.active.name === '正码一' || nav.active.name === '正码二' || nav.active.name === '正码三' || nav.active.name === '正码四'">
            <group class="u-bb u-br" size="big" :list="Table[1]" :length="4" :title="nav.active.name" :color="false" />
            <div class="l-flex o-mt-s">
                <group class="u-bb u-br" size="big" :list="Table[2]" :length="3" title="上下" :color="false" />
                <group class="u-bb u-br" size="big" :list="Table[3]" :length="3" title="奇偶" :color="false" />
            </div>
            <div class="l-flex o-mt-s">
                <group class="u-bb u-br" size="big" :list="Table[4]" :length="1" title="大小" :color="false" />
                <group class="u-bb u-br" size="big" :list="Table[5]" :length="1" title="单双" :color="false" />
                <group class="u-bb u-br" size="big" :list="Table[6]" :length="1" title="总大小" :color="false" />
                <group class="u-bb u-br" size="big" :list="Table[7]" :length="1" title="总单双" :color="false" />
                <group class="u-bb u-br" size="big" :list="Table[8]" :length="1" title="总尾大小" :color="false" />
            </div>
        </div>
        <div v-else-if="nav.active.name === '龙虎斗'">
            <div class="l-flex o-mt">
                <group class="u-bb u-br" :list="Table[1]" :length="2" title="正一VS正二" />
                <group class="u-bb u-br" :list="Table[2]" :length="2" title="正一VS正三" />
                <group class="u-bb u-br" :list="Table[3]" :length="2" title="正一VS正四" />
                <group class="u-bb u-br" :list="Table[4]" :length="2" title="正一VS特码" />
            </div>
            <div class="l-flex o-mt">
                <group class="u-bb u-br" :list="Table[5]" :length="2" title="正二VS正三" />
                <group class="u-bb u-br" :list="Table[6]" :length="2" title="正二VS正四" />
                <group class="u-bb u-br" :list="Table[7]" :length="2" title="正二VS特码" />
            </div>
            <div class="l-flex o-mt">
                <group class="u-bb u-br" :list="Table[8]" :length="2" title="正三VS正四" />
                <group class="u-bb u-br" :list="Table[9]" :length="2" title="正三VS特码" />
            </div>
            <div class="l-flex o-mt">
                <group class="u-bb u-br" :list="Table[10]" :length="2" title="正四VS特码" />
            </div>
        </div>
        <div v-else-if="nav.active.name === '两面盘'">
            <div class="l-flex o-mb">
                <group class="u-bb u-br" size="big" :list="Table[1]" :length="1" title="特码" />
                <group class="u-bb u-br" size="big" :list="Table[2]" :length="1" title="正码一" />
                <group class="u-bb u-br" size="big" :list="Table[3]" :length="1" title="正码二" />
                <group class="u-bb u-br" size="big" :list="Table[4]" :length="1" title="正码三" />
                <group class="u-bb u-br" size="big" :list="Table[5]" :length="1" title="正码四" />
            </div>
            <group class="u-bb u-br" size="big" :list="Table[6]" :length="6" title="总和" />
        </div>
        <div v-else>
            <group class="u-bb u-br" size="big" :list="TableAll" :length="5" :title="nav.active.name" :color="false" />
        </div>
    </div>
</template>

<script>
import Group from './odds-group'
import Fast from './odds-fast'
export default {
    name: 'OddsGroupTql4',
    mixins: [],
    data() {
        return {

        }
    },
    props: {
        nav: {},
        Table: {},
        tabs: {}
    },
    computed: {
        TableAll() {
            let list = []
            for (let unit in this.Table) {
                for (let item of this.Table[unit]) {
                    list.push(item)
                }
            }
            return list
        }
    },
    methods: {

    },
    components: {
        Group, Fast
    }
}
</script>
