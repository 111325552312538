<style lang="scss">
.OtherRegistered {
    margin: 0 auto;
    width: 28vw;
    background: #fff;
    text-align: center;
    padding: 1rem;
    margin-top: 4rem;
    border-radius: 1rem;
    .sys {
        margin: 1rem 0;
    }
    .footer {
        // margin: 0 auto;
        text-align: center;
    }
}
</style>
<template>
    <div class="OtherRegistered">
        <img :src="Img('index/logo.png')" style="padding: 2rem;">
        <el-form title="注册" width="30%" center>
            <el-form :model="form">
                <el-form-item label="账号" :label-width="formLabelWidth">
                    <el-input v-model="form.username" autocomplete="off" />
                </el-form-item>
                <el-form-item label="密码" :label-width="formLabelWidth">
                    <el-input v-model="form.pass" autocomplete="off" type="password" />
                </el-form-item>
                <el-form-item label="确认密码" :label-width="formLabelWidth">
                    <el-input v-model="form.pass1" autocomplete="off" type="password" />
                </el-form-item>
                <el-form-item label="提款密码" :label-width="formLabelWidth">
                    <el-input v-model="form.pay_pass" autocomplete="off" type="password" />
                </el-form-item>
                <el-form-item label="QQ" :label-width="formLabelWidth">
                    <el-input v-model="form.qq" autocomplete="off" />
                </el-form-item>
                <el-form-item label="微信" :label-width="formLabelWidth">
                    <el-input v-model="form.wx" autocomplete="off" />
                </el-form-item>
                <el-form-item label="姓名" :label-width="formLabelWidth">
                    <el-input v-model="form.real_name" autocomplete="off" />
                </el-form-item>
                <el-form-item label="手机号" :label-width="formLabelWidth">
                    <el-input v-model="form.phone" autocomplete="off" type="number" />
                </el-form-item>
            </el-form>
            <div class="footer">
                <el-button type="primary" @click="sub">注 册</el-button>
            </div>
        </el-form>
    </div>
</template>
<script>
import map from '~/dgx/map.js'
const store = 'base';
const STORE = store.toUpperCase();
const model = 'sign';
const MODEL = model.toUpperCase();
import signMixin from '../../mixin/sign'
export default {
    name: 'OtherRegistered',
    data() {
        return {
            centerDialogVisible: true,
            formLabelWidth: '120px',
            form: {}
        }
    },
    computed: {

    },
    methods: {
        init() {
            if (this.$route.query != null) {
                this.form.i = this.$route.query.i;
            } else {
                // this.Toast('无效链接');
            }
            this.Get();
        },
        Get() {
            let data = {
                i: this.$route.query.i
            };
            this.BASE_ViewsLinks(data)
        },
        sub() {
            this.BASE_SIGNUP(this.form).then(res => {
                this.Toast('注册成功！即将跳转到登录页...');
                // this.Go('/login');
                setTimeout(() => {
                    this.$router.push('/login');
                }, 2000);
            })
        },
        ...map(store, model, [
            'BASE_SIGNUP',
            'BASE_ViewsLinks'
        ])
    },
    mounted() {
        this.init()
    }
}
</script>

