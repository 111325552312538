
export default {

    name: '其他',
    list: [
        {
            path: '/login',
            name: 'login',
            meta: {
                name: '新资讯',
                public: true,
                nodirectory: true
            },
            component: require('@lottery.2.0/page/other/login.vue').default
        },
        {
            path: '/registered',
            name: 'registered',
            meta: {
                name: '新资讯',
                public: true,
                nodirectory: true
            },
            component: require('@lottery.2.0/page/other/registered').default
        },
        {
            path: '/maintain',
            name: 'maintain',
            meta: {
                name: '新资讯',
                public: true,
                nodirectory: true
            },
            component: require('@lottery.2.0/page/other/maintain').default
        },
        {
            path: '*',
            name: '404',
            meta: {
                name: '404',
                public: true,
                nodirectory: true
            },
            component: require('@lottery.2.0/page/other/404.vue').default
        }
    ]
}
