import {mapState, mapGetters, mapActions, mapMutations} from 'vuex'

export default function(store, model, action=[], mutation=[]) {
    let STORE = store.toUpperCase();
    let MODEL = model.toUpperCase();
    let actions = {
        'GET_MAIN': `GET_${MODEL}`,
        'POST_MAIN': `POST_${MODEL}`,
        'PUT_MAIN': `PUT_${MODEL}`,
        'DELETE_MAIN': `DELETE_${MODEL}`
    }
    let mutations = {
        'STORE_ADD': `${STORE}_ADD`,
        'STORE_UPDATE': `${STORE}_UPDATE`,
        'STORE_REMOVE': `${STORE}_REMOVE`,
        'STORE_CLEAN': `${STORE}_CLEAN`,
        'STORE_RESET': `${STORE}_RESET`

    }
    return {
        ...mapActions(store, actions),
        ...mapActions(store, action),
        ...mapMutations(store, mutations),
        ...mapMutations(store, mutation)
    }
}