<style lang="scss">
    @import "../../../../../style/config.scss";
    .OddsGroupTql5 {

    }
</style>
<template>
    <div class="OddsGroupTql5">
        <div v-if="nav.active.name === '大小骰宝'">
            <group class="u-bb u-br" :list="Table[1]" :length="6" :color="false" :vertical="false" :dice="true" title="三军" />
            <group class="u-bb u-br" :list="Table[2]" :length="5" :color="false" title="三军大小单双" />
            <group class="u-bb u-br" :list="Table[3]" :length="6" :color="false" :vertical="false" :dice="true" title="全骰、围骰" />
            <group class="u-bb u-br" :list="Table[4]" :length="6" :color="false" :vertical="false" :dice="true" title="短牌" />
            <group class="u-bb u-br" :list="Table[5]" :length="5" :color="false" :vertical="false" title="点数" />
            <group class="u-bb u-br" :list="Table[6]" :length="5" :color="false" :vertical="false" :dice="true" title="长牌" />
        </div>
        <div v-else>
            <group class="u-bb u-br" size="big" :list="TableAll" :length="5" :title="nav.active.name" :color="false" />
        </div>
    </div>
</template>

<script>
import Group from './odds-group'
import Fast from './odds-fast'
export default {
    name: 'OddsGroupTql5',
    mixins: [],
    data() {
        return {

        }
    },
    props: {
        nav: {},
        Table: {},
        tabs: {}
    },
    computed: {
        TableAll() {
            let list = []
            for (let unit in this.Table) {
                for (let item of this.Table[unit]) {
                    list.push(item)
                }
            }
            return list
        }
    },
    methods: {

    },
    components: {
        Group, Fast
    }
}
</script>
