
<style lang="scss">
    @import "../../../../style/config.scss";
    .GameCreditXGLHC {
        position:relative; min-height:400px;
        .ctrl {
            $h:48px;
            width:650px; height:$h; line-height:$h; padding-left:24px;
            position:absolute; left:210px; top:-$h;
        }
        .contact {
            .group {
                border:1px solid #f0f0f0; border-radius:7px; cursor:pointer;
                i.icon {color:#ddd; position:relative; top:-2px; margin-right:2px;}
                &:hover {
                    border-color:$color-t;
                }
                &.active {
                    border-color:$color-t;
                    i.icon {color:$color-t;}
                }
            }
        }
        .GameCreditGroup{
            li {
                .goods {
                    &.ball {
                        img,span {
                            font-size:.75rem; font-weight:bold;
                            // width:$height*0.74; height:$height*0.74; line-height:$height*0.74; position:absolute; border-radius:99px;
                            // top:$height*0.13; right:$height*0.13; bottom:$height*0.13; left:$height*0.13;
                            // background-color:#999; color:#fff;
                        }
                    }
                    &.text {
                        font-size:.75rem;
                    }
                }
            }
        }
        .longcode {
            position:absolute; right:-35px; top:60px; width:25px; text-align:center;
            .btn {
                background-color:#dc9541; color:#fff; border-radius:0 10px 10px 0; cursor:pointer;
                border:1px solid #b97c32; border-left:none;
            }
        }
        .fast-input {
            position:absolute; right:-35px; top:170px; width:25px; text-align:center;
            .btn {
                background-color:#9041dc; color:#fff; border-radius:0 10px 10px 0; cursor:pointer;
                border:1px solid #60209e; border-left:none;
            }
        }
    }
    .longcode-popover {
        .longcoderow {
            background-color:#fff2dd;
            &:nth-child(odd){
                background-color:#f1e6e6;
            }
        }
    }
    .fast-input-popover {
        .longcoderow {
            background-color:#fff2dd;
            &:nth-child(odd){
                background-color:#f1e6e6;
            }
        }
    }
</style>
<template>
    <div class="GameCreditXGLHC">
        <div class="longcode" style="z-index:100;">
            <el-popover popper-class="longcode-popover" placement="left-start" width="200" trigger="hover">
                <div style="min-height:100px;">
                    <div v-if="$store.state.game.tips._loading" class="c-text-c">
                        <Load />
                    </div>
                    <ul v-if="Tips">
                        <li v-for="(item,index) in Tips" class="longcoderow l-flex-c c-line-17 o-plr">
                            <div class="l-flex-1">{{ item.title }}</div>
                            <p class="c-text-75">{{ item.count }} 期</p>
                        </li>
                        <li v-if="Tips.length == 0" class="o-ptb-l c-text-c">
                            <Icon name="newshot" size="1.6" style="color:#ddd;" />
                            <p class="o-mt-s">暂无长龙</p>
                        </li>
                    </ul>
                </div>
                <div slot="reference" class="btn o-ptb">
                    <p>长</p>
                    <p>龙</p>
                    <p>提</p>
                    <p>示</p>
                </div>
            </el-popover>
        </div>


        <div v-if="Running.nav && Running.nav.item" class="ctrl">
            <el-radio-group v-if="direction[Running.nav.item.title]" v-model="direction.active" class="o-ml-l" :disabled="plays._loading" size="mini" @change="$emit('reset')">
                <el-radio-button label="a">{{ direction[Running.nav.item.title] }}A</el-radio-button>
                <el-radio-button label="b">{{ direction[Running.nav.item.title] }}B</el-radio-button>
            </el-radio-group>
        </div>
        <div v-if="List && List.length">


            <Category key="特码" title="特码">
                <group
                    :list="SpecialGroup[0]" :prompt="prompt" :prompt-interval="promptInterval" :title="false" :length="5"
                    type="queue" :min="12" :six_color="true" :pick-mark="true"
                    :pick-fast="true"
                />
                <fast-six
                    v-for="index in DirectionIndex" :key="'FastSix' + index" class="o-pt" :direction="direction.active" :list="List[index]"
                    type="特码" :textarea="false" :outbtn="true" @Reset="$emit('reset')"
                    @Checkout="$emit('checkout')"
                />
                <fast-input-six v-for="index in DirectionIndex" :key="'FastInputSix' + index" :direction="direction.active" :list="List[index]" type="特码" @Reset="$emit('reset')" @Checkout="$emit('checkout')" />
                <div class="o-pt" />
                <group :list="SpecialGroup[1]" :prompt="prompt" :prompt-interval="promptInterval" :title="false" :length="5" type="queue" :min="12" :six_color="true" :pick-mark="true" />
            </Category>


            <Category key="生肖色波" title="生肖色波">
                <group-six-full :list="List[0]" :prompt="promptFull" />
            </Category>


            <Category key="半波" title="半波">
                <group-six-full :list="List[0]" :prompt="promptFull" />
            </Category>


            <Category key="合肖" title="合肖">
                <group :list="List[0]" :title="false" :prompt="['类别','赔率','勾选']" :prompt-interval="[10,8,6]" :length="6" mode="box" type="queue" @box="ToMergeChange" />
                <group-six-full
                    v-if="ToMerge.dir" :list="ToMerge.dir" :prompt="['生肖','','选择','号码']" :prompt-interval="[4,0,4,16]" :length="3"
                    type="queue" mode="box" :fortynine="false" :has-odds="false"
                    @box="ToMergeChange()"
                />
                <div class="o-pl-h u-bb" style="height:36px; line-height:36px;">
                    <b class="c-color-t">{{ ToMerge.explain }}</b>
                </div>
            </Category>

            <Category key="正码" title="正码">
                <group
                    :list="List[0]" :prompt="prompt" :prompt-interval="promptInterval" :title="false" :length="5"
                    type="queue" :min="12" :six_color="true" :pick-mark="true"
                    :pick-fast="true"
                />
                <fast-six class="o-pt" :list="List[0]" type="正码" :textarea="false" />
                <fast-input-six :list="List[0]" type="正码" />
            </Category>


            <Category key="正码1-6" title="正码1-6">
                <el-row tag="div">
                    <el-col v-for="unit in [0,1,2,3,4,5]" :key="unit" :span="4">
                        <group :key="unit" :list="List[unit]" :prompt="prompt" :prompt-interval="promptInterval" :six_color="true" />
                    </el-col>
                </el-row>
            </Category>


            <Category key="连码" title="连码">
                <div class="o-ptb-l c-text-c">
                    <div class="o-pb">
                        <el-radio v-for="(item,index) in List" :key="index" v-model="ToCode.active" :label="index" size="medium" border @change="ToCodeGroup(item)">{{ item.title }}</el-radio>
                    </div>
                    <div v-for="(item,index) in List" v-if="ToCode.active === index" class="o-pt">
                        <el-radio v-for="(sub,i) in item.child" :key="i" v-model="ToCode.mode" class="o-plr" :label="sub.title" @change="$emit('reset')">{{ sub.title }}</el-radio>
                    </div>
                </div>
                <template v-if="ToCode.mode === '连碰' || ToCode.mode === '胆拖'">
                    <group
                        :key="ToCode.active" :list="ToCodeList" :title="false" :length="5" type="queue"
                        :min="12" :prompt="['号码','赔率','勾选']" :prompt-interval="[7,10,7]" mode="box"
                        :six_color="true" @box="ToCodeChange"
                    />
                    <p class="o-pl-h u-bb" style="height:36px; line-height:36px; position:relative; top:-1px;">
                        <b class="o-pl-h c-color-t">{{ ToCode.explain }}</b>
                    </p>
                </template>
                <template v-if="ToCode.mode === '生肖对碰' || ToCode.mode === '尾数对碰' || ToCode.mode === '混合对碰'">
                    <group-six-full :key="ToCode.active" :list="ToCodeList" :prompt="['生肖','赔率','选择','号码']" :prompt-interval="[3,4,3,14]" :length="3" type="queue" mode="box" @box="ToCodeChange" />
                    <p class="o-pl-h u-bb" style="height:36px; line-height:36px; position:relative; top:-1px;">
                        <b class="o-pl-h c-color-t">{{ ToCode.explain }}</b>
                    </p>
                </template>
            </Category>


            <Category key="自选不中" title="自选不中">
                <div class="o-ptb-l c-text-c">
                    <el-radio v-for="(item,index) in List" :key="index" v-model="ToMiss.active" :label="index" size="small" border @change="$emit('reset');OddTimerRun(true)">{{ item.title }}</el-radio>
                </div>
                <div v-for="(list,index) in List" v-if="ToMiss.active === index">
                    <el-row tag="div">
                        <el-col v-for="(pack,unit) in MakeQueue(list,5)" :key="unit" :span="Span(5)">
                            <group :list="pack" :title="false" :prompt="['号码','赔率','勾选']" :prompt-interval="[8,8,8]" mode="box" :disabled="false" :six_color="true" @box="ToMissChange" />
                        </el-col>
                    </el-row>
                </div>
            </Category>


            <Category key="一肖尾数" title="一肖尾数">
                <group-six-full :list="List[0]" :prompt="promptFull" :length="2" type="queue" />
                <group :list="List[1]" :title="false" :length="6" type="queue" :min="12" :prompt="prompt" :prompt-interval="promptInterval" />
            </Category>


            <Category key="连肖" title="连肖">
                <div class="o-ptb-l c-text-c">
                    <el-radio v-for="(item,index) in List" :key="index" v-model="ToContact.active" :label="index" size="small" border @change="ToContactGroup()">{{ item.title }}</el-radio>
                </div>
                <div class="c-text-c o-pb">
                    <el-radio-group v-model="ToContact.mode" @change="ToContactGroup()">
                        <el-radio v-for="(item,index) in ToContact.dir" :key="index" class="o-plr" :label="item">{{ item }}</el-radio>
                    </el-radio-group>
                </div>
                <div v-for="(pack,unit) in List" v-if="ToContact.active === unit" :key="unit">
                    <group-six-full :list="pack" :prompt="['生肖','赔率','选择','号码']" :prompt-interval="[2,3,3,16]" :length="2" type="queue" mode="box" @box="ToContactChange(pack,unit + 2)" />
                </div>
                <p class="o-pl-l u-bb" style="height:36px; line-height:36px;">
                    <b class="o-pl-h c-color-t">{{ ToContact.explain }}</b>
                </p>
            </Category>


            <Category key="连尾" title="连尾">
                <div class="o-ptb-l c-text-c">
                    <el-radio v-for="(item,index) in List" :key="index" v-model="ToContact.active" :label="index" size="small" border @change="ToContactGroup()">{{ item.title }}</el-radio>
                </div>
                <div class="c-text-c o-pb">
                    <el-radio-group v-model="ToContact.mode" @change="ToContactGroup()">
                        <el-radio v-for="(item,index) in ToContact.dir" :key="index" class="o-plr" :label="item">{{ item }}</el-radio>
                    </el-radio-group>
                </div>
                <div v-for="(pack,unit) in List" v-if="ToContact.active === unit" :key="unit" class="o-mt">
                    <group
                        v-for="(item,index) in pack.child" v-if="ToContact.mode == item.title" :key="index" :list="item" :title="false"
                        :prompt="['号码','赔率','勾选']" :prompt-interval="[8,8,8]" mode="box" :disabled="false"
                        type="queue" @box="ToContactChange(item,[2,3,4,2,3,4][unit])"
                    />
                </div>
                <p class="o-pl-l u-bb" style="height:36px; line-height:36px;">
                    <b class="o-pl-l c-color-t">{{ ToContact.explain }}</b>
                </p>
            </Category>


            <Category key="正码特" title="正码特">
                <div class="o-ptb-l c-text-c">
                    <el-radio v-for="(item,index) in ToSpecial.list" :key="item" v-model="ToSpecial.active" :label="item" size="small" border @change="$emit('reset');OddTimerRun(true)">{{ item }}</el-radio>
                </div>
                <template v-if="ToSpecialList">
                    <group :prompt="prompt" :prompt-interval="promptInterval" :title="false" :list="ToSpecialList" :length="5" type="queue" :six_color="true" :pick-mark="true" :pick-fast="true" />
                    <fast-six class="o-pt" :list="ToSpecialList" type="正特" :textarea="false" />
                    <fast-input-six class="o-pt" :list="ToSpecialList" type="正特" />
                </template>
            </Category>

        </div>
    </div>
</template>

<script>
import Group from './components/group'
import GroupSixFull from './components/group_six_full'
import Prompt from './components/prompt'
import FastSix from './components/group_fast'
import FastInputSix from './components/group_fast_input'
import Common from './xglhc.js'
export default {
    name: 'GameCreditXGLHC',
    mixins: [Common],
    components: {
        Group, GroupSixFull, Prompt, FastSix, FastInputSix
    },
    mounted() {
        this.$store.state.base.Event.removeAllListeners('NavTable/GOODS_RELOAD')
        this.$store.state.base.Event.addListener('NavTable/GOODS_RELOAD', (res) => {
            this.CheckOpt()
        })
        this.init()
    }
}
</script>
