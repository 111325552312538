<style lang="scss">
    .GameFormalNum2 {
        .row {
            height: 46px; line-height: 46px; padding: 5px 0;
        }
    }
</style>
<template>
    <div class="GameFormalPlay GameFormalNum2">
        <ul v-if="params" class="box">
            <li v-for="(row,unit) in template.sub[0]" class="row l-flex">
                <div class="title">
                    <p>{{ row }}</p>
                </div>
                <div class="balls">
                    <li v-for="(item,index) in MakeBallArray()" class="ball" :class="{'active': params[unit] && params[unit][index]}" @click="Pick(unit,index)">
                        <span>{{ item }}</span>
                    </li>
                </div>
                <div class="tools">
                    <li v-for="(item,index) in ['全','清']" class="ball" @click="Tools(unit,item)">
                        <span>{{ item }}</span>
                    </li>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import Common from './num2.js'
export default {
    mixins: [Common],
    components: {

    }
}
</script>
