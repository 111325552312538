<style lang="scss">
    .CreditLotteryHistory {
        position:relative;
        .ball {
            width:24px; height:24px; line-height:24px; text-align:center; border-radius:99px; display:inline-block;
            font-size:15px; font-weight:bold; background-color:#ccc;
            &.red {background-color:#f56c6c; color:#fff;}
            &.blue {background-color:#409eff; color:#fff;}
            &.green {background-color:#67c23a; color:#fff;}
        }
        .text {
            display:inline-block; padding:1px 3px;
            &.red {background-color:#f56c6c; color:#fff;}
            &.blue {background-color:#409eff; color:#fff;}
            &.green {background-color:#67c23a; color:#fff;}
        }
        .text[mark="1"] {color:#6c00ff;}
        .text[mark="2"] {color:#e422d4;}
        .text[mark="3"] {color:#ff9b00;}
        .dice {
            width:25px; height:25px;
        }
    }
</style>
<template>
    <div class="CreditLotteryHistory">


        <table v-if="Filter.games_type == 1 || Filter.games_type == 3" v-loading="main._loading" class="table u-bl u-br u-bb" border="0" cellspacing="1" cellpadding="0">
            <thead>
                <tr>
                    <td rowspan="2">期数</td>
                    <td rowspan="2">开奖时间</td>
                    <td colspan="5">彩球号码</td>
                    <td rowspan="2">总合</td>
                    <td rowspan="2">总单双</td>
                    <td rowspan="2">总大小</td>
                    <td v-for="item in ['第一球','第二球','第三球','第四球','第五球']" colspan="2">{{ item }}</td>
                    <td rowspan="2">前三</td>
                    <td rowspan="2">中三</td>
                    <td rowspan="2">后三</td>
                </tr>
                <tr>
                    <td v-for="item in ['第一球','第二球','第三球','第四球','第五球']">{{ item }}</td>
                    <template v-for="item in 5">
                        <td>大小</td>
                        <td>单双</td>
                    </template>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item,index) in main._list" :key="item.id" class="c-text-c">
                    <td>{{ item.number }}</td>
                    <td>{{ item.open_time }}</td>
                    <td v-for="(sub,i) in 5">
                        <a class="ball red">{{ FormatCode(item.data)[i] }}</a>
                    </td>
                    <td name="总合">{{ item.total_num }}</td>
                    <td name="总单双"><span v-markColor class="text">{{ item.total_num_single_double }}</span></td>
                    <td name="总大小"><span v-markColor class="text">{{ item.total_num_big_small }}</span></td>
                    <template v-for="(key,index) in ['one','two','three','four','five']">
                        <td><span v-markColor class="text">{{ item[key + '_big_small'] }}</span></td>
                        <td><span v-markColor class="text">{{ item[key + '_single_double'] }}</span></td>
                    </template>
                    <td>{{ item.top_three }}</td>
                    <td>{{ item.secondary_three }}</td>
                    <td>{{ item.str_after_three }}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr v-if="main._empty">
                    <td class="empty" colspan="100">暂无数据</td>
                </tr>
            </tfoot>
        </table>


        <table v-if="Filter.games_type == 2" v-loading="main._loading" class="table u-bl u-br u-bb" border="0" cellspacing="1" cellpadding="0">
            <thead>
                <tr>
                    <td rowspan="2">期数</td>
                    <td rowspan="2">开奖时间</td>
                    <td colspan="3">彩球号码</td>
                    <td rowspan="2">总和</td>
                    <td rowspan="2">总和单双</td>
                    <td rowspan="2">总和大小</td>
                    <td v-for="item in ['百位','十位','个位']" colspan="2">{{ item }}</td>
                    <td v-if="btn" rowspan="2">操作</td>
                </tr>
                <tr>
                    <td v-for="item in ['百位','十位','个位']">{{ item }}</td>
                    <template v-for="item in 3">
                        <td>大小</td>
                        <td>单双</td>
                    </template>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item,index) in main._list" :key="item.id" class="c-text-c">
                    <td>{{ item.number }}</td>
                    <td>{{ item.open_time }}</td>
                    <td v-for="(sub,i) in 3">
                        <a class="ball red">{{ FormatCode(item.data)[i] }}</a>
                    </td>
                    <td><span v-markColor class="text">{{ item.total_num }}</span></td>
                    <td><span v-markColor class="text">{{ item.total_num_single_double }}</span></td>
                    <td><span v-markColor class="text">{{ item.total_num_big_small }}</span></td>
                    <template v-for="(key,index) in ['one','two','three']">
                        <td><span v-markColor class="text">{{ item[key + '_big_small'] }}</span></td>
                        <td><span v-markColor class="text">{{ item[key + '_single_double'] }}</span></td>
                    </template>
                    <td v-if="btn">
                        <Button size="mini"@click="Handel(item)">{{ btn }}</Button>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr v-if="main._empty">
                    <td class="empty" colspan="100">暂无数据</td>
                </tr>
            </tfoot>
        </table>


        <table v-if="Filter.games_type == 4" v-loading="main._loading" class="table u-bl u-br u-bb" border="0" cellspacing="1" cellpadding="0">
            <thead>
                <tr>
                    <td rowspan="2">期数</td>
                    <td rowspan="2">开奖时间</td>
                    <td colspan="5">彩球号码</td>
                    <td rowspan="2">总合</td>
                    <td rowspan="2">总单双</td>
                    <td rowspan="2">总大小</td>
                    <td rowspan="2">总尾大小</td>
                    <td v-for="item in ['正码一','正码二','正码三','正码四','特码','上下奇偶']" colspan="2">{{ item }}</td>
                </tr>
                <tr>
                    <td v-for="item in ['正码一','正码二','正码三','正码四','特码']">{{ item }}</td>
                    <template v-for="item in 5">
                        <td>大小</td>
                        <td>单双</td>
                    </template>
                    <td>上下</td>
                    <td>奇偶</td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item,index) in main._list" :key="item.id" class="c-text-c">
                    <td>{{ item.number }}</td>
                    <td>{{ item.open_time }}</td>
                    <td v-for="(sub,i) in 5">
                        <a class="ball red">{{ FormatCode(item.data)[i] }}</a>
                    </td>
                    <td name="总合">{{ item.total_num }}</td>
                    <td name="总单双"><span v-markColor class="text">{{ item.total_num_single_double }}</span></td>
                    <td name="总大小"><span v-markColor class="text">{{ item.total_num_big_small }}</span></td>
                    <td name="总尾大小"><span v-markColor class="text">{{ item.total_num_mantissa_big_small }}</span></td>
                    <template v-for="(key,index) in ['one','two','three','four','special']">
                        <td><span v-markColor class="text">{{ item[key + '_num_big_small'] }}</span></td>
                        <td><span v-markColor class="text">{{ item[key + '_num_single_double'] }}</span></td>
                    </template>
                    <td><span v-markColor class="text">{{ item.on_off_draw }}</span></td>
                    <td><span v-markColor class="text">{{ item.odd_number_even_draw }}</span></td>
                </tr>
            </tbody>
            <tfoot>
                <tr v-if="main._empty">
                    <td class="empty" colspan="100">暂无数据</td>
                </tr>
            </tfoot>
        </table>


        <table v-if="Filter.games_type == 5" v-loading="main._loading" class="table u-bl u-br u-bb" border="0" cellspacing="1" cellpadding="0">
            <thead>
                <tr>
                    <td rowspan="2">期数</td>
                    <td rowspan="2">开奖时间</td>
                    <td colspan="3">彩球号码</td>
                    <td rowspan="2">总和</td>
                    <td rowspan="2">总和单双</td>
                    <td rowspan="2">总和大小</td>
                </tr>
                <tr>
                    <td v-for="item in ['骰1','骰2','骰3']">{{ item }}</td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item,index) in main._list" :key="item.id" class="c-text-c">
                    <td style="vertical-align:middle;">{{ item.number }}</td>
                    <td style="vertical-align:middle;">{{ item.open_time }}</td>
                    <td v-for="(sub,i) in 3" style="vertical-align:middle;">
                        <!-- <a class="ball blue">{{ FormatCode(item.data)[i] }}</a> -->
                        <img v-if="FormatCode(item.data)[i] == 1" class="dice" src="../../../../image/game/dice/1.png">
                        <img v-if="FormatCode(item.data)[i] == 2" class="dice" src="../../../../image/game/dice/2.png">
                        <img v-if="FormatCode(item.data)[i] == 3" class="dice" src="../../../../image/game/dice/3.png">
                        <img v-if="FormatCode(item.data)[i] == 4" class="dice" src="../../../../image/game/dice/4.png">
                        <img v-if="FormatCode(item.data)[i] == 5" class="dice" src="../../../../image/game/dice/5.png">
                        <img v-if="FormatCode(item.data)[i] == 6" class="dice" src="../../../../image/game/dice/6.png">
                    </td>
                    <td style="vertical-align:middle;">{{ item.champion_runnerup }}</td>
                    <td style="vertical-align:middle;"><span v-markColor class="text">{{ item.total_num_single_double }}</span></td>
                    <td style="vertical-align:middle;"><span v-markColor class="text">{{ item.total_num_big_small }}</span></td>
                </tr>
            </tbody>
            <tfoot>
                <tr v-if="main._empty">
                    <td class="empty" colspan="100">暂无数据</td>
                </tr>
            </tfoot>
        </table>


        <table v-if="Filter.games_type == 6" v-loading="main._loading" class="table u-bl u-br u-bb" border="0" cellspacing="1" cellpadding="0">
            <thead>
                <tr>
                    <td rowspan="2">期数</td>
                    <td rowspan="2">开奖时间</td>
                    <td colspan="10">彩球号码</td>
                    <td rowspan="2">冠亚和值</td>
                    <td rowspan="2">冠亚和值单双</td>
                    <td rowspan="2">冠亚和值大小</td>
                </tr>
                <tr>
                    <td v-for="item in ['冠军','亚军','第三名','第四名','第五名','第六名','第七名','第八名','第九名','第十名']">{{ item }}</td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item,index) in main._list" :key="item.id" class="c-text-c">
                    <td>{{ item.number }}</td>
                    <td>{{ item.open_time }}</td>
                    <td v-for="(sub,i) in 10">
                        <a class="ball blue">{{ FormatCode(item.data)[i] }}</a>
                    </td>
                    <td>{{ item.champion_runnerup }}</td>
                    <td><span v-markColor class="text">{{ item.total_num_single_double }}</span></td>
                    <td><span v-markColor class="text">{{ item.total_num_big_small }}</span></td>
                </tr>
            </tbody>
            <tfoot>
                <tr v-if="main._empty">
                    <td class="empty" colspan="100">暂无数据</td>
                </tr>
            </tfoot>
        </table>


        <table v-if="Filter.games_type == 7" v-loading="main._loading" class="table u-bl u-br u-bb" border="0" cellspacing="1" cellpadding="0">
            <thead>
                <tr>
                    <td rowspan="2">期数</td>
                    <td rowspan="2">星期</td>
                    <td rowspan="2">日期</td>
                    <td colspan="7">彩球号码</td>
                    <td rowspan="2">特<br>单双</td>
                    <td rowspan="2">特<br>大小</td>
                    <td rowspan="2">总分</td>
                    <td rowspan="2">总<br>单双</td>
                    <td rowspan="2">总<br>大小</td>
                    <td rowspan="2">家禽<br>野兽</td>
                    <td rowspan="2">合<br>单双</td>
                    <td rowspan="2">合<br>大小</td>
                    <td rowspan="2">合尾<br>大小</td>
                    <td rowspan="2">波<br>段</td>
                    <td v-if="btn" rowspan="2">操作</td>
                </tr>
                <tr>
                    <td>一</td>
                    <td>二</td>
                    <td>三</td>
                    <td>四</td>
                    <td>五</td>
                    <td>六</td>
                    <td>特</td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item,index) in main._list" :key="item.id" class="c-text-c">
                    <td>{{ item.number }}</td>
                    <td>{{ item.week }}</td>
                    <td>{{ item.date }}</td>
                    <td v-for="(sub,i) in ['一','二','三','四','五','六','特']">
                        <template v-if="FormatCode(item.data)[i]">
                            <a class="ball" :class="CodeColor(FormatCode(item.data)[i])">{{ FormatCode(item.data)[i] }}</a>
                            <b class="c-text-65">{{ CodeZodiac(item,sub) }}</b>
                        </template>
                    </td>
                    <td><span v-markColor class="text">{{ item.special_single_double }}</span></td>
                    <td><span v-markColor class="text">{{ item.special_big_small }}</span></td>
                    <td><span v-markColor class="text">{{ item.total_num }}</span></td>
                    {{/*总*/}}
                    <td><span v-markColor class="text">{{ item.total_num_single_double }}</span></td>
                    <td><span v-markColor class="text">{{ item.total_num_big_small }}</span></td>
                    <td><span v-markColor class="text">{{ item.poultry_beast }}</span></td>
                    {{/*合*/}}
                    <td><span v-markColor class="text">{{ item.total_single_double }}</span></td>
                    <td><span v-markColor class="text">{{ item.total_big_small }}</span></td>
                    <td><span v-markColor class="text">{{ item.total_tail_big_small }}</span></td>
                    <td>
                        <span class="text" :class="CodeColor(item.wave_band)">{{ item.wave_band }}</span>
                    </td>
                    <td v-if="btn">
                        <Button size="mini"@click="Handel(item)">{{ btn }}</Button>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr v-if="main._empty">
                    <td class="empty" colspan="100">暂无数据</td>
                </tr>
            </tfoot>
        </table>
        <Pagination v-if="main._total" v-model="Page" class="o-mtb" :total="main._total" :count="main._count" :page-size="Filter.per_page" @turning="Get" />
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
import SixMix from '@lottery.admin.2.0/mixin/six.js'
export default {
    name: 'CreditSixOpen',
    mixins: [StoreMix, SixMix],
    data() {
        return {
            store: 'game/history',
            Filter: {}
        }
    },
    props: {
        filter: {
            default: {}
        },
        admin: {
            default: false
        },
        btn: {
            default: null
        }
    },
    directives: {
        markColor: {
            inserted(el, e, vnode, oldvnode) {
                let text = el.innerText[el.innerText.length-1], mark;
                if (text == '大' || text == '单' || text == '奇' || text == '上' || text == '家禽') {
                    mark = '1'
                }
                if (text == '小' || text == '双' || text == '偶' || text == '下' || text == '野兽') {
                    mark = '2'
                }
                if (text == '和') {
                    mark = '3'
                }
                if (mark >= 0 && mark <= 3) {
                    el.setAttribute('mark', mark)
                }
            },
            update(el, binding, vnode, oldvnode) {
                let text = el.innerText[el.innerText.length-1], mark;
                if (text == '大' || text == '单' || text == '奇' || text == '上' || text == '家禽') {
                    mark = '1'
                }
                if (text == '小' || text == '双' || text == '偶' || text == '下' || text == '野兽') {
                    mark = '2'
                }
                if (text == '和') {
                    mark = '3'
                }
                if (mark >= 0 && mark <= 3) {
                    el.setAttribute('mark', mark)
                }
            }
        }
    },
    methods: {
        init() {
            this.$set(this, 'Filter', this.filter)
            this.$set(this.Filter, 'games_type', this.filter.games_type)
            this.Get()
        },
        reload() {
            this.$set(this, 'Filter', this.filter)
            this.$set(this.Filter, 'games_type', this.filter.games_type)
            this.MakeFilter()
        },
        FormatCode(str) {
            if (str) {
                let normal = str.split('+')[0]
                if (normal) {
                    normal = normal.split(',')
                }
                let special = str.split('+')[1]
                if (special) {
                    special = special.split(',')
                    normal = normal.concat(special)
                }
                return normal
            }
            return []
        },
        CodeZodiac(item, unit) {
            let map = {
                '一': 'one_zodiac',
                '二': 'two_zodiac',
                '三': 'three_zodiac',
                '四': 'four_zodiac',
                '五': 'five_zodiac',
                '六': 'six_zodiac',
                '特': 'special_zodiac'
            }
            return map[unit] && item[map[unit]] ? item[map[unit]] : '-'
        },
        Handel(e) {
            this.$emit('Handel', e)
        }
    },
    mounted() {
        this.init()
    }
}
</script>
