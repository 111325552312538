<style lang="scss"></style>
<template>
    <el-dialog class="MyLotterWebsitesMudul" :title="Title" :visible.sync="view" size="small" top="5vh" :modal-append-to-body="false" :close-on-click-modal="false">
        <el-form label-width="100px" size="small">
            <el-form-item label="站点名称">
                <el-input v-model="Params.name" placeholder="请输入站点名称" />
            </el-form-item>
        </el-form>
        <div class="c-text-r">
            <Button @click="Cancel()">取消</Button>
            <Button :loading="main.editing" type="primary" @click="Submit()">提交</Button>
        </div>
    </el-dialog>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.modul.js'
export default {
    name: 'MyLotterWebsitesMudul',
    mixins: [StoreMix],
    data() {
        return {
            store: 'game/tsite',
            Params: {
                name: null
            }
        }
    },
    methods: {
        init() {

        }
    },
    mounted() {

    }
}
</script>
