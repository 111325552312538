
import Common from './common.js'
export default {
    name: 'GameFormal',
    mixins: [Common],
    data() {
        return {
            rank: {
                radio: 0,
                data: null
            },
            oddsTimer: null
        }
    },
    computed: {
        // 上级玩法列表
        List() {
            if (this.Running.play) {
                return this.Running.play.list
            }
            return []
        },
        template() {
            if (this.Running.template) {
                return this.Running.template
            }
            return null
        }
    },
    watch: {
        // 不同游戏种类切换
        'Running.code'(val) {
            this.depotInit()
        },
        'Running.type'(val) {
            if (val==='formal') {
                this.depotInit()
            }
        },
        // 同个游戏分类切换
        'Running.play.belong'(val) {
            // this.RankReset()
            this.depotInit()
        },
        // 指定商品切换
        'Running.depot_active_id'(val, old) {
            if (val && val != old) {
                this.DataReset('rank')
                this.$nextTick(() => {
                    if (this.$refs['play']) {
                        this.$refs['play'].Reset()
                    }
                })
            }
        },
        // 冷热遗漏
        'rank.radio'(val) {
            if (val) {
                this.RankReload(val)
            }
        },
        'Running.nav.side.id'(val) {
            if (val) {
                this.side = val
            }
        }
    },
    methods: {
        EventRegister() {
            this.$store.state.game.Event.addListener('GOODS_RELOAD_ODDS', this.e_GOODS_RELOAD_ODDS)
            this.$store.state.game.Event.addListener('RESET_GAME', this.e_RESET_GAME)
        },
        EventRemove() {
            clearInterval(this.oddsTimer)
            this.$emit('updateOpt', {key: 'formal', val: false})
            this.$store.state.game.Event.removeListener('GOODS_RELOAD_ODDS', this.e_GOODS_RELOAD_ODDS)
            this.$store.state.game.Event.removeListener('RESET_GAME', this.e_RESET_GAME)
        },
        e_GOODS_RELOAD_ODDS(data) {
            let {id, res} = data
            if (this.Running.nav.side.id == id) {
                this.OddTimerInit()
            }
        },
        e_RESET_GAME() {
            this.EventRemove()
        },

        init() {
            this.depotInit()
            this.EventRegister()
        },
        Reset() {
            this.$refs.chipin.Reset() // 下单列表
            this.$refs.history.Reload() // 历史记录
        },
        depotInit() {
            this.$nextTick(() => {
                if (this.List[0]) {
                    this.depotPick(this.List[0].child[0])
                }
            })
        },
        depotPick(item) {
            this.$store.commit('game/RUNNING_SET', {
                depot_active_id: item.id,
                depot_active: item.games_play_group_id,
                depot: item,
                template: item.formal
            })
        },
        SaveParams(e) {
            this.$refs.chipin.LoadParams(e)
        },
        RankReset(deep) {
            if (deep) {
                this.DataReset('rank')
            }
            this.rank.radio = 0
        },
        RankReload(type) {
            if (!this.rank.data) {
                let games_id = this.Running.id
                this.$store.dispatch('game/GET_RANK', {games_id, ac: 'analysis'}).then(res => {
                    if (this.Running.id == games_id) {
                        let obj = {}
                        for (let row in res) {
                            obj[row] = {
                                codes: {}
                            }
                            let group = res[row].code_mums
                            let statis = [null, [], []]
                            for (let code in group) {
                                let info = group[code]
                                statis[1].push(info.ac)
                                statis[2].push(info.mc)
                                obj[row].codes[code] = {
                                    1: group[code].ac,
                                    2: group[code].mc
                                }
                            }
                            obj[row].max = [null, Math.max(...statis[1]), Math.max(...statis[2])]
                            obj[row].min = [null, Math.min(...statis[1]), Math.min(...statis[2])]
                        }
                        this.$set(this.rank, 'data', obj)
                    }
                })
            }
        },

        OddTimerInit() {
            this.Dp('game/GOODS_CLEAN_ODDS').then(res => {
                this.$nextTick(() => {
                    this.OddTimerRun(true)
                })
            })
        },
        OddTimerRun(immediately) {
            clearInterval(this.oddsTimer)
            if (immediately) {
                this.$nextTick(() => {
                    this.OddTimerGet()
                })
            }
            this.oddsTimer = setInterval(this.OddTimerGet, 60000)
        },
        OddTimerGet() {
            if (this.$route.name === 'credit/game' || this.$route.name === 'index/pages/table') {
                console.log('OddTimerGet')
                if (this.Running.expect.now && this.Running.expect.now.id) {
                    if (this.Running.nav && this.Running.nav.item && this.DB) {
                        let title = this.Running.nav.item.title
                        let belong = null
                        let ids = []
                        for (let id in this.DB) {
                            if (!belong || this.DB[id].belong == belong) {
                                ids.push(id)
                            }
                        }
                        // 子项目
                        const checkSubset = function(list, valid=false) {
                            for (let item of list) {
                                if (item.child) {
                                    checkSubset(item.child)
                                } else if (item.formal && item.formal.algorithm && (item.formal.algorithm[0] == 'R15' || item.formal.algorithm[0] == 'R7' || item.formal.algorithm[0] == 'R6' || item.formal.algorithm[0] == 'R10' || item.formal.defaultCheckedPos && item.formal.algorithm[0] !== 'R7' )) {
                                    for (let sub of item.formal.subset) {
                                        ids.push(sub.id)
                                    }
                                } else if (item.formal) {
                                    ids.push(item.id)
                                }
                            }
                        }
                        checkSubset(this.List)
                        this.Dp('game/GOODS_UPDATE_ODDS', {ids, lottery_id: this.Running.expect.now.id, games_id: this.Running.id}).then(res => {
                            this.OddUpdate()
                        })
                    }
                }
            }
        },
        OddUpdate(res) {
            try {
                this.$refs['play'].MakeOrder()
            } catch (e) {}
        }
    },
    activated() {

    },
    mounted() {
        this.init()
    },
    beforeDestroy() {
        // clearInterval(this.oddsTimer)
        // this.$emit('updateOpt',{key:'formal',val:false})
        this.EventRemove()
    }
}