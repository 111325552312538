<style lang="scss">
    .CreditSixBackup {

    }
</style>
<template>
    <div class="CreditSix CreditSixBackup">
        <div class="l-flex-c o-plr">
            <div class="l-flex-1">
                <span>游戏类型：</span>
                <el-select v-model="Filter.games_id" style="width:7rem;" size="small">
                    <el-option v-for="(item,index) in Opt.hasDir.game" :key="item.id" :label="item.title" :value="item.id" @click.native="ChangeGame(item)" />
                </el-select>
                <span class="o-pl">注单类型：</span>
                <el-select v-model="Filter.is_transfer" size="small" style="width:6rem;" @change="MakeFilter()">
                    <el-option label="普通" :value="0" />
                    <el-option label="走飞" :value="1" />
                </el-select>
                <span class="o-pl-s" />
                <Button class="o-ml" @click="file()">导出所有数据</Button>
            </div>
        </div>
        <div class="o-pt">
            <table v-loading="main._loading" class="table" border="0" cellspacing="1" cellpadding="0">
                <thead>
                    <tr>
                        <td>注单号</td>
                        <td>用户名</td>
                        <td>用户类型</td>
                        <td>期数</td>
                        <td>游戏</td>
                        <td>盘类</td>
                        <td>玩法</td>
                        <td>下注号码</td>
                        <td>下注金额</td>
                        <td>奖金赔率</td>
                        <td>创建时间</td>
                        <td>IP</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in main._list" class="c-text-c">
                        <td>{{ item.sequence }}</td>
                        <td>{{ item.username }}</td>
                        <td>{{ item.user_type }}</td>
                        <td>{{ item.number }}</td>
                        <td>{{ item.games }}</td>
                        <td>{{ item.side }}</td>
                        <td>{{ item.play }}</td>
                        <td>{{ item.code }}</td>
                        <td>{{ item.amount }}</td>
                        <td>{{ item.bonus_prop }}</td>
                        <td>{{ item.create_at }}</td>
                        <td>{{ item.ip }}</td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr v-if="main._empty">
                        <td class="empty" colspan="100">暂无数据</td>
                    </tr>
                </tfoot>
            </table>
            <Pagination v-if="main._total" v-model="Page" class="o-mtb" :total="main._total" :count="main._count" :page-size="Filter.per_page" @turning="Get" />
        </div>
        <editer v-model="Editer.view" title="导出报表" :form="Editer.form" />
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
import SixMix from '@lottery.admin.2.0/mixin/six.js'
import Config from '@lottery.admin.2.0/library/config.js'
import Editer from './model/backup'
export default {
    name: 'CreditSixBackup',
    mixins: [StoreMix, SixMix],
    data() {
        return {
            store: 'myTeam/backup',
            Filter: {
                games_id: 31,
                is_transfer: 0,
                games_type: undefined,
                games_id: undefined,
                per_page: 20
            }
        }
    },
    computed: {

    },
    methods: {
        init() {
            this.$set(this.Filter, 'games_id', this.Running.id)
            this.Get(1)
        },

        file() {
            this.Editer.view = true
            this.Editer.form = this.Origin(this.Filter)
        },
        ChangeGame(item) {
            if (this.Running.id !== item.id) {
                this.$store.state.base.Event.emit('Credit/ChangeGame', item.name)
                this.init()
            }
        }
    },
    components: {
        Editer
    },
    activated() {
        this.init()
    }
}
</script>
