<style lang="scss">
    .GameCreditK3 {

    }
</style>
<template>
    <div v-if="List" v-loading="!List.length" class="GameCreditK3">
        <category title="大小骰宝">
            <group :list="{child: []}" :length="5" :prompt="prompt" :prompt-interval="promptInterval" type="queue" />
            <group v-for="unit in [0,1,2,3,4]" :key="unit" :list="List[unit]" :length="5" :prompt="[]" type="queue" :dice="true" />
        </category>
    </div>
</template>

<script>
import Credit from './other.js'
import Category from './components/category'
import Row from './components/row'
import Group from './components/group'
export default {
    name: 'GameCreditK3',
    mixins: [Credit],
    data() {
        return {

        }
    },
    props: ['List'],
    computed: {
        prompt() {
            return this.COptions.type == 1 ? ['号码', '赔率', '金额'] : ['号码', '赔率']
        },
        promptInterval() {
            return this.COptions.type == 1 ? [7, 6, 11] : [12, 12]
        }
    },
    methods: {

    },
    components: {
        Category, Row, Group
    }
}
</script>
