<style lang="scss">
    @import "../../../../style/config.scss";
    .GameCreditBackupMudul {
        .el-checkbox, .el-radio {
            margin-right: .6rem;
        }
    }
</style>
<template>
    <el-dialog class="GameCreditBackupMudul" :title="title" :visible.sync="view" size="" top="5vh" :modal-append-to-body="false">
        <el-form v-loading="main._loading" label-width="80px">
            <el-form-item label="帐号">
                <el-input v-model="Params.account" max-length="24" placeholder="请输入帐号" :disabled="!!Params.id" />
            </el-form-item>
            <el-form-item label="名称">
                <el-input v-model="Params.name" max-length="24" placeholder="请输入名称" />
            </el-form-item>
            <el-form-item label="密码">
                <el-input v-model="Params.pass" max-length="24" placeholder="请输入密码" />
            </el-form-item>
            <el-form-item label="权限">
                <el-checkbox-group v-model="menu">
                    <el-checkbox v-for="(item,index) in authDir" v-if="item.view" :key="index" class="o-mr" :label="index" :disabled="!item.enable">{{ item.title }}</el-checkbox>
                </el-checkbox-group>
            </el-form-item>
        </el-form>
        <div class="c-text-r">
            <!--             <el-button type="info" @click="Cancel()" plain>取消</el-button>
            <el-button :loading="main._editing" type="info" @click="Submit()">提交</el-button> -->
            <Button type="info" plain @click="Cancel()">取消</Button>
            <Button :loading="main._editing" type="info" @click="Submit()">提交</Button>
        </div>
    </el-dialog>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.modul.js'
export default {
    name: 'GameCreditBackupMudul',
    mixins: [StoreMix],
    data() {
        return {
            store: 'admin/account',
            Params: {
                menu: ''
            }
        }
    },
    computed: {
        authDir() {
            let dir = this.Origin(this.Opt.routerAuthDir)
            if (this.Opt.isSub) {
                for (let key in dir) {
                    if (!this.Opt.isSub[key]) {
                        dir[key].enable = false
                    }
                }
            }
            return dir
        },
        menu: {
            get: function() {
                try {
                    return this.Params.menu.split(',')
                } catch (err) {
                    return []
                }
            },
            set: function(val) {
                this.$set( this.Params, 'menu', val ? val.join(',') : '')
            }
        }
    },
    methods: {
        init() {

        }
    }
}
</script>
