
export default {
    name: 'GameLayoutCategory',
    methods: {
        ChangeCategory(item) {
            this.$emit('change', item)
        },
        ChangeType(type) {
            this.$router.push({name: 'game', params: {
                code: this.$route.params.code,
                type
            }})
        }
    },
    mounted() {
        //this.init()
    }
}
