<style lang="scss">
    @import "./nav.scss";
    .GameLayoutNav {
        .credit-info {
            .cover {
                width:100px; height:90px; position:absolute; top:-30px; left:180px;
                img.logo {
                    width:100px; height:100px;
                }
            }
            @media screen and (max-width: 1439px){
                .cover {
                    position:absolute; top:-30px; left:160px;
                }
            }
        }
        .games {
            padding:16px 0;
            i.icon {
                font-size:24px; margin-right:7px; font-weight:normal; color:#184182!important;
            }
            .mark {
                color:#d8175d!important;
            }
            .el-submenu__title {
                height:42px; line-height:42px; font-weight:bold;
                span {
                    font-weight:bold;
                }
            }
            .el-submenu .el-menu-item {
                height:40px; line-height:40px; font-weight:bold;
            }
            .el-menu{
                background-color: transparent;
            }
            .el-submenu__title:hover, .el-menu-item:hover {
                background-color: rgba(0,0,0,.03)!important;
            }
            .el-menu-item.is-active {
                background-color: rgba(0,0,0,.05)!important;
                border-color: #184182!important;
                color:#0a78ec!important;
            }
        }
    }
</style>
<template>
    <nav v-if="!(Opt.isCredit && Opt.isTeam)" class="GameLayoutNav" :class="{'cash': Opt.isCash,'credit': Opt.isCredit && !Opt.isTeam}">
        <!--         <template v-if="Opt.isCash">
            {{/* 用户信息 */}}
            <div class="tools">
                <ul class="o-plr-s">
                    <li v-for="(item,index) in Opt.subMenu.pc" v-if="item.enable">
                        <div @click="Go(item.link)">
                            <div class="cover" :style="'background:'+item.color">
                                <i class="icon" :class="'icon-'+item.icon"></i>
                            </div>
                            <p class="o-pt-s">{{ item.title }}</p>
                        </div>
                    </li>
                    <li>
                        <div @click="Logout()">
                            <div class="cover" style="background:#FC712B;">
                                <i class="icon" :class="'icon-ai-out'"></i>
                            </div>
                            <p class="o-pt-s">退出登录</p>
                        </div>
                    </li>
                </ul>
            </div>

            {{/* 历史开奖 */}}
            <div class="history">
                <div class="title l-flex-c">
                    <h6 class="l-flex-1">开奖历史</h6>
                    <a class="more" @click="HistoryMore()">更多></a>
                </div>
                <ul class="info">
                    <li v-for="(item,index) in Running.history" v-if="index < 5 ">
                        <div class="item">
                            <div class="index">{{ index + 1 }}</div>
                            <div class="qi row">{{ item.number }}</div>
                            <div class="balls row">
                                <span class="ball" :class="{'scroll':item.data.length > 12}">{{ item.data }}</span>
                            </div>
                        </div>
                    </li>
                    <p v-if="Running.history && Running.history.length == 0" class="c-text-c o-ptb-l">暂无数据</p>
                </ul>
            </div>

            {{/* 游戏目录 */}}
            <div class="games">
                <el-menu :default-active="Running.id+''" :unique-opened="true">
                    <el-submenu v-for="(pack,unit) in Category" v-if="Opt.hasDir.typeById[pack.id]" :key="pack.title" :index="unit">
                        <template slot="title">
                            <p class="l-flex-c">
                                <i v-if="icons[pack.id]" class="icon" :class="icons[pack.id].icon"></i>
                                <i v-else class="icon icon-game-other"></i>
                                <span class="l-flex-1">{{ pack.title }}</span>
                                <b class="mark" style="padding-right:20px;">NEW</b>
                            </p>
                        </template>
                        <el-menu-item v-for="(item,index) in pack.games" :key="item.id" :index="item.id+''" @click.native="pickGames(item)">
                            <span class="l-flex-1" style="padding-left:11px;">{{ item.title }}</span>
                        </el-menu-item>
                    </el-submenu>
                </el-menu>
            </div>
        </template> -->
        <div v-if="Opt.isCredit">
            <div class="credit-info" style="padding-top:65px;">
                <div class="cover c-text-c">
                    <img class="logo" :src="Img(Logo.cover)">
                </div>
                <header>账户信息</header>
                <ul>
                    <li class="l-flex-c">
                        <span class="l-flex-1">会员帐号：</span>
                        <p class="c-text-r">{{ User.username }}</p>
                    </li>
                    <li class="l-flex-c">
                        <span class="l-flex-1">玩法类型：</span>
                        <p class="c-text-r">{{ CreditInfo.group }}</p>
                    </li>
                    <li class="l-flex-c">
                        <span class="l-flex-1">信用额度：</span>
                        <p class="c-text-r">{{ CreditInfo.balance }}</p>
                    </li>
                    <li class="l-flex-c">
                        <span class="l-flex-1">已用额度：</span>
                        <p class="c-text-r">{{ CreditInfo.ubalance }}</p>
                    </li>
                    <li class="l-flex-c">
                        <span class="l-flex-1">可用盘类：</span>
                        <p class="c-text-r">{{ CreditInfo.side }}</p>
                    </li>
                    <li class="l-flex-c">
                        <span class="l-flex-1">下注期数：</span>
                        <span>{{ CreditInfo.expect }}</span>
                    </li>
                    <li class="l-flex-c">
                        <span class="l-flex-1">开奖日期：</span>
                        <p class="c-text-r">{{ CreditInfo.date }}</p>
                    </li>
                </ul>
            </div>
            <div class="games">
                <el-menu :default-active="Running.id + ''" :unique-opened="true">
                    <el-submenu v-for="(pack,unit) in Category" v-if="Opt.hasDir.typeById[pack.id]" :key="pack.title" :index="unit">
                        <template slot="title">
                            <p class="l-flex-c">
                                <i v-if="icons[pack.id]" class="icon" :class="icons[pack.id].icon" />
                                <i v-else class="icon icon-game-other" />
                                <span class="o-pl-s l-flex-1">{{ pack.title }}</span>
                                <b class="mark" style="padding-right:20px;">NEW</b>
                            </p>
                        </template>
                        <el-menu-item v-for="(item,index) in pack.games" :key="item.id" :index="item.id + ''" @click.native="pickGames(item)">
                            <span class="l-flex-1" style="padding-left:11px;">{{ item.title }}</span>
                        </el-menu-item>
                        <a :href="user_link"><li role="menuitem" tabindex="-1" class="el-menu-item" style="padding-left: 40px;"><span class="l-flex-1" style="padding-left: 11px;">番摊</span></li></a>
                    </el-submenu>
                </el-menu>
            </div>
        </div>
    </nav>
</template>

<script>
import Common from './nav.js'
export default {
    name: 'GameLayoutNav',
    mixins: [Common],
    data() {
        return {
            user_link : 'https://api.ac6677.com/v3/ft?id='+localStorage.getItem('fantantoken'),
        }
    },
    computed: {
        Logo() {
            try {
                let info = this.Running.remote_data
                return {
                    title: info.title,
                    cover: info.logo_img
                }
            } catch (e) {

            }
            return {
                title: '香港六合彩',
                cover: '/static/images/logo/xglhc.png'
            }
        }
    }

}
</script>
