<style lang="scss"></style>
<template>
    <div class="CreditSix CreditSixReport">
        <div class="ctrl o-mb">
            <div class="o-plr">
                <span>类型：</span>
                <el-select v-model="type" size="small" style="width:100px;">
                    <el-option label="总账" :value="'amount'" />
                    <el-option v-if="Opt.isTeam" label="分类账" :value="'subsidiary'" />
                </el-select>
                <span class="o-pl">游戏：</span>
                <el-select v-model="Filter.games_type" size="small" style="width:120px;" @change="TypeChange">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option v-for="(item, index) in Opt.hasDir.game" :key="index" :label="item.title" :value="item.id"></el-option>
                    <!-- <el-option label="整合" :value="''"></el-option> -->
                    <!-- <el-option label="全部" :value="7"></el-option>
                     <el-option label="香港六合彩" :value="8"></el-option> -->
                    <!-- <el-option label="澳门六合彩" :value="9" /> -->
                    <!-- <el-option label="台湾大乐透" :value="10"></el-option>
                     <el-option label="新加坡多多彩" :value="11"></el-option> -->
                </el-select>
                <template v-if="Filter.games_type > 7">
                    <span class="o-pl">期数：</span>
                    <el-select v-model="Filter.lottery_id" size="small" style="width:120px;">
                        <el-option label="所有" :value="undefined" />
                        <el-option v-for="(item,index) in expect.list" :key="item.id" :label="item.number" :value="item.id" />
                    </el-select>
                </template>
                <span class="o-pl">状态：</span>
                <el-select v-model="Filter.status" size="small" style="width:100px;">
                    <el-option label="未结算" :value="0"></el-option>
                    <el-option label="已结算" :value="1"></el-option>
                </el-select>

                <div class="o-pt-s" />
                <DatePicker v-model="Filter.interval" />
                <el-button-group style="position:relative; top:-2px;">
                    <el-button v-for="(item,index) in datatime" :key="item.name" size="mini" :type="Filter.interval && item.default && Filter.interval[0] == item.default[0] && Filter.interval[1] == item.default[1] ? 'info' : ''" @click="PickDate(item.name)">{{ item.title }}</el-button>
                </el-button-group>
                <span class="o-pl" />
                <Button @click="Check()">生成报表</Button>
                <Button @click="Print()">表格打印</Button>
            </div>
        </div>
        <div v-if="use === 'amount' && Opt.isTeam" class="ctrl l-flex-c o-mb">
            <button v-if="breadcrumb.length > 1" class="btn" plain @click="Breadcrumb(breadcrumb.length - 2)">返回上一级</button>
            <div class="l-flex-1 o-pl">
                <div class="breadcrumb" style="background-color:#f5f5f5; border-radius:4px; padding:10px 12px;">
                    <el-breadcrumb separator="/">
                        <el-breadcrumb-item v-for="(item,index) in breadcrumb" :key="index"><a @click="Breadcrumb(index)">{{ item.title }}</a></el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>
        </div>
        <template v-if="use">
            <amount v-if="use === 'amount'" ref="amount" :form="Filter" :target="BreadcrumbTarget" @Breadcrumb="Breadcrumb" @GetByBreadcrumb="GetByBreadcrumb" />
            <subsidiary v-if="use === 'subsidiary'" ref="subsidiary" :form="Filter" />
        </template>
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
import LevelMix from '@lottery.2.0/mixin/level_2.js'
import Amount from './report/amount'
import Subsidiary from './report/subsidiary'
export default {
    name: 'CreditSixReport',
    mixins: [StoreMix, LevelMix],
    data() {
        return {
            //store : 'myRecord/order',
            type: 'amount',
            use: null,
            Filter: {
                games_type: 0,
                lottery_id: undefined,
                status: 1
            },
            expect: {
                list: []
            },
            datatime: [
                {name: 'day', title: '今日'},
                {name: 'yesterday', title: '昨天'},
                {name: 'lweek', title: '上周'},
                {name: 'week', title: '本周'},
                {name: 'lmonth', title: '上月'},
                {name: 'month', title: '本月'}
            ]
        }
    },
    methods: {
        init() {
            if (this.Running.id) {
                this.Filter.games_type = this.Running.model
            }
            for (let item of this.Opt.hasDir.type) {
                if (item) {
                    this.Filter.games_type = item.id
                    break
                }
            }
            this.use = null
            for (let item of this.datatime) {
                this.$set(item, 'default', this.FilterInterval(item.name))
            }
        },
        Check() {
            this.$set(this.Filter, 'pid', undefined)
            this.$delete(this.Filter, 'b')
            // let todaydate = new Date().setHours(0, 0, 0, 0);
            // let paramsdate = new Date(this.Filter.interval[0]).getTime();
            // if (todaydate === paramsdate) {
            //     if(this.Filter.games_type < 1){
            //         this.Filter.status = 0;
            //         let nowtimeHours = new Date().getHours();
            //         let nowtimeMinutes = new Date().getMinutes();
            //         if (nowtimeHours >= 21) {
            //             if (nowtimeHours > 21) {
            //                 this.Filter.status = 1;
            //             } else if (nowtimeMinutes > 33) {
            //                 this.Filter.status = 1;
            //             }
            //         }
            //     }
            //     // if (this.Filter.games_type < 1) {
            //     //     this.Filter.status = 1;
            //     // }
            //     // if (this.Filter.games_type == 31 || this.Filter.games_type == 34 || this.Filter.games_type == 37) {
            //     //     if (nowtimeHours >= 21) {
            //     //         if (nowtimeHours > 21) {
            //     //             this.Filter.status = 1;
            //     //         } else if (nowtimeMinutes > 33) {
            //     //             this.Filter.status = 1;
            //     //         }
            //     //     }
            //     // }
            //     // if (this.Filter.games_type == 35) {
            //     //     if (nowtimeHours >= 20) {
            //     //         if (nowtimeHours > 20) {
            //     //             this.Filter.status = 1;
            //     //         } else if (nowtimeMinutes > 29) {
            //     //             this.Filter.status = 1;
            //     //         }
            //     //     }
            //     // }
            //     // if (this.Filter.games_type == 36) {
            //     //     if (nowtimeHours >= 18) {
            //     //         if (nowtimeHours > 18) {
            //     //             this.Filter.status = 1;
            //     //         } else if (nowtimeMinutes > 24) {
            //     //             this.Filter.status = 1;
            //     //         }
            //     //     }
            //     // }
            // } else {
            //     this.Filter.status = 1;
            // }
            this.Go(`credit/report`, {}, {...this.Filter, use: this.type, type: this.type})
        },
        Print() {
            let type = this.use
            if (type && this.$refs[type]) {
                this.$refs[type].Print()
            }
        },
        GetHistory() {
            const {games_type} = this.Filter
            const games_id = games_type
            this.Dp('myRecord/_GET_HISTORY', {params: {games_id, games_type, per_page: 10}}).then(res => {
                this.$set(this.expect, 'list', res.data)
            })
        },
        PickDate(type) {
            this.$set(this.Filter, 'lottery_id', undefined)
            this.FilterIntervalSet(type)
        },
        TypeChange(val) {
            this.$set(this.Filter, 'lottery_id', undefined)
            this.GetHistory()
        },
        MakeFilter() {
            this.use = null
            let breadcrumb = this.Filter.b
            let pid = this.Filter.pid
            //console.log('MakeFilter',this.Origin(this.Filter))
            this.DataReset('Filter')
            if (breadcrumb) {
                this.Filter.b = breadcrumb
                this.Filter.pid = breadcrumb
            }
            this.$nextTick(() => {
                let Filter = this.Origin(this.$route.query)
                if (!Filter.interval) {
                    this.FilterIntervalSet('day')
                }
                this.Filter.pid = pid ? pid : this.User.id
                for (let key in Filter) {
                    if (Filter[key] != undefined) {
                        let value = Filter[key]
                        if (value) {
                            if (key === 'games_type' || key === 'lottery_id' || key === 'status') {
                                value = parseInt(value)
                            }
                        }
                        this.$set(this.Filter, key, value)
                    }
                }
                if (this.Filter.use) {
                    this.type = this.Filter.use
                    this.$nextTick(() => {
                        this.use = this.Filter.use
                    })
                }
            })
        }
    },
    components: {
        Amount,
        Subsidiary
    },
    activated() {
        // this.init()
        if (JSON.stringify(this.$route.query) == '{}') {
            this.type = 'amount'
            this.use = ''
        }

        // if(this.$route.params.auto){
        //     this.DataReset('Filter')
        //     this.type = 'amount'
        //     this.FilterIntervalSet('day')
        //     this.Check()
        // }
    },
    mounted() {
        this.GetHistory()
        this.FilterIntervalSet('day')
    }
    // beforeRouteEnter(to, from, next){
    //     next(vm=>{
    //         vm.LoadBreadcrumb()
    //         //vm.LoadBreadcrumb()
    //         vm.DataReset('Filter')
    //         console.log('启动 beforeRouteEnter 2')
    //     })
    // },
    // beforeRouteUpdate(to, from, next){
    //     this.LoadBreadcrumb()
    //     this.DataReset('Filter')
    //     console.log('复用 beforeRouteUpdate 2')
    //     //
    //     next()
    // },
}
</script>
