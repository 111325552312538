<style lang="scss">
    @import "../../../style/config.scss";
    .GameCredit {
        .statis {
            background-color:#FFF;
            .statis-type {
                display:block; width:100px; height:2.4rem; line-height:2.4rem;
                color:#fff; text-shadow:0 1px 1px #000; background-color:#353535;
                text-align:center;
                &.active {
                    background-color:$color-t;
                }
            }
            .list {
                min-height:300px; max-height:500px; overflow:auto;
            }
        }
        .header-tools {
            $height:2.4rem;
            height:$height; line-height:$height; overflow:hidden;
            .rebate {
                height:$height; line-height:$height; float:left; width:400px;
            }
            .btn {
                float:right;
            }
        }
        .checkout {
            height:52px; line-height:52px; text-align:center;
        }
        .selection {
            min-height:260px;
        }
        .rebate {
            .rebate-btn {
                width:1rem; height:1rem; line-height:1rem; margin:0 3px;  border:1px solid $color-t;
                font-size:.65rem; cursor:pointer; text-align:center; font-weight:bold;
                display:inline-block; background-color:#fff; color:$color-t;  border-radius:999px;
            }
        }
        .el-col-5 {
            width: 20%; //width: 20.83333%;
        }
        .table.el-col-19 {
            width: 80%;
        }
        .credit-empty {
            height:4.2rem; line-height:4.2rem; margin:1.5rem 1rem; text-align:center; font-size:.9rem; border-radius:6px;
            background-color:#ff5151; color:#fff;
        }
        .side {
            .el-radio-button__inner {
                padding-left:12px; padding-right:12px;
            }
        }
    }
</style>
<template>
    <div class="GameCredit">
        <div v-show="Running.expect.now.id && Running.expect.now.status == 0" v-loading="!Running.play || !List.length || (Running.nav && Running.nav.loading)">

            <div class="header-tools o-plr-l">
                <p style="float:left;">
                    <span>当前时间：{{ Time(Running.expect.time,'yyyy-MM-dd hh:mm:ss') }}，</span>
                    <span v-if="Running.expect.now.status == 0">{{ CountDown.prompt }}: {{ CountDown.format[0] }} 时 {{ CountDown.format[1] }} 分 {{ CountDown.format[2] }} 秒</span>
                    <span v-if="Running.expect.now.status == 1">本期已封盘</span>
                </p>
                <el-radio-group v-model="side" class="o-mr-s side" size="mini" @change="NavTable($event,Running.nav.item)">
                    <el-radio-button v-for="(item,index) in hasSide" :key="item.id" :label="item.id">{{ item.title }}</el-radio-button>
                </el-radio-group>
                <div class="btn quick">
                    {{/* 下注方式 */}}
                    <el-radio-group v-model="type" class="o-mr-s quick-type" size="mini" :disabled="opt.disabledQuick">
                        <el-radio-button :label="1">一般</el-radio-button>
                        <el-radio-button :label="2">快捷</el-radio-button>
                    </el-radio-group>
                    {{ /*快选金额设置*/ }}
                    <el-popover v-model="quick.view" class="quick-input" placement="bottom-end" width="200" @show="quickInit">
                        <div class="">
                            <div class="l-flex">
                                <b class="c-text-8 c-color-t l-flex-1">快选金额</b>
                                <el-checkbox v-model="quick.enable">启用</el-checkbox>
                            </div>
                            <p class="c-text-6 o-mt-s">设置只保存在您的电脑，清空浏览器缓存或更换电脑会显示默认值。</p>
                            <div v-show="quick.enable">
                                <ul v-if="quick.cache" class="o-pt o-pb-l">
                                    <li v-for="(item,index) in quick.cache" class="l-flex o-mt-s">
                                        <span class="c-line-14 c-text-55">快捷{{ ['一','二','三','四','五'][index] }}</span>
                                        <el-input v-model.number="quick.cache[index]" class="o-plr" size="mini" style="width:3.6rem;" />
                                        <span class="l-flex-1 o-pr c-line-14">元</span>
                                        <el-button v-if="quick.cache.length > 1" type="primary" size="mini" icon="el-icon-delete" circle @click="quick.cache.splice(index,1)" />
                                    </li>
                                </ul>
                                <div class="l-flex-c c-text-c">
                                    <Button type="warning" size="mini" :disabled="quick.cache && quick.cache.length >= 5" @click="quick.cache.push(null)">新增一项</Button>
                                    <Button type="primary" class="" size="mini" @click="quickSave()">保存设置</Button>
                                </div>
                            </div>
                        </div>
                        <el-button slot="reference" size="mini" type="warning" plain>快选金额</el-button>
                    </el-popover>
                </div>
            </div>
            {{/* 下单列表 */}}
            <div class="selection o-pb l-flex">
                <div class="l-flex-1">
                    <component :is="Running.remote_data.credit_tmp" v-if="Running.remote_data && Running.remote_data.credit_tmp" v-show="List.length" ref="order" :list="List" :d-b="DB" @reset="Reset()" @checkout="Checkout()" @updateOpt="updateOpt" />
                </div>
                {{/* 统计工具 */}}
                <!--                     <div class="statis" v-if="Running.model && Running.model != 7">
                        <div class="l-flex">
                            <a class="statis-type" :class="{'active':statis.type == 1}" @click="StatisChange(1)">出码排行</a>
                            <a class="statis-type" :class="{'active':statis.type == 2}" @click="StatisChange(2)">遗漏排行</a>
                        </div>
                        <div class="c-text-c u-bb o-ptb u-bl">
                            <span class="o-mlr">
                                <span v-if="statis.type == 1">次数</span>
                                <span v-if="statis.type == 2">分类</span>
                                <span>:</span>
                            </span>
                            <el-select v-model="statis.category" style="width:5rem;" size="small" placeholder=" ">
                                <el-option v-for="item in statis.list" :key="item.code" :label="item.title" :value="item.code"></el-option>
                            </el-select>
                        </div>
                        <div class="list c-text-6" v-loading="$store.state.game.statis._loading">
                            <ul v-if="statisList && statisList.length">
                                <li class="l-flex o-ptb-s u-bb u-bl" v-for="(item,index) in statisList">
                                    <p class="o-plr l-flex-1">{{ item.game_name }}<span class="c-color-t">@{{ item.item_name }}</span></p>
                                    <p class="o-plr">{{ item.count }} 期</p>
                                </li>
                            </ul>
                            <li v-else class="o-ptb-h c-text-c c-text-6">本项暂无数据</li>
                        </div>
                    </div> -->
            </div>
            <div v-if="Opt.isCash && Running.remote_data && Running.remote_data.max_money_credit && Running.remote_data.max_turn_money_credit" class="o-mt o-pl">
                提示：{{ Running.title }}单玩法最高派奖{{ Running.remote_data.max_money_credit }}元，单期最高派奖{{ Running.remote_data.max_turn_money_credit }}元。
            </div>
            {{/* 下单组件 */}}
            <div v-show="opt.checkoutBtn" class="checkout o-pb">
                <Button @click="Reset()">重置</Button>
                <el-popover v-if="opt.onlyQuick || (type == 2 && !opt.disabledQuick)" v-model="quick.tools" popper-class="quick-modul" placement="top" width="85" trigger="click" :disabled="!quick.value || !quick.value.length > 0">
                    <div v-for="(val,i) in quick.value" class="quick-item" @click="quickInput(val)">{{ val }}</div>
                    <el-input slot="reference" v-model="amount" class="o-ml-l o-mr-s" size="small" style="width:7rem;" placeholder="请输入下注金额" @keyup.enter.native="Checkout()" />
                </el-popover>
                <Button @click="Checkout()">确定</Button>
            </div>

        </div>
        <div v-if="Running.expect.now.id === null || Running.expect.now.status == 1">
            <div v-if="Opt.isCredit" class="credit-empty">
                <b>目前没有可以下注期数！</b>
            </div>
        </div>

        <history v-if="Opt.isCash" ref="history" />

        {{/* 确认注单 - 弹窗 */}}
        <el-dialog title="确认注单" :visible.sync="checkView" width="460px" :close-on-click-modal="false">
            <template v-if="chipinState == 0 || chipinState == 1">
                <div v-if="orderEditing" class="o-ptb-l c-text-c">
                    <Load />
                </div>
                <template v-else-if="Cart.length > 0">
                    <p v-show="chipinState == 1" class="c-color-e o-pb c-text-c">注意：部分注单赔率变动，请核对后重新点击投注!</p>
                    <el-table :data="Cart" height="300px" size="mini" style="width:100%">
                        <el-table-column prop="belong" label="内容" width="180">
                            <template slot-scope="scope">
                                <span>{{ scope.row.belong }}:{{ scope.row.name }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="赔率">
                            <template slot-scope="scope">
                                <span v-if="scope.row.old_odds">
                                    <span class="c-color-e">{{ scope.row.old_odds }}<span v-if="scope.row.old_max_odds">/{{ scope.row.old_max_odds }}</span></span>
                                    <span>-></span>
                                </span>
                                <span>{{ scope.row.odds }}<span v-if="scope.row.max_odds">/{{ scope.row.max_odds }}</span></span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="amount" label="下注金额">
                            <template slot-scope="scope">
                                <input v-if="!scope.row.success" v-model="scope.row.amount" style="width: 90%; padding: 3px 6px; box-sizing: border-box; border-radius: 3px; border: 1px solid #ccc;" @keyup.enter.native="Submit()">
                                <p v-else class="c-text-c">投注成功</p>
                            </template>
                        </el-table-column>
                    </el-table>
                </template>
                <p class="o-p u-bb c-text-r">投注期号：<b class="c-color-t">{{ lotteryNow }}</b></p>
                <div class="c-text-r o-p">
                    <span>共 <b class="c-color-t">{{ calcuCart.count }}</b> 注，</span>
                    <span>合计下注金额 <b class="c-color-t">{{ calcuCart.total }}</b></span>
                </div>
            </template>
            <template v-else-if="chipinState == 2">
                <div class="c-text-c">
                    <Icon class="c-color-s" name="roundcheckfill" size="3.6" />
                    <p class="c-line-16 o-pt c-text-85">所有注单已提交成功</p>
                    <p class="c-text-7">共 {{ chipinSubmitTotal.count }} 注，合计金额 {{ chipinSubmitTotal.total }} 元</p>
                </div>
            </template>
            <div slot="footer" class="dialog-footer">
                <div :class="{'c-text-c': chipinState == 2}">
                    <Button v-show="chipinState == 0" @click="checkView = false">放弃</Button>
                    <Button :disabled="$store.state.game.order._editing" @keyup.enter.native="Submit()" @click="Submit()">
                        <span v-show="chipinState != 2">立即投注</span>
                        <span v-show="chipinState == 2">确定</span>
                    </Button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>

import History from './formal/history.vue'

import ssc from './credit/ssc.vue'
import pk10 from './credit/pk10.vue'
import p115 from './credit/p115.vue'
import k3 from './credit/k3.vue'
import xglhc from './credit/xglhc.vue'
import dpc from './credit/dpc.vue'

import Common from './credit.js'
export default {
    mixins: [Common],
    // computed: {
    //     templateName(){
    //         let name = this.Running.remote_data.credit_tmp
    //         let type = this.Running.model
    //         let map = this.TypeMap[type]
    //         if(map){

    //         }
    //         return name
    //     },
    // },
    components: {
        ssc, pk10, p115, k3, xglhc, dpc,
        History
    }
}
</script>
