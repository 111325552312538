<style lang="scss">
    @import "../../../../../style/config.scss";
    .OddsFast {
        input {
            height:20px; line-height:20px; width:45px; padding:0 5px; vertical-align:middle;
            border:1px solid #ccc; border-radius:4px; outline:none;
            &:focus, &.change {
                background-color:#fdebdf; border-color:#ffbf95;
            }
        }
    }
</style>
<template>
    <div class="OddsFast">
        <span>
            <slot />
        </span>
        <span v-if="side" class="o-pr-s">A:</span>
        <input v-model.number="FastTable.odds" class="o-ml-s">
        <template v-if="side">
            <span class="o-pr-s">B:</span>
            <input v-model.number="FastTable.odds_b" class="o-ml-s">
        </template>
        <span class="o-pl" />
        <Button v-for="item in FastTable.tools" :key="item" size="mini" @click="FastInput(item)">{{ item }}</Button>
    </div>
</template>

<script>
import SixMix from '@lottery.admin.2.0/mixin/six.js'
export default {
    name: 'OddsFast',
    mixins: [SixMix],
    data() {
        return {
            FastTable: {
                input: 0,
                marks: {
                    '自选不中': 0,
                    '正码特': 0
                },
                tools: ['所有', '大', '小', '单', '双', '合单', '合双', '红波', '蓝波', '绿波', '家禽', '走兽'],
                odds: null,
                odds_b: null
            }
        }
    },
    props: {
        table: {},
        group: {},
        side: {
            default: 0
        },
        type: {
            default: 7
        }
    },
    computed: {
        groupDir() {
            if (this.group) {
                let dir = {}
                if (this.group.length) {
                    for (let key of this.group) {
                        dir[key] = true
                    }
                } else {
                    dir[this.group] = true
                }
                return dir
            }
            return false
        },
        Tools() {
            let type = this.type
            return this.FastTable.tools
        }
    },
    methods: {
        FastInput(name) {
            for (let key of ['odds', 'odds_b']) {
                if (this.FastTable[key] > 0 && this.groupDir) {
                    let targetDir = this.FindTarget(name)
                    for (let index in this.table) {
                        if (this.groupDir[index]) {
                            for (let item of this.table[index]) {
                                if (targetDir[item.name]) {
                                    this.$set(item, key, this.FastTable[key])
                                }
                            }
                        }
                    }
                }
            }
        },
        FindTarget(name) {
            let target = {}
            switch (name) {
                case '所有':
                    for (let code=1;code<=49;code++) {
                        target[code] = true
                    }
                    break;
                case '大':
                    for (let code=1;code<=49;code++) {
                        if (code >= 25) {
                            target[code] = true
                        }
                    }
                    break;
                case '小':
                    for (let code=1;code<=49;code++) {
                        if (code < 25) {
                            target[code] = true
                        }
                    }
                    break;
                case '单':
                    for (let code=1;code<=49;code++) {
                        if (code % 2 == 1) {
                            target[code] = true
                        }
                    }
                    break;
                case '双':
                    for (let code=1;code<=49;code++) {
                        if (code % 2 != 1) {
                            target[code] = true
                        }
                    }
                    break;
                case '合单':
                    for (let code=1;code<=49;code++) {
                        let merge = 0;
                        for (let num of `${code}`) {
                            merge+=Number(num)
                        }
                        if (merge % 2 == 1) {
                            target[code] = true
                        }
                    }
                    break;
                case '合双':
                    for (let code=1;code<=49;code++) {
                        let merge = 0;
                        for (let num of `${code}`) {
                            merge+=Number(num)
                        }
                        if (merge % 2 != 1) {
                            target[code] = true
                        }
                    }
                    break;
                case '红波':
                    for (let code of this.QuickBetList['红波']) {
                        target[code] = true
                    }
                    break;
                case '蓝波':
                    for (let code of this.QuickBetList['蓝波']) {
                        target[code] = true
                    }
                    break;
                case '绿波':
                    for (let code of this.QuickBetList['绿波']) {
                        target[code] = true
                    }
                    break;
                case '家禽':
                    for (let code of this.QuickBetList['家禽']) {
                        target[code] = true
                    }
                    break;
                case '走兽':
                    for (let code of this.QuickBetList['走兽']) {
                        target[code] = true
                    }
                    break;
            }
            return target
        }
    },
    components: {

    }
}
</script>
