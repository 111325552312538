<style lang="scss">
    .MyAccountUserLevelMudul {
        .el-dialog__body {
            padding-top:5px;
        }
        table.table {
            // font-size: 9pt;
            tr td {
                border:1px solid #aaa;
            }
            thead tr td,td.bg-t {background-color:#b0e5f2!important; color:#000!important;}
            a.btn {
                text-decoration:underline; padding:1px 3px;
            }
            thead tr:nth-child(2){
                td {
                    background-color:#e3fff1!important;
                }
            }
        }
    }
</style>
<template>
    <el-dialog class="MyAccountUserLevelMudul" :title="Title" :visible.sync="view" top="5vh" :modal-append-to-body="false">
        <div v-if="form" style="min-height:300px;">
            <div class="l-flex-c o-pb">
                <p class="l-flex-1">帐号：{{ form.username }}</p>
                <span class="o-pl">占成类型：</span>
                <el-select v-model="Filter.games_id" style="width:160px;" size="small" @change="reload()">
                    <el-option v-for="item in Opt.hasDir.game" :key="item.id" :label="item.title" :value="item.id" />
                </el-select>
            </div>
            <table v-loading="main._loading" class="table" border="0" cellspacing="1" cellpadding="0">
                <thead>
                    <tr>
                        <td>帐号</td>
                        <td>级别</td>
                        <td>占成</td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item,index) in list" class="c-text-c">
                        <td>{{ item.name }}</td>
                        <td>
                            <span v-if="item.type == 1">代理</span>
                            <span v-if="item.type == 0">会员</span>
                        </td>
                        <td>{{ item.point }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="c-text-r o-mt">
            <Button type="info" plain @click="Cancel()">确定</Button>
        </div>
    </el-dialog>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.modul.js'
export default {
    name: 'MyTeamUserResetModul',
    mixins: [StoreMix],
    data() {
        return {
            store: 'credit/superior',
            Filter: {
                id: null,
                games_id: 34
            },
            list: []
        }
    },
    methods: {
        init() {
            this.DataReset('list')
            this.Filter.id = this.form.id
            this.Filter.games_id = 34
            this.reload()
        },
        reload() {
            this.Get().then(res => {
                this.$set(this, 'list', res.data.reverse())
            })
        }
    },
    mounted() {

    }
}
</script>
