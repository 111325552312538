import MapKey from '@lottery.2.0/mixin/formal_mapkey'

export default {
    name: 'GameFormalChipin',
    data() {
        return {
            multiple: 1,      // 下注倍数
            mode: 4,          // 模式：元 角 分 厘
            // rebate : 0,        // 反水率
            lock: true,      // 是否锁定倍数

            randomNumber: 1,
            order: [],

            params: {
                order: [],
                chip: 0
            },
            cart: [],

            chipinStep: 1
        }
    },
    computed: {
        // 最高折扣点数
        // max_rebate(){
        //     if(this.User && this.User.max_rebate){
        //         let rebate = this.User.max_rebate
        //         return parseFloat(rebate)
        //     }
        //     return 0
        // },
        modeTitle() {
            let mode = this.mode
            return [null, '厘', '分', '角', '元'][mode]
        },

        // 反水点统计
        // calcuRebate(){

        //     //  不可滑动                  可滑动部分  被占用
        //     // |------------------------|-----------|-----|
        //     let scroll = this.Big(1).sub(this.User.min_odds).toNumber() // 可滑动的长度
        //     let occupy = this.Big(1).sub(this.User.odds_rebate).toNumber() // 被占用的反水点数
        //     let obj = {
        //         scroll,
        //         occupy,
        //         max : this.Big(scroll).sub(occupy).mul(100).round(1,0).toNumber(), // 最终可滑动的最大值
        //     }
        //     return obj
        // },
        // 当前订单统计，最多保留四位小数
        calcuOdds() {
            let data = {
                odds: 0, bonus: 0,
                price: this.Running.remote_data && this.Running.remote_data.default_price ? this.Running.remote_data.default_price : 2
            }
            let {depot_active_id, depot_active, depot} = this.Running
            if (depot_active_id && this.Running.play && this.Running.play.odds) {
                if (this.Running.play.odds[depot_active_id] && this.Running.play.odds[depot_active_id].odds) {
                    data.odds = this.Running.play.odds[depot_active_id].odds
                }
            }
            data.bonus = this.Big(data.odds).mul(1).round(2, 0).toNumber()
            //if(this.Running.play && this.Running.play.odds && this.Running.play.odds[depot_active_id].odds){
            // try{
            //     data.odds = this.Running.play.odds[depot_active_id].odds
            // }catch(e){}
            // .bonus = this.Big(data.odds).mul(data.price).round(2,0).toNumber()

            //}
            return data
        },
        calcuTotal() {
            let obj = {
                chip: 0, // 下了多少注
                total: 0, // 合计多钱钱
                profit: 0, // 预计中奖盈利
                bonus: 0
            }
            // 计算总下注量
            let chip = 0
            for (let item of this.params.order) {
                chip += item.chip
            }
            let price = this.calcuOdds.price //this.Running.remote_data && this.Running.remote_data.default_price ? this.Running.remote_data.default_price : 2 // 每注单价
            let mode = [0.001, 0.01, 0.1, 1][this.mode-1] // 应用模式
            let multiple = this.multiple ? this.multiple : 1 // 应用倍率
            let bonus = this.template ? this.template.odds : 0 // 最大奖金

            //let rebate = this.Big(this.rebate).div(100).add(1).toNumber() // 最终反水率
            let revise = 1 // 反水率修正
            if (chip) {
                obj.chip = chip
                let max_bonus = 0
                // 冲突统计
                let codeType;
                let conflictTotal = {
                    range: 0, // 范围冲突
                    animal: 0, // 龙虎冲突
                    parity: 0, // 奇偶数冲突
                    only: 0, // 唯一冲突
                    arbitrarily: 0 // 任选冲突
                }
                for (let item of this.params.order) {
                    codeType = null
                    if (item.odds) {
                        if (this.Big(item.odds).toNumber() > this.Big(max_bonus).toNumber()) {
                            max_bonus = item.odds
                        }
                        if (item.code == '大' || item.code == '小') {
                            codeType = 'range'
                        } else if (item.code == '龙' || item.code == '虎' || item.code == '和') {
                            codeType = 'animal'
                        } else if (item.code == '单' || item.code == '双') {
                            codeType = 'parity'
                        } else if (item.suffix) {
                            codeType = item.suffix
                        } else {
                            codeType = 'only'
                        }
                    } else if (this.IsArbitrarily()) {
                        codeType = 'arbitrarily'
                    }

                    // 下注总额
                    let total = this.Big(item.chip).mul(mode).mul(multiple).mul(price).round(4, 3).toNumber()
                    obj.total = this.Big(obj.total).add(total).toNumber()

                    // 获取赔率
                    // 奖金计算
                    let odds = this.calcuOdds.odds


                    let conflict = 0; profit;

                    // 盈利 = （最大奖金  x 模式 x ( 平台反水 + 用户反水 )) * 应用倍率 - 下注总额
                    let profit = this.Big(odds).mul(mode).mul(revise).mul(multiple).sub(total).round(4, 3).toNumber()

                    // 多注单多赔率订单计算
                    if (item.odds && codeType) {
                        odds = item.odds
                        if (conflictTotal[codeType] > 0) {
                            if (this.Big(item.odds).toNumber() > this.Big(conflictTotal[codeType]).toNumber()) {
                                profit = this.Big(item.odds).sub(conflictTotal[codeType]).mul(mode).mul(revise).mul(multiple).sub(total).round(4, 3).toNumber()
                            } else {
                                profit = this.Big(total).mul(-1).toNumber()
                            }
                        } else {
                            profit = this.Big(item.odds).mul(mode).mul(revise).mul(multiple).sub(total).round(4, 3).toNumber()
                        }
                        conflictTotal[codeType] = item.odds
                    // 任选冲突计算
                    } else if (this.IsArbitrarily()) {
                        odds = this.calcuOdds.odds
                        if (conflictTotal[codeType] > 0) {
                            profit = this.Big(total).mul(-1).toNumber()
                        } else {
                            profit = this.Big(odds).mul(mode).mul(revise).mul(multiple).sub(total).round(4, 3).toNumber()
                        }
                        conflictTotal[codeType] = odds
                    }

                    obj.profit = this.Big(obj.profit).add(profit).add(conflict).toNumber()
                }
                // 奖金
                obj.bonus += this.Big(max_bonus ? max_bonus : bonus).mul(mode).mul(revise).round(4, 3).toNumber()
            } else {
                obj.bonus = this.Big(bonus).mul(revise).round(4, 3).toNumber()
            }
            return obj
        },
        // 当前购物车统计
        calcuCart() {
            let obj = {
                chip: 0,
                length: this.cart.length,
                total: 0
            }
            for (let item of this.cart) {
                obj.chip += item.chip
                obj.total = this.Big(obj.total).add(item.total).toNumber()
            }
            return obj
        }
    },
    watch: {
        'Running.nav.side'(val) {
            if (val && val.id) {
                this.cancelOrder(0, 999)
                this.ResetOptions()
            }
        },
        'Running.depot_active_id'(val, old) {
            if (val && val != old) {
                this.ResetOptions()
            }
        }
    },
    props: ['template', 'rank'],
    methods: {
        init() {

        },
        Reset() {
            //this.rank = 0
            this.chipinStep = 1
            this.ParamsReset()
        },
        ResetOptions() {
            if (!this.lock) {
                this.multiple = 1
                this.mode = 4
                this.rebate = 0
            }
        },
        IsArbitrarily() {
            let opt = this.Running.template
            if (opt && (opt.algorithm[0] === 'R7' || opt.tpl === 'danshi2' || opt.tpl === 'hezhi2' || opt.tpl === 'renxuan1')) {
                return true
            }
            return false
        },
        LoadParams(params) {
            this.$set(this.$data, 'params', params)
        },
        pickMode(e) {
            this.mode = e
        },
        hasOrder() {
            if (this.calcuTotal.chip > 0) {
                return true
            }
            this.Err('未选择号码')
            return false
        },
        // 确认选号
        checkout() {
            if (this.hasOrder()) {
                let list = this.createOrder()
                for (let item of list) {
                    this.cart.push(item)
                }
                this.Reset()
                this.$parent.$refs['play'].Reset()
                this.chipinStep = 2
            }
        },
        // 快速下注
        quickOrder() {
            if (this.hasOrder()) {
                let list = this.createOrder()
                this.makeOrder(list)
                this.Reset()
                this.$parent.$refs['play'].Reset()
            }
        },
        createOrder(order=this.params.order) {
            let list = []
            let price = this.Running.remote_data.default_price
            let mode_multiple = [0.001, 0.01, 0.1, 1][this.mode-1]
            // let odds = this.User.odds_rebate
            // if(this.rebate){
            //     let rebate = this.Big(this.rebate).div(100).toNumber()
            //     odds = this.Big(odds).sub(rebate).toNumber()
            // }
            if (price) {
                for (let item of order) {
                    let obj = {
                        title: item.fullTitle ? item.fullTitle : `${this.template.title}${item.suffix ? item.suffix : ''}`,
                        mode: this.mode,
                        multiple: this.multiple,
                        mode_multiple,
                        //rebate   : this.rebate,
                        number: item.code,
                        total: this.Big(item.chip).mul(mode_multiple).mul(this.multiple).mul(price).round(4, 3).toNumber(),
                        chip: item.chip,
                        price,
                        // 后端参数
                        games_id: this.Running.id,
                        games_play_group_id: this.Running.depot_active, // this.template.games_play_group_id,
                        games_play_id: item.id ? item.id : this.Running.depot_active_id, // item.id ? item.id : this.template.games_play_id,
                        side: this.Running.nav.side.id,
                        code: item.code,
                        odds: item.odds || item.bonus_prop
                    }
                    if (!obj.odds) {
                        try {
                            obj.odds = this.Running.play.odds[obj.games_play_id].odds
                        } catch (e) {}
                    }
                    if (obj.odds) {
                        list.push(obj)
                    }
                }
                return list
            }
            return []
        },
        cancelOrder(index, num=1) {
            this.cart.splice(index, num)
        },
        makeOrder(list) {
            let target = list ? list : this.cart
            list = []
            if (!target || !target.length) {
                return this.Notify('操作失败', '投注列表不能为空', 'error')
            }
            for (let item of target) {
                list.push({
                    lottery_id: this.Running.expect.now.id, // 当前期数
                    ...item
                })
            }
            this.sendOrder(list).then(res => {
                if (res) {
                    try {
                        this.$parent.$refs['history'].Reload()
                    } catch (err) {

                    }
                    this.$store.dispatch('base/BASE_USER') // 重置余额
                    this.Suc('下注成功')
                }
            })
        },
        // 发布订单
        sendOrder(list) {
            if (this.Running.play.odds_loading) {
                return console.log('赔率未加载完成')
            }
            return this.$store.dispatch('game/POST_ORDER', {
                data: JSON.stringify(list),
                games_id: this.Running.id,
                side: this.Running.nav.side.id
            }).then(res => {
                if (res) {
                    this.chipinStep = 1
                    this.cancelOrder(0, 999)
                }
                return res
            })
        },
        multipleAdd() {
            if (this.Is('int', this.multiple) && this.multiple < 10000000) {
                this.multiple = this.multiple + 1
            }
        },
        multipleReduce() {
            if (this.Is('int', this.multiple) && this.multiple > 1) {
                this.multiple = this.multiple - 1
            }
        },
        RandomOrder(e) {
            if (e) {
                for (let i=0;i<e;i++) {
                    this.orderRandom(e)
                }
                this.chipinStep = 2
            }
        },
        // 随机下注
        orderRandom() {
            let format = this.template.format[0]
            let total  = this.randomNumber
            if (format && total > 0 && total < 100) {
                let list = []
                let repeat = format[0] != 'm' // 是否可以重复选号
                // 生成指定注数
                for (let i=0;i<total;i++) {
                    let balls = this.ShuffleArray(this.$parent.$refs['play'].MakeBallArray()) // 获取一个被打乱的球组
                    let box = []
                    // 递归行与列
                    for (let pack of format.split('|')) {
                        let row = []
                        for (let item of pack.split(',')) {
                            if (repeat) {
                                let index = Math.floor(Math.random()*balls.length)
                                row.push(balls[index])
                            } else {
                                row.push(balls.pop()) // 禁止重复每次取号，此号码都会移除出数组
                            }
                        }
                        box.push(row.join(','))
                    }
                    let order = {
                        chip: 1,
                        code: box.join('|')
                    }
                    // 某些带有子玩法的需要补齐子玩法 ID
                    try {
                        let tplMode = this.template.algorithm[0]
                        let {enableMapping, subset, tpl} = this.template
                        if (tplMode == 'R6' || tplMode == 'R7' || tpl == 'danshi2') {
                            let randomIds = []
                            for (let key in enableMapping) {
                                randomIds.push(enableMapping[key])
                            }
                            let targetId = randomIds[Math.floor(Math.random()*randomIds.length)]
                            for (let item of subset) {
                                if (item.id == targetId) {
                                    order.id = item.id
                                    order.fullTitle = item.name
                                }
                            }
                        }
                    } catch (e) {}
                    console.log(this.template.algorithm)
                    // 加入订单队列
                    list.push(order)
                }
                // 加入购物车

                for (let item of this.createOrder(list)) {
                    this.cart.push(item)
                }
            }
        },
        // rebateAdd(){
        //     if(this.rebate > 0){
        //         //this.$set(this,'rebate',this.Big(this.rebate).sub(0.1).toNumber())
        //         this.rebate = this.Big(this.rebate).sub(0.1).toNumber()
        //     }
        // },
        // rebateSub(){
        //     if(this.rebate < this.calcuRebate.max){
        //         //this.$set(this,'rebate',this.Big(this.rebate).sub(0.1).toNumber())
        //         this.rebate = this.Big(this.rebate).add(0.1).toNumber()
        //     }
        // },

        FollowFinish() {
            this.cancelOrder(0, 999)
            try {
                this.$parent.$refs['history'].Reload()
            } catch (err) {

            }
            this.$store.dispatch('base/BASE_USER') // 重置余额
        },

        // 冷热遗漏
        rankRadio(val) {
            if (this.rank.radio == val) {
                setTimeout(() => {
                    this.$set(this.rank, 'radio', 0)
                    this.$set(this.rank, 'data', null)
                }, 30)
            }
        }
    },
    mounted() {
        this.init()
    }
}