<style lang="scss">
    @import "../../../../style/config.scss";
    .GameHistoryDetails {
        .el-dialog {
            max-width:740px; min-width:740px;
        }
        .content {
            background-color:#f0f0f0; margin:8px 0; min-height:24px; line-height:24px; padding:10px; border-radius:5px;
            .codes {
                min-height:24px; word-wrap:break-word; width:600px;
            }
        }
        .expect, .follow {
            min-height:120px;
        }
        .follow-info {
            background-color:#f0f0f0; margin:8px 0; border-radius:5px; padding:10px;
        }
        .el-dialog__body {
            padding-top:16px;
        }
        .el-form-item--mini.el-form-item {
            margin-bottom:0;
        }
        .c-color-g {color:#aaa;}
    }
</style>
<template>
    <el-dialog class="GameHistoryDetails" title="注单详情" :visible.sync="item.view">

        {{/* 基础数据 */}}
        <el-row :gutter="16">
            <el-col :span="8">
                <el-form ref="form" label-width="80px" label-position="left" size="mini">
                    <el-form-item label="注单编号">{{ order.order_number }}</el-form-item>
                    <el-form-item label="倍数模式">{{ order.mode_multiple }}</el-form-item>
                    <el-form-item label="奖期">{{ order.lottery_number }}</el-form-item>
                    <el-form-item label="投注数量">{{ order.bet_num }}</el-form-item>
                </el-form>
            </el-col>
            <el-col :span="8">
                <el-form ref="form" label-width="80px" label-position="left" size="mini">
                    <el-form-item label="游戏名称">{{ order.title }}</el-form-item>
                    <el-form-item label="投单时间">{{ Time(order.bet_time * 1000) }}</el-form-item>
                    <el-form-item label="注单奖金">
                        <span v-if="supplement.expect">{{ supplement.expect.bonus }}</span>
                    </el-form-item>
                    <el-form-item label="开奖内容">
                        <span v-if="supplement.expect">{{ supplement.expect.lottery_data }}</span>
                    </el-form-item>
                </el-form>
            </el-col>
            <el-col :span="8">
                <el-form ref="form" label-width="80px" label-position="left" size="mini">
                    <el-form-item label="玩法">{{ order.play_name }}</el-form-item>
                    <el-form-item label="总金额">{{ order.amount }}</el-form-item>
                    <el-form-item label="动态返点">{{ order.rebate }}</el-form-item>
                    <el-form-item label="注单状态">
                        <span v-if="order.status == 1" class="c-color-w">已下单</span>
                        <span v-if="order.status == 2" class="c-color-e">已撤单</span>
                        <span v-if="order.status == 3" class="c-color-s">已中奖</span>
                        <span v-if="order.status == 4" class="">未中奖</span>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>

        {{/* 下注号码 */}}
        <div class="content">
            <div class="l-flex-c">
                <div class="o-pr-s">投注内容</div>
                <div class="l-flex-1">
                    <div class="codes u-bl o-pl">{{ order.code }}</div>
                </div>
            </div>
        </div>

        {{/* 详情 */}}
        <div class="o-pt-s o-pb">
            <el-radio-group v-model="tabActive" size="small">
                <el-radio-button label="expect">可能中奖情况</el-radio-button>
                <el-radio-button label="follow" :disabled="!order.follow">追号详情</el-radio-button>
            </el-radio-group>
            <el-button style="float:right;" size="small" type="warning" @click="Append()">再次下注</el-button>
        </div>
        <div class="tab-content u-bt o-pt">
            <div v-if="tabActive == 'expect'" v-loading="!supplement.expect" class="expect">
                <el-table v-if="supplement.expect && supplement.expect.lottery" :data="supplement.expect.lottery" height="240" style="width:100%; font-size:12px;" size="mini">
                    <el-table-column prop="name" label="奖级名称" align="center" />
                    <el-table-column prop="code" label="号码" align="center">
                        <template slot-scope="scope">
                            {{ CheckContextLength(scope.row.code,24) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="multiple" label="倍数" align="center" />
                    <el-table-column prop="level" label="奖级" align="center" />
                    <el-table-column prop="bonus" label="奖金" align="center" />
                    <el-table-column prop="lottery_bonus" label="中奖奖金" align="center" />
                </el-table>
            </div>
            <div v-if="tabActive == 'follow'" v-loading="!supplement.follow" class="follow">
                <el-row :gutter="20">
                    <el-col :span="10">
                        <div class="follow-info">
                            <el-form v-if="supplement.follow" ref="form" label-width="80px" label-position="left" size="mini">
                                <el-form-item label="追号编号">{{ supplement.follow.sn }}</el-form-item>
                                <el-form-item label="开始期号">{{ supplement.follow.start_number }}</el-form-item>
                                <el-form-item label="终止条件">
                                    <p v-if="!supplement.follow.stop_type">无</p>
                                    <p v-else>官方跳开及停</p>
                                    <p v-if="supplement.follow.stop_type == 2">中奖后停止追号</p>
                                </el-form-item>
                                <el-form-item label="追号进度">
                                    <span>{{ supplement.follow.follow_amount_over + supplement.follow.follow_amount_cancel }} / {{ supplement.follow.follow_amount }}</span>
                                    <span v-if="supplement.follow.follow_amount_cancel">(被取消：{{ supplement.follow.follow_amount_cancel }})</span>
                                </el-form-item>
                            </el-form>
                        </div>
                    </el-col>
                    <el-col :span="14">
                        <el-table v-if="supplement.follow && supplement.follow.list" :data="supplement.follow.list" height="240" style="width:100%; font-size:12px;" size="mini">
                            <el-table-column prop="number" label="奖期" align="center" />
                            <el-table-column prop="multiple" label="倍数" align="center" />
                            <el-table-column prop="status" label="状态" align="center" />
                        </el-table>
                    </el-col>
                </el-row>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'GameHistoryDetails',
    data() {
        return {
            //view : false
            tabActive: 'expect',
            supplement: {
                expect: null,
                follow: null
            }
        }
    },
    props: ['item'],
    computed: {
        order() {
            if (this.item && this.item.order) {
                return this.item.order
            }
            return {}
        }
    },
    watch: {
        'item.view'(val) {
            if (val) {
                this.init()
            }
        },
        tabActive(val) {
            if (val == 'follow') {
                this.GetFollow()
            }
        }
    },
    methods: {
        init() {
            this.Reload()
        },
        Reload() {
            this.tabActive = 'expect'
            this.DataReset('supplement')
            // this.GetExpect()
        },
        pickMode(e) {
            this.mode = e
        },
        GetExpect() {
            if (this.order.id) {
                this.Dp('game/GET_EXPECT_BY_ORDER', this.order.id).then(res => {
                    this.$set(this.supplement, 'expect', res)
                })
            }
        },
        GetFollow() {
            this.$set(this.supplement, 'follow', null)
            this.Dp('game/GET_FOLLOW_BY_ORDER', this.order.follow_sn).then(res => {
                this.$set(this.supplement, 'follow', res)
            })
        },
        Append() {
            this.$set(this.item, 'view', false)
            this.$emit('append', this.item.order)
        }
    },
    mounted() {
        this.init()
    }
}
</script>
