<style lang="scss">
    .CreditSixAgent {
        table tr td {padding-left:8px!important; padding-right:8px!important;}
        .row {
            background-color:#fff; padding-bottom:4px;
        }
        .row-title {
            background-color:#7baaff; color:#fff; padding:4px 10px;
        }
        input {
            padding:2px 4px;
        }
        button {
            cursor:pointer;
        }
    }
</style>
<template>
    <div class="CreditSix CreditSixAgent">
        <el-row :gutter="20">
            <el-col :span="6">
                <div class="o-plr">
                    <div class="u-bl u-br u-bb">
                        <div class="row">
                            <p class="row-title">代理账户</p>
                            <h6 class="c-line-16 o-plr">{{ User.username }}</h6>
                        </div>
                        <div class="row">
                            <p class="row-title">信用额度</p>
                            <h4 class="c-line-20 c-color-t o-plr">{{ CreditInfo.balance ? Price(CreditInfo.balance) : 0 }}</h4>
                        </div>
                        <div class="row">
                            <p class="row-title">代理设置</p>
                            <div class="o-plr">
                                <div class="o-ptb ">
                                    <button @click="Edit()">修改密码</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="18">
                <el-select v-model="Filter.games_id" size="small" style="width:120px;" @change="TypeChange">
                    <el-option v-for="(item, index) in Opt.hasDir.game" :key="index" :label="item.title" :value="item.id"></el-option>
                </el-select>
                <table v-loading="main._loading" class="table line u-bb u-br u-bl" border="0" cellspacing="1" cellpadding="0">
                    <thead>
                        <tr style="text-align:left;">
                            <td style="text-align:left;">项目</td>
                            <td style="text-align:left;">A盘退水</td>
                            <td style="text-align:left;">B盘退水</td>
                            <td style="text-align:left;">C盘退水</td>
                            <td style="text-align:left;">D盘退水</td>
                            <td style="text-align:left;">E盘退水</td>
                            <td style="text-align:left;">单注限额</td>
                            <td style="text-align:left;">单项限额</td>
                        </tr>
                    </thead>
                    <tbody style="text-align:left;">
                        <tr v-for="(item,index) in table.list">
                            <td>{{ item.name }}</td>
                            <td>{{ item.backwater_a }}</td>
                            <td>{{ item.backwater_b }}</td>
                            <td>{{ item.backwater_c }}</td>
                            <td>{{ item.backwater_d }}</td>
                            <td>{{ item.backwater_e }}</td>
                            <td>{{ Price(item.order_max_amount) }}</td>
                            <td>{{ Price(item.bets_limit_amount) }}</td>
                        </tr>
                    </tbody>
                </table>
            </el-col>
        </el-row>
        <editer v-model="Editer.view" :title="Editer.title" :form="Editer.form" />
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
import Editer from '@lottery.2.0/page/my/pages/account/modul/password'
export default {
    name: 'CreditSixAgent',
    mixins: [StoreMix],
    data() {
        return {
            store: 'myAccount/gamesgroup',
            Filter: {
                games_id: 31
            },
            table: {
                length: 1,
                list: [],
                fast: [null]
            },
            Params: {}
        }
    },
    methods: {
        init() {
            this.load()
            this.loadData()
        },
        load() {
            this.DataReset('table')
            this.Get().then(res => {
                if (res) {
                    let list = []
                    for (let item of res.data.data) {
                        list.push(item)
                    }
                    this.$set(this.table, 'list', list)
                }
            })
        },
        loadData() {
            for (let key of ['ssc', 'ffc', 'klc', 'pks', 'dpc', 'lhc']) {
                this.$set(this.Params, `${key}_proportion`, this.User[`${key}_proportion`])
                this.$set(this.Params, `${key}_transfer_rebate`, this.User[`${key}_transfer_rebate`])
            }
        },
        TypeChange(val) {
            this.$set(this.Filter)
            this.load()
        }
    },
    components: {
        Editer
    },
    activated() {
        if (!this.Opt.isTeam) {
            this.Rd('credit')
        }
        this.init()
    }
}
</script>
