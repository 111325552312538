<style lang="scss">
    @import "../../../../style/config.scss";
    .CreditSystemOddsbase {
        a.nav {
            padding:5px 10px; background-color:rgba(195,211,247,.5); text-decoration:underline;
            &:hover {
                color:#333; background-color:rgba(#e400ff,.2);
            }
            &.active {
                background-color:rgba(#e400ff,.5); color:#fff;
            }
        }
        .tabs {
            margin-bottom:12px;
            .tab {
                display:inline-block; width:78px; height:24px; line-height:24px; background-color:#fff; color:#333; text-align:center; cursor:pointer;
                &:hover {
                    background-color:#bfcff7;
                }
                &.active {
                    background-color:#5a79c6; color:#fff;
                }
            }
        }
    }
</style>
<template>
    <div v-loading="nav.editing" class="CreditSystemOddsbase">
        <div class="ctrl l-flex-c">
            <span>游戏：</span>
            <el-select v-model="Filter.games_id" placeholder="请选择游戏" style="width:120px;" size="small" @change="Reset($event)">
                <el-option v-for="(item,index) in Opt.hasDir.game" :key="item.id" :label="item.title" :value="item.id" />
            </el-select>
            <!-- <el-cascader v-model="Filter.games_id" :show-all-levels="false" :props="{emitPath:false}" :options="GamesSelector"></el-cascader> -->
            <span class="o-pl">盘类：</span>
            <el-select v-model="nav.side" style="width:80px;" size="small" @change="Load(null,index)">
                <el-option v-for="(item,index) in ['A盘','B盘','C盘','D盘','E盘']" :key="index" :label="item" :value="index">{{ item }}</el-option>
            </el-select>
            <span class="o-plr-l" />
        </div>
        <div class="l-flex-c o-pt">
            <a v-for="(item,index) in nav.list" class="nav" :class="{'active': nav.item === item.id}" @click="Load(item)">{{ item.name }}</a>
        </div>
        <div v-loading="main._loading || nav.loading" class="o-pt o-pb-l" style="min-height:200px;">
            <div v-show="!main._loading && !nav.loading">
                <component :is="'Tql' + ActiveGame.type" v-if="ActiveGame && ActiveGame.type" :nav="nav" :tabs="tabs" :table="Table" />
            </div>
            <div class="o-mt">
                <Button @click="Submit()">确定修改</Button>
                <Button @click="Load()">重读</Button>
            </div>
        </div>
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
import SixMix from '@lottery.admin.2.0/mixin/six.js'
import Tql1 from './components/odds-tql-1'
import Tql2 from './components/odds-tql-2'
import Tql3 from './components/odds-tql-1'
import Tql4 from './components/odds-tql-4'
import Tql5 from './components/odds-tql-5'
import Tql6 from './components/odds-tql-6'
import Tql7 from './components/odds-tql-7'
export default {
    name: 'CreditSystemOddsbase',
    mixins: [StoreMix, SixMix],
    data() {
        return {
            store: 'admin/oddsbase',
            Filter: {
                games_id: 31
            },
            nav: {
                side: 0,
                active: {},
                item: null,
                list: [],
                table: [],
                group: 0,
                loading: false,
                editing: false
            }
        }
    },
    computed: {
        Table() {
            let obj = {}
            for (let item of this.nav.table) {
                if (!obj[item.group_mark]) {
                    obj[item.group_mark] = []
                }
                obj[item.group_mark].push(item)
            }
            return obj
        },
        // 被选定的游戏
        ActiveGame() {
            let id = this.Filter.games_id
            for (let item of this.$store.state.game.games._list) {
                if (item.id == id) {
                    return item
                }
            }
            return null
        },
        tabs() {
            if (this.ActiveGame) {
                let model = this.ActiveGame.model
                let dir = {
                    '7': {
                        '正码特': {'正码一': 0, '正码二': 1, '正码三': 2, '正码四': 3, '正码五': 4, '正码六': 5},
                        '自选不中': {'五不中': 51, '六不中': 62, '七不中': 73, '八不中': 84, '九不中': 95, '十不中': 106, '十一不中': 117, '十二不中': 128}
                    }
                }
                if (model && dir[model]) {
                    return dir[model]
                }
            }
            return {}
        }
    },
    methods: {
        init(e) {
            this.Reset(this.Running.id)
        },
        Reset(id) {
            this.$set(this.Filter, 'games_id', id)
            this.Get().then(res => {
                this.$set(this.nav, 'list', this.Origin(res.data))
                this.Load(res.data[0])
            })
        },
        Load(item, side) {
            if (item) {
                this.$set(this.nav, 'item', item.id)
                this.$set(this.nav, 'active', item)
                if (item.name === '正码特') {
                    this.nav.group = 0
                }
                if (item.name === '自选不中') {
                    this.nav.group = 51
                }
            }
            if (side >=0) {
                this.$set(this.nav, 'side', side)
            }
            let id = this.nav.item
            this.nav.loading = true
            this.nav.editing = false
            this.Dp('admin/GET_ODDSBASE', {
                id,
                params: {
                    side: this.nav.side,
                    games_id: this.Filter.games_id
                }
            }).then(res => {
                this.nav.loading = false
                if (id === this.nav.item) {
                    this.$set(this.nav, 'table', res.data)
                    for (let item of this.nav.table) {
                        this.$set(item, 'odds', item.odds ? parseFloat(item.odds) : 0)
                        this.$set(item, '_odds', item.odds)
                        this.$set(item, 'odds_b', item.odds_b ? parseFloat(item.odds_b) : 0)
                        this.$set(item, '_odds_b', item.odds_b)
                        this.$set(item, 'max_odds', item.max_odds ? parseFloat(item.max_odds) : 0)
                        this.$set(item, '_max_odds', item.max_odds)
                        this.$set(item, 'max_odds_b', item.max_odds_b ? parseFloat(item.max_odds_b) : 0)
                        this.$set(item, '_max_odds_b', item.max_odds_b)
                    }
                }
            })
        },
        Submit() {
            let data = []
            let mark = null
            let name = this.nav.active.name
            let count = 0
            if (this.tabs[name]) {
                mark = this.nav.group
            }
            for (let item of this.nav.table) {
                if (mark === null || mark == item.group_mark) {
                    if (item.odds != item._odds || item.odds_b != item._odds_b || item.max_odds != item._max_odds || item.max_odds_b != item._max_odds_b) {
                        count++
                        data.push({
                            id: item.id,
                            odds: item.odds,
                            odds_b: item.odds_b,
                            max_odds: item.max_odds,
                            max_odds_b: item.max_odds_b
                        })
                    }
                }
            }
            this.nav.editing = true
            this.Dp('admin/_POST_ODDSBASE', {
                data: {
                    games_id: this.Filter.games_id,
                    data: JSON.stringify(data),
                    side: this.nav.side
                }
            }).then(res => {
                this.Suc('提交成功')
                this.Load()
            })
        }
    },
    components: {
        Tql1, Tql2, Tql3, Tql4, Tql5, Tql6, Tql7
    },
    mounted() {
        this.init()
    }
}
</script>
