
import Formal from '../../../../../mixin/formal'
export default {
    name: 'GameFormalDanshi1',
    mixins: [Formal],
    data() {
        return {
            files: null,
            content: ''
        }
    },
    computed: {
        contentFilter() {
            return this.DanShiFilter(this.content)
        }
    },
    watch: {
        content(val) {
            if (val) {
                this.$emit('change', this.MakeOrder())
            }
        }
    },
    props: ['template', 'rank'],
    methods: {
        init() {
            this.Reset()
        },
        Reset() {
            this.content = ''
        },
        Clean() {
            this.Reset()
        },
        Pick() {
            this.$refs.file.click()
        }
    },
    components: {

    },
    mounted() {
        this.init()
    }
}
