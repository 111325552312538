<style lang="scss">
    .GameFormalNum3 {
        .row {
            .balls, .tools {
                .ball {
                    width:82px; height:30px; line-height:30px; margin-top:5px; margin-right:14px;
                }
                .odds {
                    position:relative; top:35px;
                }
                img,span {
                    display:block; width:100%; height:100%; position:absolute; top:0; right:0; left:0; bottom:0; z-index:1;
                }
                span {z-index:2; font-size:14px;}
            }
        }
    }
</style>
<template>
    <div class="GameFormalPlay GameFormalNum3">
        <ul v-if="params" class="box">
            <li v-for="(row,unit) in MakeBallDir" class="row l-flex l-flex-1">
                <div class="title">
                    <p>{{ row.title }}</p>
                </div>
                {{/* 选球组 */}}
                <div class="balls">
                    <!--                     <li class="ball" :class="{'active':params[unit] &&params[unit][index]}" v-for="(ball,index) in row.balls" @click="Pick(unit,index)">
                        <p class="border">{{ ball.title }}</p>
                        <p class="odds" v-if="ball.odds">{{ applyOdds(ball.odds) }}</p>
                    </li> -->
                    <li v-for="(ball,index) in row.balls" class="ball" :class="{'active': params[unit] && params[unit][index]}" @click="Pick(unit,index)">
                        <span style="letter-spacing:1px;">{{ ball.title }}</span>
                        <p v-if="ball.odds" class="odds">{{ applyOdds(ball.odds) }}</p>
                    </li>
                </div>
                <div class="tools">
                    <li v-for="(item,index) in ['全','清']" class="ball" @click="Tools(unit,item)">
                        <p class="border">{{ item }}</p>
                    </li>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import Common from './num3.js'
export default {
    mixins: [Common],
    components: {

    }
}
</script>
