import {
    mapState,
    mapGetters,
    mapActions,
    mapMutations
} from 'vuex'

export default function (store, model, action = [], mutations = []) {
    let STORE = store.toUpperCase();
    let MODEL = model.toUpperCase();
    let funs = {
        '_GET_MAIN': `_GET_${MODEL}`,
        '_POST_MAIN': `_POST_${MODEL}`,
        '_PUT_MAIN': `_PUT_${MODEL}`,
        '_DELETE_MAIN': `_DELETE_${MODEL}`
    };
    let mut = {
        '_ADD': `_${STORE}_ADD`,
        '_UPDATE': `_${STORE}_UPDATE`
    };
    return {
        ...mapActions(store, funs),
        ...mapActions(store, action),
        ...mapMutations(store, mut),
        ...mapMutations(store, mutations)
    }
}
