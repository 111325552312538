<style lang="scss">
    @import "../../../../style/config.scss";
    .CreditSixSubsidiary {
        .tabs {
            border-bottom:1px solid #aaa; position:relative; height:1.8rem; line-height:1.8rem; width:100%; overflow:hidden; color:#999;
            li {
                position:relative; padding:0 .6rem; bottom:2px; border-bottom:3px solid $color-n; float:left; font-size:.7rem; cursor:pointer;
                &.active{
                    border-color:$color-t; font-weight:bold; color:#333;
                }
            }
        }
        .el-table__expanded-cell[class*=cell]{
            padding:14px 40px 8px 40px;
            h4 {font-size:19px;}
        }
        .el-progress-bar__outer {
            background-color:#dbdde2;
        }
        table.table-light {
            tr td.mark, tr td[mark=mark] {
                color:red!important;
            }
        }
    }
</style>
<template>
    <div class="CreditSix CreditSixSubsidiary">
        <div class="ctrl o-mb">
            <div ref="table">
                <table v-loading="main._loading" class="table-light" border="0" cellspacing="1" cellpadding="0" style="border-collapse:collapse;">
                    <thead>
                        <tr style="text-align:center;">
                            <!-- <td v-if="!Filter.games_type"><div class="table-row-2">类型</div></td> -->
                            <td><div class="table-row-2">游戏</div></td>
                            <td><div class="table-row-2">名称</div></td>
                            <td><div class="table-row-2">下单数</div></td>
                            <td><div class="table-row-2">下注金额</div></td>
                            <td><div class="table-row-2">会员结果</div></td>
                            <td><div class="table-row-2">代理结果</div></td>

                            <td><div class="table-row-2">走飞金额</div></td>
                            <td><div class="table-row-2">走飞结果</div></td>

                            <td><div class="table-row-2">代理合计</div></td>
                            <td><div class="table-row-2">自己走飞</div></td>
                        </tr>
                    </thead>
                    <tbody style="text-align:left;">
                        <tr v-for="(item,index) in main._list">
                            <!-- <td v-if="!Filter.games_type" style="text-align:left;">{{ Opt.typeById[item.games_type].title }}</td> -->
                            <td style="text-align:left;">{{ item.games }}</td>
                            <td style="text-align:left;">{{ item.name }}</td>
                            <td style="text-align:left;">{{ item.bets_num }}</td>
                            <td style="text-align:left;" :class="{'mark': item.bets_amount < 0}">
                                <a class="btn" @click="Edit(item,0)">{{ item.bets_amount }}</a>
                            </td>
                            <td style="text-align:left;" :class="{'mark': item.members_result < 0}">{{ item.members_result }}</td>
                            <td style="text-align:left;" :class="{'mark': item.js_result < 0}">{{ item.js_result }}</td>

                            <!--                             <td style="text-align:left;"><a class="c-color-t" @click="Edit(scope.row,'EditerTransfer')"><b>{{ item.out_transfer_profit_loss }}</b></a></td>
                            <td style="text-align:left;">{{ item.out_transfer_amount }}</td> -->
                            <td style="text-align:left;">
                                <a class="btn" :class="{'mark': item.transfer_amount < 0}" @click="Edit(item,1)">{{ item.transfer_amount }}</a>
                            </td>
                            <td style="text-align:left;" :class="{'mark': item.transfer_result < 0}">{{ item.transfer_result }}</td>

                            <td style="text-align:left;" :class="{'mark': item.total_result < 0}">{{ item.total_result }}</td>
                            <td style="text-align:left;" :class="{'mark': item.out_transfer < 0}">{{ item.out_transfer }}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr v-if="main._empty">
                            <td class="empty" colspan="100">暂无数据</td>
                        </tr>
                        <tr v-if="!main._empty" class="subtotal">
                            <td style="text-align:left;">合计</td>
                            <td style="text-align:left;"></td>
                            <td style="text-align:left;">{{ CalcuRow('bets_num') }}</td>
                            <td style="text-align:left;" :class="{'mark': CalcuRow('bets_amount') < 0}">{{ CalcuRow('bets_amount') }}</td>
                            <td style="text-align:left;" :class="{'mark': CalcuRow('members_result') < 0}">{{ CalcuRow('members_result') }}</td>
                            <td style="text-align:left;" :class="{'mark': CalcuRow('js_result') < 0}">{{ CalcuRow('js_result') }}</td>

                            <td style="text-align:left;" :class="{'mark': CalcuRow('transfer_amount') < 0}">{{ CalcuRow('transfer_amount') }}</td>
                            <td style="text-align:left;" :class="{'mark': CalcuRow('transfer_result') < 0}">{{ CalcuRow('transfer_result') }}</td>

                            <td style="text-align:left;" :class="{'mark': CalcuRow('total_result') < 0}">{{ CalcuRow('total_result') }}</td>
                            <td style="text-align:left;" :class="{'mark': CalcuRow('out_transfer') < 0}">{{ CalcuRow('out_transfer') }}</td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
        <editer v-model="Editer.view" title="注单详情" :form="Editer.form" />
        <!-- <EditerTransfer v-model="EditerTransfer.view" title="收飞占成额详情" :form="EditerTransfer.form"></EditerTransfer> -->
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
import Editer from './modul/amount'
// import EditerTransfer from './modul/subsidiary-transfer'
export default {
    name: 'CreditSixSubsidiary',
    mixins: [StoreMix],
    data() {
        return {
            store: 'credit/subsidiary',
            Params: {},
            Filter: {
                games_type: null,
                per_page: 999,
                uid: null
            },
            EditerTransfer: {
                view: false,
                form: null
            }
        }
    },
    props: ['form', 'size'],
    methods: {
        init() {
            this.DataReset('Filter')
            this.Filter.uid = this.User.id
            if (this.form && typeof this.form === 'object') {
                for (let key in this.form) {
                    if (this.form[key] || this.form[key]==0) {
                        this.$set(this.Filter, key, this.form[key])
                    }
                }
            }
            this.Clean()
            this.Get(1)
        },
        CalcuNumber(number) {
            try {
                return this.Big(number).mul(100).round(2, 0).toNumber()
            } catch (err) {
                return 0
            }
        },
        Edit(item, transfer) {
            this.Editer.view = true
            this.Editer.form = {
                uid: this.User.id,
                pid: this.User.id,
                games_type: item.games_type,
                interval: this.Filter.interval,
                lottery_id: this.Filter.lottery_id,
                mark_id: item.id,
                status: this.Filter.status,
                transfer
            }
        },
        Print() {
            this.PrintTable()
        }
    },
    components: {
        Editer
        //EditerTransfer,
    },
    mounted() {
        this.init()
    }
}
</script>
