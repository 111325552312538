<style lang="scss">
    .GameFormalNum8 {
        .row {
            .balls, .tools {
                float:none;
                .ball {
                    margin:0 auto; margin-top:0; margin-bottom:0; display:block;
                }
            }
            .odds{
                font-size:13px;
            }
        }
    }
</style>
<template>
    <div class="GameFormalPlay GameFormalNum8">
        <ul v-if="params" class="box">
            <li v-for="(row,unit) in MakeBallDir" class="row l-flex-1">
                <div class="title">
                    <p>{{ row.title }}</p>
                </div>

                {{/* 选球组 */}}
                <div class="balls l-flex">
                    <div v-for="(ball,index) in row.balls" style="width:72px; text-align: center;">
                        <li class="ball" :class="{'active': params[unit] && params[unit][index]}" @click="Pick(unit,index)">
                            <span>{{ ball.title }}</span>
                        </li>
                        <p v-if="ball.odds" class="odds">{{ applyOdds(ball.odds) }}</p>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import Common from './num8.js'
export default {
    mixins: [Common],
    components: {

    }
}
</script>
