<style lang="scss">
    @import "../../../../../style/config.scss";
    .OddsGroupTql1 {

    }
</style>
<template>
    <div class="OddsGroupTql1">
        <div v-if="nav.active.name === '龙虎斗'">
            <div class="l-flex o-mt">
                <group class="u-bb u-br" :list="Table[1]" :length="3" title="万千 第一球VS第二球" />
                <group class="u-bb u-br" :list="Table[2]" :length="3" title="万百 第一球VS第三球" />
                <group class="u-bb u-br" :list="Table[3]" :length="3" title="万十 第一球VS第四球" />
                <group class="u-bb u-br" :list="Table[4]" :length="3" title="万个 第一球VS第五球" />
            </div>
            <div class="l-flex o-mt">
                <group class="u-bb u-br" :list="Table[5]" :length="3" title="千百 第二球VS第三球" />
                <group class="u-bb u-br" :list="Table[6]" :length="3" title="千十 第二球VS第四球" />
                <group class="u-bb u-br" :list="Table[7]" :length="3" title="千个 第二球VS第五球" />
            </div>
            <div class="l-flex o-mt">
                <group class="u-bb u-br" :list="Table[8]" :length="3" title="百十 第三球VS第四球" />
                <group class="u-bb u-br" :list="Table[9]" :length="3" title="百个 第三球VS第五球" />
            </div>
            <div class="l-flex o-mt">
                <group class="u-bb u-br" :list="Table[10]" :length="3" title="十个 第四球VS第五球" />
            </div>
        </div>
        <div v-else-if="nav.active.name === '全5中1'">
            <div class="l-flex o-mt">
                <group class="u-bb u-br" size="big" :list="Table[1]" :length="5" title="全5中1" :color="false" />
            </div>
        </div>
        <div v-else-if="nav.active.name === '整合'">
            <div class="l-flex">
                <group class="u-bb u-br" size="big" :list="Table[1]" :length="1" title="第一球" :color="false" />
                <group class="u-bb u-br" size="big" :list="Table[4]" :length="1" title="第二球" :color="false" />
                <group class="u-bb u-br" size="big" :list="Table[7]" :length="1" title="第三球" :color="false" />
                <group class="u-bb u-br" size="big" :list="Table[10]" :length="1" title="第四球" :color="false" />
                <group class="u-bb u-br" size="big" :list="Table[13]" :length="1" title="第五球" :color="false" />
                <div>
                    <group class="u-bb u-br" size="big" :list="Table[16]" :length="1" title="综合大小" :color="false" />
                    <group class="u-bb u-br" size="big" :list="Table[17]" :length="1" title="综合单双" :color="false" />
                </div>
            </div>
            <div class="l-flex o-mt-s">
                <group class="u-bb u-br" size="big" :list="Table[2]" :length="1" title="第一球大小" :color="false" />
                <group class="u-bb u-br" size="big" :list="Table[5]" :length="1" title="第二球大小" :color="false" />
                <group class="u-bb u-br" size="big" :list="Table[8]" :length="1" title="第三球大小" :color="false" />
                <group class="u-bb u-br" size="big" :list="Table[11]" :length="1" title="第四球大小" :color="false" />
                <group class="u-bb u-br" size="big" :list="Table[14]" :length="1" title="第五球大小" :color="false" />

            </div>
            <div class="l-flex o-mt-s">
                <group class="u-bb u-br" size="big" :list="Table[3]" :length="1" title="第一球单双" :color="false" />
                <group class="u-bb u-br" size="big" :list="Table[6]" :length="1" title="第二球单双" :color="false" />
                <group class="u-bb u-br" size="big" :list="Table[9]" :length="1" title="第三球单双" :color="false" />
                <group class="u-bb u-br" size="big" :list="Table[12]" :length="1" title="第四球单双" :color="false" />
                <group class="u-bb u-br" size="big" :list="Table[15]" :length="1" title="第五球单双" :color="false" />
            </div>
            <group class="u-bb u-br o-mt-s" size="big" :list="Table[18]" :length="5" title="前三" :color="false" />
            <group class="u-bb u-br o-mt-s" size="big" :list="Table[19]" :length="5" title="中三" :color="false" />
            <group class="u-bb u-br o-mt-s" size="big" :list="Table[20]" :length="5" title="后三" :color="false" />
        </div>
        <div v-else-if="nav.active.name === '定位胆'">
            <div class="l-flex o-mt">
                <group class="u-bb u-br" size="big" :list="Table[1]" :length="1" title="定位胆万位" :color="false" />
                <group class="u-bb u-br" size="big" :list="Table[2]" :length="1" title="定位胆千位" :color="false" />
                <group class="u-bb u-br" size="big" :list="Table[3]" :length="1" title="定位胆百位" :color="false" />
                <group class="u-bb u-br" size="big" :list="Table[4]" :length="1" title="定位胆十位" :color="false" />
                <group class="u-bb u-br" size="big" :list="Table[4]" :length="1" title="定位胆个位" :color="false" />
            </div>
        </div>
        <div v-else-if="nav.active.name === '不定胆'">
            <div class="l-flex o-mt">
                <group class="u-bb u-br" size="big" :list="Table[1]" :length="1" title="后三一码不定胆" :color="false" />
                <group class="u-bb u-br" size="big" :list="Table[2]" :length="1" title="后三二码不定胆" :color="false" />
                <group class="u-bb u-br" size="big" :list="Table[3]" :length="1" title="前三一码不定胆" :color="false" />
                <group class="u-bb u-br" size="big" :list="Table[4]" :length="1" title="前三二码不定胆" :color="false" />
            </div>
        </div>
        <div v-else-if="nav.active.name === '趣味'">
            <div class="l-flex o-mt">
                <group class="u-bb u-br" size="big" :list="Table[1]" :length="1" title="一帆风顺" :color="false" />
                <group class="u-bb u-br" size="big" :list="Table[2]" :length="1" title="好事成双" :color="false" />
                <group class="u-bb u-br" size="big" :list="Table[3]" :length="1" title="三星报喜" :color="false" />
                <group class="u-bb u-br" size="big" :list="Table[4]" :length="1" title="四季发财" :color="false" />
            </div>
        </div>
        <div v-else-if="nav.active.name === '任选二'">
            <group class="u-bb u-br" size="big" :list="Table[1]" :length="4" title="任选二直选复式" :color="false" />
            <group class="u-bb u-br o-mt-s" size="big" :list="Table[2]" :length="4" title="任选二直选单式" :color="false" />
            <group class="u-bb u-br o-mt-s" size="big" :list="Table[3]" :length="4" title="任选二直选和值" :color="false" />
            <group class="u-bb u-br o-mt-s" size="big" :list="Table[4]" :length="4" title="任选二组选复式" :color="false" />
            <group class="u-bb u-br o-mt-s" size="big" :list="Table[5]" :length="4" title="任选二组选单式" :color="false" />
            <group class="u-bb u-br o-mt-s" size="big" :list="Table[6]" :length="4" title="任选二组选和值" :color="false" />
        </div>
        <div v-else-if="nav.active.name === '任选三'">
            <group class="u-bb u-br o-mt-s" size="big" :list="Table[1]" :length="4" title="任选三直选复式" :color="false" />
            <group class="u-bb u-br o-mt-s" size="big" :list="Table[2]" :length="4" title="任选三直选单式" :color="false" />
            <group class="u-bb u-br o-mt-s" size="big" :list="Table[3]" :length="4" title="任选三直选和值" :color="false" />
            <group class="u-bb u-br o-mt-s" size="big" :list="Table[4]" :length="4" title="任选三组选" :color="false" />
            <group class="u-bb u-br o-mt-s" size="big" :list="Table[5]" :length="4" title="任选三组选和值" :color="false" />
        </div>
        <div v-else-if="nav.active.name === '任选四'">
            <group class="u-bb u-br" size="big" :list="Table[1]" :length="4" title="任选四直选复式" :color="false" />
            <group class="u-bb u-br o-mt-s" size="big" :list="Table[2]" :length="4" title="任选四直选单式" :color="false" />
            <div class="l-flex o-mt">
                <group class="u-bb u-br" size="big" :list="Table[3]" :length="1" title="任选四组选4" :color="false" />
                <group class="u-bb u-br o-mt-s" size="big" :list="Table[4]" :length="1" title="任选四组选6" :color="false" />
                <group class="u-bb u-br o-mt-s" size="big" :list="Table[5]" :length="1" title="任选四组选12" :color="false" />
                <group class="u-bb u-br o-mt-s" size="big" :list="Table[6]" :length="1" title="任选四组选24" :color="false" />
            </div>
        </div>
        <div v-else-if="nav.active.name === '龙虎'">
            <div v-for="(pack,unit) in ['万千','万百','万十','万个','千百','千十','千个','百十','百个','十个']" class="l-flex o-mb-s">
                <group class="u-bb u-br" size="big" :list="Table[(unit * 3) + 1]" :length="3" :title="pack + '龙虎'" :color="false" />
                <group class="u-bb u-br" size="big" :list="Table[(unit * 3) + 2]" :length="2" :title="pack + '和值大小'" :color="false" />
                <group class="u-bb u-br" size="big" :list="Table[(unit * 3) + 3]" :length="2" :title="pack + '和值单双'" :color="false" />
            </div>
        </div>
        <div v-else>
            <group class="u-bb u-br" size="big" :list="TableAll" :length="5" :title="nav.active.name" :color="false" />
        </div>
    </div>
</template>

<script>
import Group from './odds-group'
import Fast from './odds-fast'
export default {
    name: 'OddsGroupTql1',
    mixins: [],
    data() {
        return {

        }
    },
    props: {
        nav: {},
        Table: {},
        tabs: {}
    },
    computed: {
        TableAll() {
            let list = []
            for (let unit in this.Table) {
                for (let item of this.Table[unit]) {
                    list.push(item)
                }
            }
            return list
        }
    },
    methods: {

    },
    components: {
        Group, Fast
    }
}
</script>
