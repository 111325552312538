<style lang="scss">
    .GameFormalNum3_1 {
        .row {
            .balls, .tools {
                float:left;
                .ball {
                    width:58px; height:30px; line-height:30px; margin-top:5px; margin-right:14px;
                    position:relative; display:inline-block; cursor:pointer;
                    .border {
                        width:30px; height:30px; line-height:30px; margin:0 auto;
                    }
                }
            }
            .tools {
                span {font-weight:bold; font-size:15px;}
                .ball {
                    width:38px;
                    .border {
                        img {width:30px; height:30px; left:0;}
                    }
                }
            }
        }
    }
</style>
<template>
    <div class="GameFormalPlay GameFormalNum3_1">
        <ul v-if="params" class="box">
            <li v-for="(row,unit) in MakeBallDir" class="row l-flex l-flex-1">
                <div class="title">
                    <p>{{ row.title }}</p>
                </div>
                {{/* 选球组 */}}
                <div class="balls">
                    <li v-for="(ball,index) in row.balls" class="ball" :class="{'active': params[unit] && params[unit][index]}" @click="Pick(unit,index)">
                        <div class="border">
                            <span>{{ ball.title }}</span>
                        </div>
                        <p v-if="ball.odds" class="odds">{{ applyOdds(ball.odds) }}</p>
                    </li>
                </div>
                <div class="tools">
                    <li v-for="(item,index) in ['全','大','小','单','双','清']" class="ball" @click="Tools(unit,item)">
                        <div class="border">
                            <span>{{ item }}</span>
                        </div>
                    </li>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import Common from './num3_1.js'
export default {
    mixins: [Common],
    components: {

    }
}
</script>
