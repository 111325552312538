<style lang="scss">
    @import "../style/config.scss";
    .DatePicker {

    }
</style>
<template>
    <span class="DatePicker">
        <slot>
            <span>时间筛选：</span>
        </slot>
        <el-date-picker v-model="start" type="date" placeholder="选择日期" :value-format="format" :size="size" style="width:140px;" @change="StartChange()" />
        <span style="padding:0 2px;">至</span>
        <el-date-picker v-model="end" type="date" placeholder="选择日期" :value-format="format" :size="size" style="width:140px;" @change="EndChange()" />
    </span>
</template>

<script>
export default {
    name: 'DatePicker',
    data() {
        return {
            start: null,
            end: null
        }
    },
    props: {
        value: {},
        size: {
            default: 'small'
        },
        format: {
            default: 'yyyy-MM-dd'
        }
    },
    watch: {
        value(val) {
            this.Input()
        }
    },
    methods: {
        init() {
            this.Input()
        },
        Input() {
            if (this.value) {
                if (this.value[0] && this.value[1]) {
                    this.$set(this, 'start', this.Time(this.value[0], this.format))
                    this.$set(this, 'end', this.value[1])
                } else if (this.value[0]) {
                    this.$set(this, 'start', this.value[0])
                    this.$set(this, 'end', this.value[0])
                } else if (this.value[1]) {
                    this.$set(this, 'start', this.value[1])
                    this.$set(this, 'end', this.value[1])
                }
            }
        },
        StartChange() {
            if (this.start >= this.end) {
                this.$set(this, 'end', this.start)
            }
            this.$emit('input', [this.start, this.end])
            this.$emit('change', [this.start, this.end])
        },
        EndChange() {
            if (this.start >= this.end) {
                this.$set(this, 'start', this.end)
            }
            this.$emit('input', [this.start, this.end])
            this.$emit('change', [this.start, this.end])
        }
    },
    mounted() {
        this.init()
    }
}
</script>