<style lang="scss">
    .CreditSixMember {
        table tr td {padding-left:8px!important; padding-right:8px!important;}
        .row {
            background-color:#fff; padding-bottom:4px;
        }
        .row-title {
            background-color:#7baaff; color:#fff; padding:4px 10px;
        }
        input {
            padding:2px 4px;
        }
        button {
            cursor:pointer;
        }
    }
</style>
<template>
    <div class="CreditSix CreditSixMember">
        <el-row :gutter="20">
            <el-col :span="7">
                <div class="o-plr">
                    <div class="u-bl u-br u-bb">
                        <div class="row">
                            <p class="row-title">会员账户</p>
                            <h6 class="c-line-16 o-plr">{{ User.username }}</h6>
                        </div>
                        <div class="row">
                            <p class="row-title">信用额度</p>
                            <h4 class="c-line-20 c-color-t o-plr">{{ CreditInfo.balance ? Price(CreditInfo.balance) : 0 }}</h4>
                        </div>
                        <div class="row">
                            <p class="row-title">会员设置</p>
                            <div class="o-plr">
                                <div class="o-ptb u-bt">
                                    <Button @click="Edit()">修改密码</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="17">
                <span>游戏类型：</span>
                <el-select v-model="Filter.games_id" style="width:7rem;" size="small">
                    <el-option v-for="(item,index) in Opt.hasDir.game" :key="item.id" :label="item.title" :value="item.id" @click.native="load()" />
                </el-select>
                <table v-loading="main._loading" class="table line u-bb u-br u-bl o-mt-s" border="0" cellspacing="1" cellpadding="0">
                    <thead>
                        <tr style="text-align:left;">
                            <td style="text-align:left;">项目</td>
                            <td style="text-align:left;">A盘退水</td>
                            <td style="text-align:left;">B盘退水</td>
                            <td style="text-align:left;">C盘退水</td>
                            <td style="text-align:left;">D盘退水</td>
                            <td style="text-align:left;">E盘退水</td>
                            <td style="text-align:left;">单注限额</td>
                            <td style="text-align:left;">单项限额</td>
                        </tr>
                    </thead>
                    <tbody style="text-align:left;">
                        <tr v-for="(item,index) in table.list">
                            <td>{{ item.name }}</td>
                            <td>{{ item.backwater_a }}</td>
                            <td>{{ item.backwater_b }}</td>
                            <td>{{ item.backwater_c }}</td>
                            <td>{{ item.backwater_d }}</td>
                            <td>{{ item.backwater_e }}</td>
                            <td>{{ Price(item.order_max_amount) }}</td>
                            <td>{{ Price(item.bets_limit_amount) }}</td>
                        </tr>
                    </tbody>
                </table>
            </el-col>
        </el-row>
        <editer v-model="Editer.view" :title="Editer.title" :form="Editer.form" />
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
import Editer from '@lottery.2.0/page/my/pages/account/modul/password'
export default {
    name: 'CreditSixMember',
    mixins: [StoreMix],
    data() {
        return {
            store: 'myAccount/gamesgroup',
            Filter: {
                games_id: 31
            },
            table: {
                length: 1,
                list: [],
                fast: [null]
            }
        }
    },
    methods: {
        init() {
            this.Filter.games_id = this.Running.id ? this.Running.id : 31
            this.load()
        },
        load() {
            this.DataReset('table')
            this.Get().then(res => {
                if (res) {
                    let list = []
                    for (let item of res.data.data) {
                        list.push(item)
                    }
                    let fast = []
                    for (let i=0;i<=length;i++) {
                        fast.push(null)
                    }
                    this.$set(this.table, 'list', list)
                }
            })
        }
    },
    components: {
        Editer
    },
    activated() {
        this.init()
    }
}
</script>
