<style lang="scss">
.MyReportAmountBets {
    .el-dialog__body {
        padding-top:.6rem;
    }
    table.table {
        tr td.mark {
            color:red!important;
        }
    }
}
</style>

<template>
    <div class="MyReportAmountBets">
        <div ref="table">
            <Table :table="main" :summary="summary"></Table>
        </div>
        <div class="l-flex-c o-pt-s">
            <div class="l-flex-1"></div>
            <Pagination v-if="main._total" class="o-mtb" v-model="Page" @turning="Get" :total="main._total" :count="main._count" :pageSize="Filter.per_page"></Pagination>
        </div>       
    </div>
</template>

<script>
import Table from './components/amount_table'
import StoreMix from '@lottery.2.0/mixin/store.js'
export default {
    name: 'MyReportAmountBets',
    mixins: [StoreMix],
    data() {
        return {
            store: 'game/order',
            Filter: {
                transfer: 0,
                interval: null,
                per_page: 20
            },
            summary: {}
        }
    },
    props: {
        model: {
            default: 'order'
        },
        filter: {
            default: {}
        },
        target: {
            default: {}
        }
    },
    methods: {
        init() {
            if (this.filter.status == 0) {
                let games_mark = localStorage.getItem('games_mark_senbo')
                this.$set(this, 'Filter', {
                    uid: this.target.id,
                    whole: 1,
                    games_type: this.filter.games_type,
                    games_mark: games_mark,
                    interval: this.$route.query.interval,
                    lottery_id: this.filter.lottery_id,
                    status: this.$route.query.status,
                    transfer: 0,
                    per_page: 20
                })
            } else {
                let games_mark = localStorage.getItem('games_mark_senbo')
                this.$set(this, 'Filter', {
                    uid: this.target.id,
                    games_type: this.filter.games_type,
                    games_mark: games_mark,
                    interval: this.$route.query.interval,
                    username: this.target.username,
                    lottery_id: this.filter.lottery_id,
                    status: this.$route.query.status,
                    transfer: 0,
                    per_page: 20
                })
            }
            this.$set(this, 'summary', {})
            this.Cm('myReport/_MYREPORT_CLEAN', {base: this.model, key: '_list'})
            this.Get(1)
            this.GetSum()
        },
        _Get(page) {
            this.Get(page)
            this.GetSum()
        },
        GetSum() {
            let type = this.model
            let models = {
                order: this.Opt.isTeam ? 'ordersum' : 'ordersum',
                transfer: this.Opt.isTeam ? 'ordersum' : 'ordersum'
            }
            this.Get('ordersum').then(res => {
                this.$set(this, 'summary', res.data)
            })
        }
    },
    components: {
        Table
    }
}
</script>