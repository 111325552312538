<style lang="scss">
    .CreditSixAnnoun {
        .title {font-size:1rem; line-height:1.5em; font-weight:bold; color:#2c5bb9;}
        .content {padding-top:.3rem; padding-bottom:.5rem; line-height:1.5em;}
    }
</style>
<template>
    <div class="CreditSix CreditSixAnnoun">
        <ul class="o-p o-pt-l">
            <li v-for="(item,index) in main._list">
                <h3 class="title">{{ item.title }}</h3>
                <p class="time">{{ item.create_at }}</p>
                <div class="content" v-html="item.content" />
            </li>
        </ul>
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
export default {
    name: 'CreditSixAnnoun',
    mixins: [StoreMix],
    data() {
        return {
            store: 'base/announ'
        }
    },
    computed: {
        // games(){
        //     return this.$store.state.game.games
        // }
    },
    methods: {
        init() {
            this.Get()
        }
    },
    components: {
        //editer,
    },
    activated() {
        this.init()
    }
}
</script>
