<style lang="scss">
    @import "../../../style/config.scss";
    .CreditSixUser {
        .breadcrumb {
            background-color:#f5f5f5; border-radius:4px; padding:10px 12px;
        }
        table.table, table.table-light {
            // font-size: 9pt;
            tr td {
                border:1px solid #aaa;
            }
            thead tr td,td.bg-t {background-color:#b0e5f2!important; color:#000!important;}
            a.btn {
                text-decoration:underline; padding:1px 3px;
            }
            thead tr:nth-child(2){
                td {
                    background-color:#e3fff1!important;
                }
            }
        }
    }
</style>
<template>
    <div class="CreditSix CreditSixUser">
        <div class="l-flex-c o-plr o-mb">
            <div class="ctrl o-ml l-flex-1">
                <DatePicker v-model="Filter.interval" @change="MakeFilterGo()" @keyup.13.native="MakeFilterGo()" />
                <span class="o-plr">
                    <span>搜索用户：</span>
                    <el-input v-model="Filter.username" placeholder="请输入关键字并回车" size="small" style="width:240px;" @keyup.13.native="MakeFilterGo()">
                        <el-button slot="append" style="width:80px;" @click.native="MakeFilterGo()">搜索</el-button>
                    </el-input>
                </span>
                <span>用户类型：</span>
                <el-select v-model="Filter.type" placeholder="请选择" size="small" style="width:90px;" @change="MakeFilterGo()">
                    <el-option label="全部" :value="9" />
                    <el-option label="会员" :value="'0'" />
                    <el-option label="代理" :value="'1'" />
                </el-select>
                <span class="o-pl">用户状态：</span>
                <el-select v-model="Filter.status" placeholder="请选择" size="small" style="width:90px;" @change="MakeFilterGo()">
                    <el-option label="全部" :value="9" />
                    <el-option label="启用" :value="'1'" />
                    <el-option label="暂停" :value="'2'" />
                    <el-option label="停用" :value="'3'" />
                </el-select>
            </div>
            <Button @click="Edit(null,null,'Editer',0)">新增会员</Button>
            <Button @click="Edit(null,null,'Editer',1)">新增代理</Button>
        </div>
        <div class="ctrl l-flex-c o-mb">
            <button v-if="breadcrumb.length > 1" class="btn" plain @click="Breadcrumb(breadcrumb.length - 2)">返回上一级</button>
            <div class="l-flex-1 o-pl">
                <div class="breadcrumb">
                    <el-breadcrumb separator="/">
                        <el-breadcrumb-item v-for="(item,index) in breadcrumb" :key="index"><a @click="Breadcrumb(index)">{{ item.title }}</a></el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
            </div>
        </div>
        <table v-loading="main._loading" class="table-light" border="0" cellspacing="1" cellpadding="0" style="border-collapse:collapse;">
            <thead>
                <tr style="text-align:center;">
                    <td rowspan="2">帐号</td>
                    <td rowspan="2">名称</td>
                    <td rowspan="2">类型</td>
                    <td rowspan="2">彩类</td>
                    <td :colspan="TargetIsTop ? 5 : 4">六合彩</td>
                    <td rowspan="2">状态</td>
                    <td rowspan="2">代理<br>人数</td>
                    <td rowspan="2">会员<br>人数</td>
                    <td rowspan="2">最高在<br>线人数</td>
                    <td rowspan="2">创建时间</td>
                    <td rowspan="2">最后登入</td>
                    <td rowspan="2">最后登入IP</td>
                    <td rowspan="2">操作</td>
                </tr>
                <tr style="text-align:center;">
                    <td>信用额度</td>
                    <td>已用额度</td>
                    <td v-if="TargetIsTop">操盘</td>
                    <td>走飞</td>
                    <td>封盘时间</td>
                </tr>
            </thead>
            <tbody style="text-align:left;">
                <tr v-for="(item,index) in main._list">
                    <td explain="帐号">
                        <a v-if="item.type > 0" class="btn" @click="GetByBreadcrumb(item)">{{ item.username }}</a>
                        <a v-if="item.type == 0" class="">{{ item.username }}</a>
                    </td>
                    <td explain="名称">{{ item.real_name }}</td>
                    <td explain="类型">
                        <span v-if="item.type == 0">会员</span>
                        <span v-if="item.type == 1">代理</span>
                        <span v-if="item.type == 2">固定</span>
                    </td>
                    <td explain="彩类">{{ item.games }}</td>

                    <td explain="信用额度">{{ item.lhc_amount }}</td>
                    <td explain="已用额度">{{ item.lhc_use_balance }}</td>

                    <td v-if="TargetIsTop" explain="操盘" style="text-align:center;">
                        <span v-if="item.lhc_trader_status">启用</span>
                        <span v-else class="c-color-t">停用</span>
                    </td>
                    <td explain="走飞" style="text-align:center;">
                        <span v-if="item.lhc_transfer_status">启用</span>
                        <span v-else class="c-color-t">停用</span>
                    </td>
                    <td explain="封盘时间" style="text-align:center;">{{ item.lhc_end_bets_date }}</td>

                    <td style="text-align:center;">
                        <span v-if="item.status === 1">启用</span>
                        <span v-if="item.status === 2" class="c-color-e">暂停</span>
                        <span v-if="item.status === 3" class="c-color-e">停用</span>
                    </td>
                    <td style="text-align:center">{{ item.has_proxys }}</td>
                    <td style="text-align:center">{{ item.has_members }}</td>
                    <td style="text-align:center">{{ item.online_persons }}</td>
                    <td style="text-align:center;">{{ item.reg_time }}</td>
                    <td style="text-align:center;">{{ item.log_time }}</td>
                    <td style="text-align:center;">{{ item.log_ip }}</td>

                    <td style="text-align:right;">
                        <Button v-if="item.type == 0 && item.amount_type == 0" size="mini" @click="BalanceRecover(item)">恢复额度</Button>
                        <Button v-if="item.type == 0" size="mini" @click="LevelView(item)">占成</Button>
                        <Button size="mini" @click="Edit(item)">编辑</Button>
                        <Button size="mini" @click="LimitView(item)">限额</Button>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr v-if="main._empty">
                    <td class="empty" colspan="100">暂无数据</td>
                </tr>
            </tfoot>
        </table>
        <Pagination v-if="main._total" v-model="Page" class="o-mtb" :total="main._total" :count="main._count" :page-size="Filter.per_page" @turning="Get" />
        <component :is="'EdiderCredit'" v-model="Editer.view" :title="Editer.title" :form="Editer.form" :parent="Editer.parent" :utype="Editer.utype" @finish="Get(Page)" />
        <!--         <Transfer v-model="transfer.view" title="划入余额" :form="transfer.form"></Transfer>
        <Percentage v-model="percentage.view" title="设置奖金组" :form="percentage.form"></Percentage>
        <EdiderReset v-model="EditerReset.view" title="设置奖金组" :form="EditerReset.form"></EdiderReset> -->
        <limit v-model="Limit.view" title="修改限额" :form="Limit.form" />
        <level v-model="Level.view" title="占成" :form="Level.form" />
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
import LevelMix from '@lottery.2.0/mixin/level.v3.js'
// import Edider from '@lottery.2.0/page/my/pages/team/modul/user'
import EdiderCredit from './model/user_credit'
// import EdiderReset from '@lottery.2.0/page/my/pages/team/modul/user_reset'
// import Percentage from '@lottery.2.0/page/my/pages/team/modul/percentage'
// import Transfer from '@lottery.2.0/page/my/pages/team/modul/transfer'
import Limit from './model/limit'
import Level from './model/user_level'
export default {
    name: 'CreditSixUser',
    mixins: [StoreMix, LevelMix],
    data() {
        return {
            store: 'credit/user',
            crumbkey: 'pid',
            Filter: {
                per_page: 20,
                type: undefined,
                status: '1'
            },
            transfer: {
                view: false,
                form: null,
                params: {
                    username: null,
                    pass: null,
                    money: null
                }
            },
            percentage: {
                view: false,
                form: null,
                params: {
                    username: null,
                    odds: null
                },
                list: null
            },
            EditerReset: {
                view: false,
                form: null
            },
            Limit: {
                view: false,
                form: null
            },
            Level: {
                view: false,
                form: null
            },
            parent: {}
        }
    },
    computed: {
        TargetIsTop() {
            return this.Opt.isAdmin && !this.BreadcrumbTarget.id && !this.$route.query.username
        }
    },
    methods: {
        init() {
            this.FilterIntervalSet('nextyear')
        },
        Transfer(item) {
            this.DataReset('transfer')
            this.transfer.view = true
            this.transfer.form = this.Origin({
                username: item.username,
                pass: null,
                money: null
            })
        },
        Percentage(item) {
            this.DataReset('percentage')
            this.percentage.view = true
            this.percentage.form = this.Origin({
                username: item.username,
                odds: null
            })
        },
        LimitView(item) {
            this.Limit.view = true
            this.Limit.form = this.Origin(item)
        },
        LevelView(item) {
            this.Level.view = true
            this.Level.form = this.Origin(item)
        },
        Edit(row, title, model='Editer', utype=0) {
            this[model].view  = true
            this[model].title = row ? '修改数据' : '新增数据'
            this[model].form  = row ? this.Origin(row) : null
            this[model].utype  = utype
            let crumb = this.breadcrumb[this.breadcrumb.length-1]
            if (crumb && crumb.id) {
                this[model].parent = {...crumb}
            } else {
                this[model].parent = {id: this.User.id}
            }
        },
        BalanceRecover(item) {
            this.Confirm(() => {
                this.Dp('credit/BALANCE_USER', {id: item.id}).then(res => {
                    this.$set(item, 'lhc_use_balance', 0)
                    this.Suc('操作成功')
                })
            }, `是否确认恢复 ${item.username} 的信用额度？`, '恢复信用额度')
        }
    },
    components: {
        EdiderCredit,
        Limit,
        Level
    }
}
</script>
