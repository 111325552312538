
export default {
    data() {
        return {
            crumbkey: 'pid',
            breadcrumb: [
                {
                    title: '所有下级',
                    type: 1,
                    id: ''
                }
            ]
        }
    },
    computed: {
        BreadcrumbTarget() {
            let index = this.breadcrumb.length - 1
            if (index >= 0) {
                return this.breadcrumb[index]
            }
            return {}
        }
    },
    methods: {
        init() {

        },

        // 新增用户
        GetByBreadcrumb(item={}) {
            let id = item.uid || item.id
            this.breadcrumb.push({
                title: item.username,
                type: item.user_type == 1 || item.type == 1 ? 1 : 0,
                id
            })
            this.Filter.subordinate = 1
            this.Go(this.$route.name, {}, {...this.$route.query, b: this.MakeBreadcrumbStr(this.breadcrumb)})
        },

        // 点击导航查询用户
        Breadcrumb(index) {
            this.$set(this, 'breadcrumb', this.breadcrumb.slice(0, index+1))
            this.Go(this.$route.name, {}, {...this.$route.query, b: this.MakeBreadcrumbStr(this.breadcrumb)})
        },

        MakeBreadcrumbStr() {
            if (this.breadcrumb.length > 1) {
                let arr = []
                for (let item of this.breadcrumb) {
                    if (item.id) {
                        arr.push(`${item.title},${item.id},${item.type}`)
                    }
                }
                return arr.join('_')
            }
            return ''
        },
        LoadBreadcrumb() {
            this.$nextTick(() => {
                let query = this.Origin(this.$route.query)
                this.DataReset('breadcrumb')
                if (query.b && query.b.length > 3) {
                    try {
                        for (let item of query.b.split('_')) {
                            this.breadcrumb.push({
                                title: item.split(',')[0],
                                id: item.split(',')[1],
                                type: item.split(',')[2]
                            })
                        }
                    } catch (err) {}
                }
                this.GetBreadcrumb()
            })
        },
        GetBreadcrumb() {
            let key = this.crumbkey
            let item = this.Origin(this.breadcrumb[this.breadcrumb.length - 1])

            this.Filter[key] = item.id
            this.Filter.subordinate = this.Filter[key] ? 1 : 0
            this.MakeFilter()
            if (this.ChangeBreadcrumb) {
                this.ChangeBreadcrumb(item)
            }
        }
    },
    mounted() {
        this.init()
    },
    activated() {
        // console.log('activated')
        //this.LoadBreadcrumb()
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.LoadBreadcrumb()
        })
    },
    beforeRouteUpdate(to, from, next) {
        this.LoadBreadcrumb()
        next()
    }
    // beforeRouteLeave(to, from, next){
    //     console.log('离开 beforeRouteLeave')
    //     next()
    // },
}