<style lang="scss">
    @import "../../../../../style/config.scss";
    .OddsGroupTql7 {

    }
</style>
<template>
    <div class="OddsGroupTql7">
        <div v-if="nav.active.name === '正码1-6'">
            <div class="l-flex">
                <div v-for="(item,index) in ['正码一','正码二','正码三','正码四','正码五','正码六']" class="o-pr">
                    <group class="u-br" :list="Table[(index + 1) * 10 + 1]" :length="1" width="2.2rem" :title="item" :btn="true" />
                    <group class="u-br" :list="Table[(index + 1) * 10 + 3]" :length="1" width="2.2rem" :btn="true" />
                    <group class="u-br" :list="Table[(index + 1) * 10 + 2]" :length="1" width="2.2rem" :btn="true" />
                    <group class="u-br" :list="Table[(index + 1) * 10 + 5]" :length="1" width="2.2rem" :btn="true" />
                    <group class="u-br" :list="Table[(index + 1) * 10 + 7]" :length="1" width="2.2rem" :btn="true" />
                    <group class="u-br" :list="Table[(index + 1) * 10 + 6]" :length="1" width="2.2rem" :btn="true" />
                    <group class="u-bb u-br" :list="Table[(index + 1) * 10 + 4]" :length="1" width="2.2rem" :btn="true" />
                </div>
            </div>
        </div>
        <div v-if="nav.active.name === '连码'">
            <group class="u-bb u-br" :list="Table[0]" :length="6" :double="true" title="连码" />
        </div>
        <div v-if="nav.active.name === '自选不中'">
            <div class="tabs">
                <a v-for="(group,name) in tabs['自选不中']" class="tab" :class="{'active': nav.group === group}" @click="nav.group = group">{{ name }}</a>
            </div>
            <template v-for="(group,name) in tabs['自选不中']" v-if="nav.group === group">
                <group class="u-bb u-br" :list="Table[group]" :length="5" :title="name" />
                <fast class="o-mt" :table="Table" :group="[group]">{{ name }}</fast>
            </template>
        </div>
        <div v-if="nav.active.name === '一肖尾数'">
            <group class="u-bb u-br" :list="Table[0]" :length="6" title="生肖" :btn="true" />
            <group class="o-mt u-bb u-br" :list="Table[1]" :length="5" title="尾数" :btn="true" />
        </div>
        <div v-if="nav.active.name === '连肖'">
            <div class="l-flex">
                <div v-for="(item,index) in ['二肖','三肖','四肖','五肖']" class="o-pr">
                    <group class="u-br" :list="Table[(index + 2) * 10 + 1]" :length="1" width="2.2rem" :title="item" :btn="true" />
                </div>
            </div>
        </div>
        <div v-if="nav.active.name === '正码特'">
            <div class="tabs">
                <a v-for="(group,name) in tabs['正码特']" class="tab" :class="{'active': nav.group === group}" @click="nav.group = group">{{ name }}</a>
            </div>
            <template v-for="(group,name) in tabs['正码特']" v-if="nav.group === group">
                <group class="u-bb u-br" :list="Table[group]" :length="5" :title="name" />
                <fast class="o-mt" :table="Table" :group="[group]">{{ name }}</fast>
            </template>
        </div>
        <div v-if="nav.active.name === '半波'">
            <group class="u-bb u-br" :list="Table[19]" :length="6" title="半波" :btn="true" />
        </div>
        <div v-if="nav.active.name === '合肖'">
            <div v-for="(pack,unit) in [['一','二','三','四','五'],['六','七','八','九','十'],['十一',null,null,null,null]]" class="l-flex o-mb">
                <div v-for="(item,index) in pack">
                    <group v-if="item" class="u-bb u-br" :list="Table[unit * 5 + index + 1]" :length="1" :title="item + '肖'" width="3.6rem" :btn="true" />
                </div>
            </div>
        </div>
        <div v-if="nav.active.name === '特码'">
            <group class="u-bb u-br" :list="Table[1]" :length="5" title="特码" :side="1" :prompt="true" />
            <fast class="o-mt" :table="Table" :group="[1]" :side="1">特码</fast>
            <div class="l-flex o-mt">
                <group width="3rem" :list="Table[4]" :length="2" :btn="true" />
                <group width="3rem" :list="Table[2]" :length="2" :btn="true" />
            </div>
            <div class="l-flex">
                <group width="3rem" :list="Table[5]" :length="2" :btn="true" />
                <group width="3rem" :list="Table[51]" :length="2" :btn="true" />
            </div>
            <div class="l-flex">
                <group width="3rem" :list="Table[8]" :length="2" :btn="true" />
                <group width="3rem" :list="Table[81]" :length="2" :btn="true" />
            </div>
            <div class="l-flex">
                <group width="3rem" :list="Table[6]" :length="2" :btn="true" />
                <group width="3rem" :list="Table[7]" :length="2" :btn="true" />
            </div>
            <div class="l-flex">
                <group width="3rem" :list="Table[10]" :length="2" :btn="true" />
            </div>
            <div class="l-flex">
                <group class="o-mt u-bb u-br" :list="Table[3]" :length="4" title="生肖" :btn="true" />
                <group class="o-mt o-ml u-bb u-br" :list="Table[9]" :length="4" title="色波" :btn="true" />
            </div>
        </div>
        <div v-if="nav.active.name === '正码'">
            <group class="u-bb u-br" :list="Table[0]" :length="5" title="正码" />
            <fast class="o-mt" :table="Table" :group="[0]">正码</fast>
            <group class="o-mt u-bb u-br":list="Table[1]" :length="4" title="双面" :btn="true" />
        </div>
        <div v-if="nav.active.name === '生肖色波'">
            <group class="u-bb u-br" :list="Table[0]" :length="4" width="2rem" title="生肖色波" :btn="true" />
        </div>
    </div>
</template>

<script>
import SixMix from '@lottery.admin.2.0/mixin/six.js'
import Group from './odds-group'
import Fast from './odds-fast'
export default {
    name: 'OddsGroupTql7',
    mixins: [SixMix],
    data() {
        return {

        }
    },
    props: {
        nav: {},
        Table: {},
        tabs: {}
    },
    computed: {

    },
    methods: {

    },
    components: {
        Group, Fast
    }
}
</script>
