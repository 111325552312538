
// 样式
import './library/components.js'
import './style/project.scss'

import 'mint-ui/lib/style.css'
import './style/mint.scss'

// 主程序
import Vue from 'vue'
import methods from './library/methods.js'
import './library/mixin.js'

Vue.config.performance = true
// 电脑版本
import "babel-polyfill"
import router from './router/index'
// import './style/element-variables.scss'
import 'element-ui/lib/theme-chalk/index.css';
//const ElementUI = () => import('element-ui')
import ElementUI from 'element-ui'
import { MessageBox } from 'element-ui'
Vue.use(ElementUI)
Vue.prototype.MessageBox = MessageBox


// 轮播插件
//import '~/cuteSlider/main.js'

router.beforeEach((to, from, next) => {
    if (window.AppOptions) {
        document.title = window.AppOptions.title // `${to.meta.name ? window.AppOptions.title + ' - ' + to.meta.name : '404'}`
    } else {
        document.title = `${to.meta.name ? to.meta.name : '404'}`
    }
    //ocument.title = `${to.meta.name ? `森博国际 - ${to.meta.name}` : '404'}`
    next()
})

// 数据管理
import store from './store'

// 启动应用
import index from './page/app.vue'
window.Vue = Vue
window.App = new Vue({
    el: '#app',
    store,
    router,
    methods,
    render: h => h(index)
})