
import Operate from '@lottery.admin.2.0/components/operate/index.vue'
export default {
    data() {
        return {

        }
    },
    computed: {
        games() {
            return this.$store.state.game.games
        },
        types() {
            return this.$store.state.game.group
        },
        onlySix() {
            return false
        }
    },
    watch: {
        'Running.id'(val) {
            if (val) {
                // this.$set(this,'id',val)
                // this.$refs['operate'].Operate(this.Running.id)
            }
        }
    },
    methods: {
        // OperateChange(item){
        //     if(item && item.id != this.Running.id){
        //         // this.Go('game',{code:item.name,type:'credit'})
        //     }
        // },
    },
    components: {
        Operate
    },
    activated() {
        if (!this.Opt.isTeam) {
            this.Rd('credit')
        }
    },
    deactivated() {
        //this.$refs['operate'].OperateCleanAll()
    },
    mounted() {
        // this.$set(this,'id',this.Running.id)
    }
}
