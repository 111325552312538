<style lang="scss">

</style>
<template>
    <div class="CreditSix CreditSixAccount">
        <div class="ctrl o-plr o-pb c-text-r">
            <Button @click="Edit()">新增{{ Opt.isAdmin ? '公司' : '子帐号' }}</Button>
        </div>
        <table v-loading="main._loading" class="table" border="0" cellspacing="1" cellpadding="0">
            <thead>
                <tr>
                    <td>帐号</td>
                    <td>名称</td>
                    <td>创建时间</td>
                    <td>最后登录时间</td>
                    <td>最后登录IP</td>
                    <td>浏览器信息</td>
                    <td>操作系统</td>
                    <td>操作</td>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item,index) in main._list" class="c-text-c">
                    <td>{{ item.account }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.create_at }}</td>
                    <td>{{ item.log_time }}</td>
                    <td>{{ item.log_ip }}</td>
                    <td>{{ item.browser }}</td>
                    <td>{{ item.os }}</td>
                    <td>
                        <Button size="mini" @click="Edit(item)">修改</Button>
                        <Button size="mini" @click="Del(item)">删除</Button>
                    </td>
                </tr>
            </tbody>
            <tfoot>
                <tr v-if="main._empty">
                    <td class="empty" colspan="100">暂无数据</td>
                </tr>
            </tfoot>
        </table>
        <editer v-model="Editer.view" title="子帐号" :form="Editer.form" @finish="Get()" />
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
import Editer from './model/account'
export default {
    name: 'CreditSixAccount',
    mixins: [StoreMix],
    data() {
        return {
            store: 'admin/account',
            Filter: {}
        }
    },
    methods: {
        init() {
            this.Get(1)
        },
        Del(item) {
            this.DelConfirm(() => {
                this.Dp('admin/_PUT_ACCOUNT', {id: item.id, data: {status: 9}}).then(res => {
                    this.Get()
                })
            })
        }
    },
    components: {
        Editer
    },
    activated() {
        if (!this.Opt.isTeam) {
            this.Rd('credit')
        }
        this.init()
    }
}
</script>
