<style lang="scss">
    @import "../../style/config.scss";
    .pj-err {
        padding:8rem 0; color:#d5d5d5;
        h1 {font-size:4rem; margin:.7rem 0; }
    }
</style>

<template>
    <div class="pj-err c-text-c">
        <h1><b>404</b></h1>
        <h2 class="c-color-t"><b>THIS PAGE NOT FOUND !</b></h2>
        <h5 class="o-mt">抱歉，当前页面不存在</h5>
    </div>
</template>

<script>

export default {
    name: 'Error',
    data () {
        return {

        }
    }
}
</script>