<style lang="scss">
.MyReportAmountTable{
    &.table-light {
        tr td.mark, tr td[mark="true"]  {
            color:red!important;
        }
    }
}
</style>

<template>
    <table v-if="table" v-loading="table._loading" class="MyReportAmountTable table-light" border="0" cellspacing="1" cellpadding="0">
        <thead>
            <tr style="text-align:left;">
                <td style="text-align:left;">注单号</td>
                <td style="text-align:left;">日期</td>
                <td style="text-align:left;">期数</td>

                <td v-if="Opt.isTeam" style="text-align:left;">会员</td>
                <td style="text-align:left;">盘类 / 退水%</td>
                <td style="text-align:left;">玩法</td>
                <td style="text-align:left;">投注内容</td>
                <td style="text-align:left;">下注时间</td>
                <td style="text-align:left;">赔率</td>

                <td v-if="Filter.transfer >= 1">走飞类型</td>
                <td style="text-align:left;">
                    <span v-if="Filter.transfer == 0">下注金额</span>
                    <span v-if="Filter.transfer == 1">走飞金额</span>
                    <span v-if="Filter.transfer == 2">收飞金额</span>
                    <span v-else>金额</span>
                </td>
                <td style="text-align:left;">派彩</td>
                <td style="text-align:left;">退水金额</td>
                <td style="text-align:left;">结果</td>
            </tr>
        </thead>
        <tbody style="text-align:left;">
            <tr v-for="(item,index) in table._list" :key="item.id">
                <td style="text-align:left;">{{ item.sequence }}</td>
                <td style="text-align:left;">{{ item.lottery_date }}</td>
                <td style="text-align:left;">{{ item.lottery_number }}</td>

                <td v-if="Opt.isTeam" style="text-align:left;">{{ item.username }}</td>
                <td style="text-align:left;">{{ item.side }} / {{ item.backwater_percent }} / {{ item.games }}</td>
                <td style="text-align:left;">{{ item.plays }}</td>
                <td style="text-align:left;">{{ CheckContextLength(item.code,48) }}</td>
                <td style="text-align:left;">{{ item.create_at }}</td>
                <td style="text-align:left;">{{ item.odds }}</td>

                <td v-if="Filter.transfer >= 1">
                    <span v-if="item.transfer_t == 1">自动</span>
                    <span v-if="item.transfer_t == 2">手动</span>
                </td>
                <td style="text-align:left;">{{ item.amount }}</td>
                <td style="text-align:left;">{{ item.bonus }}</td>
                <td style="text-align:left;">{{ item.backwater }}</td>
                <td v-mark style="text-align:left;" :class="{'mark': item.result < 0}">{{ item.result }}</td>
            </tr>
        </tbody>
        <tfoot>
            <tr v-if="table._empty">
                <td class="empty" colspan="100">暂无数据</td>
            </tr>
            <tr v-if="!table._empty" key="subtotal" class="subtotal">
                <td style="text-align:left;" :colspan="Opt.isTeam ? 9 : 8">
                    <p class="c-text-r o-pr">页面小计 (共 {{ table._list.length }} 笔)</p>
                </td>
                <td v-if="Filter.transfer >= 1">-</td>
                <td style="text-align:left;">{{ CalcuRow('amount') }}</td>
                <td style="text-align:left;">{{ CalcuRow('bonus') }}</td>
                <td style="text-align:left;">{{ CalcuRow('backwater') }}</td>
                <td v-mark style="text-align:left;" :class="{'mark': CalcuRow('result') < 0}">{{ CalcuRow('result') }}</td>
            </tr>
            <tr v-if="!table._empty" key="total" class="total">
                <td style="text-align:left;" :colspan="Opt.isTeam ? 9 : 8">
                    <p class="c-text-r o-pr">合计 (共 {{ summary.bets_num }} 笔)</p>
                </td>
                <td v-if="Filter.transfer >= 1">-</td>
                <td style="text-align:left;">{{ Price(summary.amount,{separate: false}) }}</td>
                <td style="text-align:left;" />
                <td style="text-align:left;">{{ Price(summary.backwater,{separate: false}) }}</td>
                <td v-mark style="text-align:left;" :class="{'mark': summary.result < 0}">{{ Price(summary.result,{separate: false}) }}</td>
            </tr>
        </tfoot>
    </table>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
export default {
    name: 'MyReportAmountBets',
    mixins: [StoreMix],
    data() {
        return {

        }
    },
    props: {
        table: {
            default: null
        },
        summary: {
            default: {}
        }
    },
    computed: {

    },
    directives: {
        mark: {
            inserted(el, e, vnode, oldvnode) {
                el.setAttribute('mark', el.innerText && el.innerText < 0 ? 'true' : 'false')
            },
            update(el, binding, vnode, oldvnode) {
                el.setAttribute('mark', el.innerText && el.innerText < 0 ? 'true' : 'false')
            }
        }
    },
    methods: {
        CalcuRow(key) {
            let total = 0
            try {
                for (let row of this.table._list) {
                    if (row[key] && parseFloat(row[key]) == row[key]) {
                        total = this.Big(total).add(row[key]).toNumber()
                    }
                }
                return this.Big(total).round(2, 0).toNumber()
            } catch (err) {
                return NaN
            }
            return total
        }
    },
    mounted() {

    }
}
</script>