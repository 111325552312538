
<template>
    <div id="app" :class="Config.environment">
        <template v-if="!loading">
            <template v-if="init || $route.meta.public">
                <Password v-if="User && User.security_level == 0" />
                <template v-else>
                    <keep-alive>
                        <router-view />
                    </keep-alive>
                </template>
            </template>
        </template>
    </div>
</template>

<script>

export default {
    data() {
        return {
            init: false,
            loading: true
        }
    },
    watch: {
        '$route.name'(name) {
            if (!this.$route.meta.public) {
                this.LoginCheck()
            }
        }
    },
    methods: {
        LoadIcon() {
            if (this.Config.icon) {
                let s = document.createElement('script')
                s.type = 'text/javascript'
                s.src = this.Config.icon
                document.body.appendChild(s)
            }
        }
    },
    mounted() {
        this.LoadIcon()
        this.$store.state.base.Event.removeAllListeners()
        this.$store.state.base.Event.on('login', () => {
            Promise.all([
                this.Dp('game/INIT_GAMES'), // 初始化游戏
                this.Dp('base/BASE_OPTIONS').then(res => {
                    // 系统版本不同时候清除缓存
                    if (res.system_version) {
                        let version = this.Cache('SYSTEM_VERSION')
                        if (version && version != res.system_version) {
                            this.ResetCache()
                        }
                        this.Cache('SYSTEM_VERSION', res.system_version)
                    }
                    return res
                }) // 初始化配置
            ]).then(refs => {
                this.init = true
                if (!this.Opt.isTeam) {
                    this.interval = setInterval(() => {
                        if (this.Token()) {
                            try {
                                this.Dp('base/BASE_USER')
                            } catch (err) {}
                        }
                    }, 30000)
                }
            })
        })
        this.Login().then(res => {
            this.loading = false
        })
        console.log(`${this.Config.environment} ${this.Config.equipment} ${this.Config.edition}`)
    }
}
</script>
