<style lang="scss"></style>
<template>
    <el-dialog class="GameCreditOutputMudul" :title="title" :visible.sync="view" :size="'bhuge'" top="5vh" :modal-append-to-body="false">
        <div class="ctrl l-flex-1 o-mb-s">
            <span class="o-pl">游戏类型：</span>
            <el-select v-model="Filter.games_id" style="width:6.5rem;" size="small">
                <el-option v-for="(item,index) in Opt.hasDir.game" :key="item.id" :label="item.title" :value="item.id" @click.native="ChangeGame(item)" />
            </el-select>
        </div>
        <history ref="history" :filter="Filter" />
    </el-dialog>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.modul.js'
import SixMix from '@lottery.admin.2.0/mixin/six.js'
import History from '../components/history'
export default {
    name: 'MCreditSixOutput',
    mixins: [StoreMix, SixMix],
    data() {
        return {
            store: 'game/history',
            Filter: {
                games_id: 37,
                games_type: 7,
                per_page: 20
            }
        }
    },
    methods: {
        init() {
            this.Filter.games_id = this.Running.id
            this.Filter.games_type = this.Running.group
            if (this.$refs['history'] && this.$refs['history'].reload) {
                this.$refs['history'].reload()
            }
        },
        ChangeGame(item) {
            this.Filter.games_id = item.id
            this.Filter.games_type = item.type
            if (this.$refs['history'] && this.$refs['history'].reload) {
                this.$refs['history'].reload()
            }
        }
    },
    components: {
        History
    },
    mounted() {
        this.init()
    }
}
</script>
