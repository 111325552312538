<style lang="scss">
    @import "../../../style/config.scss";
    .CreditSystem {
        li {
            //border-right:3px solid $color-n;
            margin:2px 0; padding:0 .5rem; padding-right:14px;
            &.active {
                //border-color:#f60;
                // background-color:rgba($color-w,0.2);
                background-color: #fdb65c; color:#e400ff;
                background: linear-gradient(90deg,#ffd8a5,#e78740);
            }
        }
        a {
            display:block; padding:5px 4px;// text-decoration:underline;
            &:hover {
                color:#e400ff;
            }
        }
        button.btn {
            padding:6px 12px;
        }
    }
</style>
<template>
    <div class="CreditSix CreditSystem">
        <el-row tag="div" :gutter="20">
            <el-col tag="ul" class="c-text-r" :span="3">
                <li v-for="(item,index) in dir" :class="{'active': $route.name == item.name}">
                    <a @click="Go(item.name)">{{ item.title }}</a>
                </li>
            </el-col>
            <el-col tag="div" :span="21">
                <router-view v-if="routerView" />
            </el-col>
        </el-row>
    </div>
</template>

<script>
import StoreMix from '@lottery.2.0/mixin/store.js'
export default {
    name: 'CreditSixSystem',
    mixins: [StoreMix],
    data() {
        return {
            dir: [
                {
                    name: 'credit/system/base',
                    title: '系统设定'
                },
                {
                    name: 'credit/system/announ',
                    title: '平台公告'
                },
                {
                    name: 'credit/system/website',
                    title: '走飞站点设置'
                },
                {
                    name: 'credit/system/backwater',
                    title: '基本退水设定'
                },
                {
                    name: 'credit/system/oddsbase',
                    title: '基本赔率设定'
                },
                {
                    name: 'credit/system/oddsauto',
                    title: '自动赔率设定'
                },
                {
                    name: 'credit/system/improper',
                    title: '自选不中热门'
                },
                {
                    name: 'credit/system/password',
                    title: '变更密码'
                },
                {
                    name: 'credit/system/betcancel',
                    title: '注单取消'
                }
            ],
            routerView: true
        }
    },
    watch: {
        '$store.state.base.router.count'(val) {
            this.ReloadRouter()
        }
    },
    methods: {
        init() {

        },
        ReloadRouter() {
            this.routerView = false
            this.$nextTick(() => {
                this.routerView = true
            })
        }
    },
    activated() {
        if (!this.Opt.isAdmin) {
            this.Rd('credit/game')
        }
    }
}
</script>