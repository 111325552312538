<style lang="scss">
    .GameLayoutCategoryCredit {
        ul {
            overflow:hidden; border-bottom:2px solid #ccc; background:linear-gradient(to bottom,#f5f5f5,#c0c0c0);
            li {
                float:left; padding:.35rem .5rem; cursor:pointer;
                &:hover,&.active {
                    background-color:#fdb65c; background:linear-gradient(to bottom,#ffd8a5,#e78740); color:#e400ff;
                }
            }
        }
        .category {
            position:relative; user-select:none;
            .btn {
                position:absolute; right:14px; top:8px;
                img {width:100px; height:33px;}
            }
        }
    }
</style>
<template>
    <nav v-show="Running.id" class="GameLayoutCategoryCredit">

        {{/* 管理员显示固定的导航 */}}
        <ul v-if="Opt.isTeam" class="category">
            <li v-for="(item,index) in LinkDir" v-if="item.enable" :class="{'active': $route.name.indexOf(item.name) >= 0}" @click="Go(item.name)">{{ item.title }}</li>
        </ul>

        <ul v-else-if="Running.nav" class="category">
            {{/* 会员显示游戏列表 */}}
            <li v-for="(item,index) in Running.nav.list" :class="{'active': $route.name == 'credit/game' && Running.nav.item && Running.nav.item.id === item.id}" @click="ChangeCategory(item)">{{ item.title }}</li>
        </ul>
    </nav>
</template>

<script>
import Common from './category.js'
export default {
    mixins: [Common],
    data() {
        return {
            gameActive: ''
        }
    },
    computed: {
        LinkDir() {
            if (this.User) {
                return [
                    {
                        name: 'credit/runtime',
                        title: '即时注单',
                        enable: this.CheckAuth(true)
                    },
                    {
                        name: 'credit/operate',
                        title: '操盘',
                        enable: this.CheckAuth(this.Opt.operate && this.Opt.operate.lhc && this.Opt.operate.lhc.manipulation, 1)
                    },
                    {
                        name: 'credit/transmit',
                        title: '走飞',
                        enable: this.CheckAuth(this.Opt.operate && this.Opt.operate.lhc && this.Opt.operate.lhc.transmit, 2)
                    },
                    {
                        name: 'credit/user',
                        title: '帐号管理',
                        enable: this.CheckAuth(true, 3)
                    },
                    {
                        name: 'credit/account',
                        title: this.Opt.isAdmin ? '公司' : '子帐号',
                        enable: this.CheckAuth(this.Opt.isTeam || this.Opt.isAdmin, 8)
                    },
                    {
                        name: 'credit/report',
                        title: '报表',
                        enable: this.CheckAuth(true, 6)
                    },
                    {
                        name: 'credit/open',
                        title: '开奖',
                        enable: this.CheckAuth(this.Opt.isAdmin, 7)
                    },
                    {
                        name: 'credit/actlog',
                        title: '操作日志',
                        enable: this.CheckAuth(true)
                    },
                    {
                        name: 'credit/password',
                        title: '修改密码',
                        enable: this.CheckAuth(this.Opt.isAdmin && this.Opt.isSub)
                    },
                    {
                        name: 'credit/system',
                        title: '系统',
                        enable: this.CheckAuth(this.Opt.isAdmin, 9)
                    }
                ]
            }
            return []
        }
    },
    methods: {
        CheckAuth(origin, key) {
            if (!origin) {
                return false
            }
            if (this.Opt.isSub && key) {
                if (!this.Opt.isSub[key]) {
                    return false
                }
            }
            return true
        },
        ChangeCategory(item) {
            if (this.Running.nav && this.Running.nav.loading) {
                return false
            }
            if (this.$route.name != 'credit/game') {
                this.Go('credit/game')
            }
            this.$emit('change', item)
        },
        ChangeGame(code) {
            this.$store.state.base.Event.emit('Credit/ChangeGame', code)
        }
    },
    created() {
        this.gameActive = this.Running.code
    }
}
</script>
