
import Credit from '../../../../mixin/credit.js'
import Category from './components/category'
import Row from './components/row'
import Group from './components/group'

export default {
    mixins: [Credit],
    data() {
        return {
            needReloadOdds: true,
            oddsTimer: null
        }
    },
    props: {
        List: {
            default: []
        },
        DB: {
            default: {}
        }
    },
    watch: {
        'Running.nav.side.id'(val) {
            if (val) {
                this.needReloadOdds = true
            }
        },
        'List'(val) {
            this.OddTimerInit(true)
        }
    },
    computed: {
        prompt() {
            return this.COptions.type == 1 ? ['号码', '赔率', '金额'] : ['号码', '赔率']
        },
        promptInterval() {
            return this.COptions.type == 1 ? [7, 6, 11] : [12, 12]
        }
    },
    methods: {
        OddTimerInit() {
            if (this.needReloadOdds) {
                this.Dp('game/GOODS_CLEAN_ODDS').then(res => {
                    this.needReloadOdds = false
                    this.$nextTick(() => {
                        this.OddTimerRun(true)
                    })
                })
            }
        },
        OddTimerRun(immediately) {
            clearInterval(this.oddsTimer)
            if (immediately) {
                this.$nextTick(() => {
                    this.OddTimerGet()
                })
            }
            this.oddsTimer = setInterval(this.OddTimerGet, 60000)
        },
        OddTimerGet() {
            //console.log('其他玩法加载赔率',111)
            if (this.$route.name === 'credit/game' || this.$route.name === 'index/pages/table') {
                if (this.Running.expect.now && this.Running.expect.now.id) {
                    if (this.Running.nav && this.Running.nav.item && this.DB) {
                        let title = this.Running.nav.item.title
                        let belong = null
                        // switch(title) {
                        //     case '自选不中':
                        //         let active = this.ToMiss.active
                        //         belong = ['五不中','六不中','七不中','八不中','九不中','十不中','十一不中','十二不中'][active]
                        //         break;
                        //     case '正码特':
                        //         belong = this.ToSpecial.active
                        //         break;
                        // }
                        let ids = []
                        for (let id in this.DB) {
                            if (!belong || this.DB[id].belong == belong) {
                                ids.push(id)
                            }
                        }
                        if (ids.length) {
                            // console.log('其他玩法加载赔率')
                            this.Dp('game/GOODS_UPDATE_ODDS', {ids, lottery_id: this.Running.expect.now.id, games_id: this.Running.id})
                        }
                    }
                }
            }
        }

    },
    components: {
        Category, Row, Group
    },
    beforeDestroy() {
        clearInterval(this.oddsTimer)
    }
}